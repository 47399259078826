import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ScreenBreakpoints } from "enums";
import { useWindowSize } from "react-use";
import { TreatmentConditionsStoryblok } from "types/component-types-sb";

export const TreatmentConditions = ({
  blok,
}: Props<TreatmentConditionsStoryblok>) => {
  const { width } = useWindowSize();

  const isScreenXLorLarger = width >= ScreenBreakpoints.XL;
  const isScreenLGorLarger = width >= ScreenBreakpoints.LG;
  const isScreenMDorLarger = width >= ScreenBreakpoints.MD;

  const getNumberOfColumns = () => {
    if (isScreenXLorLarger) return 4;
    if (isScreenLGorLarger) return 3;
    if (isScreenMDorLarger) return 2;

    return 1;
  };

  const numberOfColumns = getNumberOfColumns();

  return (
    <div
      {...storyblokEditable(blok)}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-2 my-4 gap-x-[30px] gap-y-[30px] md:my-16 md:mx-4 lg:mx-6 xl:mx-[45px]"
    >
      {blok.treatments?.map((treatment, index) => {
        const rowNumber = Math.floor(index / numberOfColumns);
        const columnNumber = index % numberOfColumns;

        const isBgDark = (rowNumber + columnNumber) % 2 === 0;

        return (
          <div key={treatment._uid}>
            <StoryblokComponent
              blok={treatment}
              hasDarkerBackground={isBgDark}
            />
          </div>
        );
      })}
    </div>
  );
};
