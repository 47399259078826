import { FooterStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ChevronSvg } from "assets/icons/ChevronSvg";
import { useToggle } from "react-use";
import { cn } from "utils";

export const Footer = ({ blok }: Props<FooterStoryblok>) => {
  const [isExpandedText, toggleIsExpandedText] = useToggle(false);

  return (
    <div {...storyblokEditable(blok)} className="text-primary py-7">
      {/* Mobile - 768 */}
      <div className="block lg:hidden">
        {/* Message */}
        <div className="bg-secondary rounded-[30px] py-[23px] px-5">
          {blok.notify?.map((notify) => (
            <div
              key={notify._uid}
              className="-mb-2 md:-mb-3 xl:-mb-4 2xl:-mb-5"
            >
              <StoryblokComponent blok={notify} />
            </div>
          ))}
        </div>

        {/* Contact number */}
        <div className="grid grid-cols-2 mt-[30px]">
          <div className="col-span-1 flex justify-start items-center">
            <span>
              Call{" "}
              <a href={`tel:${blok.contact_number}`}>
                <strong>{blok.contact_number}</strong>
              </a>{" "}
              or
            </span>
          </div>
          <div className="col-span-1 flex justify-end items-center">
            {blok.contact_button?.map((button) => (
              <StoryblokComponent key={button._uid} blok={button} />
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div className="block mt-[30px]">
          {blok.buttons?.map((button) => (
            <div key={button._uid} className="mb-[10px]">
              <StoryblokComponent blok={button} />
            </div>
          ))}
        </div>

        {/* Description */}
        <div className="text-12 leading-[24px] mt-[30px]">
          {blok.description}
        </div>
        <div
          className="flex items-center text-sm cursor-pointer mt-2 font-medium"
          onClick={() => toggleIsExpandedText()}
        >
          {isExpandedText ? "Collapse" : "Expand"}
          <span
            className={cn("ml-1 duration-500", {
              "-rotate-180": isExpandedText,
            })}
          >
            <ChevronSvg />
          </span>
        </div>
        <div
          className={cn(
            "text-12 leading-[24px] mt-5 grid",
            isExpandedText
              ? "grid-rows-[1fr] opacity-100"
              : "grid-rows-[0fr] opacity-0"
          )}
        >
          {blok.expandDescription}
        </div>

        {/* Policies */}
        <div className="mt-[30px] grid grid-rows-3 grid-flow-col text-12 font-medium pb-[74px]">
          {blok.established}
          {blok.policies?.map((policy) => {
            policy.level = "";

            return <StoryblokComponent key={policy._uid} blok={policy} />;
          })}
        </div>
      </div>

      {/* Desktop - 768 */}
      <div className="hidden lg:block">
        {/* Message */}
        <div className="bg-secondary rounded-full flex items-center justify-center py-[23px]">
          {blok.notify?.map((notify) => (
            <div
              key={notify._uid}
              className="-mb-2 md:-mb-3 xl:-mb-4 2xl:-mb-5"
            >
              <StoryblokComponent blok={notify} />
            </div>
          ))}
        </div>

        <div className="px-[45px]">
          {/* Logo and Contact number */}
          <div className="grid grid-cols-5 py-10 border-b-[1px]">
            <div className="col-span-1 flex justify-start items-center">
              <img src={blok.footerLogo?.filename} alt={blok.footerLogo?.alt} />
            </div>
            <div className="col-span-4">
              <div className="flex justify-end items-center">
                <span className="flex items-center">
                  Call{" "}
                  <strong className="mx-2">
                    <a
                      href={`tel:${blok.contact_number}`}
                      className="cursor-pointer"
                    >
                      {blok.contact_number}
                    </a>
                  </strong>{" "}
                  or
                  {blok.contact_button?.map((button) => (
                    <div key={button._uid} className="ml-2">
                      <StoryblokComponent blok={button} />
                    </div>
                  ))}
                </span>
              </div>
            </div>
          </div>

          {/* Navigation, Internal land pages & Description */}
          <div className="mt-[93px] grid grid-cols-5">
            <div className="col-span-1">
              {blok.first_column?.map((category) => (
                <div key={category._uid} className="mb-5">
                  <StoryblokComponent blok={category} />
                </div>
              ))}

              {blok.buttons?.map((button) => {
                button.isFullWidth = true;

                return (
                  <div key={button._uid} className="mb-[10px]">
                    <StoryblokComponent blok={button} />
                  </div>
                );
              })}
            </div>

            <div className="col-span-2 pl-16 xl:pl-[141px]">
              {blok.second_column?.map((item) => (
                <div key={item._uid} className="mb-5">
                  <StoryblokComponent blok={item} />
                </div>
              ))}

              <div className="mb-5">
                <span className="text-lg text-primary">Connect with us</span>

                <div className="flex pt-4 gap-5">
                  {blok.connect?.map((item) => (
                    <div
                      key={item._uid}
                      className="flex h-10 w-10 aspect-square"
                    >
                      <a href={item?.href?.url} target={item?.href?.target}>
                        <img
                          src={item?.icon?.filename}
                          alt={item?.text}
                          className="size-full hover:scale-[1.06] transition-all duration-300"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-span-2 pl-16 xl:pl-[141px]">
              <div className="text-15 leading-[30px]">{blok.description}</div>
              <div
                className="flex items-center text-sm cursor-pointer mt-2 font-medium"
                onClick={() => toggleIsExpandedText()}
              >
                {isExpandedText ? "Collapse" : "Expand"}
                <span
                  className={cn("ml-1 duration-500", {
                    "-rotate-180": isExpandedText,
                  })}
                >
                  <ChevronSvg />
                </span>
              </div>
              <div
                className={cn(
                  "text-15 leading-[30px] mt-5 duration-300 grid",
                  isExpandedText
                    ? "grid-rows-[1fr] opacity-100"
                    : "grid-rows-[0fr] opacity-0"
                )}
              >
                {blok.expandDescription}
              </div>
            </div>
          </div>

          {/* Policies */}
          <div className="mt-20 xl:flex xl:gap-1">
            {blok.established}
            <span className="flex gap-1">
              {blok.policies?.map((policy, index) => (
                <span key={policy._uid} className="flex gap-1">
                  <StoryblokComponent blok={policy} />
                  {blok.policies && index !== blok.policies.length - 1 && "|"}
                </span>
              ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
