import { ScreenBreakpoints } from "enums";
import { createBreakpoint } from "react-use";

// @media (min-width: <number>px) { ... }
const useBreakpointInnerHook = createBreakpoint({
  xs: 0,
  sm: ScreenBreakpoints.SM,
  md: ScreenBreakpoints.MD,
  lg: ScreenBreakpoints.LG,
  xl: ScreenBreakpoints.XL,
  xxl: ScreenBreakpoints.XXL,
});

export const useBreakpoint = () => {
  const breakpoint = useBreakpointInnerHook();

  return {
    breakpoint,
    isXs: breakpoint === "xs",
    isSm: breakpoint === "sm",
    isMd: breakpoint === "md",
    isLg: breakpoint === "lg",
    isXl: breakpoint === "xl",
    isXxl: breakpoint === "xxl",
    isMobile: breakpoint === "xs" || breakpoint === "sm",
  };
};
