import { useRef } from "react";

import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import { isNil } from "lodash";
import { useToggle } from "react-use";

type Props<TParams, TResponse> = {
  queryFn: (
    params: TParams,
    cancelToken: CancelTokenSource
  ) => Promise<AxiosResponse<TResponse>>;
};

export const useCancelQuery = <TParams, TResponse>({
  queryFn,
}: Props<TParams, TResponse>) => {
  const cancelTokenRef = useRef<CancelTokenSource>();
  const [isLoading, toggleLoading] = useToggle(false);

  const queryAsync = async (params: Parameters<typeof queryFn>[0]) => {
    if (!isNil(cancelTokenRef.current)) {
      cancelTokenRef.current.cancel();
    }

    try {
      const cancelToken = Axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      toggleLoading(true);
      const { data } = await queryFn(params, cancelToken);

      cancelTokenRef.current = undefined;
      toggleLoading(false);

      return Promise.resolve(data);
    } catch (error) {
      const isAxiosError = Axios.isAxiosError(error);

      if (isAxiosError) {
        toggleLoading(false);
      }

      return Promise.reject(error);
    }
  };

  return { queryAsync, isLoading };
};
