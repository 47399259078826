import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Controller, useForm } from "react-hook-form";
import { LoginFormStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { PasswordField } from "components/forms/PasswordField";
import { TextInput } from "components/forms/TextInput";
import { LoginFormResolver } from "./login.resolver";

export type LoginFormData = {
  email: string;
  password: string;
};

export const LoginForm = ({ blok }: Props<LoginFormStoryblok>) => {
  const { control, handleSubmit } = useForm<LoginFormData>({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(LoginFormResolver),
  });

  const handleOnSubmit = async (data: LoginFormData) => {
    console.log(data);
  };

  return (
    <div
      className="flex flex-col w-full my-10 gap-y-4 md:gap-x-6 md:my-20 lg:gap-x-14 md:flex-row md:px-4 lg:flex-row lg:px-20 xl:px-40"
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col gap-y-2 md:w-7/12">
        <div className="w-full font-medium leading-10 text-center md:w-3/4 text-30 md:text-40 md:leading-48 xl:text-58 xl:leading-61 md:text-left text-primary">
          {blok.formTitle}
        </div>

        <form className="flex flex-col gap-y-2">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                name="email"
                title={blok.emailTitle}
                onChangeValue={(e) => field.onChange(e.target.value)}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordField
                title={blok.passwordTitle}
                placeHolder={blok.passwordPlaceholder}
                onChangeValues={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />

          <button type="button" className="text-center md:text-left">
            <span className="font-normal underline text-15 text-dark-grey">
              {blok.forgotPasswordTitle}
            </span>
          </button>

          <div className="w-full">
            <button
              type="submit"
              onClick={handleSubmit(handleOnSubmit)}
              className="w-full md:w-auto font-semibold cursor-pointer rounded-full bg-primary text-light px-3 h-[50px] text-sm hover:-translate-y-1 duration-200 hover:shadow-lg"
            >
              <span className="px-2">{blok.loginButtonTitle}</span>
            </button>
          </div>
        </form>
      </div>

      <div className="md:w-5/12">
        {blok.sidebar?.map((formSidebar) => (
          <StoryblokComponent key={formSidebar._uid} blok={formSidebar} />
        ))}
      </div>
    </div>
  );
};
