import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import { globalConfig } from "core/config";
import { UserRole } from "enums";
import { first, get } from "lodash";
import { PropsWithChildren } from "react";
import { ExternalRoutes, Routes } from "routes/main.routes";
import { KEY_TACKLIT_ROLES } from "services/auth.service";
import {
  getAttachedPsychologistAuth0,
  redirectTo,
  redirectToPsychologistDetails,
} from "utils";
import { cleanUpStorageAfterLogin } from "utils/storage.util";

const REDIRECT_AFTER_LOGIN_ROUTES = [
  ExternalRoutes.GP_BOOKING,
  `${Routes.OUR_PSYCHOLOGISTS}?invited=true`,
];

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const onRedirectCallback = (
    appState?: AppState | undefined,
    user?: User | undefined
  ) => {
    cleanUpStorageAfterLogin();

    const psychologistId = getAttachedPsychologistAuth0(user);
    const userRole = first(get(user, KEY_TACKLIT_ROLES, []));
    const redirectUrl = appState?.returnTo;

    const shouldRedirectAfterLogin =
      redirectUrl && REDIRECT_AFTER_LOGIN_ROUTES.includes(redirectUrl);

    if (shouldRedirectAfterLogin) {
      redirectTo(redirectUrl);

      return;
    }

    if (psychologistId) {
      redirectToPsychologistDetails(psychologistId);
      return;
    }

    switch (userRole) {
      case UserRole.PATIENT:
        redirectTo(globalConfig.TACKLIT_CLIENT_PORTAL_URL);
        break;
      case UserRole.CLINICIAN:
        redirectTo(globalConfig.TACKLIT_ADMIN_PORTAL_URL);
        break;
      default:
        redirectTo(Routes.OUR_PSYCHOLOGISTS);
        break;
    }
  };

  return (
    <Auth0Provider
      domain={globalConfig.AUTH0_DOMAIN}
      clientId={globalConfig.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: globalConfig.AUTH0_AUDIENCE,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
