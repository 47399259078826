type Props = {};

export const TooltipIconFallbackSvg = (props: Props) => {
  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 37.7067C13.2341 37.7067 7.05662 34.1767 3.67369 28.2933C0.29076 22.4835 0.29076 15.3499 3.67369 9.46658C7.05662 3.65677 13.2341 0.0532179 20 0.0532179C26.6923 0.0532179 32.8698 3.65677 36.2528 9.46658C39.6357 15.3499 39.6357 22.4835 36.2528 28.2933C32.8698 34.1767 26.6923 37.7067 20 37.7067ZM28.3102 15.4235H28.2367C28.9721 14.7616 28.9721 13.6585 28.2367 12.9231C27.5748 12.2612 26.4717 12.2612 25.8098 12.9231L17.6467 21.1598L14.1902 17.7033C13.4548 16.9679 12.3516 16.9679 11.6898 17.7033C10.9543 18.3652 10.9543 19.4683 11.6898 20.1302L16.3964 24.8368C17.0583 25.5723 18.1614 25.5723 18.8969 24.8368L28.3102 15.4235Z"
        fill="#CBAAC3"
      />
      <path
        d="M28.3102 12.9231C28.9721 13.6585 28.9721 14.7616 28.3102 15.4235L18.8969 24.8368C18.1614 25.5723 17.0583 25.5723 16.3964 24.8368L11.6898 20.1302C10.9543 19.4683 10.9543 18.3652 11.6898 17.7033C12.3516 16.9679 13.4548 16.9679 14.1902 17.7033L17.6467 21.1598L25.8098 12.9231C26.4717 12.2612 27.5748 12.2612 28.2367 12.9231H28.3102Z"
        fill="#502334"
      />
    </svg>
  );
};
