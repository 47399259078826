import { defaultTo, first, isEqual } from "lodash";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useToggle } from "react-use";

import { useAuth0 } from "@auth0/auth0-react";
import {
  DEFAULT_HOLDING_TIMEOUT_SECONDS,
  MODAL_TIMEOUT_RESET_HIDE_AT_SECONDS,
  MODAL_TIMEOUT_RESET_REMAINING_SECONDS,
} from "core/booking.constant";
import { ConfirmRadioType } from "enums";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { useState } from "react";
import { useTimeLogStore } from "stores/timelog.store";
import { match } from "ts-pattern";
import {
  calculateRemainingTime,
  cn,
  toMinute,
  toPageAlias,
  toSecond,
} from "utils";
import { reclaimReserveAppointment } from "utils/booking.util";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  getStoredSignupSessionUser,
  removeStoredReserveData,
} from "utils/storage.util";
import { customToast } from "utils/toast.util";
import {
  renderTimeOutWarningModal,
  renderTimeOutModal,
} from "utils/warning.util";
import { v4 as GUID } from "uuid";
import { ReserveTimeOutNoticeBox } from "./ReserveTimeOutNoticeBox";
import { Funding } from "models";

type CountDownClockProps = {
  clockClassNames?: string;
  isClockPlaying?: boolean;
  isVisible?: boolean;
  shouldShowTimeOutModal?: boolean;
  shouldShowTimeOutNoticeBox?: boolean;
  clockDuration?: number;
  clockSize?: number;
  isGPBooking?: boolean;

  onTimeOut?: () => void;
};

export const CountDownClock = ({
  clockClassNames = "",
  isClockPlaying = true,
  isVisible = true,
  shouldShowTimeOutModal = true,
  shouldShowTimeOutNoticeBox = true,
  clockDuration = DEFAULT_HOLDING_TIMEOUT_SECONDS,
  clockSize = 200,
  isGPBooking = false,
  ...props
}: CountDownClockProps) => {
  const { timeLogs, toggleMaxResetReachedForSection } = useTimeLogStore();
  const { loginRedirectSOH } = useLoginNavigate();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, toggleLoading] = useToggle(false);
  const [clockKey, setClockKey] = useState(GUID());
  const [isTimeOut, toggleIsTimeOut] = useToggle(false);
  const [psychologistSlugUrl, setPsychologistSlugUrl] = useState<string>("");

  const [shouldShowTimeOutResetModal, toggleShowTimeOutResetModal] =
    useToggle(false);

  const reserveAppointmentData = getStoredReserveData();

  const appointmentDataCreateAt = first(
    reserveAppointmentData?.reserveAppointment.appointments
  )?.updatedAt;

  const currentBookingPageAlias = toPageAlias();

  const fundingMethod = getStoredFundingMethod();

  const handleOnTimeOut = () => {
    if (props.onTimeOut) {
      props.onTimeOut();
    }

    toggleShowTimeOutResetModal(false);
    toggleIsTimeOut(true);

    const slugUrl: string = defaultTo(
      reserveAppointmentData?.psychologist.slugUrl,
      ""
    );
    setPsychologistSlugUrl(slugUrl);

    removeStoredReserveData();
  };

  const handleOnClockTick = (remainingTime: number) => {
    const isReachedTimeOut =
      0 < remainingTime &&
      remainingTime <= MODAL_TIMEOUT_RESET_REMAINING_SECONDS;

    const currentLog = timeLogs.find((log) =>
      isEqual(log.section, currentBookingPageAlias)
    );
    const isMaxResetReached = !!currentLog?.maxResetReached;

    const shouldShowResetModal = isReachedTimeOut && !isMaxResetReached;

    if (shouldShowResetModal) {
      toggleShowTimeOutResetModal(true);
    }

    const shouldHideTimeOutResetModal =
      shouldShowTimeOutResetModal &&
      isEqual(remainingTime, MODAL_TIMEOUT_RESET_HIDE_AT_SECONDS) &&
      !isAuthenticated;

    if (shouldHideTimeOutResetModal) {
      toggleShowTimeOutResetModal(false);
      toggleMaxResetReachedForSection();
    }
  };

  const handleResetTimerAsync = async () => {
    const reserveId = reserveAppointmentData?.reserveAppointment.reserveId;

    if (!reserveId || !isAuthenticated) {
      customToast.error("Something went wrong. Please try again.");

      return;
    }

    toggleLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();

      await reclaimReserveAppointment(reserveId, accessToken);

      toggleShowTimeOutResetModal(false);
      setClockKey(GUID());
    } catch (err) {
      customToast.error("Failed to reset timer. Please try again later.");
    } finally {
      toggleLoading(false);
    }
  };

  const onResetTimerModalButtonClick = async (
    confirmType: ConfirmRadioType
  ) => {
    await match(confirmType)
      .with(ConfirmRadioType.YES, async () => {
        await handleResetTimerAsync();
      })
      .with(ConfirmRadioType.NO, () => {
        toggleShowTimeOutResetModal(false);
      })
      .exhaustive();

    toggleMaxResetReachedForSection();
  };

  const isExistingClient = isAuthenticated || !isGPBooking;

  const createdAtSource = isExistingClient
    ? appointmentDataCreateAt
    : getStoredSignupSessionUser()?.clientRecord?.createdAt;

  const initialRemainingTime = calculateRemainingTime(createdAtSource);

  return (
    <div>
      <div
        className={cn(clockClassNames, {
          hidden: !isVisible,
        })}
      >
        <div className="flex flex-col gap-y-4">
          <span className="leading-7 text-14 text-primary md:text-18 md:leading-6 xl:text-24 xl:leading-9">
            {isTimeOut ? <>No time remaining</> : <>Time remaining</>}
          </span>

          <span className="mb-5">
            {!isTimeOut &&
              renderClockTimeRemainingNotice(fundingMethod, clockDuration)}
          </span>
        </div>

        <div className="flex justify-center">
          {!isTimeOut && (
            <CountdownCircleTimer
              key={clockKey}
              isPlaying={isClockPlaying}
              duration={clockDuration}
              initialRemainingTime={initialRemainingTime}
              colors={"#502334"}
              size={clockSize}
              onComplete={handleOnTimeOut}
              onUpdate={handleOnClockTick}
            >
              {({ remainingTime }) => (
                <div className="block w-2/3">
                  <div className="flex w-full text-4xl text-primary">
                    <div className="flex flex-[2] justify-center">
                      {toMinute(remainingTime)}
                    </div>
                    <div className="flex flex-[1] justify-center"> : </div>
                    <div className="flex flex-[2] justify-center">
                      {toSecond(remainingTime)}
                    </div>
                  </div>
                  <div className="flex w-full text-sm text-primary">
                    <div className="flex flex-[2] justify-center">mins</div>
                    <div className="flex flex-[1] justify-center"></div>
                    <div className="flex flex-[2] justify-center">secs</div>
                  </div>
                </div>
              )}
            </CountdownCircleTimer>
          )}
        </div>
      </div>

      {isTimeOut && (
        <>
          <ReserveTimeOutNoticeBox isVisible={shouldShowTimeOutNoticeBox} />

          {renderTimeOutModal(
            isAuthenticated,
            isGPBooking,
            shouldShowTimeOutModal,
            psychologistSlugUrl,
            loginRedirectSOH
          )}
        </>
      )}

      {renderTimeOutWarningModal(
        isAuthenticated,
        shouldShowTimeOutResetModal,
        isLoading,
        isGPBooking,
        onResetTimerModalButtonClick
      )}
    </div>
  );
};

const renderClockTimeRemainingNotice = (
  fundingType: Funding | null,
  clockDuration: number
) => {
  const holdingMinutes = clockDuration / 60;

  return match(fundingType)
    .with(Funding.SELF_FUNDED, () => (
      <>
        To confirm this appointment, you need to complete registration within{" "}
        {holdingMinutes} minutes.
      </>
    ))
    .otherwise(() => (
      <>
        To confirm this appointment, you need to complete registration and
        payment within {holdingMinutes} minutes.
      </>
    ));
};
