/* eslint-disable react-hooks/exhaustive-deps */
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEmpty } from "lodash";
import React from "react";
import InfiniteScrollComponent from "react-infinite-scroller";
import { useUnmount, useWindowSize } from "react-use";
import { Props } from "types/core";
import { v4 } from "uuid";

import { PsychologistCardsSkeleton } from "components/our-psychologists/PsychologistCardsSkeleton";
import { DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE } from "core/booking.constant";
import { ScreenBreakpoints } from "enums";
import { CliniciansResponse } from "services/psychologist.service";
import {
  OurPsychologistListStoryblok,
  PsychologistCardItemStoryblok,
} from "types/component-types-sb";
import { cn } from "utils";
import { useBookingStore } from "stores/booking.store";

type CustomProps = Props<OurPsychologistListStoryblok> & {
  cliniciansResponse: CliniciansResponse;
  items: CliniciansResponse["clinicians"];

  isLoading?: boolean;
  onPageChange: (
    page: number
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const OurPsychologistList = ({
  blok,
  cliniciansResponse,
  items = [],
  isLoading = false,
  ...props
}: CustomProps) => {
  const { totalItem, perPage, page } = cliniciansResponse?.paging ?? {};

  const { width } = useWindowSize();
  const { reset: resetBookingStore } = useBookingStore();

  useUnmount(() => {
    resetBookingStore();
  });

  const breakpoints = [
    { breakpoint: ScreenBreakpoints.XXL, offsetBottom: 1400 },
    { breakpoint: ScreenBreakpoints.XL, offsetBottom: 2200 },
    { breakpoint: ScreenBreakpoints.LG, offsetBottom: 2500 },
    { breakpoint: ScreenBreakpoints.MD, offsetBottom: 2600 },
    { breakpoint: ScreenBreakpoints.SM, offsetBottom: 2400 },
    { breakpoint: 0, offsetBottom: 1600 },
  ];

  const threshold = breakpoints.find(
    ({ breakpoint }) => width >= breakpoint
  )?.offsetBottom;

  const hasMore = page * perPage < totalItem;

  const handleClickChangePage = (step: number) => {
    props.onPageChange(step);
  };

  const containerClasses = cn(
    "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full",
    "gap-6 p-6 md:p-8 w-full bg-neutral-100 rounded-xl",
    "max-md:p-0 max-md:bg-transparent"
  );

  if (isEmpty(items) && isLoading) {
    return (
      <div className={containerClasses}>
        {/* <SpinnerLoading /> */}
        {[...new Array(DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE)].map((_, index) => (
          <PsychologistCardsSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center justify-center gap-y-5"
      {...storyblokEditable(blok)}
    >
      <div className={cn("w-full")}>
        <InfiniteScrollComponent
          className={cn(containerClasses)}
          loadMore={(nextPage) => {
            if (!isLoading && hasMore) {
              handleClickChangePage(1);
            }
          }}
          initialLoad={false}
          pageStart={0}
          hasMore={hasMore || isLoading}
          useWindow={true}
          threshold={threshold}
          loader={
            <React.Fragment key={v4()}>
              {[...new Array(DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE)].map(
                (_, index) => (
                  <PsychologistCardsSkeleton key={index} />
                )
              )}
            </React.Fragment>
          }
        >
          {items?.map((psychologist, index) => {
            const cardBlok = {
              component: "psychologistCardItem",
            } as PsychologistCardItemStoryblok;

            return (
              <StoryblokComponent
                key={psychologist._id}
                blok={cardBlok}
                detailFunctionBlok={blok}
                otherProps={{ ...psychologist, index }}
              />
            );
          })}

          {isEmpty(items) && !isLoading && (
            <div className="w-full col-span-1 py-8 text-center sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5 text-primary">
              <h3 className="mb-5 text-xl">
                Can’t find what you’re looking for?
              </h3>
              <div className="text-15">
                Sorry, we’re unable to provide a match. Please adjust your
                criteria or <strong>clear all filters</strong>
              </div>
            </div>
          )}
        </InfiniteScrollComponent>
      </div>

      {/* <Pagination
        page={page}
        perPage={perPage}
        totalItem={totalItem}
        onClickChangePage={handleClickChangePage}
      /> */}
    </div>
  );
};
