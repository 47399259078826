import { AxiosResponse } from "axios";
import {
  ContactUsFormData,
  HubspotObjectTypeId,
  HubspotSubmission,
  HubspotSubmissionDataField,
} from "models";
import { HubspotApiClient } from "./axios.service";
import { globalConfig } from "core/config";

export const submitContactUsToHubspot = async (
  submissionData: ContactUsFormData
): Promise<AxiosResponse> => {
  const hubspotSubmission =
    convertToHubspotContactUsSubmissionData(submissionData);

  const PORTAL_ID = globalConfig.HUBSPOT_PORTAL_ID;
  const FORM_ID = globalConfig.HUBSPOT_CONTACT_US_FORM_ID;

  return HubspotApiClient.post<HubspotSubmission>(
    `/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`,
    hubspotSubmission
  );
};

const convertToHubspotContactUsSubmissionData = (
  submissionData: ContactUsFormData
): HubspotSubmission => {
  const hubspotSubmissionContactUsData: HubspotSubmission = {
    context: {
      pageUri: window.location.hostname + window.location.pathname,
      pageName: "Contact Us - Online Mental Health Services | Someone.health",
    },
    fields: [
      {
        objectTypeId: HubspotObjectTypeId.TICKETS,
        name: "subject",
        value: submissionData.subject
          ? submissionData.subject
          : "Website Enquiry",
      },
      {
        objectTypeId: HubspotObjectTypeId.CONTACTS,
        name: "firstname",
        value: submissionData.firstName,
      },
      {
        objectTypeId: HubspotObjectTypeId.CONTACTS,
        name: "lastname",
        value: submissionData.lastName,
      },
      {
        objectTypeId: HubspotObjectTypeId.CONTACTS,
        name: "email",
        value: submissionData.email,
      },
      {
        objectTypeId: HubspotObjectTypeId.CONTACTS,
        name: "mobilephone",
        value: submissionData.phoneNumber,
      },
      {
        objectTypeId: HubspotObjectTypeId.TICKETS,
        name: "content",
        value: submissionData.comments,
      },
      {
        objectTypeId: HubspotObjectTypeId.CONTACTS,
        name: "receive_soh_updates",
        value: `${submissionData.isReceiveUpdates}`,
      },
    ],
  };

  if (submissionData.preferredContactMethod) {
    hubspotSubmissionContactUsData.fields.push({
      objectTypeId: HubspotObjectTypeId.CONTACTS,
      name: "preferred_contact_method",
      value: submissionData.preferredContactMethod,
    });
  }

  return hubspotSubmissionContactUsData;
};

export const submitGetMatchedToHubspot = async (
  submissionData: HubspotSubmissionDataField[],
  isConfirmedReceiveCommunication: boolean
): Promise<AxiosResponse> => {
  const hasConsent = typeof isConfirmedReceiveCommunication === "boolean";
  const marketingSubscriptionId =
    globalConfig.HUBSPOT_SUBSCRIPTION_MARKETING_ID;

  const hubspotSubmissionFormData: HubspotSubmission = {
    context: {
      pageUri: window.location.hostname + window.location.pathname,
      pageName:
        "Get Matched with the Right Online Psychologist | Someone.health",
    },
    fields: submissionData,
    legalConsentOptions: hasConsent
      ? {
          consent: {
            consentToProcess: true,
            text: "I agree to allow Someone.health to store and process my personal data.",
            communications: [
              {
                value: isConfirmedReceiveCommunication,
                subscriptionTypeId: marketingSubscriptionId,
                text: "I agree to receive additional communications from Someone.health.",
              },
            ],
          },
        }
      : undefined,
  };

  const PORTAL_ID = globalConfig.HUBSPOT_PORTAL_ID;
  const FORM_ID = globalConfig.HUBSPOT_CONTACT_INFORMATION_FORM_ID;

  return HubspotApiClient.post<HubspotSubmission>(
    `/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`,
    hubspotSubmissionFormData
  );
};
