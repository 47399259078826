import { Skeleton } from "antd";

type Props = {};

export const PsychologistDetailSkeleton = ({ ...props }: Props) => {
  return (
    <div className="flex flex-col gap-5 py-5 pb-10 border-b md:px-12 md:py-7">
      {/* Banner */}
      <div className="flex flex-col-reverse items-start justify-between w-full px-10 lg:px-24 py-10 bg-gray-100 lg:items-center rounded-30 lg:flex-row gap-y-7">
        <div className="flex flex-col gap-y-5">
          <Skeleton.Input active />
          <Skeleton.Input active size="large" />
          <div className="flex flex-row gap-3 flex-wrap">
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
            <Skeleton.Input active size="small" />
          </div>
        </div>

        <Skeleton.Image
          active
          style={{ width: "200px", height: "200px", borderRadius: "50%" }}
        />
      </div>

      <div className="flex flex-col-reverse gap-5 lg:flex-row">
        <div className="flex flex-col flex-1 gap-y-5">
          <div className="flex flex-1 flex-row flex-wrap items-center justify-start w-full gap-x-10 gap-y-5">
            <div className="flex flex-row items-center justify-center gap-x-2">
              <Skeleton.Avatar active size="large" />
              <Skeleton.Input
                active
                size="small"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-x-2">
              <Skeleton.Avatar active size="large" />
              <Skeleton.Input
                active
                size="small"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-x-2">
              <Skeleton.Avatar active size="large" />
              <Skeleton.Input
                active
                size="small"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-x-2">
              <Skeleton.Avatar active size="large" />
              <Skeleton.Input
                active
                size="small"
                style={{ borderRadius: "10px" }}
              />
            </div>
          </div>

          {/* Details */}
          <div className="flex flex-col w-full gap-y-2">
            <Skeleton.Input
              active
              size="large"
              style={{ borderRadius: "10px" }}
            />
            <Skeleton.Input
              active
              block
              size="large"
              style={{ height: "10rem", borderRadius: "10px" }}
            />
          </div>
          <div className="flex flex-col w-full gap-y-2">
            <Skeleton.Input
              active
              size="large"
              style={{ borderRadius: "10px" }}
            />
            <Skeleton.Input
              active
              block
              size="large"
              style={{ height: "10rem", borderRadius: "10px" }}
            />
          </div>
          <div className="flex flex-col w-full gap-y-2">
            <Skeleton.Input
              active
              size="large"
              style={{ borderRadius: "10px" }}
            />
            <Skeleton.Input
              active
              block
              size="large"
              style={{ height: "10rem", borderRadius: "10px" }}
            />
          </div>
        </div>

        {/* Functions */}
        <div className="flex flex-col h-fit lg:w-[460px] justify-start items-end gap-y-4">
          <div className="w-2/3">
            <Skeleton.Button active size="large" shape="round" block />
          </div>
          <div className="flex flex-col items-start justify-start w-full py-6 bg-light-grey rounded-3xl px-7 gap-y-5">
            <Skeleton.Input
              active
              size="large"
              style={{ borderRadius: "10px" }}
            />
            <div className="w-full">
              <Skeleton.Button active block style={{ borderRadius: "10px" }} />
            </div>
            <div className="w-full">
              <Skeleton.Button active block style={{ borderRadius: "10px" }} />
            </div>
            <div className="w-full">
              <Skeleton.Button active block style={{ borderRadius: "10px" }} />
            </div>
            <div className="w-full pb-5 border-b border-b-gray-200">
              <Skeleton.Input
                active
                block
                size="large"
                style={{ height: "10rem", borderRadius: "10px" }}
              />
            </div>
            <div className="w-full">
              <Skeleton.Input
                active
                block
                size="large"
                style={{ height: "10rem", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
