import { StoryblokComponent } from "@storyblok/react";
import { TooltipCloseSvg } from "assets/icons/TooltipCloseSvg";
import { WarningSvg } from "assets/icons/WarningSvg";
import {
  DEFAULT_TIMEOUT_CONTENT,
  DEFAULT_TIMEOUT_TITLE,
  DEFAULT_WARNING_BUTTON_CONTENT,
} from "core/booking.constant";
import {
  ButtonHoverStyles,
  ButtonSizes,
  ButtonTypes,
  HeadingLevels,
} from "enums";
import { ReactElement } from "react";
import { ButtonStoryblok, HeadingStoryblok } from "types/component-types-sb";
import { cn, redirectTo } from "utils";
import { v4 as GUID, v4 } from "uuid";

type CustomProps = {
  isVisible: boolean;
  redirectUrl: string;

  heading?: string;
  content?: string[] | JSX.Element[];

  shouldShowButtons?: boolean;
  buttonContent?: string;
  additionButtons?: ReactElement[];

  showCloseButton?: boolean;
  onClose?: () => void;
};

const renderModalHeader = (content: string) => {
  const heading: HeadingStoryblok = {
    _uid: GUID(),
    component: "heading",
    content: content,
    level: HeadingLevels.H4,
    fontWeight: "font-normal",
  };

  return <StoryblokComponent key={heading._uid} blok={heading} />;
};

const renderModalButton = (redirectUrl: string, btnContent: string) => {
  const button: ButtonStoryblok = {
    _uid: GUID(),
    component: "button",
    name: btnContent,
    type: ButtonTypes.SOLID,
    hoverStyle: ButtonHoverStyles.OUTLINED,
    size: ButtonSizes.MEDIUM,
    customStyles: "w-full md:w-fit self-end",
  };

  const handleOnClick = () => {
    redirectTo(redirectUrl);
  };

  return (
    <StoryblokComponent
      key={button._uid}
      blok={button}
      onClick={handleOnClick}
    />
  );
};

export const WarningModal = ({
  isVisible,
  heading = DEFAULT_TIMEOUT_TITLE,
  content = DEFAULT_TIMEOUT_CONTENT,
  redirectUrl,
  shouldShowButtons = true,
  buttonContent = DEFAULT_WARNING_BUTTON_CONTENT,
  additionButtons,
  showCloseButton = false,
  onClose,
}: CustomProps) => {
  if (!isVisible) return <></>;

  const handleCloseModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    onClose?.();
  };

  return (
    <div className="fixed top-0 left-0 z-40 flex items-center justify-center w-screen h-screen bg-opacity-50 bg-dark-grey">
      <div
        className={cn(
          "flex flex-col w-4/5 max-w-[60rem] gap-3 px-5 py-8 overflow-y-scroll shadow-xl opacity-100 lg:flex-row no-scrollbar",
          "bg-light md:w-2/3 rounded-10 md:rounded-30 md:px-14 gap-x-5"
        )}
      >
        <div>
          <WarningSvg />
        </div>

        <div className="relative flex flex-col w-full gap-y-5 text-12 md:text-base">
          {showCloseButton && (
            <span
              className="absolute right-0 -top-16 md:-top-8 lg:top-0 cursor-pointer"
              onClick={handleCloseModal}
            >
              <TooltipCloseSvg />
            </span>
          )}

          {renderModalHeader(heading)}

          {content.map((item) => (
            <span key={v4()}>{item}</span>
          ))}

          <div className="flex flex-col md:flex-row justify-evenly pt-3 gap-2">
            {shouldShowButtons && renderModalButton(redirectUrl, buttonContent)}

            {additionButtons}
          </div>
        </div>
      </div>
    </div>
  );
};
