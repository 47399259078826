import { LinkStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { storyblokEditable } from "@storyblok/react";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { isEmpty } from "lodash";
import { Routes } from "routes/main.routes";
import {
  cn,
  getLinkStyle,
  redirectTo,
  toNoCachedLink,
  toSafeLink,
} from "utils";

export const Link = ({ blok }: Props<LinkStoryblok>) => {
  const linkStyles = cn(getLinkStyle(blok), blok?.fontWeight, blok?.lineHeight);

  const linkTo = toNoCachedLink(blok.href);

  const handleOnClick = () => {
    if (!linkTo) return;

    redirectTo(toSafeLink(linkTo), blok.href.target);
  };

  return (
    <LinkWrapper
      disabled={isEmpty(linkTo)}
      to={toSafeLink(linkTo) || Routes.PLACEHOLDER}
      target={blok?.href?.target}
      className={cn("w-full", cn(linkStyles))}
    >
      <span
        {...storyblokEditable(blok)}
        className={cn({
          "w-full flex flex-row justify-start items-center gap-x-2": Boolean(
            blok?.icon
          ),
        })}
        onClick={handleOnClick}
      >
        {blok.text}

        {blok?.icon && (
          <span>
            <img src={blok?.icon?.filename} alt={blok?.icon.alt} />
          </span>
        )}
      </span>
    </LinkWrapper>
  );
};
