import * as Yup from "yup";

import AwesomeDebounce from "awesome-debounce-promise";
import { isAxiosError } from "axios";
import {
  MEDICARE_NUMBER_REGEX,
  ONLY_DIGITS_REGEX,
  POSTCODE_REGEX,
} from "core/regex.constant";
import { ConfirmRadioType } from "enums";
import { isEmpty, isEqual, toNumber } from "lodash";
import { AddressFormData } from "models";
import { MinifiedSignupUserData, Profile } from "models/client.model";
import { validateMedicareAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import { getSessionStoreData } from "stores/session.store";
import { toExpiryDate, toInvalidMessage, toRequiredMessage } from "utils";
import {
  getAdultBasicUserInformationForBookingForm,
  getChildBasicUserInformationForBookingForm,
} from "utils/booking.util";
import { getStoredSignupSessionUser } from "utils/storage.util";
import { customToast } from "utils/toast.util";

export const getAddressFormValidation = (accessToken: string) => {
  return Yup.object({
    streetAddress: Yup.string()
      .trim()
      .required(toRequiredMessage("Street address")),
    streetLineTwoAddress: Yup.string().optional(),
    city: Yup.string().trim().required(toRequiredMessage("City")),
    state: Yup.string().required(toRequiredMessage("State")),
    postcode: Yup.string()
      .required(toRequiredMessage("Postcode"))
      .matches(POSTCODE_REGEX, "Postcode must have 4 digits"),
  }).test({
    name: "validateAddress",
    message: "Invalid address",
    test: async (formData, context) => {
      const invalidAddress = isInvalidAddressFields(formData);
      const invalidAccessToken =
        Boolean(getSessionStoreData()?.isAuthenticated) && isEmpty(accessToken);

      if (invalidAddress || invalidAccessToken) return false;

      await handleContactPutAsync(formData, accessToken, context);

      return true;
    },
  });
};

const handleContactPutAsync = async (
  formData: AddressFormData,
  accessToken: string,
  context: Yup.TestContext<Yup.AnyObject>
) => {
  // if (isEqual(formData, context.originalValue)) return;

  const isValid = await debouncePutContactAddressAsync(formData, accessToken);

  if (!isValid) {
    context.createError({ message: "Invalid address" });
  }
};

export const getMedicareMySelfFieldsValidation = (
  profile: Profile,
  accessToken: string
) =>
  Yup.object({
    isRegisteredWithMedicareName: Yup.boolean().required(),

    firstName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("First name"))
            : field
      ),
    lastName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Last name"))
            : field
      ),
    dateOfBirth: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Date of birth"))
            : field
      ),

    medicareNumber: Yup.string()
      .required(toRequiredMessage("Medicare card number"))
      .matches(
        MEDICARE_NUMBER_REGEX,
        "Medicare card number must have 10 digits"
      ),
    irnNumber: Yup.string()
      .required(toRequiredMessage("IRN number"))
      .matches(ONLY_DIGITS_REGEX, "Must be only digits"),

    expiryDate: Yup.string().trim().required(toRequiredMessage("Expiry date")),
  }).test({
    name: "isMedicareCardValid",
    message: "Medicare card is not valid",
    test: async (formData, context) => {
      // Write code to compare formData and context, if they are the same, return true
      // if (isEqual(formData, context.originalValue)) return true;

      const invalidMedicareFields = isInvalidAdultMedicareFields(formData);

      if (invalidMedicareFields) {
        return false;
      }

      const result = await debounceValidateAdultMedicareCardAsync(
        formData,
        profile,
        accessToken
      );

      return result;
    },
  });

export const getMedicareSomeOneFieldsValidation = (
  profile: Profile,
  accessToken: string
) =>
  Yup.object({
    bookerName: Yup.string().trim().required(toRequiredMessage("Your name")),
    bookerPhone: Yup.string()
      .trim()
      .required(toRequiredMessage("Your contact")),

    isRegisteredWithMedicareName: Yup.boolean().required(),

    firstName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("First name"))
            : field
      ),
    lastName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Last name"))
            : field
      ),
    dateOfBirth: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Date of birth"))
            : field
      ),

    medicareNumber: Yup.string()
      .required(toRequiredMessage("Medicare card number"))
      .matches(
        MEDICARE_NUMBER_REGEX,
        "Medicare card number must have 10 digits"
      ),
    irnNumber: Yup.string()
      .required(toRequiredMessage("IRN number"))
      .matches(ONLY_DIGITS_REGEX, "Must be only digits"),

    expiryDate: Yup.string().trim().required(toRequiredMessage("Expiry date")),
  }).test({
    name: "isMedicareCardValid",
    message: "Medicare card is not valid",
    test: async (formData) => {
      const invalidMedicareFields = isInvalidAdultMedicareFields(formData);

      if (invalidMedicareFields) {
        return false;
      }

      return await debounceValidateAdultMedicareCardAsync(
        formData,
        profile,
        accessToken
      );
    },
  });

export const getMedicareChildFieldsValidation = (
  profile: Profile,
  accessToken: string
) =>
  Yup.object({
    // Child from 13 to 18
    guardianFirstName: Yup.string()
      .trim()
      .required(toRequiredMessage("Guardian first name")),
    guardianLastName: Yup.string()
      .trim()
      .required(toRequiredMessage("Guardian last name")),
    guardianEmail: Yup.string()
      .email(toInvalidMessage("Guardian email"))
      .required(toRequiredMessage("Guardian email")),

    // Separate card value
    hasChildSeparateCard: Yup.string().required(
      toRequiredMessage("Has child separate card value")
    ),

    // Separate card Yes
    childSeparateNameMatchedCard: Yup.boolean().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field.required()
          : field.optional()
    ),
    childSeparateFirstName: Yup.string()
      .trim()
      .when(
        ["hasChildSeparateCard", "childSeparateNameMatchedCard"],
        ([hasChildSeparateCard, childSeparateNameMatchedCard], field) =>
          hasChildSeparateCard === ConfirmRadioType.YES &&
          !childSeparateNameMatchedCard
            ? field.required(toRequiredMessage("Child first name"))
            : field.optional()
      ),
    childSeparateLastName: Yup.string()
      .trim()
      .when(
        ["hasChildSeparateCard", "childSeparateNameMatchedCard"],
        ([hasChildSeparateCard, childSeparateNameMatchedCard], field) =>
          hasChildSeparateCard === ConfirmRadioType.YES &&
          !childSeparateNameMatchedCard
            ? field.required(toRequiredMessage("Child last name"))
            : field.optional()
      ),
    childSeparateDateOfBirth: Yup.string()
      .trim()
      .when(
        ["hasChildSeparateCard", "childSeparateNameMatchedCard"],
        ([hasChildSeparateCard, childSeparateNameMatchedCard], field) =>
          hasChildSeparateCard === ConfirmRadioType.YES &&
          !childSeparateNameMatchedCard
            ? field.required(toRequiredMessage("Child date of birth"))
            : field.optional()
      ),
    childSeparateMedicareNumber: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field
              .required(toRequiredMessage("Child Medicare card number"))
              .matches(
                MEDICARE_NUMBER_REGEX,
                "Medicare card number must have 10 digits"
              )
          : field.optional()
    ),
    childSeparateIrn: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field
              .required(toRequiredMessage("Child IRN number"))
              .matches(ONLY_DIGITS_REGEX, "Must be only digits")
          : field.optional()
    ),
    childSeparateExpiryDate: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field.required(toRequiredMessage("Child expiry"))
          : field.optional()
      ),

    // Separate card No
    parentFirstName: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent first name"))
          : field.optional()
      ),
    parentLastName: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent last name"))
          : field.optional()
      ),
    parentDateOfBirth: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent date of birth"))
          : field.optional()
      ),
    parentMedicareNumber: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field
              .required(toRequiredMessage("Parent Medicare card number"))
              .matches(
                MEDICARE_NUMBER_REGEX,
                "Medicare card number must have 10 digits"
              )
          : field.optional()
    ),
    parentIrn: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field
              .required(toRequiredMessage("Parent IRN number"))
              .matches(ONLY_DIGITS_REGEX, "Must be only digits")
          : field.optional()
    ),
    parentExpiryDate: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent expiry date"))
          : field.optional()
      ),
    childIrn: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field
              .required(toRequiredMessage("Child IRN number"))
              .matches(ONLY_DIGITS_REGEX, "Must be only digits")
          : field.optional()
    ),
  }).test({
    name: "isMedicareCardValid",
    message: "Medicare card is not valid",
    test: async (formData) => {
      const invalidChildMedicareFields = isInvalidChildMedicareFields(formData);

      if (invalidChildMedicareFields) {
        return false;
      }

      return await debounceValidateChildMedicareCardAsync(
        formData,
        profile,
        accessToken
      );
    },
  });

const isInvalidAddressFields = (formData: AddressFormData): boolean => {
  const isValidStreetAddress = Yup.string()
    .trim()
    .required()
    .isValidSync(formData.streetAddress);

  const isValidCity = Yup.string().trim().required().isValidSync(formData.city);
  const isValidState = Yup.string().required().isValidSync(formData.state);
  const isValidPostcode = Yup.string()
    .matches(POSTCODE_REGEX)
    .required()
    .isValidSync(formData.postcode);

  return [
    isValidStreetAddress,
    isValidCity,
    isValidState,
    isValidPostcode,
  ].some((isValid) => !isValid);
};

const isInvalidAdultMedicareFields = (formData: {
  firstName?: string | undefined;
  lastName?: string | undefined;
  dateOfBirth?: string | undefined;
  isRegisteredWithMedicareName: NonNullable<boolean | undefined>;
  medicareNumber: string;
  irnNumber: string;
  expiryDate: string;
}): boolean => {
  const isValidFirstName = formData.isRegisteredWithMedicareName
    ? true
    : Yup.string().trim().isValidSync(formData.firstName);
  const isValidLastName = formData.isRegisteredWithMedicareName
    ? true
    : Yup.string().trim().isValidSync(formData.lastName);
  const isValidDateOfBirth = formData.isRegisteredWithMedicareName
    ? true
    : Yup.string().trim().isValidSync(formData.dateOfBirth);
  const isValidMedicareNumber = Yup.string()
    .matches(MEDICARE_NUMBER_REGEX)
    .isValidSync(formData.medicareNumber);
  const isValidIrnNumber = Yup.string()
    .matches(ONLY_DIGITS_REGEX)
    .isValidSync(formData.irnNumber);
  const isValidExpiryDate = !isEmpty(formData.expiryDate?.trim());

  return [
    isValidFirstName,
    isValidLastName,
    isValidDateOfBirth,
    isValidMedicareNumber,
    isValidIrnNumber,
    isValidExpiryDate,
  ].some((isValid) => !isValid);
};

const isInvalidChildMedicareFields = (formData: {
  hasChildSeparateCard?: string | undefined;
  childSeparateNameMatchedCard?: boolean | undefined;
  childSeparateFirstName?: string | undefined;
  childSeparateLastName?: string | undefined;
  childSeparateDateOfBirth?: string | undefined;
  childSeparateMedicareNumber?: string | undefined;
  childSeparateIrn?: string | undefined;
  childSeparateExpiryDate?: string | undefined;
  childIrn?: string | undefined;
  parentFirstName?: string | undefined;
  parentLastName?: string | undefined;
  parentDateOfBirth?: string | undefined;
  parentMedicareNumber?: string | undefined;
  parentIrn?: string | undefined;
  parentExpiryDate?: string | undefined;
}): boolean => {
  const {
    hasChildSeparateCard,
    childSeparateNameMatchedCard,
    childSeparateFirstName,
    childSeparateLastName,
    childSeparateDateOfBirth,
    childSeparateMedicareNumber,
    childSeparateIrn,
    childSeparateExpiryDate,
    childIrn,
    parentFirstName,
    parentLastName,
    parentDateOfBirth,
    parentMedicareNumber,
    parentIrn,
    parentExpiryDate,
  } = formData;

  const usingChildCard = isEqual(hasChildSeparateCard, ConfirmRadioType.YES);

  const validFirstName = usingChildCard
    ? childSeparateNameMatchedCard
      ? true
      : Yup.string().trim().required().isValidSync(childSeparateFirstName)
    : Yup.string().trim().required().isValidSync(parentFirstName);

  const validLastName = usingChildCard
    ? childSeparateNameMatchedCard
      ? true
      : Yup.string().trim().required().isValidSync(childSeparateLastName)
    : Yup.string().trim().required().isValidSync(parentLastName);

  const validDateOfBirth = usingChildCard
    ? childSeparateNameMatchedCard
      ? true
      : Yup.string().trim().required().isValidSync(childSeparateDateOfBirth)
    : Yup.string().trim().required().isValidSync(parentDateOfBirth);

  const medicareNumber = usingChildCard
    ? childSeparateMedicareNumber
    : parentMedicareNumber;
  const validMedicareNumber = Yup.string()
    .matches(MEDICARE_NUMBER_REGEX)
    .required()
    .isValidSync(medicareNumber);

  const validIrnNumber = usingChildCard
    ? Yup.string()
        .matches(ONLY_DIGITS_REGEX)
        .required()
        .isValidSync(childSeparateIrn)
    : Yup.string()
        .matches(ONLY_DIGITS_REGEX)
        .required()
        .isValidSync(parentIrn) &&
      Yup.string().matches(ONLY_DIGITS_REGEX).required().isValidSync(childIrn);

  const expiryDate = usingChildCard
    ? childSeparateExpiryDate
    : parentExpiryDate;
  const validExpiryDate = !isEmpty(expiryDate?.trim());

  return [
    validFirstName,
    validLastName,
    validDateOfBirth,
    validMedicareNumber,
    validIrnNumber,
    validExpiryDate,
  ].some((isValid) => !isValid);
};

const putContactAddressAsync = async (
  formData: AddressFormData,
  accessToken: string
) => {
  try {
    const isAuthenticated = getSessionStoreData()?.isAuthenticated;

    const token = isAuthenticated
      ? accessToken
      : getStoredSignupSessionUser()?.authToken;

    await TacklitService.putContactAddressAsync(formData, token);

    return true;
  } catch (err) {
    if (isAxiosError(err)) {
      customToast.error(
        "Something went wrong while saving your address. Please try again."
      );
    }

    return false;
  }
};

const validateChildMedicareCardByTacklitAsync = async (
  formData: {
    hasChildSeparateCard?: string | undefined;
    childSeparateNameMatchedCard?: boolean | undefined;
    childSeparateFirstName?: string | undefined;
    childSeparateLastName?: string | undefined;
    childSeparateDateOfBirth?: string | undefined;
    childSeparateMedicareNumber?: string | undefined;
    childSeparateIrn?: string | undefined;
    childSeparateExpiryDate?: string | undefined;
    childIrn?: string | undefined;
    parentFirstName?: string | undefined;
    parentLastName?: string | undefined;
    parentDateOfBirth?: string | undefined;
    parentMedicareNumber?: string | undefined;
    parentIrn?: string | undefined;
    parentExpiryDate?: string | undefined;
  },
  profile: Profile,
  accessToken: string
) => {
  const userBasicData = getChildBasicUserInformationForBookingForm(
    formData,
    profile,
    accessToken
  );

  try {
    const usingChildCard = isEqual(
      formData.hasChildSeparateCard,
      ConfirmRadioType.YES
    );

    const medicareNumber = usingChildCard
      ? toNumber(formData.childSeparateMedicareNumber)
      : toNumber(formData.parentMedicareNumber);
    const medicareExpiryDate = usingChildCard
      ? toExpiryDate(formData.childSeparateExpiryDate)
      : toExpiryDate(formData.parentExpiryDate);
    const irnNumber = usingChildCard
      ? toNumber(formData.childSeparateIrn)
      : toNumber(formData.childIrn);

    await validateMedicareAsync(
      {
        firstName: userBasicData.firstName,
        lastName: userBasicData.lastName,
        dateOfBirth: userBasicData.dateOfBirth,
        number: medicareNumber,
        expiryDate: medicareExpiryDate,
        irn: irnNumber,
        // parent: usingChildCard
        //   ? undefined
        //   : {
        //       firstName: defaultTo(formData.parentFirstName, ""),
        //       lastName: defaultTo(formData.parentLastName, ""),
        //       irn: toNumber(formData.parentIrn),
        //       dateOfBirth: defaultTo(
        //         toDateOfBirth(formData.parentDateOfBirth),
        //         ""
        //       ),
        //     },
        shouldRejectInvalidDetails: true,
      },
      userBasicData.authToken
    );

    return true;
  } catch (err) {
    if (isAxiosError(err)) {
      customToast.error("Invalid Medicare card. Please check again.");
    }

    return false;
  }
};

const validateAdultMedicareCardByTacklitAsync = async (
  formData: {
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    isRegisteredWithMedicareName: NonNullable<boolean | undefined>;
    medicareNumber: string;
    irnNumber: string;
    expiryDate: string;
  },
  profile: Profile,
  accessToken: string
): Promise<boolean> => {
  const userBasicData: MinifiedSignupUserData =
    getAdultBasicUserInformationForBookingForm(formData, profile, accessToken);

  try {
    await validateMedicareAsync(
      {
        firstName: userBasicData.firstName,
        lastName: userBasicData.lastName,
        dateOfBirth: userBasicData.dateOfBirth,
        number: toNumber(formData.medicareNumber),
        expiryDate: toExpiryDate(formData.expiryDate),
        irn: toNumber(formData.irnNumber),
        shouldRejectInvalidDetails: true,
      },
      userBasicData.authToken
    );

    return true;
  } catch (err) {
    if (isAxiosError(err)) {
      customToast.error("Invalid Medicare card. Please check again.");
    }

    return false;
  }
};

const debouncePutContactAddressAsync = AwesomeDebounce(
  putContactAddressAsync,
  3000
);

const debounceValidateAdultMedicareCardAsync = AwesomeDebounce(
  validateAdultMedicareCardByTacklitAsync,
  3000
);

const debounceValidateChildMedicareCardAsync = AwesomeDebounce(
  validateChildMedicareCardByTacklitAsync,
  3000
);
