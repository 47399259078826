import { TextStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { storyblokEditable } from "@storyblok/react";

import cn from "classnames";
import { StylesByAlignments } from "utils";

const DEFAULT_FONT_SIZE = 14;

export const Text = ({ blok }: Props<TextStoryblok>) => {
  const styles = cn(blok.fontWeight, blok.lineHeight, {
    [StylesByAlignments[blok.align ?? ""]]: Boolean(blok.align),
  });

  const { fontSize = DEFAULT_FONT_SIZE } = blok;

  return (
    <p
      {...storyblokEditable(blok)}
      className={cn(styles)}
      style={{ fontSize: `${fontSize}px` }}
    >
      {blok.text}
    </p>
  );
};
