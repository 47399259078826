import AwesomeDebounce from "awesome-debounce-promise";
import { defaultTo, isEqual, toNumber } from "lodash";
import * as Yup from "yup";

import { MEDICARE_NUMBER_REGEX, ONLY_DIGITS_REGEX } from "core/regex.constant";
import { DVACardType } from "enums";
import { Profile } from "models/client.model";
import { validateMedicareAsync } from "services/psychologist.service";
import { getSessionStoreData } from "stores/session.store";
import { toExpiryDate, toRequiredMessage } from "utils";
import { getUserMedicareBasicInformation } from "utils/booking.util";
import { getAddressFormValidation } from "../shared/shared.resolver";

export const isDVAWhiteCard = (dvaCardType: string): boolean =>
  isEqual(dvaCardType, DVACardType.WHITE);

export const getBookDVAFormResolver = (profile: Profile, accessToken: string) =>
  Yup.object({
    dvaCardNumber: Yup.string()
      .required(toRequiredMessage("DVA card number"))
      .length(8, "DVA number must be 8 characters long"),
    dvaCardType: Yup.string().required(toRequiredMessage("DVA card type")),

    medicareNumber: Yup.string().when("dvaCardType", ([dvaCardType], field) =>
      isDVAWhiteCard(dvaCardType)
        ? field
            .required(toRequiredMessage("Medicare card number"))
            .matches(
              MEDICARE_NUMBER_REGEX,
              "Medicare card number must have 10 digits"
            )
        : field.optional()
    ),
    irnNumber: Yup.string().when("dvaCardType", ([dvaCardType], field) =>
      isDVAWhiteCard(dvaCardType)
        ? field
            .required(toRequiredMessage("IRN number"))
            .matches(ONLY_DIGITS_REGEX, "Must be only digits")
        : field.optional()
    ),
    expiryDate: Yup.string()
      .trim()
      .when("dvaCardType", ([dvaCardType], field) =>
        isDVAWhiteCard(dvaCardType)
          ? field.required(toRequiredMessage("Expiry date"))
          : field.optional()
      ),
  })
    .test({
      name: "isValidMedicareCard",
      message: "Invalid Medicare card number",
      test: async (formData, context) => {
        if (!isDVAWhiteCard(formData.dvaCardType)) return true;

        const invalidMedicareCardFields = isInvalidMedicareCardFields(formData);

        if (invalidMedicareCardFields) return false;

        const isValid = await debounceValidateMedicareCardAsync(
          formData,
          profile,
          accessToken
        );

        if (!isValid) {
          return context.createError({
            path: "dvaCardNumber",
            message: "Error while validating your DVA card",
          });
        }

        return isValid;
      },
    })
    .concat(getAddressFormValidation(accessToken));

const isInvalidMedicareCardFields = (formData: {
  dvaCardNumber?: string | undefined;
  medicareNumber?: string | undefined;
  irnNumber?: string | undefined;
  expiryDate?: string | undefined;
}): boolean => {
  const validDvaCardNumber = Yup.string()
    .required()
    .length(8)
    .isValidSync(formData.dvaCardNumber);

  const validMedicareNumber = Yup.string()
    .required()
    .matches(MEDICARE_NUMBER_REGEX)
    .isValidSync(formData.medicareNumber);

  const validIrnNumber = Yup.string()
    .required()
    .matches(ONLY_DIGITS_REGEX)
    .isValidSync(formData.irnNumber);

  const validExpiryDate = Yup.string()
    .required()
    .isValidSync(formData.expiryDate);

  return [
    validDvaCardNumber,
    validMedicareNumber,
    validIrnNumber,
    validExpiryDate,
  ].some((isValid) => !isValid);
};

const validateMedicareCardByTacklit = async (
  formData: {
    medicareNumber?: string | undefined;
    irnNumber?: string | undefined;
    expiryDate?: string | undefined;
    dvaCardNumber?: string | undefined;
  },
  profile: Profile,
  accessToken: string
): Promise<boolean> => {
  const userMedicareInformation = getUserMedicareBasicInformation(profile);
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  try {
    await validateMedicareAsync(
      {
        firstName: defaultTo(userMedicareInformation?.firstName, ""),
        lastName: defaultTo(userMedicareInformation?.lastName, ""),
        dateOfBirth: userMedicareInformation?.dateOfBirth ?? "",
        number: toNumber(formData.medicareNumber),
        expiryDate: toExpiryDate(formData.expiryDate),
        irn: toNumber(formData.irnNumber),
        dva: formData.dvaCardNumber,
        shouldRejectInvalidDetails: true,
      },
      isAuthenticated ? accessToken : undefined
    );

    return true;
  } catch (err) {
    return false;
  }
};

const debounceValidateMedicareCardAsync = AwesomeDebounce(
  validateMedicareCardByTacklit,
  400
);
