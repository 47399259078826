import { Tooltip } from "antd";
import { InfoCircleSvg } from "assets/icons/InfoCircleSvg";
import { isNil } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { HintStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { TooltipCloseSvg } from "assets/icons/TooltipCloseSvg";
import { TooltipIconFallbackSvg } from "assets/icons/TooltipIconFallbackSvg";
import { renderRichText, storyblokEditable } from "@storyblok/react";

type HintType = "tooltip" | "toast";
const MODE: HintType = "tooltip";

export const Hint = ({ blok }: Props<HintStoryblok>) => {
  const [isTooltipVisible, toggleTooltipVisible] = useState(false);

  const handleClickIcon = () => {
    if (MODE !== ("toast" as HintType)) return;

    toast(
      (t) =>
        getTooltipContent({
          onClose: () => toast.dismiss(t.id),
        }),
      blok?.shouldRetain ? { duration: Infinity } : undefined
    );
  };

  const getTooltipContent = (props?: { onClose: () => void }) => {
    return (
      <div className="flex gap-3.5 justify-between text-primary">
        <div className="flex items-start justify-center max-w-8 pt-1.5 rounded min-w-[32px]">
          {!isNil(blok?.icon) ? (
            <img src={blok.icon?.filename} alt={blok.icon?.alt} />
          ) : (
            <TooltipIconFallbackSvg />
          )}
        </div>

        <div className="flex-1 font-poppins">
          <h2 className="pr-4 mb-1 font-medium text-17 line-clamp-1">
            {blok?.title || "Lorem ipsum"}
          </h2>
          <div
            className="text-sm text-dark-grey"
            dangerouslySetInnerHTML={{
              __html:
                typeof blok?.description !== "string"
                  ? renderRichText(blok?.description)
                  : String(blok?.description),
            }}
          />
        </div>

        <span
          className="absolute right-[16px] hover:cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();

            props?.onClose?.();
          }}
        >
          <TooltipCloseSvg className="hover:cursor-pointer" />
        </span>
      </div>
    );
  };

  return (
    <Tooltip
      title={
        <div className="px-2 py-2">
          {getTooltipContent({ onClose: () => toggleTooltipVisible(false) })}
        </div>
      }
      color="white"
      trigger={"hover"}
      overlayStyle={{ minWidth: "320px", padding: "0 10px" }}
      open={MODE === "tooltip" ? isTooltipVisible : false}
      onOpenChange={toggleTooltipVisible}
      fresh
    >
      <span
        onClick={handleClickIcon}
        className="cursor-pointer"
        {...storyblokEditable(blok)}
      >
        <InfoCircleSvg />
      </span>
    </Tooltip>
  );
};
