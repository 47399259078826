import { StoryblokComponent } from "@storyblok/react";
import { PricingTableRowStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const PricingTableRow = ({ blok }: Props<PricingTableRowStoryblok>) => {
  return (
    <>
      {blok.isHeader && (
        <tr className="border-b-[1px] border-secondary-darker">
          {blok.content?.map((head, index) => (
            <th
              key={`${head._uid}_${index}`}
              className={cn(
                "px-4 py-6 font-medium",
                blok.content && blok.content.length <= 2 ? "lg:min-w-[375px]" : "xl:min-w-[275px] xl:max-w-[375px]"
              )}
            >
              <StoryblokComponent key={head._uid} blok={head} />
            </th>
          ))}
        </tr>
      )}

      {!blok.isHeader && (
        <tr className="border-b-[1px] border-secondary-darker">
          {blok.content?.map((content, index) => (
            <td
              key={`${content._uid}_${index}`}
              className="p-4"
            >
              <StoryblokComponent key={content._uid} blok={content} />
            </td>
          ))}
        </tr>
      )}
    </>
  );
};
