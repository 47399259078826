import { isArray, isNil, lowerCase } from "lodash";
import { ReactElement } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import { SelectionOption } from "models";
import { cn, defaultStyles } from "utils";

type Props = {
  isLoading?: boolean;
  title: string;
  placeHolder: string;
  options: SelectionOption[];
  selectedValues?: SelectionOption[];
  isMultiple?: boolean;
  hintComponent?: ReactElement;
  isShowTitle?: boolean;
  isShowHint?: boolean;
  isRequired?: boolean;

  onChangeSingleSelection?: (option: SelectionOption) => void;
  onChangeMultiSelection?: (options: SelectionOption[]) => void;
};

export const EmptyOption: SelectionOption = {
  label: "",
  value: "",
};

export const SelectionDropdown = ({
  isLoading = false,
  title,
  placeHolder,
  options,
  selectedValues,
  isMultiple = false,
  isShowTitle = false,
  isShowHint = false,
  isRequired = false,
  hintComponent,
  ...props
}: Props) => {
  const isHaveTitleOrHint = isShowTitle || isShowHint;

  const isLabelIncludes = (candidate: SelectionOption, input: string) => {
    return lowerCase(candidate.label).includes(lowerCase(input));
  };

  const handleSelectOptions = (
    options: SingleValue<SelectionOption> | MultiValue<SelectionOption>
  ) => {
    if (isArray(options)) {
      props?.onChangeMultiSelection?.(options as SelectionOption[]);

      return;
    }

    if (isNil(options)) {
      props?.onChangeSingleSelection?.(EmptyOption);

      return;
    }

    props?.onChangeSingleSelection?.(options as SelectionOption);
  };

  return (
    <div className="flex flex-col w-full gap-y-1">
      {isHaveTitleOrHint && (
        <div className="flex flex-row items-center justify-between">
          <div>{isShowTitle && title}</div>
          <div>{isShowHint && hintComponent}</div>
        </div>
      )}

      <div className="scrollbar-3">
        <Select
          isLoading={isLoading}
          isMulti={isMultiple}
          isClearable={!isRequired}
          options={isLoading ? [] : options}
          value={isLoading ? [] : selectedValues}
          onChange={handleSelectOptions}
          styles={defaultStyles}
          formatOptionLabel={(option: SelectionOption) => (
            <div className={cn(option.disabled && "text-neutral bg-none")}>
              {option.label}
            </div>
          )}
          isOptionDisabled={(option: SelectionOption) => !!option.disabled}
          noOptionsMessage={() => "No more options"}
          placeholder={placeHolder}
          filterOption={isLabelIncludes}
        />
      </div>
    </div>
  );
};
