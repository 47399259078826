import { ImageStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { storyblokEditable } from "@storyblok/react";
import { cn } from "utils";

export const Image = ({ blok }: Props<ImageStoryblok>) => {
  return (
    <div
      {...storyblokEditable(blok)}
      style={{
        marginTop: `${blok.marginTop}px`,
        marginBottom: `${blok.marginBottom}px`,
        marginLeft: `${blok.marginLeft}px`,
        marginRight: `${blok.marginRight}px`,
      }}
    >
      <div className="flex flex-col items-center">
        <img
          style={{
            height: `${blok.height}%`,
            width: `${blok.width}%`,
            borderRadius: `${blok.borderRadius}px`,
          }}
          src={blok.file?.filename}
          alt={blok.file?.alt}
        />

        <div
          className={cn(
            "w-full text-12 font-semibold text-primary mt-2 uppercase flex",
            blok.captionAlign
          )}
        >
          {blok.caption}
        </div>
      </div>
    </div>
  );
};
