import { useAuth0 } from "@auth0/auth0-react";
import { getClientRecordAsync } from "services/psychologist.service";
import { useProfileStore } from "stores/profile.store";
import useSWR from "swr";
import { isRoleClinician } from "utils";

export const useSwrMyProfile = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const setProfile = useProfileStore((state) => state.setProfile);
  const resetProfile = useProfileStore((state) => state.reset);

  const fetcher = async () => {
    if (!isAuthenticated) {
      resetProfile();

      return Promise.resolve(null);
    }

    const accessToken = await getAccessTokenSilently();

    const { data } = await getClientRecordAsync(accessToken);

    setProfile(data);

    return data;
  };

  const queryResult = useSWR(["/client-records/me", user?.sub], fetcher, {
    isPaused: () => isRoleClinician(),
    errorRetryCount: 3,
  });

  return queryResult;
};
