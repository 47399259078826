import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  PathValue,
  useWatch,
} from "react-hook-form";
import { AssetStoryblok, HintStoryblok } from "types/component-types-sb";

import { AddressFieldsType } from "models";
import { MinifiedSignupUserData } from "models/client.model";
import { DateInput } from "../DateInput";
import { FilterCheckbox } from "../FilterCheckbox";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { BookMedicareGPMySelfFormData } from "../book-gp/GPBehalfOfMySelfForm";
import { BookMedicareMySelfFormData } from "../book-medicare/BehalfOfMySelfForm";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { AddressFields } from "./AddressFields";

export type MedicareMySelfFieldsType = {
  isRegisteredWithMedicareName: boolean;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  medicareNumber: string;
  irnNumber: string;
  expiryDate: string;
} & AddressFieldsType;

type Props<T extends FieldValues> = {
  control: Control<T>;
  errors: FieldErrors<T>;
  userInformation: MinifiedSignupUserData | null;

  medicareNumberHints?: HintStoryblok[];
  irnHints?: HintStoryblok[];
  expiryDateHints?: HintStoryblok[];
  streetAddressHints?: HintStoryblok[];

  medicareImageAsset?: AssetStoryblok;
  medicareImageAlt?: string;
};

export const MedicareMySelfFields = <
  T extends BookMedicareMySelfFormData | BookMedicareGPMySelfFormData
>({
  control,
  errors,
  userInformation,
  medicareNumberHints,
  irnHints,
  expiryDateHints,
  streetAddressHints,
  medicareImageAsset,
  medicareImageAlt = "medicare-alt",
  ...props
}: Props<T>) => {
  const { isRegisteredWithMedicareName } = useWatch({ control });

  if (!userInformation) return <></>;

  const isNoUseNameInCard = !isRegisteredWithMedicareName;

  return (
    <div className="flex flex-col w-full gap-y-4">
      <Controller
        name={"isRegisteredWithMedicareName" as FieldPath<T>}
        control={control}
        render={({ field }) => (
          <FilterCheckbox
            isChecked={field.value as boolean}
            title="My name registered with Medicare matches the name previously entered"
            onCheck={field.onChange}
          />
        )}
      />

      <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-5">
        {isNoUseNameInCard && (
          <div className="flex flex-col flex-1 gap-y-5">
            <div className="flex flex-row mb-1 gap-x-5">
              <div className="flex flex-col flex-1">
                <Controller
                  name={"firstName" as FieldPath<T>}
                  control={control}
                  defaultValue={
                    userInformation.firstName as PathValue<T, FieldPath<T>>
                  }
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      title="First name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />

                <TextError fieldError={errors.firstName as FieldError} />
              </div>

              <div className="flex flex-col flex-1">
                <Controller
                  name={"lastName" as FieldPath<T>}
                  control={control}
                  defaultValue={
                    userInformation.lastName as PathValue<T, FieldPath<T>>
                  }
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      title="Last name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />

                <TextError fieldError={errors.lastName as FieldError} />
              </div>
            </div>

            <div className="flex flex-row mb-1 gap-x-5">
              <Controller
                name={"dateOfBirth" as FieldPath<T>}
                control={control}
                defaultValue={
                  userInformation.dateOfBirth as PathValue<T, FieldPath<T>>
                }
                render={({ field }) => (
                  <div className="flex flex-col flex-1">
                    <DateInput
                      title="Date of birth"
                      maxDate={new Date()}
                      selectedDate={
                        field.value
                          ? new Date(field.value as string)
                          : undefined
                      }
                      onChangeValue={field.onChange}
                    />

                    <TextError fieldError={errors.dateOfBirth as FieldError} />
                  </div>
                )}
              />
              <div className="flex-1"></div>
            </div>
          </div>
        )}

        {/* MEDICARE CARD IMAGE */}
        <div>
          <img src={medicareImageAsset?.filename} alt={medicareImageAlt} />
        </div>

        <div className="flex flex-col flex-1">
          <Controller
            name={"medicareNumber" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                title="Medicare card number"
                onChangeValue={field.onChange}
                hintElement={getHintFromStoryBlok(medicareNumberHints)}
                numberOnly
              />
            )}
          />

          <TextError fieldError={errors.medicareNumber as FieldError} />
        </div>

        <div className="flex flex-col xl:flex-row items-start justify-center w-full gap-x-5 gap-y-5">
          <Controller
            name={"irnNumber" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-1 lg:min-w-[275px] flex-col w-full">
                <TextInput
                  {...field}
                  title="Your individual reference (IRN)"
                  onChangeValue={field.onChange}
                  hintElement={getHintFromStoryBlok(irnHints)}
                  numberOnly
                />

                <TextError fieldError={errors.irnNumber as FieldError} />
              </div>
            )}
          />

          <Controller
            name={"expiryDate" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-col flex-1 w-full">
                <DateInput
                  type="month"
                  title="Expiry date"
                  selectedDate={
                    field.value ? new Date(field.value as string) : undefined
                  }
                  hintComponent={getHintFromStoryBlok(expiryDateHints)}
                  onChangeValue={field.onChange}
                  showMonthYearPicker
                  minDate={new Date()}
                  isShowHint
                />

                <TextError fieldError={errors.expiryDate as FieldError} />
              </div>
            )}
          />
        </div>

        {/* ADDRESS */}
        <AddressFields<T>
          streetAddressHints={streetAddressHints}
          control={control}
          errors={errors}
        />
      </div>
    </div>
  );
};
