import { useAuth0 } from "@auth0/auth0-react";
import { isEqual, isNil } from "lodash";
import { useMemo } from "react";

import { BookingRuleType } from "enums";
import { useSwrBookingRuleType } from "./useSwrBookingRuleType";
import { useSwrLastAppointment } from "./useSwrLastAppointment";

export const useClientType = () => {
  const { isAuthenticated } = useAuth0();

  const { data: bookingRuleTypeResponse } = useSwrBookingRuleType();
  const { bookingRuleType } = bookingRuleTypeResponse || {};

  const { data: appointmentHistory } = useSwrLastAppointment();
  const { lastAppointment } = appointmentHistory || {};

  const hasBookedAppointment = !isNil(lastAppointment);

  const isExistingClient = useMemo(() => {
    if (!isAuthenticated) return false;

    const isBookingExisting = isEqual(
      bookingRuleType,
      BookingRuleType.EXISTING
    );

    return isBookingExisting && hasBookedAppointment;
  }, [isAuthenticated, bookingRuleType, hasBookedAppointment]);

  const isNewClient = useMemo(() => {
    if (!isAuthenticated) return true;

    const isBookingNew = isEqual(bookingRuleType, BookingRuleType.NEW);

    return isBookingNew || !hasBookedAppointment;
  }, [isAuthenticated, bookingRuleType, hasBookedAppointment]);

  return {
    isExistingClient,
    isNewClient,
  };
};
