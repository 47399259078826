import { BookDVAFormData } from "components/forms/book-dva/BookDVAForm";
import { DefaultConfirmBookingFormData } from "components/forms/book-finalise/ConfirmBookingDefaultForm";
import { SelfFundConfirmBookingFormData } from "components/forms/book-finalise/ConfirmBookingSelfFundForm";
import { BookMedicareGPChildFormData } from "components/forms/book-gp/GPBehalfOfChildForm";
import { BookMedicareGPMySelfFormData } from "components/forms/book-gp/GPBehalfOfMySelfForm";
import { BookMedicareGPSomeOneFormData } from "components/forms/book-gp/GPBehalfOfSomeOneForm";
import { BookMedicareChildFormData } from "components/forms/book-medicare/BehalfOfChildForm";
import { BookMedicareMySelfFormData } from "components/forms/book-medicare/BehalfOfMySelfForm";
import { BookMedicareSomeOneFormData } from "components/forms/book-medicare/BehalfOfSomeOneForm";
import { BookNDISFormData } from "components/forms/book-ndis/BookNDISForm";
import { BookWorkCoverFormData } from "components/forms/book-workcover/BookWorkCoverForm";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_UPLOAD_REFERRAL_ERROR_MESSAGE,
} from "core/booking.constant";
import {
  BookBehalfOfType,
  ConfirmRadioType,
  DVACardType,
  KeyClientContactType,
  ManageRadioType,
  ProvideReferralType,
  StateType,
} from "enums";
import {
  cloneDeep,
  compact,
  defaultTo,
  get,
  isEmpty,
  isEqual,
  last,
  pick,
  set,
} from "lodash";
import { Funding, ReserveAppointmentLocalStorageData } from "models";
import {
  KeyClientContact,
  MinifiedSignupUserData,
  Profile,
} from "models/client.model";
import { Routes } from "routes/main.routes";
import {
  AppointmentHistoryResponse,
  claimReserveAppointmentAsync,
  getAppointmentsHistory,
  getReserveDetailsByIdAsync,
  processReserveWithPaymentAsync,
  ReserveResponse,
} from "services/booking.service";
import {
  PsychologistResponse,
  updateReferralAsync,
  UpdateReferralParams,
  uploadFileAsync,
  UploadFileResponse,
} from "services/psychologist.service";
import { getSessionStoreData } from "stores/session.store";
import { match } from "ts-pattern";
import {
  getAttachedPsychologistAuth0,
  isFilledRequiredFieldsGP,
  redirectTo,
} from "./common.util";
import {
  parseProfileDateOfBirth,
  toDateOfBirth,
  toISODateFormatFromProfileExpiryDate,
} from "./date.util";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  getStoredSignupSessionUser,
  PATH_FIRST_RESERVE_APPOINTMENT_HAS_GP_REFERRAL,
  PATH_FIRST_RESERVE_APPOINTMENT_IS_NDIS_PLAN_MANAGED,
  PATH_FIRST_RESERVE_APPOINTMENT_IS_WORK_COVER_NSW,
  setStoredReserveData,
} from "./storage.util";
import { customToast } from "./toast.util";

const getDefaultAddressFieldValues = (profile: Profile) => {
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  return match(isAuthenticated)
    .with(true, () => ({
      streetAddress: defaultTo(profile.address?.line1, ""),
      streetLineTwoAddress: defaultTo(profile.address?.line2, ""),
      city: defaultTo(profile.address?.suburb, ""),
      state: defaultTo(profile.address?.state as StateType, StateType.NSW),
      postcode: defaultTo(profile.address?.postcode, ""),
    }))
    .with(false, () => ({
      streetAddress: "",
      streetLineTwoAddress: "",
      city: "",
      state: StateType.NSW,
      postcode: "",
    }))
    .exhaustive();
};

const getDefaultMedicareFieldValues = (profile: Profile) => {
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  return match(isAuthenticated)
    .with(true, () => ({
      medicareNumber: isEmpty(profile.medicare)
        ? ""
        : String(profile.medicare?.number),
      irnNumber: isEmpty(profile.medicare) ? "" : String(profile.medicare?.irn),
      expiryDate: isEmpty(profile.medicare)
        ? ""
        : toISODateFormatFromProfileExpiryDate(profile.medicare?.expiryDate),
    }))
    .with(false, () => ({
      medicareNumber: "",
      irnNumber: "",
      expiryDate: "",
    }))
    .exhaustive();
};

const getDefaultBehalfOfChildMedicareFieldValues = (profile: Profile) => {
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  return match(isAuthenticated)
    .with(true, () => {
      const medicareNumber = isEmpty(profile.medicare)
        ? ""
        : String(profile.medicare?.number);
      const irnNumber = isEmpty(profile.medicare)
        ? ""
        : String(profile.medicare?.irn);
      const expiryDate = isEmpty(profile.medicare)
        ? ""
        : toISODateFormatFromProfileExpiryDate(profile.medicare?.expiryDate);

      return {
        childSeparateMedicareNumber: medicareNumber,
        childSeparateIrn: irnNumber,
        childSeparateExpiryDate: expiryDate,
        parentMedicareNumber: medicareNumber,
        parentIrn: irnNumber,
        parentExpiryDate: expiryDate,
      };
    })
    .with(false, () => ({
      childSeparateMedicareNumber: "",
      childSeparateIrn: "",
      childSeparateExpiryDate: "",
      parentMedicareNumber: "",
      parentIrn: "",
      parentExpiryDate: "",
    }))
    .exhaustive();
};

export const getUserMedicareBasicInformation = (
  profile: Profile,
  isBehalfOfChildBooking: boolean = false
): MinifiedSignupUserData | null => {
  const isAuthenticated: boolean = Boolean(
    getSessionStoreData()?.isAuthenticated
  );
  const isDVABooking: boolean = isEqual(getStoredFundingMethod(), Funding.DVA);

  if (!isAuthenticated) {
    return getStoredSignupSessionUser();
  }

  if (isEmpty(profile.medicare) || isDVABooking || isBehalfOfChildBooking) {
    return {
      firstName: defaultTo(profile.firstName, ""),
      lastName: defaultTo(profile.lastName, ""),
      dateOfBirth: defaultTo(parseProfileDateOfBirth(profile.dateOfBirth), ""),
    };
  }

  return {
    firstName: defaultTo(profile.medicare.firstName, ""),
    lastName: defaultTo(profile.medicare.lastName, ""),
    dateOfBirth: defaultTo(profile.medicare.dateOfBirth, ""),
  };
};

export const getBehalfOfMySelfFormDefaultData = (
  profile: Profile
): BookMedicareMySelfFormData => ({
  ...getDefaultAddressFieldValues(profile),
  ...getDefaultMedicareFieldValues(profile),
  ...populateFinaliseBookingFormData(profile),

  isRegisteredWithMedicareName: isMatchedProfileMedicare(profile),
  isHaveGPReferralAndTreatmentPlan: ConfirmRadioType.YES,

  provideReferral: ProvideReferralType.UPLOAD,
  videoAppointmentWithBulkBillGP: ConfirmRadioType.YES,
  gPReferralList: [],
  treatmentPlanList: [],
});

export const getBehalfOfSomeOneFormDefaultData = (
  profile: Profile
): BookMedicareSomeOneFormData => ({
  ...getDefaultAddressFieldValues(profile),
  ...getDefaultMedicareFieldValues(profile),
  ...populateFinaliseBookingFormData(profile),

  bookerName: "",
  bookerPhone: "",

  isRegisteredWithMedicareName: true,
  isHaveGPReferralAndTreatmentPlan: ConfirmRadioType.YES,
  provideReferral: ProvideReferralType.UPLOAD,
  videoAppointmentWithBulkBillGP: ConfirmRadioType.YES,
  gPReferralList: [],
  treatmentPlanList: [],
});

export const getBehalfOfChildFormDefaultData = (
  profile: Profile
): BookMedicareChildFormData => ({
  ...getDefaultAddressFieldValues(profile),
  ...getDefaultBehalfOfChildMedicareFieldValues(profile),
  ...populateFinaliseBookingFormData(profile),

  // Child from 13 to 18
  guardianFirstName: "",
  guardianLastName: "",
  guardianEmail: "",

  // Separate card value
  hasChildSeparateCard: ConfirmRadioType.YES,

  // Separate card Yes
  childSeparateNameMatchedCard: true,

  // Separate card No
  parentFirstName: "",
  parentLastName: "",
  parentDateOfBirth: "",
  childIrn: "",

  isConsentForParent: false,

  isHaveGPReferralAndTreatmentPlan: ConfirmRadioType.YES,
  provideReferral: ProvideReferralType.UPLOAD,
  videoAppointmentWithBulkBillGP: ConfirmRadioType.YES,
  gPReferralList: [],
  treatmentPlanList: [],
});

export const getGPBehalfOfMySelfDefaultData = (
  profile: Profile
): BookMedicareGPMySelfFormData => {
  return {
    ...getDefaultAddressFieldValues(profile),
    ...getDefaultMedicareFieldValues(profile),

    isRegisteredWithMedicareName: isMatchedProfileMedicare(profile),
  };
};

const isMatchedProfileMedicare = (profile: Profile) => {
  const storedUserInfo = getUserMedicareBasicInformation(profile);
  const storedCardInfo = pick(profile.medicare, [
    "firstName",
    "lastName",
    "dateOfBirth",
  ] as (keyof MinifiedSignupUserData)[]);

  if (isEmpty(storedCardInfo)) return true;

  const result = isEqual(storedCardInfo, storedUserInfo);

  return result;
};

export const getGPBehalfOfSomeOneFormDefaultData = (
  profile: Profile
): BookMedicareGPSomeOneFormData => ({
  ...getDefaultAddressFieldValues(profile),
  ...getDefaultMedicareFieldValues(profile),

  bookerName: "",
  bookerPhone: "",

  isRegisteredWithMedicareName: true,
});

export const getGPBehalfOfChildFormDefaultData = (
  profile: Profile
): BookMedicareGPChildFormData => ({
  ...getDefaultAddressFieldValues(profile),
  ...getDefaultBehalfOfChildMedicareFieldValues(profile),

  // Child from 13 to 18
  guardianFirstName: "",
  guardianLastName: "",
  guardianEmail: "",

  // Separate card value
  hasChildSeparateCard: ConfirmRadioType.YES,

  // Separate card Yes
  childSeparateNameMatchedCard: true,

  // Separate card No
  parentFirstName: "",
  parentLastName: "",
  parentDateOfBirth: "",
  childIrn: "",

  isConsentForParent: false,
});

export const getDVADefaultFormData = (profile: Profile): BookDVAFormData => {
  return {
    ...getDefaultAddressFieldValues(profile),
    ...getDefaultMedicareFieldValues(profile),

    dvaCardNumber: "",
    dvaCardType: DVACardType.WHITE,
  };
};

export const getNDISDefaultFormData = (profile: Profile): BookNDISFormData => ({
  ...getDefaultAddressFieldValues(profile),

  behalfOf: BookBehalfOfType.MY_SELF,

  bookerName: "",
  bookerPhone: "",

  parentFirstName: "",
  parentLastName: "",
  parentEmail: "",

  ndisNumber: "",

  plan: ManageRadioType.PLAN,
  uploadedFiles: [],
});

export const getWorkCoverDefaultFormData = (
  profile: Profile
): BookWorkCoverFormData => ({
  ...getDefaultAddressFieldValues(profile),

  behalfOf: BookBehalfOfType.MY_SELF,

  bookerName: "",
  bookerPhone: "",

  workCoverNumber: "",
  managerDetail: "",
  totalApproved: "",
  isClaimInNSW: ConfirmRadioType.YES,

  uploadedFiles: [],
});

export const getAdultBasicUserInformationForBookingForm = (
  formData: {
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    isRegisteredWithMedicareName: NonNullable<boolean | undefined>;
  },
  profile: Profile,
  accessToken: string
): MinifiedSignupUserData => {
  const sourceData = getUserMedicareBasicInformation(profile);
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  return {
    firstName: formData.isRegisteredWithMedicareName
      ? defaultTo(sourceData?.firstName, "")
      : defaultTo(formData.firstName, ""),
    lastName: formData.isRegisteredWithMedicareName
      ? defaultTo(sourceData?.lastName, "")
      : defaultTo(formData.lastName, ""),
    dateOfBirth: formData.isRegisteredWithMedicareName
      ? defaultTo(toDateOfBirth(sourceData?.dateOfBirth), "")
      : defaultTo(toDateOfBirth(formData.dateOfBirth), ""),
    authToken: isAuthenticated ? accessToken : undefined,
  };
};

export const getChildBasicUserInformationForBookingForm = (
  formData: {
    hasChildSeparateCard?: string | undefined;
    childSeparateNameMatchedCard?: boolean | undefined;
    childSeparateFirstName?: string | undefined;
    childSeparateLastName?: string | undefined;
    childSeparateDateOfBirth?: string | undefined;
    parentFirstName?: string | undefined;
    parentLastName?: string | undefined;
    parentDateOfBirth?: string | undefined;
  },
  profile: Profile,
  accessToken: string
): MinifiedSignupUserData => {
  const {
    hasChildSeparateCard,
    childSeparateNameMatchedCard,
    childSeparateFirstName,
    childSeparateLastName,
    childSeparateDateOfBirth,
  } = formData;

  const usingChildCard = isEqual(hasChildSeparateCard, ConfirmRadioType.YES);
  const isBehalfOfChildBooking = true;

  const sourceData = getUserMedicareBasicInformation(
    profile,
    isBehalfOfChildBooking
  );
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  return {
    firstName: usingChildCard
      ? childSeparateNameMatchedCard
        ? defaultTo(sourceData?.firstName, "")
        : defaultTo(childSeparateFirstName, "")
      : defaultTo(sourceData?.firstName, ""),
    lastName: usingChildCard
      ? childSeparateNameMatchedCard
        ? defaultTo(sourceData?.lastName, "")
        : defaultTo(childSeparateLastName, "")
      : defaultTo(sourceData?.lastName, ""),
    dateOfBirth: usingChildCard
      ? childSeparateNameMatchedCard
        ? defaultTo(toDateOfBirth(sourceData?.dateOfBirth), "")
        : defaultTo(toDateOfBirth(childSeparateDateOfBirth), "")
      : defaultTo(toDateOfBirth(sourceData?.dateOfBirth), ""),
    authToken: isAuthenticated ? accessToken : undefined,
  };
};

export const getLastEmergencyContact = (
  profile: Profile
): KeyClientContact | undefined => {
  const keyContacts = profile.keyClientContacts?.filter((contact) =>
    contact.tags?.includes(KeyClientContactType.EMERGENCY)
  );

  return last(keyContacts);
};

const getLastPersonBookingContact = (
  profile: Profile
): KeyClientContact | undefined =>
  last(
    profile.keyClientContacts?.filter((contact) =>
      contact.tags?.includes(KeyClientContactType.ADULT)
    )
  );

export const populateFinaliseBookingFormData = (
  profile: Profile
): DefaultConfirmBookingFormData => {
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  return match(isAuthenticated)
    .with(true, () => {
      const emergencyContact = getLastEmergencyContact(profile);

      const name = defaultTo(emergencyContact?.firstName, "");
      const phone = defaultTo(emergencyContact?.mobileNumber, "");

      return {
        emergencyContactName: name,
        emergencyContactPhoneNumber: phone,
      };
    })
    .with(false, () => ({
      emergencyContactName: "",
      emergencyContactPhoneNumber: "",
    }))
    .exhaustive();
};

export const getDefaultFinaliseBookingSelfFundFormData = (
  profile: Profile
): SelfFundConfirmBookingFormData => {
  const isAuthenticated = Boolean(getSessionStoreData()?.isAuthenticated);

  const unChangeFieldData = {
    ...getDefaultAddressFieldValues(profile),

    isAgreeCancellationPolicy: false,
    parentFirstName: "",
    parentLastName: "",
    parentEmail: "",
  };

  return match(isAuthenticated)
    .with(true, () => {
      const emergencyContact = getLastEmergencyContact(profile);
      const name = defaultTo(emergencyContact?.firstName, "");
      const phone = defaultTo(emergencyContact?.mobileNumber, "");

      const personBooking = getLastPersonBookingContact(profile);
      const personBookingName = defaultTo(personBooking?.firstName, "");
      const personBookingPhone = defaultTo(personBooking?.mobileNumber, "");

      return {
        ...unChangeFieldData,

        onBehalfOf: isEmpty(personBooking)
          ? BookBehalfOfType.MY_SELF
          : BookBehalfOfType.SOMEONE_ELSE_ADULT,
        emergencyContactName: name,
        emergencyContactPhoneNumber: phone,
        bookerName: personBookingName,
        bookerPhone: personBookingPhone,
      };
    })
    .with(false, () => ({
      ...unChangeFieldData,

      onBehalfOf: BookBehalfOfType.MY_SELF,
      emergencyContactName: "",
      emergencyContactPhoneNumber: "",
      bookerName: "",
      bookerPhone: "",
    }))
    .exhaustive();
};

export const reclaimReserveAppointment = async (
  reserveId: string,
  accessToken?: string
) => {
  await claimReserveAppointmentAsync(reserveId, accessToken);

  const updatedReserveData = await getReserveDetailsByIdAsync(reserveId);

  const oldReserveData = cloneDeep(getStoredReserveData());

  const newReserveData = getCloneCustomReserveAppointmentData({
    oldReserveData: oldReserveData,
    updatedReserveData: updatedReserveData.data,
  });

  setStoredReserveData(newReserveData);
};

export const getCloneCustomReserveAppointmentData = (params: {
  oldReserveData: ReserveAppointmentLocalStorageData | null;
  updatedReserveData: ReserveResponse;
}): ReserveAppointmentLocalStorageData => {
  const { oldReserveData, updatedReserveData } = params;

  const newReserveData = {
    ...oldReserveData,
    reserveAppointment: updatedReserveData,
  };

  // Medicare - GP Referral
  const hasGpReferral = get(
    oldReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_HAS_GP_REFERRAL,
    false
  );

  set(
    newReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_HAS_GP_REFERRAL,
    hasGpReferral
  );

  // NDIS - Plan Managed
  const isNdisPlanManaged = get(
    oldReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_IS_NDIS_PLAN_MANAGED,
    false
  );

  set(
    newReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_IS_NDIS_PLAN_MANAGED,
    isNdisPlanManaged
  );

  // WorkCover NSW
  const isWorkCoverNsw = get(
    oldReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_IS_WORK_COVER_NSW,
    false
  );

  set(
    newReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_IS_WORK_COVER_NSW,
    isWorkCoverNsw
  );

  return newReserveData as ReserveAppointmentLocalStorageData;
};

export const processMedicareBooking = async (
  profile: Profile | null | undefined,
  lastGpAppointment: AppointmentHistoryResponse | undefined,
  reserveId: string,
  accessToken: string
) => {
  if (!profile) return;

  const isCompletedGPBooking =
    lastGpAppointment && isFilledRequiredFieldsGP(profile);

  // Skipping Booking Confirm page because of required fields are filled in GP Booking flow
  if (isCompletedGPBooking) {
    await processReserveAsync({
      hasSavedCard: Boolean(profile.hasSavedCard),
      reserveId: reserveId,
      accessToken: accessToken,
    });

    return;
  }

  const { medicare, address, referral } = profile;
  const lastEmergencyContact = getLastEmergencyContact(profile);

  const isMissingRequiredFields = [
    medicare,
    address,
    referral,
    lastEmergencyContact,
  ].some(isEmpty);

  if (isMissingRequiredFields) {
    redirectTo(Routes.BOOKING_MEDICARE);

    return;
  }

  await processReserveAsync({
    hasSavedCard: Boolean(profile.hasSavedCard),
    reserveId: reserveId,
    accessToken: accessToken,
  });
};

export const processDVABooking = async (
  profile: Profile | null | undefined,
  reserveId: string,
  accessToken: string
) => {
  if (!profile) return;

  const { medicare, address } = profile;

  if ([medicare, medicare?.dva, address].some(isEmpty)) {
    redirectTo(Routes.BOOKING_DVA);

    return;
  }

  const lastEmergencyContact = getLastEmergencyContact(profile);

  if (isEmpty(lastEmergencyContact)) {
    redirectTo(Routes.BOOKING_CONFIRM);

    return;
  }

  await processReserveAsync({
    hasSavedCard: Boolean(profile.hasSavedCard),
    reserveId: reserveId,
    accessToken: accessToken,
  });
};

export const processWorkCoverBooking = async (
  profile: Profile | null | undefined,
  lastAppointment: AppointmentHistoryResponse | null | undefined,
  reserveId: string,
  accessToken: string
) => {
  if (!profile) return;

  if (isEmpty(lastAppointment)) {
    redirectTo(Routes.BOOKING_WORKCOVER);

    return;
  }

  await processReserveAsync({
    hasSavedCard: Boolean(profile.hasSavedCard),
    reserveId: reserveId,
    accessToken: accessToken,
  });
};

export const processNDISBooking = async (
  profile: Profile | null | undefined,
  lastAppointment: AppointmentHistoryResponse | null | undefined,
  reserveId: string,
  accessToken: string
) => {
  if (!profile) return;

  if (isEmpty(lastAppointment)) {
    redirectTo(Routes.BOOKING_NDIS);

    return;
  }

  await processReserveAsync({
    hasSavedCard: Boolean(profile.hasSavedCard),
    reserveId: reserveId,
    accessToken: accessToken,
  });
};

export const processSelfFundBooking = async (
  profile: Profile | null | undefined,
  lastGpAppointment: AppointmentHistoryResponse | undefined,
  reserveId: string,
  accessToken: string
) => {
  if (!profile) return;

  const { hasSavedCard, address } = profile;
  const lastEmergencyContact = getLastEmergencyContact(profile);

  const isCompletedGPBooking =
    lastGpAppointment && isFilledRequiredFieldsGP(profile);

  // Skipping Booking Confirm page because of required fields are filled in GP Booking flow
  if (isCompletedGPBooking) {
    await processReserveAsync({
      hasSavedCard: Boolean(hasSavedCard),
      reserveId: reserveId,
      accessToken: accessToken,
    });

    return;
  }

  const isMissingRequiredFields = [address, lastEmergencyContact].some(isEmpty);

  if (isMissingRequiredFields) {
    redirectTo(Routes.BOOKING_CONFIRM);

    return;
  }

  await processReserveAsync({
    hasSavedCard: Boolean(hasSavedCard),
    reserveId: reserveId,
    accessToken: accessToken,
  });
};

const processReserveAsync = async (params: {
  hasSavedCard: boolean;
  reserveId: string;
  accessToken: string;
}) => {
  if (params.hasSavedCard) {
    await processReserveWithPaymentAsync(params.reserveId, params.accessToken);

    const isFirstPsychologistAppt: boolean =
      await isClientFirstPsychologistAppointment(params.accessToken);

    if (isFirstPsychologistAppt) {
      redirectTo(Routes.BOOKING_THANK_YOU);

      return;
    }

    redirectTo(Routes.BOOKING_THANK_YOU_CONFIRMED);
  } else {
    const { data } = await processReserveWithPaymentAsync(
      params.reserveId,
      params.accessToken
    );

    if (data?.checkoutUrl) {
      redirectTo(data.checkoutUrl);
    }
  }
};

export const isEmergencyContactExisted = (params: {
  profile: Profile | null | undefined;
  firstName: string | undefined;
  mobileNumber: string | undefined;
}): boolean => {
  const { profile, firstName, mobileNumber } = params;

  if (!profile || isEmpty(profile.keyClientContacts)) return false;

  const existedEmergencyContacts = profile.keyClientContacts?.find(
    (contact) =>
      isEqual(contact.firstName, firstName) &&
      isEqual(contact.mobileNumber, mobileNumber) &&
      contact.tags?.includes(KeyClientContactType.EMERGENCY)
  );

  return Boolean(existedEmergencyContacts);
};

export const isClientFirstPsychologistAppointment = async (
  accessToken: string | undefined
) => {
  if (!Boolean(accessToken)) return true;

  try {
    const { data: appointmentHistory } = await getAppointmentsHistory(
      accessToken
    );

    return isEqual(
      appointmentHistory.filter((appt) => !Boolean(appt?.isGP)).length,
      1
    );
  } catch {
    return true;
  }
};

export const filterOpenToNewClients = ({
  clinicians,
  attachedPsyId,
}: {
  clinicians: PsychologistResponse[];
  attachedPsyId: string | undefined;
}): PsychologistResponse[] => {
  if (isEmpty(clinicians)) return [];

  const activeClinicians = clinicians?.filter(({ caseLoad, _id }) => {
    const attachedPsychologistId = defaultTo(
      attachedPsyId,
      getAttachedPsychologistAuth0()
    );

    if (attachedPsychologistId === _id) return true;

    return caseLoad?.isOpenToNewClients !== false;
  });

  const hiddenClinicians = clinicians?.filter(
    ({ caseLoad }) => caseLoad?.isOpenToNewClients === false
  );

  if (!isEmpty(hiddenClinicians)) {
    console.log("🚀 ~ hiddenClinicians:", hiddenClinicians);
  }

  return activeClinicians;
};

export const handleMedicareUpdateReferralAsync = async (params: {
  formData:
    | BookMedicareMySelfFormData
    | BookMedicareSomeOneFormData
    | BookMedicareChildFormData;
  isExistingClient?: boolean;
  accessToken: string;
}): Promise<boolean> => {
  const { formData, isExistingClient = false, accessToken } = params || {};

  const isNoReferral = isEqual(
    formData.isHaveGPReferralAndTreatmentPlan,
    ConfirmRadioType.NO
  );

  try {
    if (isNoReferral) {
      const isUploaded = await handleUploadNoReferralAsync(accessToken);

      return isUploaded;
    }

    const {
      gPReferralList = [],
      treatmentPlanList = [],
      provideReferral,
    } = formData || {};

    const awaitingReferrals =
      provideReferral === ProvideReferralType.UPLOAD
        ? [...gPReferralList, ...treatmentPlanList]
        : [];

    const uploadedFileResponse = await Promise.all(
      awaitingReferrals.map((fileItem) =>
        handleUploadFileAsync(fileItem.file, accessToken)
      )
    );

    const files = compact(uploadedFileResponse);

    const payload: UpdateReferralParams = {
      files: files,
      isReferredByGP: true,
      isHaveTreatmentPlan: true,
      isGPSentReferral: provideReferral === ProvideReferralType.SENT_TO,
      addToReferralGroup: !isExistingClient,
    };

    await updateReferralAsync(payload, accessToken);

    !isEmpty(files) && customToast.success("Upload the referral successfully");

    return true;
  } catch (err) {
    console.log("[error]: >>", err);

    const errorMessage = isNoReferral
      ? DEFAULT_ERROR_MESSAGE
      : DEFAULT_UPLOAD_REFERRAL_ERROR_MESSAGE;

    customToast.error(errorMessage);

    return false;
  }
};

export const handleUploadNoReferralAsync = async (
  accessToken: string,
  isExistingClient?: boolean
): Promise<boolean> => {
  try {
    const payload: UpdateReferralParams = {
      files: [],
      isReferredByGP: false,
      isHaveTreatmentPlan: false,
      isGPSentReferral: false,
      addToReferralGroup: !Boolean(isExistingClient),
    };

    await updateReferralAsync(payload, accessToken);

    return true;
  } catch (err) {
    console.log("[error]: >>", err);

    customToast.error(DEFAULT_ERROR_MESSAGE);

    return false;
  }
};

export const handleNdisOrWorkCoverUpdateReferralAsync = async (params: {
  formData: BookNDISFormData | BookWorkCoverFormData;
  isExistingClient?: boolean;
  accessToken: string | undefined;
}) => {
  const { formData, isExistingClient = false, accessToken } = params || {};
  const { uploadedFiles } = formData || {};

  let files: UploadFileResponse[] = [];

  try {
    if (uploadedFiles && !isEmpty(uploadedFiles)) {
      const uploadedFileResponse = await Promise.all(
        uploadedFiles.map((fileItem) =>
          handleUploadFileAsync(fileItem.file, accessToken)
        )
      );

      files = compact(uploadedFileResponse);
    }

    await updateReferralAsync(
      {
        files: files,
        isReferredByGP: false,
        isHaveTreatmentPlan: false,
        isGPSentReferral: false,
        addToReferralGroup: !isExistingClient,
      },
      accessToken
    );

    !isEmpty(files) && customToast.success("Upload documents successfully");

    return true;
  } catch (err) {
    console.log("[error]: >>", err);

    customToast.error("Error while updating documents");

    return false;
  }
};

export const handleUploadFileAsync = async (
  uploadedFile: File,
  accessToken: string | undefined
) => {
  try {
    const { data } = await uploadFileAsync({ file: uploadedFile }, accessToken);

    return data;
  } catch (err) {
    console.log("[error]: >>", err);

    return Promise.reject(err);
  }
};
