import { get, isEmpty, isEqual } from "lodash";
import {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import OtpInput from "react-otp-input";
import { useInterval, useToggle } from "react-use";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { LinkWrapper } from "components/shared/LinkWrapper";
import {
  SUPPORT_CENTER_URL,
  RESEND_INTERVAL_OTP_SECONDS,
  MSG_INVITED_PHONE_ERROR,
  MSG_INVITED_EMAIL_ERROR,
  SIGNUP_INVITED_OTP_LENGTH,
} from "core/booking.constant";
import {
  AlertType,
  CommunicationPreferenceType,
  OTPMessageResponse,
} from "enums";
import { Routes } from "routes/main.routes";
import {
  checkEmailMobileNumberInviteAsync,
  CheckEmailMobileNumberInvitePayload,
  postPatientSignUpWithInvitationAsync,
  SignUpWithInvitePayload,
} from "services/booking.service";
import { toTacklitPhoneNumberFormat } from "services/signup.service";
import { cn, getDefaultButtonStyles, toClientDateOfBirthFormat } from "utils";
import { AlertText } from "../shared/AlertText";
import { SignupFormData } from "./SignupForm";

type Props = {};

export type OTPVerifyModalPayload = {
  formData: SignupFormData;
};

export type OTPVerifyModalRef = {
  show: (payload: OTPVerifyModalPayload) => void;
};

export const OTPVerifyModal = forwardRef(
  ({ ...props }: Props, ref: React.Ref<unknown>): ReactElement => {
    const { loginWithRedirect } = useAuth0();

    useImperativeHandle(ref, () => ({ show }));

    const dialogRef = useRef<HTMLDialogElement>(null);

    const [signUpData, setSignUpData] = useState<SignupFormData>();
    const [otp, setOtp] = useState<string>("");

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");

    const [secondsRemaining, setOtpSecondsRemaining] = useState<number>(
      RESEND_INTERVAL_OTP_SECONDS
    );

    const [isLoading, toggleIsLoading] = useToggle(false);

    const isResendDisabled = secondsRemaining > 0;
    const isVerifyDisabled =
      isLoading || isEmpty(otp) || otp.length < SIGNUP_INVITED_OTP_LENGTH;

    useInterval(() => {
      if (secondsRemaining <= 0) return;

      setOtpSecondsRemaining((timeRemaining) => timeRemaining - 1);
    }, 1000);

    const show = (payload: OTPVerifyModalPayload) => {
      setSignUpData(payload.formData);

      clearAlertMessages();

      setOtpSecondsRemaining(RESEND_INTERVAL_OTP_SECONDS);

      const dialogElement = dialogRef.current;

      if (!dialogElement) return;

      dialogElement.showModal();
    };

    const clearAlertMessages = () => {
      setErrorMessage("");
      setSuccessMessage("");
    };

    const handleChangeOtp = (otp: string) => {
      setOtp(otp);

      clearAlertMessages();
    };

    const handleResendOTPCode = async () => {
      if (!signUpData) return;

      try {
        clearAlertMessages();

        const payload: CheckEmailMobileNumberInvitePayload = {
          email: signUpData.email,
          mobileNumber: toTacklitPhoneNumberFormat(signUpData.phone),
          shouldSendOtp: true,
        };

        const { data } = await checkEmailMobileNumberInviteAsync(payload);
        const { used, invited, emailInvited } = data || {};

        if (used) {
          setErrorMessage("Email address is already used.");

          return;
        }

        if (!invited) {
          setErrorMessage(
            emailInvited ? MSG_INVITED_PHONE_ERROR : MSG_INVITED_EMAIL_ERROR
          );

          return;
        }

        setOtp("");
        setSuccessMessage("OTP code sent successfully.");
        setOtpSecondsRemaining(RESEND_INTERVAL_OTP_SECONDS);
      } catch (err) {
        console.log("[error]: >>", err);

        setErrorMessage("Failed to send OTP code. Please try again.");
      }
    };

    const handleVerifyOTP = async () => {
      if (!signUpData || isEmpty(otp)) return;

      try {
        toggleIsLoading(true);

        clearAlertMessages();

        const communicationPreference = signUpData.isRegistered
          ? CommunicationPreferenceType.NO_PREFERENCE
          : CommunicationPreferenceType.EMAIL;

        const payload: SignUpWithInvitePayload = {
          data: {
            firstName: signUpData.firstName,
            lastName: signUpData.lastName,
            email: signUpData.email,
            mobileNumber: toTacklitPhoneNumberFormat(signUpData.phone),
            dateOfBirth: toClientDateOfBirthFormat(signUpData.dob),
            password: signUpData.password,
            communicationPreference: communicationPreference,
          },
          smsOtp: otp,
        };

        await postPatientSignUpWithInvitationAsync(payload);

        loginWithRedirect({
          appState: { returnTo: `${Routes.OUR_PSYCHOLOGISTS}?invited=true` },
        });
      } catch (err) {
        console.log("[error]: >>", err);

        const msg = get(err, "response.data.message");

        if (isEqual(msg, OTPMessageResponse.INVALID)) {
          setErrorMessage("Invalid SMS OTP code. Please try again.");
        } else if (isEqual(msg, OTPMessageResponse.EXPIRED)) {
          setErrorMessage(
            "SMS OTP code has expired. Please resend a new code."
          );
        } else {
          setErrorMessage(
            msg || "Failed to verify OTP code. Please try again."
          );
        }
      } finally {
        toggleIsLoading(false);
      }
    };

    return (
      <dialog ref={dialogRef} className="daisy-modal">
        <div className="daisy-modal-box py-8">
          <p className="pb-4 text-base">Hi {signUpData?.firstName},</p>
          <p>Please enter your verification code:</p>

          <OtpInput
            containerStyle="w-full py-5 flex justify-center items-center gap-x-1.5 sm:gap-x-3 gap-y-2"
            inputStyle={cn(
              "otpInputStyle",
              "!w-[2.5rem] h-[2.5rem] sm:!w-[3.5rem] sm:h-[3.5rem]"
            )}
            value={otp}
            onChange={handleChangeOtp}
            numInputs={SIGNUP_INVITED_OTP_LENGTH}
            shouldAutoFocus
            renderInput={(props) => <input {...props} placeholder="-" />}
          />

          <p>This code will only be valid for the next 5 minutes.</p>

          <button
            disabled={isResendDisabled}
            className={`${
              isResendDisabled ? "cursor-not-allowed" : "cursor-pointer"
            } py-2 underline text-primary`}
            onClick={handleResendOTPCode}
          >
            Send me another code
          </button>

          <p>
            {isResendDisabled
              ? `You can resend a code in ${secondsRemaining} seconds`
              : "New code available"}
          </p>

          <div className="flex justify-start items-center">
            <button
              disabled={isVerifyDisabled}
              className={cn(getDefaultButtonStyles(), "my-4")}
              onClick={handleVerifyOTP}
            >
              Verify Mobile
            </button>

            {isLoading && <SpinnerLoading className="w-12 h-12 ml-3" />}
          </div>

          <p>
            Not receiving a code via SMS? Please{" "}
            <LinkWrapper to={SUPPORT_CENTER_URL} target="_blank">
              <u className="cursor-pointer text-primary">contact us</u>
            </LinkWrapper>{" "}
            so we can help
          </p>

          {successMessage && (
            <AlertText
              type={AlertType.SUCCESS}
              message={successMessage}
              classes="mt-5"
            />
          )}

          {errorMessage && (
            <AlertText
              type={AlertType.ERROR}
              message={errorMessage}
              classes="mt-5"
            />
          )}
        </div>
      </dialog>
    );
  }
);
