import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { BlogSearchForm } from "components/forms/blog/BlogForm";
import { Pagination } from "components/pagination/Pagination";
import { Banner } from "components/stats/Banner";
import { BLOG_DEFAULT_ITEM_PER_PAGE } from "core/booking.constant";
import { first, isEmpty } from "lodash";
import { BlogSearchFormData } from "models";
import { useRef, useState } from "react";
import { useEffectOnce, useSearchParam, useToggle } from "react-use";
import {
  SearchStoriesDataParams,
  Story,
  searchBlogsData,
} from "services/storyblok.service";
import {
  BannerStoryblok,
  BlogSectionStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";
import { removeUrlSearchParams } from "utils";
import { renderArticleBanner, renderBlogItem } from "utils/blog.util";
import { customToast } from "utils/toast.util";

export const BlogSection = ({ blok }: Props<BlogSectionStoryblok>) => {
  const categoryUrlParam = useSearchParam("category");
  const [blogData, setBlogData] = useState<Story[]>([]);
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(1);
  const [isSearching, toggleIsSearching] = useToggle(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCategories, setSearchCategories] = useState<string[]>([]);
  const [banner, setBanner] = useState<BannerStoryblok>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const perPage = Number(
    isEmpty(blok.itemsPerPage) ? BLOG_DEFAULT_ITEM_PER_PAGE : blok.itemsPerPage
  );

  const handleSearchForm = (searchData: BlogSearchFormData) => {
    setSearchTerm(searchData.keywordOrTag || "");
    setSearchCategories(searchData.category || []);
    setPage(1);

    searchBlogItems({
      skip: perPage * (page - 1),
      searchTerm: searchData.keywordOrTag,
      categories: searchData.category,
    });

    removeUrlSearchParams();
  };

  const handleClickChangePage = (step: number) => {
    setPage(page + step);

    searchBlogItems({
      skip: perPage * (page + step - 1),
      searchTerm: searchTerm,
      categories: searchCategories,
    });

    setTimeout(() => {
      wrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  };

  async function searchBlogItems(params: SearchStoriesDataParams) {
    toggleIsSearching(true);

    try {
      const searchParams: SearchStoriesDataParams = {
        startWith: "blog/",
        searchTerm: params.searchTerm,
        excludingSlugs: "blog/",
        categories: params.categories,
        take: perPage,
        skip: params.skip,
      };

      const data = await searchBlogsData(searchParams);

      setBlogData(data.responses);
      setTotalItem(data.totalItem);

      const renderedBanner = renderArticleBanner(first(data.responses));

      if (renderedBanner) setBanner(renderedBanner);
    } catch (err) {
      customToast.error("Failed to get blog items, please try again later");

      console.log("err :>> ", err);
    } finally {
      toggleIsSearching(false);
    }
  }

  useEffectOnce(() => {
    searchBlogItems({
      skip: 0,
      categories: categoryUrlParam ? [categoryUrlParam] : [],
    });
  });

  return (
    <div {...storyblokEditable(blok)} ref={wrapperRef}>
      {/* BANNER */}
      {banner === undefined ? (
        <div className="flex h-96 justify-center items-center">
          <SpinnerLoading />
        </div>
      ) : (
        <div>
          <Banner blok={banner} isArticleBanner />
        </div>
      )}

      {/* SEARCH FORM */}
      <BlogSearchForm
        onFormSubmit={handleSearchForm}
        searchedCategories={categoryUrlParam ? [categoryUrlParam] : []}
      />

      {/* BLOG ITEMS */}
      {blogData && !isSearching && (
        <div className="grid grid-cols-1 gap-4 px-4 py-8 md:gap-6 xl:gap-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {blogData?.map((blogItem) => {
            const renderedItem = renderBlogItem(blogItem);

            if (!renderedItem) {
              return <></>;
            }

            return (
              <StoryblokComponent
                key={blogItem.uuid}
                blok={renderedItem}
                fullSlug={blogItem.full_slug}
              />
            );
          })}
        </div>
      )}

      {/* NO BLOG ITEMS FOUND */}
      {isEmpty(blogData) && !isSearching && (
        <div className="w-full bg-neutral-100 rounded-10 lg:rounded-30 col-span-1 py-20 px-3 text-center sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5 text-primary">
          <h3 className="mb-5 text-xl">Can’t find what you’re looking for?</h3>
          <div className="text-15">
            Sorry, we’re unable to provide a match. Please adjust your criteria
            or <strong>clear all filters</strong>
          </div>
        </div>
      )}

      {isSearching && (
        <div className="flex justify-center py-40">
          <SpinnerLoading />
        </div>
      )}

      {!isEmpty(blogData) && (
        <Pagination
          page={page}
          perPage={perPage}
          totalItem={totalItem}
          onClickChangePage={handleClickChangePage}
        />
      )}
    </div>
  );
};
