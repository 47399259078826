import { ReactElement } from "react";
import { match } from "ts-pattern";

import { AlertType } from "enums";
import { cn } from "utils";
import { AlertSuccessSvg } from "assets/icons/AlertSuccessSvg";
import { AlertErrorSvg } from "assets/icons/AlertErrorSvg";
import { AlertInfoSvg } from "assets/icons/AlertInfoSvg";

type Props = {
  type?: AlertType;
  message: string;
  classes?: string;
};

export const AlertText = ({
  type = AlertType.INFO,
  message,
  classes,
}: Props) => {
  const getAlertClasses = (type: AlertType): string => {
    return match(type)
      .with(AlertType.SUCCESS, () =>
        cn("daisy-alert daisy-alert-success bg-highlight-green", classes)
      )
      .with(AlertType.ERROR, () =>
        cn("daisy-alert daisy-alert-error bg-red-50", classes)
      )
      .otherwise(() => cn("daisy-alert daisy-alert-info bg-pink-50", classes));
  };

  const getAlertIcon = (type: AlertType): ReactElement => {
    return match(type)
      .with(AlertType.SUCCESS, () => <AlertSuccessSvg />)
      .with(AlertType.ERROR, () => <AlertErrorSvg />)
      .otherwise(() => <AlertInfoSvg />);
  };

  return (
    <div role="alert" className={cn("flex flex-row", getAlertClasses(type))}>
      {getAlertIcon(type)}
      <span className="text-left text-balance text-dark-grey text-sm md:text-center">
        {message}
      </span>
    </div>
  );
};
