import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { defaultTo, isEqual, toNumber } from "lodash";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useToggle } from "react-use";
import { BookGpFormStoryblok } from "types/component-types-sb";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { ConfirmRadioType } from "enums";
import { Profile } from "models/client.model";
import { Routes } from "routes/main.routes";
import { savePaymentDetailAsync } from "services/booking.service";
import { validateMedicareAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import {
  getDefaultButtonStyles,
  openInNewTab,
  redirectTo,
  toDateOfBirth,
  toExpiryDate,
} from "utils";
import {
  getChildBasicUserInformationForBookingForm,
  getGPBehalfOfChildFormDefaultData,
  getUserMedicareBasicInformation,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import { FilterCheckbox } from "../FilterCheckbox";
import {
  MedicareChildFields,
  MedicareChildFieldsType,
} from "../shared/MedicareChildFields";
import { getBookGPChildFormResolver } from "./book-gp.resolver";

export type BookMedicareGPChildFormData = {
  isConsentForParent: boolean;
} & MedicareChildFieldsType;

type Props = {
  bookGPFormBlok: BookGpFormStoryblok;
  profile: Profile;
  accessToken: string;
};

export const GPBehalfOfChildForm = ({
  bookGPFormBlok,
  profile,
  accessToken,
}: Props) => {
  const [isValidating, toggleValidating] = useToggle(false);

  const {
    control,
    formState: { errors, isValid: isFormValid },
    handleSubmit,
  } = useForm<BookMedicareGPChildFormData>({
    mode: "onChange",
    defaultValues: getGPBehalfOfChildFormDefaultData(profile),
    resolver: yupResolver(getBookGPChildFormResolver(profile, accessToken)),
  });

  const { isConsentForParent } = useWatch({ control });

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  if (!userMedicareInformation) return;

  const isDisabledSubmit = isValidating || !isConsentForParent || !isFormValid;

  const validateMedicareCardAsync = async (
    formData: BookMedicareGPChildFormData
  ): Promise<boolean> => {
    try {
      const userBasicData = getChildBasicUserInformationForBookingForm(
        formData,
        profile,
        accessToken
      );

      const usingChildCard = isEqual(
        formData.hasChildSeparateCard,
        ConfirmRadioType.YES
      );

      const medicareNumber = usingChildCard
        ? toNumber(formData.childSeparateMedicareNumber)
        : toNumber(formData.parentMedicareNumber);
      const medicareExpiryDate = usingChildCard
        ? toExpiryDate(formData.childSeparateExpiryDate)
        : toExpiryDate(formData.parentExpiryDate);
      const irnNumber = usingChildCard
        ? toNumber(formData.childSeparateIrn)
        : toNumber(formData.childIrn);

      await validateMedicareAsync(
        {
          firstName: userBasicData.firstName,
          lastName: userBasicData.lastName,
          dateOfBirth: userBasicData.dateOfBirth,
          number: medicareNumber,
          expiryDate: medicareExpiryDate,
          irn: irnNumber,
          parent: usingChildCard
            ? undefined
            : {
                firstName: defaultTo(formData.parentFirstName, ""),
                lastName: defaultTo(formData.parentLastName, ""),
                irn: toNumber(formData.parentIrn),
                dateOfBirth: defaultTo(
                  toDateOfBirth(formData.parentDateOfBirth),
                  ""
                ),
              },
          shouldRejectInvalidDetails: true,
        },
        userBasicData.authToken
      );

      customToast.success("Valid Medicare card");

      return true;
    } catch (err) {
      console.log("[error]: >>", err);

      customToast.error("Invalid Medicare card");

      return false;
    }
  };

  const onSubmit: SubmitHandler<BookMedicareGPChildFormData> = async (
    formData
  ) => {
    toggleValidating(true);

    try {
      // Put contact address and guardian profile
      await Promise.all([
        TacklitService.putContactAddressAsync(formData, accessToken),

        TacklitService.putChildGuardianProfileAsync(
          {
            parentFirstName: formData.guardianFirstName,
            parentLastName: formData.guardianLastName,
            parentEmail: formData.guardianEmail,
          },
          accessToken
        ),
      ]);

      const isValidMedicareCheck = await validateMedicareCardAsync(formData);

      if (!isValidMedicareCheck) return;

      // Update payment card details
      const { data: checkoutUrl } = await savePaymentDetailAsync(accessToken);

      if (!checkoutUrl) {
        customToast.error(
          "Failed when processing payment. Please try again later."
        );

        return;
      }

      redirectTo(checkoutUrl);
    } catch (err) {
      console.log("[error]: >>", err);
    } finally {
      toggleValidating(false);
    }
  };

  const handleClickConsentAgreement = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();

    openInNewTab(Routes.CONSENT_AGREEMENT);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col flex-1 gap-y-5"
    >
      <div className="flex flex-col w-full gap-y-4">
        {/* MEDICARE GP CHILD */}
        <MedicareChildFields<BookMedicareGPChildFormData>
          control={control}
          errors={errors}
          userInformation={userMedicareInformation}
          medicareNumberHints={bookGPFormBlok.medicareNumberHints}
          irnHints={bookGPFormBlok.irnHints}
          expiryDateHints={bookGPFormBlok.expiryDateHints}
          streetAddressHints={bookGPFormBlok.streetAddressHints}
          medicareImageAsset={bookGPFormBlok.medicareImage}
          medicareImageAlt={bookGPFormBlok.medicareImageAlt}
        />

        {/* GP Summary */}
        {bookGPFormBlok?.gpSummaries?.map((gpSummaryItem) => (
          <StoryblokComponent key={gpSummaryItem._uid} blok={gpSummaryItem} />
        ))}

        <Controller
          name="isConsentForParent"
          control={control}
          render={({ field }) => (
            <div className="flex flex-row items-center justify-start">
              <FilterCheckbox
                isChecked={isConsentForParent || false}
                title={
                  <div className="static lg:flex flex-row items-center justify-start lg:min-w-[345px]">
                    <span>I have read and agree to the</span>
                    <span
                      onClick={handleClickConsentAgreement}
                      className="ml-1 text-sm underline cursor-pointer"
                    >
                      consent agreement
                    </span>
                  </div>
                }
                onCheck={field.onChange}
              />
            </div>
          )}
        />
      </div>

      <div className="flex items-center justify-start">
        <button
          type="submit"
          disabled={isDisabledSubmit}
          className={getDefaultButtonStyles()}
        >
          Proceed to payment details
        </button>
        {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
      </div>
    </form>
  );
};
