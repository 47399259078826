import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { AboutReferenceContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const AboutReferenceContainer = ({
  blok,
}: Props<AboutReferenceContainerStoryblok>) => {
  return (
    <div
      className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6 xl:gap-8"
      {...storyblokEditable(blok)}
    >
      {blok.items?.map((item) => (
        <StoryblokComponent key={item._uid} blok={item} />
      ))}
    </div>
  );
};
