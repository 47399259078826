import { useAuth0 } from "@auth0/auth0-react";

import { ProfileMenuDropdown } from "components/header/ProfileMenuDropdown";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { HeaderNavItemLoginStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { UserSvg } from "assets/icons/UserSvg";
import { cn } from "utils";
import { useWindowSize } from "react-use";
import { ScreenBreakpoints } from "enums";

type NavItemLoginProps = Props<HeaderNavItemLoginStoryblok> & {
  onSmallScreenAvatarClick: () => void;
};

export const NavItemLogin = ({
  blok,
  onSmallScreenAvatarClick,
}: NavItemLoginProps) => {
  const { isAuthenticated } = useAuth0();
  const { loginRedirectSOH } = useLoginNavigate();

  const { width: windowWidth } = useWindowSize();

  const handleRedirectLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    loginRedirectSOH();
  };

  const isSmallScreen = windowWidth <= ScreenBreakpoints.XL;

  return isAuthenticated ? (
    <ProfileMenuDropdown
      isSmallScreen={isSmallScreen}
      onSmallScreenAvatarClick={onSmallScreenAvatarClick}
    />
  ) : (
    <button
      type="button"
      className={cn(
        "flex gap-x-2 items-center text-sm text-primary px-[13px] h-[30px]",
        "hover:bg-secondary rounded-full duration-300"
      )}
      onClick={handleRedirectLogin}
    >
      <span>
        <UserSvg />
      </span>
      <span>{blok?.text ?? "Login"}</span>
    </button>
  );
};
