import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { questionStepFieldName } from "models";
import { Controller, useFormContext } from "react-hook-form";
import { QuestionStepStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn, stepperStyles } from "utils";

export type CustomProps = Props<QuestionStepStoryblok> & {
  name: string;
  isDisplay: boolean;
  onChangeStep: (change: number) => void;
};

export const QuestionStep = ({ blok, name, isDisplay }: CustomProps) => {
  const fieldName = `${name}.${questionStepFieldName}`;
  const { control } = useFormContext();

  return (
    <div
      className={cn(
        isDisplay
          ? cn(stepperStyles, "py-8 max-md:px-0 lg:gap-8 xl:px-12 lg:pb-6")
          : "hidden"
      )}
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col gap-2">
        {blok.question?.map((question) => (
          <StoryblokComponent key={question._uid} blok={question} />
        ))}
      </div>

      <div className="p-4 md:p-8 bg-light-grey rounded-2xl max-w-[650px] min-h-[400px] w-full">
        {blok.answer?.map((answer) => (
          <Controller
            key={answer._uid}
            name={fieldName}
            control={control}
            defaultValue=""
            render={() => (
              <StoryblokComponent
                key={answer._uid}
                blok={answer}
                name={fieldName}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};
