import { defaultTo } from "lodash";

export const SCROLL_OFFSET = 350;
export const NUM_SEGMENTS = 10;

export const DOT_RADIUS = 6;
export const DOT_OFFSET = 5;

export const updateDotPosition = (
  path: SVGGeometryElement | null,
  startPoint: SVGCircleElement | null,
  ...dots: (SVGCircleElement | null)[]
) => {
  if (!path || !dots?.length) return;

  const pathLength = path.getTotalLength();
  const pathRect = path.getBoundingClientRect();

  if (
    document.documentElement.scrollHeight - window.innerHeight < 0 ||
    pathRect.top > SCROLL_OFFSET
  ) {
    dots?.forEach((dot) => {
      dot?.setAttribute(
        "cx",
        defaultTo(String(startPoint?.cx.baseVal.value), "0")
      );
      dot?.setAttribute(
        "cy",
        defaultTo(String(startPoint?.cy.baseVal.value), "0")
      );
    });

    return;
  }

  let scrollPercentage =
    Math.abs(pathRect.top - window.screenY - SCROLL_OFFSET) / pathRect.height;
  const targetLength = scrollPercentage * pathLength;

  if (scrollPercentage > 1) {
    dots?.forEach((dot) => {
      const point = path.getPointAtLength(pathLength);

      dot?.setAttribute("cx", String(point.x));
      dot?.setAttribute("cy", String(point.y + DOT_RADIUS));
    });

    return;
  }

  for (let i = 0; i < NUM_SEGMENTS; i++) {
    const segmentLength = pathLength / NUM_SEGMENTS;
    const startLength = i * segmentLength;
    const endLength = (i + 1) * segmentLength;

    if (targetLength >= startLength && targetLength <= endLength) {
      const segmentScrollPercentage =
        (targetLength - startLength) / segmentLength;

      dots?.forEach((dot, index) => {
        const point = path.getPointAtLength(
          startLength +
            segmentScrollPercentage * segmentLength -
            index * DOT_OFFSET
        );

        dot?.setAttribute("cx", String(point.x));
        dot?.setAttribute("cy", String(point.y));
      });

      break;
    }
  }
};
