import { useAuth0 } from "@auth0/auth0-react";
import { UserRole } from "enums";
import { cloneDeep, first, get, isEmpty, isEqual, set } from "lodash";
import { PropsWithChildren, useEffect } from "react";
import { useMount } from "react-use";
import {
  KEY_TACKLIT_ATTACHED_CLINICIAN,
  KEY_TACKLIT_ROLES,
  refetchRoleAuth0Async,
} from "services/auth.service";
import { getAttachedClinicianAsync } from "services/psychologist.service";
import { getSessionStoreData, useSessionStore } from "stores/session.store";
import {
  getAttachedPsychologistAuth0,
  getAttachedRoleAuth0,
  rolesAndAttachedClinicianIdExisted,
} from "utils";
import { customToast } from "utils/toast.util";

export const UserDataUpdater = ({ children }: PropsWithChildren) => {
  const session = useAuth0();
  const setSession = useSessionStore((state) => state.setSession);

  useEffect(() => {
    if (!session) return;

    if (!Boolean(session.isAuthenticated)) {
      setSession(session);

      return;
    }

    const storedUserDataAuth0 = getSessionStoreData()?.user;

    if (Boolean(session.isAuthenticated) && !isEmpty(storedUserDataAuth0)) {
      return;
    }

    setSession(session);
  }, [session, setSession]);

  useMount(async () => {
    if (!session.isAuthenticated) return;

    const clonedUserSession = cloneDeep(session?.user);

    if (isEmpty(clonedUserSession)) return;

    const clonedRoles = first(
      get(clonedUserSession, KEY_TACKLIT_ROLES, []) as string[]
    );
    const clonedAttachedClinician = first(
      get(clonedUserSession, KEY_TACKLIT_ATTACHED_CLINICIAN, []) as string[]
    );

    const existedStoredRoles = getAttachedRoleAuth0();

    if (isEqual(existedStoredRoles, UserRole.CLINICIAN)) return;

    if (!isEmpty(existedStoredRoles)) {
      set(clonedUserSession, KEY_TACKLIT_ROLES, [existedStoredRoles]);
    }

    const existedStoredAttachedClinician = getAttachedPsychologistAuth0();

    if (!isEmpty(existedStoredAttachedClinician)) {
      set(clonedUserSession, KEY_TACKLIT_ATTACHED_CLINICIAN, [
        existedStoredAttachedClinician,
      ]);
    }

    const dataExisted = [
      rolesAndAttachedClinicianIdExisted(clonedRoles, clonedAttachedClinician),
      rolesAndAttachedClinicianIdExisted(
        existedStoredRoles,
        existedStoredAttachedClinician
      ),
    ].some(Boolean);

    if (dataExisted) return;

    try {
      const accessToken = await session.getAccessTokenSilently();

      const updatedRoles = await refetchRoleAuth0Async(accessToken);

      const updatedAttachedClinician = isEqual(updatedRoles, UserRole.CLINICIAN)
        ? null
        : await getAttachedClinicianAsync(accessToken);
      const updatedAttachedClinicianId =
        updatedAttachedClinician?.data?.clinician?._id;

      const invalidOrSameRole =
        isEmpty(updatedRoles) || isEqual(existedStoredRoles, updatedRoles);
      const invalidOrSameAttachedPsychologistId =
        isEmpty(updatedAttachedClinicianId) ||
        isEqual(existedStoredAttachedClinician, updatedAttachedClinicianId);

      if (
        [invalidOrSameRole, invalidOrSameAttachedPsychologistId].every(Boolean)
      ) {
        return;
      }

      if (!invalidOrSameRole) {
        set(clonedUserSession, KEY_TACKLIT_ROLES, [updatedRoles]);
      }

      if (!invalidOrSameAttachedPsychologistId) {
        set(clonedUserSession, KEY_TACKLIT_ATTACHED_CLINICIAN, [
          updatedAttachedClinicianId,
        ]);
      }

      setSession({
        ...session,
        user: clonedUserSession,
      });
    } catch {
      customToast.error("Failed to retrieve your latest profile data!");
    }
  });

  return <>{children}</>;
};
