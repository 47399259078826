import {
  DEFAULT_GST
} from "core/booking.constant";
import { MedicareRole } from "enums";
import { first } from "lodash";
import { formatDollar, getAppointmentRate } from "utils";
import {
  getStoredReserveData
} from "utils/storage.util";
import { CountDownClock } from "../CountDownClock";
import { renderPsychologistDisplayRole } from "./booking-finalise.util";

export const SelfFundCartSummarySection = () => {
  const reserveAppointmentData = getStoredReserveData();
  const medicareRole = reserveAppointmentData?.psychologist?.medicare
    ?.role as MedicareRole;

  const apptRate = getAppointmentRate();

  const apptGst = DEFAULT_GST;

  const appointmentData = first(
    reserveAppointmentData?.reserveAppointment.appointments
  );

  if (!appointmentData) return <></>;

  return (
    <div>
      <div className="flex flex-col gap-3 bg-highlight p-7 text-15 leading-loose mb-2 rounded-10">
        <div className="mb-2">
          <span className="text-14 text-primary leading-7 md:text-18 md:leading-6 xl:text-24 xl:leading-9">
            Cost summary
          </span>
        </div>

        <div className="flex">
          <div className="flex-[2] font-bold">
            {renderPsychologistDisplayRole(medicareRole)} consult
          </div>
          <div className="flex-1 text-right">{formatDollar(apptRate)}</div>
        </div>

        <div className="flex">
          <div className="flex-[2]">GST (incl.)</div>
          <div className="flex-1 text-right">{formatDollar(apptGst)}</div>
        </div>

        <div className="flex">
          <div className="flex-[2]">
            <div className="font-bold">Total</div>
            <div className="text-14">(not incl. bank fees)</div>
          </div>
          <div className="flex-1 text-right">{formatDollar(apptRate)}</div>
        </div>
      </div>

      <div className="flex flex-col gap-3 bg-light-grey p-7 rounded-10">
        <CountDownClock />
      </div>
    </div>
  );
};
