import * as Yup from "yup";

import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  toInvalidMessage,
  toRequiredMessage,
  validateBehalfOfChild,
  validateBehalfOfSomeOne,
} from "utils";
import { getAddressFormValidation } from "../shared/shared.resolver";
import { validatePhoneAsync } from "../signup/signup.resolver";

export const getDefaultConfirmBookingFormResolver = () => {
  return Yup.object().shape({
    emergencyContactName: Yup.string()
      .trim()
      .required(toRequiredMessage("Name")),
    emergencyContactPhoneNumber: Yup.string()
      .trim()
      .required(toRequiredMessage("Phone number"))
      .test({
        name: "phone",
        message: "Phone number is not found",
        test: async (phoneNumber, context) => {
          if (
            !isValidPhoneNumber(phoneNumber || "", DEFAULT_PHONE_COUNTRY_CODE)
          )
            return context.createError({
              path: "emergencyContactPhoneNumber",
              message: "Phone number is not valid",
            });

          const isValid = await validatePhoneAsync(phoneNumber);

          return isValid;
        },
      }),
  });
};

export const getSelfFundConfirmBookingFormResolver = (accessToken: string) => {
  return Yup.object()
    .shape({
      onBehalfOf: Yup.string().required(),
      emergencyContactName: Yup.string()
        .trim()
        .required(toRequiredMessage("Name")),
      emergencyContactPhoneNumber: Yup.string()
        .required(toRequiredMessage("Phone number"))
        .test({
          name: "phone",
          message: "Phone number is not found",
          test: async (phoneNumber, context) => {
            if (
              !isValidPhoneNumber(phoneNumber || "", DEFAULT_PHONE_COUNTRY_CODE)
            )
              return context.createError({
                path: "emergencyContactPhoneNumber",
                message: "Phone number is not valid",
              });

            const isValid = await validatePhoneAsync(phoneNumber);

            return isValid;
          },
        }),
      isAgreeCancellationPolicy: Yup.boolean().required(),
      bookerName: Yup.string()
        .trim()
        .when("onBehalfOf", ([onBehalfOf], field) =>
          validateBehalfOfSomeOne(onBehalfOf)
            ? field.required(toRequiredMessage("Your name"))
            : field.optional()
        ),
      bookerPhone: Yup.string()
        .trim()
        .when("onBehalfOf", ([onBehalfOf], field) =>
          validateBehalfOfSomeOne(onBehalfOf)
            ? field.required(toRequiredMessage("Your contact"))
            : field.optional()
        ),
      parentFirstName: Yup.string()
        .trim()
        .when("onBehalfOf", ([onBehalfOf], field) =>
          validateBehalfOfChild(onBehalfOf)
            ? field.required(toRequiredMessage("Parent first name"))
            : field.optional()
        ),
      parentLastName: Yup.string()
        .trim()
        .when("onBehalfOf", ([onBehalfOf], field) =>
          validateBehalfOfChild(onBehalfOf)
            ? field.required(toRequiredMessage("Parent last name"))
            : field.optional()
        ),
      parentEmail: Yup.string().when("onBehalfOf", ([onBehalfOf], field) =>
        validateBehalfOfChild(onBehalfOf)
          ? field
              .email(toInvalidMessage("Parent email"))
              .required(toRequiredMessage("Parent email"))
          : field.optional()
      ),
    })
    .concat(getAddressFormValidation(accessToken));
};
