import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ChevronSvg } from "assets/icons/ChevronSvg";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { BadgeTypes } from "enums";
import { useToggle } from "react-use";
import { Routes } from "routes/main.routes";
import { BlogItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn, toBlogDateFormat, toSafeLink } from "utils";

export type BlogItemCustomProps = Props<BlogItemStoryblok> & {
  fullSlug?: string;
};

export const BlogItem = ({
  blok,
  fullSlug = Routes.PLACEHOLDER,
}: BlogItemCustomProps) => {
  const [isHovering, toggleHovering] = useToggle(false);

  const publishDate = toBlogDateFormat(blok.publishDate);

  return (
    <LinkWrapper
      to={toSafeLink(fullSlug)}
      className={cn(
        "rounded-2xl w-full md:max-w-[410px] cursor-pointer duration-300",
        blok?.backgroundColor || "bg-light-grey",
        {
          "bg-secondary duration-300": isHovering,
        }
      )}
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
      {...storyblokEditable(blok)}
    >
      <div>
        <div className="relative overflow-hidden rounded-t-2xl">
          <img
            src={blok.image?.filename}
            alt={blok.image?.alt}
            className={cn(
              "h-[300px] md:h-[250px] w-full object-cover duration-300",
              {
                "duration-300 scale-110": isHovering,
              }
            )}
          />

          {/* CATEGORY */}
          <div className="absolute top-3.5 left-3.5">
            {blok.category
              ?.slice(0, 3)
              .map((category) => ({
                ...category,
                type: isHovering ? BadgeTypes.SECONDARY : BadgeTypes.PLAIN,
              }))
              .map((category) => (
                <div key={category._uid} className="mb-2">
                  <StoryblokComponent blok={category} />
                </div>
              ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 m-4 md:m-6 lg:m-8">
          <div className="text-xs text-primary font-semibold flex items-center justify-start">
            <span
              className={cn(
                "-rotate-90 absolute duration-300",
                isHovering ? "opacity-100" : "opacity-0"
              )}
            >
              <ChevronSvg className="fill-primary" />
            </span>
            <span
              className={isHovering ? "ml-5 duration-300" : "ml-0 duration-300"}
            >
              {publishDate}
            </span>
          </div>

          <div className="md:line-clamp-5">
            {blok.title?.map((title) => (
              <StoryblokComponent key={title._uid} blok={title} />
            ))}
          </div>

          <div className="inline-block [&>*]:mr-2">
            {blok.tags?.map((tag) => (
              <StoryblokComponent key={tag._uid} blok={tag} />
            ))}
          </div>
        </div>
      </div>
    </LinkWrapper>
  );
};
