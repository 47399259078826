import { TimeZoneType } from "enums";
import { FundingCategory } from "services/psychologist.service";

export type ClientRecord = {
  accountId: string;
  tacklitId: string;
  clinicianAuth0Ids: string[];
  recordStatus: string;
  recordType: string;
  clientProfiles: ClientProfile[];
  invoiceSummary: ESummary;
  episodeSummary: ESummary;
  appointmentStatusOverviewConfiguration: AppointmentStatusOverviewConfiguration;
  timeZone: string;
  _id: string;
  attachedGroups: any[];
  attachedInterventions: any[];
  createdAt: Date;
  updatedAt: Date;
  __v: number;
};

export type ESummary = {
  order: number;
  count: number;
};

export type CreateClientMinifiedClientRecord = {
  avatar: string;
  mobile: string;
  email: string;
  name: string;
  password: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  postCode: string;
  timeZone: string;
  communicationPreference: string;
  appointmentTypeId?: string;
  atsi: string;
  recordType: RecordType;
};

export enum RecordType {
  ADULT = "adult",
  YOUNG_PERSON = "youngPerson",
}

export type ClientProfile = {
  profileType: string;
  firstName: string;
  lastName: string;
  name: string;
  initials: string;
  initialsBackgroundColor: string;
  hashedMobileNumber: string;
  hashedEmail: string;
  encrypted: EncryptedData;
  isMobileNumberThirdParty: boolean;
  isEmailThirdParty: boolean;
  isPrimaryContact: boolean;
  communicationPreference: string;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
};

export type EncryptedData = {
  authTag: string;
  iv: string;
  salt: string;
  text: string;
};

export type AppointmentStatusOverviewConfiguration = {
  target: number;
  limit: number;
  displayTagCount: string[];
};

export type SignupUserData = {
  mobile: string;
  email: string;
  name: string;
  password: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  authToken: string;
};

export type MinifiedSignupUserData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  authToken?: string;
  clientRecord?: ClientRecord;
};

export type Profile = {
  _id?: string;
  address?: Address;
  /**
   * bulkbill for first two appointments, rebate for third and onwards
   */
  appointmentBookingClaimType?: FundingCategory;
  clientRecordId?: string;
  dateOfBirth?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  gpSurveyCompleted?: boolean;
  hasSavedCard?: boolean;
  isAccountMedicareEnabled?: boolean;
  isAccountSmpEnabled?: boolean;
  isReferralSentByGP?: boolean;
  keyClientContacts?: KeyClientContact[];
  medicare?: ResponseMedicare;
  recordType?: RecordType;
  referral?: Referral;
  timeZone?: TimeZoneType;
};

export type Address = {
  country?: string;
  line1?: string;
  line2?: string;
  postcode?: string;
  state?: string;
  suburb?: string;
};

export type KeyClientContact = {
  email?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  tags?: string[];
};

export type ResponseMedicare = {
  dateOfBirth?: string;
  dva?: string;
  dvaCardDetails?: DvaCardDetails;
  expiryDate?: string;
  firstName?: string;
  irn?: number;
  lastName?: string;
  number?: number;
  status?: Status;
};

export type DvaCardDetails = {
  expiryDate?: string;
  type?: Type;
};

export enum Type {
  Gold = "gold",
  Orange = "orange",
  White = "white",
}

export type Status = {
  dva?: Dva;
  medicare?: StatusMedicare;
};

export type Dva = {
  code?: number;
  message?: string;
};

export type StatusMedicare = {
  code?: number;
  message?: string;
};

export type Referral = {
  date?: string;
  files?: File[];
  generalPractitionerId?: string;
  isHaveTreatmentPlan?: boolean;
  isReferredByGP?: boolean;
  source?: string;
  sourceName?: string;
  treatmentPlanDate?: string;
};

export type File = {
  bucketName?: string;
  clientProfileId?: string;
  fileName?: string;
  fileUrl?: string;
};

export type ZonedTimeInfo = {
  timeZone: TimeZoneType;
};
