import { storyblokEditable } from "@storyblok/react";
import { AuthGuardStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const AuthGuard = ({ blok }: Props<AuthGuardStoryblok>) => {
  return (
    <div
      className="flex flex-col w-fit p-8 rounded-lg bg-highlight-green gap-y-3"
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col gap-y-3 lg:flex-row lg:gap-x-5 lg:items-center">
        <div className="flex w-10 h-10 justify-center items-center">
          <img src={blok.icon?.filename} alt={blok.icon?.alt} />
        </div>

        <div className="font-normal text-lg text-primary">{blok.heading}</div>
      </div>

      <div className="font-normal text-base text-dark-grey">
        {blok.description}
      </div>
    </div>
  );
};
