import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ArticleReferencesContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const ArticleReferencesContainer = ({
  blok,
}: Props<ArticleReferencesContainerStoryblok>) => {
  const margin = blok.margin ?? "0px 0px 0px 0px";

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-3 border border-light-grey-2 rounded-10 md:rounded-30 p-2 md:p-7"
      style={{
        margin: margin,
      }}
    >
      {blok.header?.map((blok) => (
        <StoryblokComponent key={blok._uid} blok={blok} />
      ))}

      {blok.items?.map((item, index) => (
        <div key={item._uid}>
          <StoryblokComponent blok={item} index={index + 1} />
        </div>
      ))}
    </div>
  );
};
