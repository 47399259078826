/* eslint-disable react-hooks/exhaustive-deps */
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { OurPsychologistsFilterBarStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { getHintFromStoryBlok } from "components/forms/book-medicare/BookMedicareForm";
import { OurPsychologistsFilterType, SelectionOption } from "models";
import {
  availableTimeOptions,
  defaultOurPsychologistsFilter,
  extract,
  getLanguageOptions,
  getMultiSelectedOptions,
  getSingleSelectedOption,
  preferredDayOptions,
  psychologistGenderOptions,
  treatmentStyleOptions,
} from "utils";
import { CheckboxInput } from "../forms/CheckboxInput";

type CustomProps = Props<OurPsychologistsFilterBarStoryblok> & {
  shouldShowFilter: boolean;
  selectedFilter: OurPsychologistsFilterType;

  onShowFilter: (shouldShowFilter: boolean) => void;
  onChangeFilter: (filter: OurPsychologistsFilterType) => void;
};

export const OurPsychologistsFilterBar = ({
  blok,
  selectedFilter,
  ...props
}: CustomProps) => {
  const handleShowFilter = (showFilter: boolean) => {
    props.onShowFilter(showFilter);
  };

  const languageOptions = getLanguageOptions();

  const handleChangeFilterValues = <K extends keyof OurPsychologistsFilterType>(
    key: K,
    values: OurPsychologistsFilterType[K]
  ) => {
    props.onChangeFilter({ ...selectedFilter, [key]: values });
  };

  const handleReset = () => {
    props.onChangeFilter(defaultOurPsychologistsFilter);
  };

  if (!props.shouldShowFilter) {
    return (
      <div className="flex items-center justify-center flex-1 py-6 border-t-2 rounded-b-xl px-7 bg-light-grey">
        <div className="w-full lg:w-1/4" onClick={() => handleShowFilter(true)}>
          {blok.showButtons?.map((buttonBlok) => (
            <StoryblokComponent key={buttonBlok._uid} blok={buttonBlok} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-5 py-7 border-t-2 rounded-b-xl px-7 bg-light-grey lg:flex-row"
    >
      <div className="flex flex-col justify-between flex-1 gap-y-5">
        {/* TODO: This will reworking on the second phrase */}
        {/* <div className="flex items-end">
          {blok.nextAvailableSelection?.map((nextAvailableSelectionBlok) => (
            <StoryblokComponent
              key={nextAvailableSelectionBlok._uid}
              blok={nextAvailableSelectionBlok}
              isLoading={false}
              options={nextAvailableOptions}
              selectedOption={getSingleSelectedOption(
                nextAvailableOptions,
                filter.nextAvailable
              )}
              onChangeSingleSelection={(selectedOption: SelectionOption) =>
                handleChangeFilterValues("nextAvailable", selectedOption.value)
              }
            />
          ))}
        </div> */}

        <div className="flex items-end min-w-[11rem]">
          {blok.psychologistGenderSelection?.map(
            (psychologistGenderSelectionBlok) => (
              <StoryblokComponent
                key={psychologistGenderSelectionBlok._uid}
                blok={psychologistGenderSelectionBlok}
                isLoading={false}
                options={psychologistGenderOptions}
                selectedOption={getSingleSelectedOption(
                  psychologistGenderOptions,
                  selectedFilter.psychologistGender
                )}
                onChangeSingleSelection={(selectedOption: SelectionOption) =>
                  handleChangeFilterValues(
                    "psychologistGender",
                    selectedOption.value
                  )
                }
              />
            )
          )}
        </div>

        <div className="flex items-end min-w-[11rem]">
          {blok.languageSelection?.map((languageSelectionBlok) => (
            <StoryblokComponent
              key={languageSelectionBlok._uid}
              blok={languageSelectionBlok}
              isLoading={false}
              options={languageOptions}
              selectedOption={getSingleSelectedOption(
                languageOptions,
                selectedFilter.languagesSpoken
              )}
              onChangeSingleSelection={(selectedOption: SelectionOption) =>
                handleChangeFilterValues(
                  "languagesSpoken",
                  selectedOption.value
                )
              }
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col justify-between flex-1 gap-y-5">
        <div className="flex items-end">
          {blok.preferredDaySelection?.map((preferredDaySelectionBlok) => (
            <StoryblokComponent
              key={preferredDaySelectionBlok._uid}
              blok={preferredDaySelectionBlok}
              isLoading={false}
              options={preferredDayOptions}
              isMultiple
              selectedOptions={getMultiSelectedOptions(
                preferredDayOptions,
                selectedFilter.preferredDays
              )}
              onChangeMultiSelection={(selectedOptions: SelectionOption[]) =>
                handleChangeFilterValues(
                  "preferredDays",
                  extract(selectedOptions, "value")
                )
              }
            />
          ))}
        </div>

        <div className="flex items-end">
          {blok.treatmentSelection?.map((treatmentSelectionBlok) => (
            <StoryblokComponent
              key={treatmentSelectionBlok._uid}
              blok={treatmentSelectionBlok}
              isLoading={false}
              options={treatmentStyleOptions}
              isMultiple
              selectedOptions={getMultiSelectedOptions(
                treatmentStyleOptions,
                selectedFilter.treatmentStyles
              )}
              onChangeMultiSelection={(selectedOptions: SelectionOption[]) =>
                handleChangeFilterValues(
                  "treatmentStyles",
                  extract(selectedOptions, "value")
                )
              }
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col justify-between flex-1 gap-y-5">
        <div className="flex items-end">
          {blok.availableTimeSelection?.map((availableTimeSelectionBlok) => (
            <StoryblokComponent
              key={availableTimeSelectionBlok._uid}
              blok={availableTimeSelectionBlok}
              isLoading={false}
              options={availableTimeOptions}
              isMultiple
              selectedOptions={getMultiSelectedOptions(
                availableTimeOptions,
                selectedFilter.availableTimes
              )}
              onChangeMultiSelection={(selectedOptions: SelectionOption[]) =>
                handleChangeFilterValues(
                  "availableTimes",
                  extract(selectedOptions, "value")
                )
              }
            />
          ))}
        </div>
        <div className="flex flex-col items-start justify-end h-full gap-y-3 whitespace-nowrap">
          <CheckboxInput
            isChecked={selectedFilter.isDoPhoneConsults}
            onCheck={(e) =>
              handleChangeFilterValues("isDoPhoneConsults", e.target.checked)
            }
            title={blok.phoneConsultsCheckboxTitle}
            hint={getHintFromStoryBlok(blok.phoneConsultsCheckboxHint)}
          />

          <CheckboxInput
            isChecked={selectedFilter.isDoTreatTeens}
            onCheck={(e) =>
              handleChangeFilterValues("isDoTreatTeens", e.target.checked)
            }
            title={blok.treatTeensCheckboxTitle}
            hint={getHintFromStoryBlok(blok.treatTeensCheckboxHint)}
          />
        </div>
      </div>

      <div className="flex flex-col justify-between flex-1 order-first gap-y-5 lg:order-last">
        <div className="flex flex-row flex-wrap items-end justify-center h-full gap-5">
          <div className="flex-1" onClick={() => handleShowFilter(false)}>
            {blok.hideButtons?.map((buttonBlok) => (
              <StoryblokComponent key={buttonBlok._uid} blok={buttonBlok} />
            ))}
          </div>
          <span className="pb-3 underline cursor-pointer" onClick={handleReset}>
            Reset
          </span>
        </div>

        <div className="flex flex-col items-start justify-center h-full"></div>
      </div>
    </div>
  );
};
