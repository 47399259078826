import React from "react";
import InputMask, { Props as InputMaskProps } from "react-input-mask";

type Props = Omit<InputMaskProps, "mask">;

export const DateInputMask = React.forwardRef(
  (props: Props, ref: React.Ref<InputMask>) => {
    return (
      <InputMask
        ref={ref}
        mask={[
          /[0-3]/,
          /[0-9]/,
          "/",
          /[0-1]/,
          /[0-9]/,
          "/",
          /[1-2]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        inputMode="numeric"
        placeholder="DD/MM/YYYY"
        maskPlaceholder={null}
        {...props}
      />
    );
  }
);

export const MonthInputMask = React.forwardRef(
  (props: Props, ref: React.Ref<InputMask>) => {
    return (
      <InputMask
        ref={ref}
        mask={[/[0-1]/, /[0-9]/, "/", /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        inputMode="numeric"
        placeholder="MM/YYYY"
        maskPlaceholder={null}
        {...props}
      />
    );
  }
);

export const YearInputMask = React.forwardRef(
  (props: Props, ref: React.Ref<InputMask>) => {
    return (
      <InputMask
        ref={ref}
        mask={[/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        inputMode="numeric"
        placeholder="YYYY"
        maskPlaceholder={null}
        {...props}
      />
    );
  }
);
