/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce, useFirstMountState, useToggle } from "react-use";

import { LoadingScreen } from "components/shared/LoadingScreen";
import { SavedPaymentStatus, UrlParamType } from "enums";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { ExternalRoutes, Routes } from "routes/main.routes";
import { BookGpContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { redirectTo } from "utils";
import { getStoredSignupSessionUser } from "utils/storage.util";
import { customToast } from "utils/toast.util";

export const BookGPContainer = ({ blok }: Props<BookGpContainerStoryblok>) => {
  const { isAuthenticated } = useAuth0();

  const { loginRedirectTacklit } = useLoginNavigate();

  const storedSignupUserData = getStoredSignupSessionUser();

  const isSignedUpUser = storedSignupUserData || isAuthenticated;

  const [searchParams, setSearchParams] = useSearchParams();

  const isFirstMount = useFirstMountState();

  const [isRedirecting, toggleIsRedirecting] = useToggle(false);

  useEffectOnce(() => {
    if (!isSignedUpUser) {
      toggleIsRedirecting(true);

      redirectTo(Routes.SIGNUP_GP);
    }
  });

  useEffect(() => {
    const savedPaymentDetailStatus = searchParams.get(
      UrlParamType.SAVE_PAYMENT_DETAIL_STATUS
    );

    if (!isFirstMount || isEmpty(savedPaymentDetailStatus)) return;

    // Redirect to GP booking page if payment detail is saved successfully
    if (savedPaymentDetailStatus === SavedPaymentStatus.SUCCESS) {
      toggleIsRedirecting(true);

      loginRedirectTacklit(ExternalRoutes.GP_BOOKING);
    } else {
      customToast.error("Failed to save payment details. Please try again.");
    }

    searchParams.delete(UrlParamType.SAVE_PAYMENT_DETAIL_STATUS);

    setSearchParams(searchParams);
  }, [searchParams]);

  if (isRedirecting) {
    return <LoadingScreen />;
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-12 px-0 py-10 lg:px-20 xl:px-40"
    >
      {/* GP FORM */}
      <div className="flex flex-1">
        {blok.forms?.map((formBlok) => (
          <StoryblokComponent key={formBlok._uid} blok={formBlok} />
        ))}
      </div>
    </div>
  );
};
