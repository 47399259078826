import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  PathValue,
  useWatch,
} from "react-hook-form";
import { AssetStoryblok, HintStoryblok } from "types/component-types-sb";

import { ConfirmRadioType } from "enums";
import { AddressFieldsType } from "models";
import { MinifiedSignupUserData } from "models/client.model";
import { DateInput } from "../DateInput";
import { FilterCheckbox } from "../FilterCheckbox";
import { RadioInput } from "../RadioInput";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { BookMedicareGPChildFormData } from "../book-gp/GPBehalfOfChildForm";
import { BookMedicareChildFormData } from "../book-medicare/BehalfOfChildForm";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { AddressFields } from "./AddressFields";

export type MedicareChildFieldsType = {
  // Child from 13 to 17
  guardianFirstName: string;
  guardianLastName: string;
  guardianEmail: string;

  // Separate card value
  hasChildSeparateCard: string;

  // Separate card Yes
  childSeparateNameMatchedCard?: boolean;
  childSeparateFirstName?: string;
  childSeparateLastName?: string;
  childSeparateDateOfBirth?: string;
  childSeparateMedicareNumber?: string;
  childSeparateIrn?: string;
  childSeparateExpiryDate?: string;

  // Separate card No
  parentFirstName?: string;
  parentLastName?: string;
  parentDateOfBirth?: string;
  parentMedicareNumber?: string;
  parentIrn?: string;
  parentExpiryDate?: string;
  childIrn?: string;

  isConsentForParent: boolean;
} & AddressFieldsType;

type Props<T extends FieldValues> = {
  control: Control<T>;
  errors: FieldErrors<T>;
  userInformation: MinifiedSignupUserData | null;

  medicareNumberHints?: HintStoryblok[];
  irnHints?: HintStoryblok[];
  expiryDateHints?: HintStoryblok[];
  streetAddressHints?: HintStoryblok[];

  medicareImageAsset?: AssetStoryblok;
  medicareImageAlt?: string;
};

export const MedicareChildFields = <
  T extends BookMedicareChildFormData | BookMedicareGPChildFormData
>({
  control,
  errors,
  userInformation,
  medicareNumberHints,
  irnHints,
  expiryDateHints,
  streetAddressHints,
  medicareImageAsset,
  medicareImageAlt = "medicare-alt",
  ...props
}: Props<T>) => {
  const { hasChildSeparateCard, childSeparateNameMatchedCard } = useWatch({
    control,
  });

  if (!userInformation) return;

  return (
    <div className="flex flex-col w-full gap-y-4">
      <div className="flex flex-col lg:flex-row items-stretch lg:items-start gap-x-5 gap-y-3">
        <div className="flex flex-col flex-1">
          <Controller
            name={"guardianFirstName" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <TextInput
                title="Parent first name"
                onChangeValue={field.onChange}
              />
            )}
          />

          <TextError fieldError={errors.guardianFirstName as FieldError} />
        </div>

        <div className="flex flex-col flex-1">
          <Controller
            name={"guardianLastName" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <TextInput
                title="Parent last name"
                onChangeValue={field.onChange}
              />
            )}
          />

          <TextError fieldError={errors.guardianLastName as FieldError} />
        </div>
      </div>

      <Controller
        name={"guardianEmail" as FieldPath<T>}
        control={control}
        render={({ field }) => (
          <div>
            <TextInput
              title="Parent email address"
              onChangeValue={field.onChange}
            />

            <TextError fieldError={errors.guardianEmail as FieldError} />
          </div>
        )}
      />

      <div className="flex flex-col gap-y-3">
        <div className="flex flex-row items-center justify-between">
          <span>Does your child have a separate Medicare card to you?</span>
        </div>

        <Controller
          name={"hasChildSeparateCard" as FieldPath<T>}
          control={control}
          render={({ field }) => (
            <div className="flex flex-row gap-x-5">
              <RadioInput
                {...field}
                label="Yes"
                value={ConfirmRadioType.YES}
                checked={hasChildSeparateCard === ConfirmRadioType.YES}
              />

              <RadioInput
                {...field}
                label="No"
                value={ConfirmRadioType.NO}
                checked={hasChildSeparateCard === ConfirmRadioType.NO}
              />
            </div>
          )}
        />
      </div>

      {hasChildSeparateCard === ConfirmRadioType.YES && (
        <Controller
          name={"childSeparateNameMatchedCard" as FieldPath<T>}
          control={control}
          render={({ field }) => (
            <FilterCheckbox
              isChecked={childSeparateNameMatchedCard || false}
              title="My child's name registered with Medicare matches the name previously entered"
              onCheck={field.onChange}
            />
          )}
        />
      )}

      <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-3">
        {hasChildSeparateCard === ConfirmRadioType.YES ? (
          <div
            className="flex flex-col gap-y-3"
            key={"childSeparateNameMatchedCardComponent"}
          >
            {!childSeparateNameMatchedCard && (
              <div className="flex flex-col flex-1">
                <div className="flex flex-row mb-1 gap-x-5 gap-y-3">
                  <div className="flex flex-col flex-1">
                    <Controller
                      name={"childSeparateFirstName" as FieldPath<T>}
                      control={control}
                      defaultValue={
                        userInformation.firstName as PathValue<
                          T,
                          FieldPath<T>
                        >
                      }
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          title="Child first name"
                          onChangeValue={field.onChange}
                        />
                      )}
                    />

                    <TextError
                      fieldError={errors.childSeparateFirstName as FieldError}
                    />
                  </div>

                  <div className="flex flex-col flex-1">
                    <Controller
                      name={"childSeparateLastName" as FieldPath<T>}
                      control={control}
                      defaultValue={
                        userInformation.lastName as PathValue<T, FieldPath<T>>
                      }
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          title="Child last name"
                          onChangeValue={field.onChange}
                        />
                      )}
                    />

                    <TextError
                      fieldError={errors.childSeparateLastName as FieldError}
                    />
                  </div>
                </div>

                <div className="flex flex-row mb-1 gap-x-5">
                  <Controller
                    name={"childSeparateDateOfBirth" as FieldPath<T>}
                    control={control}
                    defaultValue={
                      userInformation.dateOfBirth as PathValue<
                        T,
                        FieldPath<T>
                      >
                    }
                    render={({ field }) => (
                      <div className="flex flex-col flex-1">
                        <DateInput
                          maxDate={new Date()}
                          selectedDate={
                            field.value
                              ? new Date(field.value as string)
                              : undefined
                          }
                          title="Child date of birth"
                          onChangeValue={field.onChange}
                        />

                        <TextError
                          fieldError={
                            errors.childSeparateDateOfBirth as FieldError
                          }
                        />
                      </div>
                    )}
                  />

                  <div className="flex-1"></div>
                </div>
              </div>
            )}

            <img src={medicareImageAsset?.filename} alt={medicareImageAlt} />

            <div className="flex flex-col">
              <Controller
                name={"childSeparateMedicareNumber" as FieldPath<T>}
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    title="Child Medicare card number"
                    onChangeValue={field.onChange}
                    hintElement={getHintFromStoryBlok(medicareNumberHints)}
                  />
                )}
              />

              <TextError
                fieldError={errors.childSeparateMedicareNumber as FieldError}
              />
            </div>

            <div className="flex flex-col justify-center w-full gap-x-5 gap-y-3 lg:flex-row">
              <div className="flex flex-col flex-1 lg:min-w-[275px]">
                <Controller
                  name={"childSeparateIrn" as FieldPath<T>}
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      title="Child individual reference (IRN)"
                      onChangeValue={field.onChange}
                      hintElement={getHintFromStoryBlok(irnHints)}
                    />
                  )}
                />

                <TextError fieldError={errors.childSeparateIrn as FieldError} />
              </div>

              <div className="flex flex-col flex-1">
                <Controller
                  name={"childSeparateExpiryDate" as FieldPath<T>}
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      type="month"
                      title="Expiry date"
                      selectedDate={
                        field.value
                          ? new Date(field.value as string)
                          : undefined
                      }
                      hintComponent={getHintFromStoryBlok(expiryDateHints)}
                      onChangeValue={field.onChange}
                      showMonthYearPicker
                      minDate={new Date()}
                      isShowHint
                    />
                  )}
                />

                <TextError
                  fieldError={errors.childSeparateExpiryDate as FieldError}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col gap-y-3"
            key={"parentNameMatchedCardComponent"}
          >
            <div className="flex flex-col flex-1">
              <div className="flex flex-row mb-1 gap-x-5">
                <div className="flex flex-col flex-1">
                  <Controller
                    name={"parentFirstName" as FieldPath<T>}
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        title="Parent first name"
                        onChangeValue={field.onChange}
                      />
                    )}
                  />
                  <TextError
                    fieldError={errors.parentFirstName as FieldError}
                  />
                </div>

                <div className="flex flex-col flex-1">
                  <Controller
                    name={"parentLastName" as FieldPath<T>}
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        title="Parent last name"
                        onChangeValue={field.onChange}
                      />
                    )}
                  />
                  <TextError fieldError={errors.parentLastName as FieldError} />
                </div>
              </div>

              <div className="flex flex-row mb-1 gap-x-5">
                <Controller
                  name={"parentDateOfBirth" as FieldPath<T>}
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col flex-1">
                      <DateInput
                        maxDate={new Date()}
                        selectedDate={
                          field.value
                            ? new Date(field.value as string)
                            : undefined
                        }
                        title="Parent date of birth"
                        onChangeValue={field.onChange}
                      />

                      <TextError
                        fieldError={errors.parentDateOfBirth as FieldError}
                      />
                    </div>
                  )}
                />

                <div className="flex-1"></div>
              </div>
            </div>

            <img src={medicareImageAsset?.filename} alt={medicareImageAlt} />

            <Controller
              name={"parentMedicareNumber" as FieldPath<T>}
              control={control}
              render={({ field }) => (
                <div className="flex flex-col flex-1">
                  <TextInput
                    {...field}
                    title="Parent Medicare card number"
                    onChangeValue={field.onChange}
                    hintElement={getHintFromStoryBlok(medicareNumberHints)}
                  />
                  <TextError
                    fieldError={errors.parentMedicareNumber as FieldError}
                  />
                </div>
              )}
            />

            <div className="flex flex-row items-start justify-center w-full gap-x-5">
              <Controller
                name={"parentIrn" as FieldPath<T>}
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col flex-1 min-w-[285px]">
                    <TextInput
                      {...field}
                      title="Parent individual reference (IRN)"
                      onChangeValue={field.onChange}
                      hintElement={getHintFromStoryBlok(irnHints)}
                    />
                    <TextError fieldError={errors.parentIrn as FieldError} />
                  </div>
                )}
              />

              <Controller
                name={"parentExpiryDate" as FieldPath<T>}
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col flex-1">
                    <DateInput
                      type="month"
                      title="Expiry date"
                      selectedDate={
                        field.value
                          ? new Date(field.value as string)
                          : undefined
                      }
                      hintComponent={getHintFromStoryBlok(expiryDateHints)}
                      onChangeValue={field.onChange}
                      showMonthYearPicker
                      minDate={new Date()}
                      isShowHint
                    />
                    <TextError
                      fieldError={errors.parentExpiryDate as FieldError}
                    />
                  </div>
                )}
              />
            </div>

            <div className="flex flex-row items-end justify-center w-full gap-x-5">
              <Controller
                name={"childIrn" as FieldPath<T>}
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col flex-1 min-w-[285px]">
                    <TextInput
                      title="Child individual reference (IRN)"
                      onChangeValue={field.onChange}
                      hintElement={getHintFromStoryBlok(irnHints)}
                    />
                    <TextError fieldError={errors.childIrn as FieldError} />
                  </div>
                )}
              />

              <div className="flex-1"></div>
            </div>
          </div>
        )}

        {/* ADDRESS */}
        <AddressFields<T>
          streetAddressHints={streetAddressHints}
          control={control}
          errors={errors}
        />
      </div>
    </div>
  );
};
