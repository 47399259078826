import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo } from "lodash";
import { ContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const Container = ({ blok }: Props<ContainerStoryblok>) => {
  return (
    <div
      style={{
        paddingTop: defaultTo(blok?.paddingY, 0) + "px",
        paddingBottom: defaultTo(blok?.paddingY, 0) + "px",
        paddingLeft: defaultTo(blok?.paddingX, 0) + "px",
        paddingRight: defaultTo(blok?.paddingX, 0) + "px",

        marginTop: defaultTo(blok?.marginY, 0) + "px",
        marginBottom: defaultTo(blok?.marginY, 0) + "px",
        marginLeft: defaultTo(blok?.marginX, 0) + "px",
        marginRight: defaultTo(blok?.marginX, 0) + "px",
      }}
      {...storyblokEditable(blok)}
    >
      {blok.sections?.map((section) => (
        <StoryblokComponent key={section._uid} blok={section} />
      ))}
    </div>
  );
};
