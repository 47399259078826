import { globalConfig } from "core/config";

export enum Routes {
  PLACEHOLDER = "#!",
  ROOT = "/",
  NOT_FOUND = "/not-found",

  OUR_PSYCHOLOGISTS = "/find-a-psychologist",
  PSYCHOLOGIST_DETAILS = `${OUR_PSYCHOLOGISTS}/details`,

  SIGNUP = "/signup",
  SIGNUP_GP = `${SIGNUP}/signup-no-gp`,

  BOOKING_THANK_YOU = "/thank-you",
  BOOKING_THANK_YOU_CONFIRMED = "/thank-you-confirmed",
  CONTACT_US_THANK_YOU = "/contact/thank-you",

  BOOKING = "/booking",
  BOOKING_MEDICARE = `${BOOKING}/medicare`,
  BOOKING_NDIS = `${BOOKING}/ndis`,
  BOOKING_DVA = `${BOOKING}/dva`,
  BOOKING_WORKCOVER = `${BOOKING}/workcover`,
  BOOKING_GP = `${BOOKING}/gp`,
  BOOKING_CONFIRM = `${BOOKING}/confirm`,

  GET_MATCHED_RESULTS = "/your-results",
  GET_MATCHED_RESULTS_GP = "/your-results-gp",
  GET_MATCHED = "/get-matched",
  BLOG = "/blog",

  CONSENT_AGREEMENT = "/parental-consent",
  CANCELLATION_POLICY = "/cancellation-policy",
  GET_A_GP_REFERRAL = "/get-a-gp-referral",
  PRICING = "/pricing",
  CONTACT = "/contact",
}

const CLIENT_PORTAL_URL = globalConfig.TACKLIT_CLIENT_PORTAL_URL;

export const ExternalRoutes = {
  MY_APPOINTMENTS: `${CLIENT_PORTAL_URL}/appointment/overview`,
  REFERRAL: `${CLIENT_PORTAL_URL}/referral`,
  MY_PROFILE: `${CLIENT_PORTAL_URL}/profile`,
  MY_PSYCHOLOGISTS: `${CLIENT_PORTAL_URL}/my-psychologist`,
  GP_BOOKING: `${CLIENT_PORTAL_URL}/gp-booking`,
};
