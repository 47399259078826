import TagManager from "react-gtm-module";

import { globalConfig } from "core/config";
import { GTM_DataLayer_Events, GTM_DataLayer_Types } from "enums";
import { Funding } from "models";
import { defaultEnvPayload, gtmDataLayerType } from "utils";

export const useGtmDataLayer = () => {
  const initializeGtmScript = () => {
    TagManager.initialize({
      gtmId: globalConfig.GTM_TRACKING_ID,
    });
  };

  /**
   * @param {Funding} [payloadFunding] - Optional funding information to override.
   */
  const pushEventRegistrationCompleted = (payloadFunding?: Funding) => {
    const gtmType = gtmDataLayerType(payloadFunding);

    if (!gtmType) return;

    TagManager.dataLayer({
      dataLayer: {
        ...defaultEnvPayload(),
        event: GTM_DataLayer_Events.REGISTRATION_COMPLETE,
        type: gtmType,
      },
    });
  };

  const pushEventGpRegistrationCompleted = () => {
    TagManager.dataLayer({
      dataLayer: {
        ...defaultEnvPayload(),
        event: GTM_DataLayer_Events.REGISTRATION_COMPLETE,
        type: GTM_DataLayer_Types.GP_APPOINTMENT,
      },
    });
  };

  /**
   * @param {Funding} [payloadFunding] - Optional funding information to override.
   */
  const pushEventFirstBooked = (payloadFunding?: Funding) => {
    const gtmType = gtmDataLayerType(payloadFunding);

    if (!gtmType) return;

    TagManager.dataLayer({
      dataLayer: {
        ...defaultEnvPayload(),
        event: GTM_DataLayer_Events.FIRST_APPOINTMENT_BOOKED,
        type: gtmType,
      },
    });
  };

  /**
   * @param {Funding} [payloadFunding] - Optional funding information to override.
   */
  const pushEventSubsequentBooked = (payloadFunding?: Funding) => {
    const gtmType = gtmDataLayerType(payloadFunding);

    if (!gtmType) return;

    TagManager.dataLayer({
      dataLayer: {
        ...defaultEnvPayload(),
        event: GTM_DataLayer_Events.SUBSEQUENT_APPOINTMENT_BOOKED,
        type: gtmType,
      },
    });
  };

  return {
    initializeGtmScript,
    pushEventRegistrationCompleted,
    pushEventGpRegistrationCompleted,
    pushEventFirstBooked,
    pushEventSubsequentBooked,
  };
};
