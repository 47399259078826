import { storyblokEditable } from "@storyblok/react";
import { ConsentCheckboxSection } from "components/forms/get-matched/ConsentCheckboxSection";
import {
  DEFAULT_PHONE_COUNTRY_CODE,
  DEFAULT_PLACEHOLDER_PHONE_NUMBER,
} from "core/booking.constant";
import { AsYouType } from "libphonenumber-js";
import { Controller, useFormContext } from "react-hook-form";
import { ContactInformationFormStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";

export type CustomProps = Props<ContactInformationFormStoryblok> & {
  name: string;
};

export const ContactInformationForm = ({ blok, name }: CustomProps) => {
  const { control, getFieldState } = useFormContext();

  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex flex-col w-full gap-4">
        {/* First name */}
        <Controller
          name={`${name}.firstName`}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col w-full gap-1.5">
              <TextInput
                name={field.name}
                title={"First name"}
                onChangeValue={field.onChange}
              />

              <TextError
                fieldError={getFieldState(`${name}.firstName`).error}
              />
            </div>
          )}
        />

        {/* Last name */}
        <Controller
          name={`${name}.lastName`}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col w-full gap-1.5">
              <TextInput
                name={field.name}
                title={"Last name"}
                onChangeValue={field.onChange}
              />

              <TextError fieldError={getFieldState(`${name}.lastName`).error} />
            </div>
          )}
        />

        {/* Email */}
        <Controller
          name={`${name}.email`}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col w-full gap-1.5">
              <TextInput
                name={field.name}
                title={"Email"}
                onChangeValue={field.onChange}
              />

              <TextError fieldError={getFieldState(`${name}.email`).error} />
            </div>
          )}
        />

        {/* Phone number */}
        <Controller
          name={`${name}.phoneNumber`}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col w-full gap-1.5">
              <TextInput
                type="tel"
                name={field.name}
                title={"Phone number"}
                placeholder={DEFAULT_PLACEHOLDER_PHONE_NUMBER}
                onChangeValue={(event) => {
                  const value = event.target.value;

                  event.target.value = new AsYouType(
                    DEFAULT_PHONE_COUNTRY_CODE
                  ).input(value);

                  field.onChange(event);
                }}
              />

              {getFieldState(`${name}.phoneNumber`).isDirty && (
                <TextError
                  fieldError={getFieldState(`${name}.phoneNumber`).error}
                />
              )}

              <ConsentCheckboxSection />
            </div>
          )}
        />
      </div>
    </div>
  );
};
