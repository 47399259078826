import AvatarPlaceholderSvg from "assets/icons/avatar-placeholder.svg";
import { AvatarStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";
import { defaultTo } from "lodash";
import { storyblokEditable } from "@storyblok/react";

export const Avatar = ({ blok }: Props<AvatarStoryblok>) => {
  const { photo, isRounded = true, size } = blok;

  const photoUrl = defaultTo(photo?.filename, AvatarPlaceholderSvg);

  const classes = cn("bg-neutral", isRounded ? "rounded-full" : "rounded");

  return (
    <div {...storyblokEditable(blok)}>
      <img
        className={classes}
        src={photoUrl}
        alt={photo?.alt}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
    </div>
  );
};
