import useSWR from "swr";

import { InclusionType } from "enums";
import {
  GetMinifiedCliniciansParams,
  getMinifiedCliniciansAsync,
} from "services/psychologist.service";

type Props = {
  params?: GetMinifiedCliniciansParams;
};

const defaultParams = {
  excludeGP: InclusionType.INCLUDE,
  includeUnlistedProfiles: InclusionType.EXCLUDE,
  status: "active",
};

export const useSwrMinifiedCliniciansQuery = ({
  params = defaultParams,
}: Props) => {
  const fetchMinifiedCliniciansAsync = async () => {
    try {
      const { data } = await getMinifiedCliniciansAsync(params);

      return data;
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  const queryResult = useSWR(
    "/minified-clinicians",
    fetchMinifiedCliniciansAsync
  );

  return queryResult;
};
