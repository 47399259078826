import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { capitalize, isArray, isEqual } from "lodash";
import { ReactElement } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useToggle } from "react-use";
import { match } from "ts-pattern";
import {
  BookWorkCoverFormStoryblok,
  HintStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { BookBehalfOfType, ConfirmRadioType } from "enums";
import { useClientType } from "hooks/useClientType";
import { AddressFieldsType, FileItem, SelectionOption } from "models";
import { Routes } from "routes/main.routes";
import { putWorkCoverCardAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import { useProfileStore } from "stores/profile.store";
import {
  getDefaultBehalfOfOptions,
  getDefaultButtonStyles,
  getFormHeadingBlok,
  redirectTo,
} from "utils";
import {
  getWorkCoverDefaultFormData,
  handleNdisOrWorkCoverUpdateReferralAsync,
} from "utils/booking.util";
import { setStoredFirstWorkCoverAppointmentIsFromNsw } from "utils/storage.util";
import { BookingInformation } from "../BookingInformation";
import { CountDownClock } from "../CountDownClock";
import { FileInput } from "../FileInput";
import { RadioInput } from "../RadioInput";
import { TextAreaInput } from "../TextAreaInput";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import {
  getHintFromStoryBlok,
  MAX_UPLOAD_ANY_DOCUMENT_FILE,
  validFileTypes,
} from "../book-medicare/BookMedicareForm";
import { AddressFields } from "../shared/AddressFields";
import { getBookWorkCoverResolver } from "./book-workcover.resolver";

export type BookWorkCoverFormData = {
  behalfOf: string;

  bookerName?: string;
  bookerPhone?: string;

  workCoverNumber: string;
  managerDetail: string;
  totalApproved: string;
  isClaimInNSW: string;

  uploadedFiles?: FileItem[];
} & AddressFieldsType;

type BookWorkCoverFormCustomProps = Props<BookWorkCoverFormStoryblok> & {
  accessToken: string;
};

export const BookWorkCoverForm = ({
  blok,
  accessToken,
}: BookWorkCoverFormCustomProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const profile = useProfileStore((state) => state.profile);

  const { isExistingClient } = useClientType();

  const [isValidating, toggleValidating] = useToggle(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<BookWorkCoverFormData>({
    mode: "onChange",
    defaultValues: getWorkCoverDefaultFormData(profile),
    resolver: yupResolver(getBookWorkCoverResolver(accessToken)),
  });

  const { behalfOf, isClaimInNSW } = useWatch({ control });

  const isClaimInNSWYes = isEqual(isClaimInNSW, ConfirmRadioType.YES);
  const isBehalfOfAdult = isEqual(
    behalfOf,
    BookBehalfOfType.SOMEONE_ELSE_ADULT
  );

  const defaultWorkCoverBehalfOfOptions = getDefaultBehalfOfOptions(
    profile
  ).filter(({ value }) => !isEqual(value, BookBehalfOfType.MY_CHILD));

  const getBehalfOfHint = (): ReactElement | undefined => {
    return match(behalfOf)
      .with(BookBehalfOfType.MY_SELF, () =>
        getHintFromStoryBlok(blok.helpingSomeoneHints)
      )
      .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () =>
        getHintFromStoryBlok(blok.helpingSomeoneFurtherHints)
      )
      .otherwise(() => undefined);
  };

  const getStreetAddressHint = (): HintStoryblok[] | undefined => {
    return match(behalfOf)
      .with(BookBehalfOfType.MY_SELF, () => undefined)
      .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () => blok.homeAddressHints)
      .otherwise(() => undefined);
  };

  const getWorkCoverRadioHint = (): ReactElement | undefined => {
    return match(behalfOf)
      .with(BookBehalfOfType.MY_SELF, () =>
        getHintFromStoryBlok(blok.workcoverNSWHints)
      )
      .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () =>
        getHintFromStoryBlok(blok.secondWorkcoverHints)
      )
      .otherwise(() => undefined);
  };

  const getWorkCoverFileUploadHint = (): HintStoryblok[] | undefined => {
    return match(behalfOf)
      .with(BookBehalfOfType.MY_SELF, () => blok.documentUploadHints)
      .with(
        BookBehalfOfType.SOMEONE_ELSE_ADULT,
        () => blok.secondDocumentUploadHints
      )
      .otherwise(() => undefined);
  };

  const onSubmit: SubmitHandler<BookWorkCoverFormData> = async (formData) => {
    toggleValidating(true);

    try {
      const accessToken = isAuthenticated
        ? await getAccessTokenSilently()
        : undefined;

      await putWorkCoverCardAsync(
        {
          claimNumber: formData.workCoverNumber,
          numberOfAppointmentApproved: parseInt(formData.totalApproved),
          caseManagerDetails: formData.managerDetail,
          approved: capitalize(formData.isClaimInNSW),
        },
        accessToken
      );

      const isUploaded = await handleNdisOrWorkCoverUpdateReferralAsync({
        formData: formData,
        isExistingClient: isExistingClient,
        accessToken: accessToken,
      });

      if (!isUploaded) return;

      await match(formData.behalfOf)
        .with(BookBehalfOfType.MY_SELF, () => {
          return TacklitService.putContactAddressAsync(formData, accessToken);
        })
        .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () => {
          return Promise.all([
            TacklitService.putContactAddressAsync(formData, accessToken),
            TacklitService.putAdultContactAsync(formData, accessToken),
          ]);
        })
        .otherwise(() => {});

      setStoredFirstWorkCoverAppointmentIsFromNsw(isClaimInNSWYes);

      redirectTo(Routes.BOOKING_CONFIRM);
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      toggleValidating(false);
    }
  };

  return (
    <div
      className="flex flex-col w-full gap-12 px-0 py-10 md:flex-row lg:px-20"
      {...storyblokEditable(blok)}
    >
      <form
        className="flex flex-[2] gap-y-5 flex-col order-2 md:order-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StoryblokComponent blok={getFormHeadingBlok(blok.title)} />

        <BookingInformation />

        <Controller
          name="behalfOf"
          control={control}
          render={({ field }) => (
            <div className="flex flex-col">
              <SelectionDropdown
                isLoading={false}
                title="I am booking on behalf of:"
                placeHolder={"Select..."}
                hintComponent={getBehalfOfHint()}
                selectedValues={defaultWorkCoverBehalfOfOptions.filter(
                  ({ value }) => value === field.value
                )}
                options={defaultWorkCoverBehalfOfOptions}
                onChangeSingleSelection={(selectedOption: SelectionOption) =>
                  field.onChange(selectedOption.value)
                }
                isShowTitle
                isShowHint
                isRequired
              />
              <TextError fieldError={errors.behalfOf} />
            </div>
          )}
        />

        <div className="flex flex-col gap-y-3">
          {/* BEHALF OF SOMEONE */}
          {isBehalfOfAdult && (
            <div className="flex flex-col items-start flex-1 mb-1 xl:flex-row gap-x-5 gap-y-3">
              <div className="flex flex-col flex-1 w-full">
                <Controller
                  name="bookerName"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      title="Your name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />
                <TextError fieldError={errors.bookerName} />
              </div>

              <div className="flex flex-col w-full flex-1 lg:min-w-[308px]">
                <Controller
                  name="bookerPhone"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      title="Your contact (either email or phone)"
                      onChangeValue={field.onChange}
                    />
                  )}
                />
                <TextError fieldError={errors.bookerPhone} />
              </div>
            </div>
          )}

          {/* ADDRESS */}
          <AddressFields<BookWorkCoverFormData>
            streetAddressHints={getStreetAddressHint()}
            control={control}
            errors={errors}
            behalfOfType={behalfOf as BookBehalfOfType}
          />

          <div className="flex flex-col flex-1 gap-y-1">
            <Controller
              name="workCoverNumber"
              control={control}
              render={({ field }) => (
                <TextInput
                  title="Workers' Claim Number"
                  onChangeValue={field.onChange}
                />
              )}
            />
            <TextError fieldError={errors.workCoverNumber} />
          </div>

          <div className="flex flex-col flex-1 gap-y-1">
            <Controller
              name="managerDetail"
              control={control}
              render={({ field }) => (
                <TextAreaInput
                  name="managerDetail"
                  title="Case manager details (name and email)"
                  numberRows={3}
                  noResize
                  onChangeValue={field.onChange}
                />
              )}
            />
            <TextError fieldError={errors.managerDetail} />
          </div>

          <div className="flex flex-col flex-1 gap-y-1">
            <Controller
              name="totalApproved"
              control={control}
              render={({ field }) => (
                <TextInput
                  title="How many appointments have been approved?"
                  placeholder="Enter whole numbers only"
                  onChangeValue={field.onChange}
                  hintElement={undefined}
                  numberOnly
                />
              )}
            />
            <TextError fieldError={errors.totalApproved} />
          </div>
        </div>

        <div className="flex flex-col p-5 gap-y-3 bg-neutral-100 rounded-xl">
          <div className="flex flex-row items-center justify-between">
            <span>Is the WorkCover claim in NSW?</span>
            {getWorkCoverRadioHint()}
          </div>

          <Controller
            name="isClaimInNSW"
            control={control}
            render={({ field }) => (
              <div className="flex flex-row gap-x-5">
                <RadioInput
                  {...field}
                  label="Yes"
                  value={ConfirmRadioType.YES}
                  checked={isClaimInNSWYes}
                />

                <RadioInput
                  {...field}
                  label="No"
                  value={ConfirmRadioType.NO}
                  checked={!isClaimInNSWYes}
                />
              </div>
            )}
          />
        </div>

        <div className="flex flex-col gap-y-3">
          <Controller
            name="uploadedFiles"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col flex-1 gap-y-1.5">
                <FileInput
                  title="Upload any documents"
                  validFileTypes={validFileTypes}
                  maxUploaded={MAX_UPLOAD_ANY_DOCUMENT_FILE}
                  selectedFiles={field.value || []}
                  blokHints={getWorkCoverFileUploadHint()}
                  onChangeFiles={(selectedFiles: FileItem[]) => {
                    if (!isArray(field.value)) return;

                    field.onChange([...field.value, ...selectedFiles]);
                  }}
                  onRemoveFile={(fileId: string) => {
                    if (!isArray(field.value)) return;

                    field.onChange(
                      field.value.filter(({ id }) => id !== fileId)
                    );
                  }}
                />
                <TextError fieldError={errors.uploadedFiles} />
              </div>
            )}
          />
        </div>

        <div className="flex items-center justify-start mt-2">
          <button
            type="submit"
            disabled={isValidating}
            className={getDefaultButtonStyles(isValidating)}
          >
            Next
          </button>
          {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
        </div>
      </form>

      {/* ACTIONS BLOK */}
      <div className="flex flex-col flex-1 gap-y-3 order-1 h-fit md:order-2">
        {blok.actions?.map((actionBlok) => (
          <StoryblokComponent key={actionBlok._uid} blok={actionBlok} />
        ))}

        <div className="flex bg-light-grey p-7 min-w-[280px] w-full rounded-xl">
          {/* COUNTDOWN CLOCK */}
          <CountDownClock />
        </div>
      </div>
    </div>
  );
};
