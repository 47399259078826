import { StoryblokComponent } from "@storyblok/react";
import { MatchedBannerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const MatchedBanner = ({ blok }: Props<MatchedBannerStoryblok>) => {
  return (
    <div
      style={{ backgroundImage: `url(${blok.backgroundImage?.filename})` }}
      className="flex flex-col justify-center items-center bg-cover bg-no-repeat w-full h-[88vh] mb-12 px-6 md:h-[86vh] md:mb-36 lg:h-[86vh] lg:mb-36 lg:px-64 rounded-3xl"
    >
      <div className="mb-8">
        {blok.header?.map((header) => (
          <StoryblokComponent key={header._uid} blok={header} />
        ))}
      </div>

      <div className="flex flex-col gap-4 mb-12 text-center">
        {blok.description?.map((desc) => (
          <StoryblokComponent key={desc._uid} blok={desc} />
        ))}
      </div>

      <div className="text-center">
        {blok.actionButton?.map((button) => (
          <StoryblokComponent key={button._uid} blok={button} />
        ))}
      </div>
    </div>
  );
};
