import { Props } from "types/core";
import { RadioStoryblok } from "types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import { v4 } from "uuid";

export const Radio = ({ blok }: Props<RadioStoryblok>) => {
  const dynamicId = v4();

  return (
    <div className="flex items-center me-4" {...storyblokEditable(blok)}>
      <input
        id={dynamicId}
        type="radio"
        checked
        value=""
        name="colored-radio"
        className="w-4 h-4 text-primary bg-light border-neutral focus:ring-primary dark:focus:ring-primary dark:ring-offset-dark focus:ring-2 dark:bg-dark dark:border-dark"
      />
      <label
        htmlFor={dynamicId}
        className="ms-2 text-sm font-medium text-dark dark:text-light"
      >
        {blok?.label}
      </label>
    </div>
  );
};
