import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { isAxiosError } from "axios";
import { defaultTo, toNumber } from "lodash";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useToggle } from "react-use";
import { BookGpFormStoryblok } from "types/component-types-sb";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { Profile } from "models/client.model";
import { savePaymentDetailAsync } from "services/booking.service";
import { validateMedicareAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import {
  getDefaultButtonStyles,
  redirectTo,
  toDateOfBirth,
  toExpiryDate,
} from "utils";
import {
  getGPBehalfOfMySelfDefaultData,
  getUserMedicareBasicInformation,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import {
  MedicareMySelfFields,
  MedicareMySelfFieldsType,
} from "../shared/MedicareMySelfFields";
import { getBookGPMySelfFormResolver } from "./book-gp.resolver";

export type BookMedicareGPMySelfFormData = MedicareMySelfFieldsType;

type Props = {
  bookGPFormBlok: BookGpFormStoryblok;
  profile: Profile;
  accessToken: string;
};

export const GPBehalfOfMySelfForm = ({
  bookGPFormBlok,
  profile,
  accessToken,
}: Props) => {
  const [isValidating, toggleValidating] = useToggle(false);

  const {
    control,
    formState: { errors, isValid: isFormValid },
    handleSubmit,
  } = useForm<BookMedicareGPMySelfFormData>({
    mode: "onChange",
    defaultValues: getGPBehalfOfMySelfDefaultData(profile),
    resolver: yupResolver(getBookGPMySelfFormResolver(profile, accessToken)),
  });

  const { isRegisteredWithMedicareName } = useWatch({
    control,
  });

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  const isDisabledForm = isValidating || !isFormValid;

  if (!userMedicareInformation) return;

  const validateMedicareCardAsync = async (
    formData: BookMedicareGPMySelfFormData
  ): Promise<boolean> => {
    const medicareCardInfoParams = isRegisteredWithMedicareName
      ? {
          firstName: defaultTo(userMedicareInformation.firstName, ""),
          lastName: defaultTo(userMedicareInformation.lastName, ""),
          dateOfBirth: toDateOfBirth(userMedicareInformation.dateOfBirth) ?? "",
        }
      : {
          firstName: formData.firstName ?? "",
          lastName: formData.lastName ?? "",
          dateOfBirth: toDateOfBirth(formData.dateOfBirth) ?? "",
        };

    try {
      await validateMedicareAsync(
        {
          ...medicareCardInfoParams,
          number: toNumber(formData.medicareNumber),
          expiryDate: toExpiryDate(formData.expiryDate),
          irn: toNumber(formData.irnNumber),
          shouldRejectInvalidDetails: true,
        },
        accessToken
      );

      customToast.success("Valid Medicare card");

      return true;
    } catch (err) {
      if (isAxiosError(err)) {
        customToast.error("Invalid Medicare card. Please check again.");
      }

      return false;
    }
  };

  const onSubmit: SubmitHandler<BookMedicareGPMySelfFormData> = async (
    formData
  ) => {
    toggleValidating(true);

    try {
      const isValidMedicareCheck = await validateMedicareCardAsync(formData);

      if (!isValidMedicareCheck) return;

      // Put contact address
      await Promise.all([
        TacklitService.putContactAddressAsync(formData, accessToken),
      ]);

      // Update payment card details
      const { data: checkoutUrl } = await savePaymentDetailAsync(accessToken);

      if (!checkoutUrl) {
        customToast.error(
          "Failed when processing payment. Please try again later."
        );

        return;
      }

      redirectTo(checkoutUrl);
    } catch (err) {
      console.log("[error]: >>", err);
    } finally {
      toggleValidating(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col flex-1 gap-y-5"
    >
      <div className="flex flex-col w-full gap-y-4">
        {/* MEDICARE GP MYSELF */}
        <MedicareMySelfFields<BookMedicareGPMySelfFormData>
          control={control}
          errors={errors}
          userInformation={userMedicareInformation}
          medicareNumberHints={bookGPFormBlok.medicareNumberHints}
          irnHints={bookGPFormBlok.irnHints}
          expiryDateHints={bookGPFormBlok.expiryDateHints}
          streetAddressHints={bookGPFormBlok.streetAddressHints}
          medicareImageAsset={bookGPFormBlok.medicareImage}
          medicareImageAlt={bookGPFormBlok.medicareImageAlt}
        />

        {/* GP Summary */}
        {bookGPFormBlok?.gpSummaries?.map((gpSummaryItem) => (
          <StoryblokComponent key={gpSummaryItem._uid} blok={gpSummaryItem} />
        ))}
      </div>

      <div className="flex items-center justify-start">
        <button
          type="submit"
          disabled={isDisabledForm}
          className={getDefaultButtonStyles()}
        >
          Proceed to payment details
        </button>

        {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
      </div>
    </form>
  );
};
