import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { AboutReferenceItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const AboutReferenceItem = ({
  blok,
}: Props<AboutReferenceItemStoryblok>) => {
  return (
    <div
      className="flex flex-col justify-between gap-y-4 py-6 px-7 border border-light-grey-2 rounded-2xl xl:max-w-[400px]"
      {...storyblokEditable(blok)}
    >
      {blok?.icon && (
        <span className="flex items-center h-[54px]">
          <img
            className="max-w-full max-h-full"
            src={blok.icon.filename}
            alt={blok.icon.alt}
          />
        </span>
      )}

      <div>
        {blok?.description?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>

      <div>
        {blok?.actions?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  );
};
