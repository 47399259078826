import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ArticleReferenceStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

type ArticleReferenceProps = Props<ArticleReferenceStoryblok> & {
  index?: number;
};

export const ArticleReference = ({ blok, index }: ArticleReferenceProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.content?.map((blok) => (
        <div
          key={blok._uid}
          className="flex items-start gap-2 -mb-1 md:-mb-2 xl:-mb-3"
        >
          {index ? (
            <p className="leading-relaxed min-w-[25px] text-right">{index}.</p>
          ) : (
            <div className="size-4 bg-secondary rounded-full"></div>
          )}

          <div className="min-w-[100px] break-words">
            <StoryblokComponent blok={blok} />
          </div>
        </div>
      ))}
    </div>
  );
};
