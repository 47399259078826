import {
  PsychologistDetailQuoteStoryblok,
  QuoteStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PsychologistResponse } from "@/services/psychologist.service";

type CustomProps = Props<PsychologistDetailQuoteStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailQuote = ({
  blok,
  psychologist,
}: CustomProps) => {
  const { helmControl } = psychologist;

  const quoteBlok = {
    content: helmControl?.quote || "Unknown",
    component: "quote",
  } as QuoteStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex items-center justify-start py-3 text-xl font-normal leading-9 md:px-12 md:py-7"
    >
      <StoryblokComponent blok={quoteBlok} />
    </div>
  );
};
