import { AddressFieldsType, AdultFieldsType, MyChildFieldsType } from "models";
import {
  postAdultContactAsync,
  postClientGroupAsync,
  postGuardianAdditionalProfileAsync,
  putContactAddressAsync,
} from "services/booking.service";
import { getGroupIdByFundingMethod } from "utils";

export const TacklitService = {
  // PUT /client-records/me/address
  putContactAddressAsync: async (
    formData: AddressFieldsType,
    accessToken?: string
  ) => {
    const putAddressAsync = putContactAddressAsync(
      {
        line1: formData?.streetAddress,
        line2: formData?.streetLineTwoAddress,
        suburb: formData?.city,
        state: formData?.state,
        postcode: formData?.postcode,
      },
      accessToken
    );

    await putAddressAsync;
  },

  // POST /client-records/me/key-client-contacts
  putAdultContactAsync: async (
    formData: AdultFieldsType,
    accessToken?: string
  ) => {
    // if (formData?.behalfOf !== BookBehalfOfType.SOMEONE_ELSE_ADULT) return;

    const postAsync = postAdultContactAsync(
      {
        firstName: formData?.bookerName,
        mobileNumber: formData?.bookerPhone,
      },
      accessToken
    );

    await postAsync;
  },

  // POST /client-records/me/additional-profiles
  putChildGuardianProfileAsync: async (
    formData: MyChildFieldsType,
    accessToken?: string
  ) => {
    // if (formData?.behalfOf !== BookBehalfOfType.MY_CHILD) return;

    const postAsync = postGuardianAdditionalProfileAsync(
      {
        firstName: formData.parentFirstName,
        lastName: formData.parentLastName,
        email: formData.parentEmail,
      },
      accessToken
    );

    await postAsync;
  },

  // POST /client-records/me/group
  addClientToGroupAsync: async (accessToken?: string) => {
    const groupId = getGroupIdByFundingMethod();

    if (!groupId) return;

    const postAsync = postClientGroupAsync(groupId, accessToken);

    await postAsync;
  },
};
