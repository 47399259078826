import { ISbStoriesParams } from "@storyblok/react";

const SOH_CLIENT_ID = process.env.REACT_APP_SOH_CLIENT_ID as string;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID as string;
const HUBSPOT_CONTACT_US_FORM_ID = process.env
  .REACT_APP_HUBSPOT_CONTACT_US_FORM_ID as string;
const HUBSPOT_CONTACT_INFORMATION_FORM_ID = process.env
  .REACT_APP_HUBSPOT_CONTACT_INFORMATION_FORM_ID as string;
const GTM_TRACKING_ID = process.env.REACT_APP_GTM_TRACKING_ID as string;

type GroupId = {
  SELF_FUND: string;
  BULK_BILL: string;
  REBATE: string;
  DVA: string;
  NDIS_PLAN_MANAGED: string;
  NDIS_SELF_MANAGED: string;
  WORKCOVER: string;
  WORKCOVER_NSW: string;
};

type EnvConfig = {
  ACCOUNT_ID: string;
  TACKLIT_CLIENT_PORTAL_URL: string;
  TACKLIT_ADMIN_PORTAL_URL: string;
  CLINICIAN_BASE_URL: string;
  SCHEDULE_BASE_URL: string;
  BILLING_BASE_URL: string;
  NOTIFY_SERVICE_BASE_URL: string;
  PATIENT_PROFILE_BASE_URL: string;
  CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  HUBSPOT_BASE_URL: string;
  HUBSPOT_PORTAL_ID: string;
  HUBSPOT_CONTACT_US_FORM_ID: string;
  HUBSPOT_CONTACT_INFORMATION_FORM_ID: string;
  HUBSPOT_SUBSCRIPTION_MARKETING_ID: string;
  GTM_TRACKING_ID: string;
  GROUP_IDS: GroupId;
};

const DevEnvConfig: EnvConfig = {
  ACCOUNT_ID: "63e61a064ce669000a55ea7c",
  TACKLIT_CLIENT_PORTAL_URL: "https://my.tacklit.co",
  TACKLIT_ADMIN_PORTAL_URL: "https://dev.tacklit.com",
  CLINICIAN_BASE_URL: "https://clinician-profile-service.tacklit.com",
  SCHEDULE_BASE_URL: "https://sched-service.tacklit.com/",
  BILLING_BASE_URL: "https://billing-service.tacklit.com",
  NOTIFY_SERVICE_BASE_URL: "https://notif-service.tacklit.com",
  PATIENT_PROFILE_BASE_URL: "https://patient-profile-service.tacklit.com",
  CLIENT_ID: SOH_CLIENT_ID,
  AUTH0_DOMAIN: AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: "tacklit-eu-apis",
  HUBSPOT_BASE_URL: "https://api.hsforms.com",
  HUBSPOT_PORTAL_ID: HUBSPOT_PORTAL_ID,
  HUBSPOT_CONTACT_US_FORM_ID: HUBSPOT_CONTACT_US_FORM_ID,
  HUBSPOT_CONTACT_INFORMATION_FORM_ID: HUBSPOT_CONTACT_INFORMATION_FORM_ID,
  HUBSPOT_SUBSCRIPTION_MARKETING_ID: "314938623",
  GTM_TRACKING_ID: GTM_TRACKING_ID,
  GROUP_IDS: {
    SELF_FUND: "6687bc09f1cdef70133967be",
    BULK_BILL: "6687bc8bf1cdef7013396a9c",
    REBATE: "6687bd96f1cdef7013397d84",
    DVA: "6687bcabf1cdef7013396aec",
    NDIS_PLAN_MANAGED: "6687bce7f1cdef7013396b69",
    NDIS_SELF_MANAGED: "6687bd1cf1cdef70133971d5",
    WORKCOVER: "6687bd52f1cdef7013397ba5",
    WORKCOVER_NSW: "6687bd5ef1cdef7013397bef",
  },
};

const ProdEnvConfig: EnvConfig = {
  ACCOUNT_ID: "63cdc36eab1b8d000a35504b",
  TACKLIT_CLIENT_PORTAL_URL: "https://my.someone.health",
  TACKLIT_ADMIN_PORTAL_URL: "https://my.someone.health",
  CLINICIAN_BASE_URL: "https://au.clinician-profile-service.tacklit.com",
  SCHEDULE_BASE_URL: "https://au.sched-service.tacklit.com",
  BILLING_BASE_URL: "https://au.billing-service.tacklit.com",
  NOTIFY_SERVICE_BASE_URL: "https://au.notif-service.tacklit.com",
  PATIENT_PROFILE_BASE_URL: "https://au.patient-profile-service.tacklit.com",
  CLIENT_ID: SOH_CLIENT_ID,
  AUTH0_DOMAIN: AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: "tacklit-au-apis",
  HUBSPOT_BASE_URL: "https://api.hsforms.com",
  HUBSPOT_PORTAL_ID: HUBSPOT_PORTAL_ID,
  HUBSPOT_CONTACT_US_FORM_ID: HUBSPOT_CONTACT_US_FORM_ID,
  HUBSPOT_CONTACT_INFORMATION_FORM_ID: HUBSPOT_CONTACT_INFORMATION_FORM_ID,
  HUBSPOT_SUBSCRIPTION_MARKETING_ID: "274310252",
  GTM_TRACKING_ID: GTM_TRACKING_ID,
  GROUP_IDS: {
    SELF_FUND: "640a916b4fc76d000b31c470",
    BULK_BILL: "65827a09f7c26c4c8aa87563",
    REBATE: "6670ef0a55729da31f464e48",
    DVA: "65efa4aa0da6df1a06db2c1b",
    NDIS_PLAN_MANAGED: "665e5db3d30e8ea2f9a055c9",
    NDIS_SELF_MANAGED: "6670eef055729da31f464117",
    WORKCOVER: "6666ae4f5e444a030138fbcd",
    WORKCOVER_NSW: "6666ae8f5e444a030139062c",
  },
};

export const globalConfig =
  process.env.REACT_APP_ENV_NAME === "PROD" ? ProdEnvConfig : DevEnvConfig;

export const isProdMode = process.env.NODE_ENV === "production";

export const isTestMode = process.env.REACT_APP_ENV_NAME === "DEV";

export const storyblokPublishedVersion: ISbStoriesParams["version"] =
  process.env.REACT_APP_STORYBLOK_VERSION === "published"
    ? "published"
    : "draft";
