import {
  CheckpointStoryblok,
  HeadingStoryblok,
  PsychologistDetailTrainedInStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PsychologistResponse } from "@/services/psychologist.service";

type CustomProps = Props<PsychologistDetailTrainedInStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailTrainedIn = ({
  blok,
  psychologist,
}: CustomProps) => {
  const { helmControl } = psychologist;

  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5"
    >
      <StoryblokComponent blok={headingBlok} />

      <div className="flex flex-col gap-5">
        {helmControl?.styles?.map((style, index) => {
          const checkpointBlok = {
            icon: blok.icon,
            text: style,
            icon_alt: blok.icon_alt,
            component: "checkpoint",
          } as CheckpointStoryblok;

          return <StoryblokComponent key={index} blok={checkpointBlok} />;
        })}
      </div>
    </div>
  );
};
