import * as Yup from "yup";

import { Profile } from "models/client.model";
import {
  getAddressFormValidation,
  getMedicareChildFieldsValidation,
  getMedicareMySelfFieldsValidation,
  getMedicareSomeOneFieldsValidation,
} from "../shared/shared.resolver";

export const getBookGPMySelfFormResolver = (
  profile: Profile,
  accessToken: string
) =>
  Yup.object()
    .concat(getAddressFormValidation(accessToken))
    .concat(getMedicareMySelfFieldsValidation(profile, accessToken));

export const getBookGPSomeOneFormResolver = (
  profile: Profile,
  accessToken: string
) =>
  Yup.object()
    .concat(getAddressFormValidation(accessToken))
    .concat(getMedicareSomeOneFieldsValidation(profile, accessToken));

export const getBookGPChildFormResolver = (
  profile: Profile,
  accessToken: string
) =>
  Yup.object({
    isConsentForParent: Yup.boolean().required(),
  })
    .concat(getAddressFormValidation(accessToken))
    .concat(getMedicareChildFieldsValidation(profile, accessToken));
