import { TimeZoneType } from "enums";
import { useBreakpoint } from "hooks/useBreakpoint";
import { get, isEqual } from "lodash";
import { TimeSlot } from "models";
import { auLabelledTimeZone, isDaylightSavingTime } from "utils";

type Props = {
  timeSlot: TimeSlot;
  timeSlotSelected: TimeSlot | null | undefined;
  timeZoneSelected: TimeZoneType;

  onSelectTimeSlot: (timeSlot: TimeSlot) => void;
};

export const TimeSlotItem = ({
  timeSlot,
  timeSlotSelected,
  timeZoneSelected,
  ...props
}: Props) => {
  const breakpoint = useBreakpoint();

  const isMatchedTimeSlot = (timeSlot: TimeSlot) => {
    const { startTime, endTime } = timeSlotSelected || {};

    return (
      isEqual(startTime, timeSlot.startTime) &&
      isEqual(endTime, timeSlot.endTime)
    );
  };

  const getTimeZoneCode = (timezone: TimeZoneType): string | undefined => {
    const labelledTimeZone = get(auLabelledTimeZone, timezone);

    if (!labelledTimeZone) return timezone;

    const isDst = isDaylightSavingTime(timezone);

    return isDst ? labelledTimeZone.dstCode : labelledTimeZone.nonDstCode;
  };

  return (
    <button
      className={`p-3 border border-secondary-darker text-sm rounded-md duration-200 ${
        isMatchedTimeSlot(timeSlot)
          ? "bg-secondary-darker text-primary"
          : "bg-white"
      }`}
      onClick={() => props?.onSelectTimeSlot(timeSlot)}
    >
      <span>
        {timeSlot.startTime} - {timeSlot.endTime}
      </span>
      <span>{breakpoint.isXs ? <br /> : " "}</span>(
      <span>{getTimeZoneCode(timeZoneSelected)})</span>
    </button>
  );
};
