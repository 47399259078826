import { BadgeStoryblok } from "@/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { BadgeSizes, BadgeTypes } from "enums";
import { isEmpty } from "lodash";
import { Routes } from "routes/main.routes";
import { cn, toNoCachedLink, toSafeLink } from "utils";

const types: Record<string, string> = {
  [BadgeTypes.SOLID]: "text-neutral bg-primary",
  [BadgeTypes.OUTLINED]: "text-primary bg-white ring-2 ring-primary",
  [BadgeTypes.PLAIN]: "text-black bg-neutral",
  [BadgeTypes.SECONDARY]: "text-primary bg-secondary",
  [BadgeTypes.SECONDARY_LIGHT]: "text-primary bg-secondary-light",
};

const sizes: Record<string, string> = {
  [BadgeSizes.MEDIUM]: "px-3.5 h-[30px] text-sm",
  [BadgeSizes.SMALL]: "px-[10px] py-[1px] h-[18px] text-10 leading-[18px]",
};

export const Badge = ({ blok }: BadgeStoryblok) => {
  const navigateUrl = toNoCachedLink(blok?.link);

  const classes = cn(
    "inline-flex justify-center items-center rounded-2xl font-medium duration-300",
    {
      uppercase: Boolean(blok?.isUpperCase),
      [types[blok?.type]]: Boolean(blok?.type),
      [sizes[blok?.size]]: Boolean(blok?.size),
      "hover:text-primary hover:bg-secondary hover:duration-300 cursor-pointer underline":
        !isEmpty(navigateUrl),
    }
  );

  return (
    <LinkWrapper
      disabled={isEmpty(navigateUrl)}
      to={toSafeLink(navigateUrl) || Routes.PLACEHOLDER}
      target={blok?.link?.target}
    >
      <div className={classes} {...storyblokEditable(blok)}>
        <span className="truncate">{blok.name}</span>
      </div>
    </LinkWrapper>
  );
};
