import { Props } from "@/types/core";
import { StatItemStoryblok } from "@/types/component-types-sb";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { cn, getStatItemAlignment } from "utils";

export const StatItem = ({ blok }: Props<StatItemStoryblok>) => {
  const itemAlignment = getStatItemAlignment(blok.alignment);

  return (
    <div
      className={cn("flex flex-col gap-y-2.5", itemAlignment)}
      {...storyblokEditable(blok)}
    >
      <div className="flex h-[40px]">
        <img
          src={blok.icon.filename}
          alt={blok.icon.alt}
          className="size-full"
        />
      </div>
      <div className={cn("flex-1 flex-col gap-2.5")}>
        {blok.content?.map((content) => (
          <StoryblokComponent key={content._uid} blok={content} />
        ))}
      </div>
    </div>
  );
};
