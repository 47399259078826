import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInputFormStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export type CustomProps = Props<CheckboxInputFormStoryblok> & {
  name: string;
};

export const CheckboxInputForm = ({ blok, name }: CustomProps) => {
  const isMultipleSelect = Boolean(blok.isMultipleSelect);

  const formFields = new Map(
    blok?.items?.map((item) => [`${name}.${item._uid}`, item.value])
  );

  const { setValue, control } = useFormContext();

  const handleCheck = (fieldName: string) => {
    if (isMultipleSelect) return;

    for (const key of formFields.keys()) {
      fieldName !== key && setValue(key, "");
    }
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex flex-col justify-between h-full gap-8">
        <div className="flex flex-col gap-4">
          {blok.items?.map((item) => (
            <Controller
              key={item._uid}
              name={`${name}.${item._uid}`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StoryblokComponent
                  key={item._uid}
                  blok={item}
                  field={field}
                  isMultipleSelect={isMultipleSelect}
                  value={formFields.get(field.name)}
                  onCheck={handleCheck}
                />
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
