import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { ExternalRoutes } from "routes/main.routes";
import {
  cn,
  getAttachedPsychologistAuth0,
  getDefaultButtonStyles,
  getDefaultOutlinedButtonStyles,
  getDefaultSoftButtonStyles,
  toPsychologistDetailsPath,
} from "utils";
import {
  cleanupStoredUserSessionLogout,
  removeStoredFundingMethod,
} from "utils/storage.util";

type Props = {
  isShowMobileMenu: boolean;

  shouldHideFindPsychologistButton: boolean;
};

export const ProfileMenuMobile = ({
  isShowMobileMenu,
  shouldHideFindPsychologistButton,
}: Props) => {
  const { logout } = useAuth0();

  const { data: attachedClinician } = useSwrAttachedClinician();

  const navStyles = cn(
    "text-primary rounded-lg text-15 font-medium bg-none py-1"
  );

  return (
    <div
      className={cn(
        "flex flex-col w-full overflow-y-scroll no-scrollbar",
        isShowMobileMenu ? "h-screen duration-500 pb-52" : "h-0 duration-500"
      )}
    >
      <div className="bg-secondary-darker h-[1px] mx-6"></div>

      <div className="flex flex-col pt-5 pb-2 mx-6 gap-y-3">
        <LinkWrapper to={ExternalRoutes.MY_APPOINTMENTS} className={navStyles}>
          Manage my appointments
        </LinkWrapper>

        <LinkWrapper to={ExternalRoutes.MY_PROFILE} className={navStyles}>
          Manage my profile
        </LinkWrapper>

        <LinkWrapper to={ExternalRoutes.REFERRAL} className={navStyles}>
          Add referral details
        </LinkWrapper>
      </div>

      <div
        className={cn(
          "w-full fixed bottom-5 justify-self-end px-6",
          isShowMobileMenu
            ? "grid-rows-[1fr] opacity-100 duration-1000"
            : "grid-rows-[0fr] opacity-0"
        )}
      >
        <div
          className={cn("flex flex-col gap-3 w-full", {
            hidden: !isShowMobileMenu,
          })}
        >
          <LinkWrapper
            to={toPsychologistDetailsPath(getAttachedPsychologistAuth0())}
            disabled={!attachedClinician?.clinician}
          >
            <button
              className={cn(getDefaultButtonStyles(), "w-full")}
              disabled={!attachedClinician?.clinician}
              onClick={removeStoredFundingMethod}
            >
              Book my psychologist
            </button>
          </LinkWrapper>

          <LinkWrapper to={ExternalRoutes.GP_BOOKING}>
            <button className={cn(getDefaultSoftButtonStyles(), "w-full")}>
              Book a GP
            </button>
          </LinkWrapper>

          <div className="bg-secondary-darker h-[1px] mx-6 my-4"></div>

          {renderSmallScreenSignOutButton(
            logout,
            shouldHideFindPsychologistButton
          )}
        </div>
      </div>
    </div>
  );
};

const renderSmallScreenSignOutButton = (
  logout: (options?: LogoutOptions | undefined) => Promise<void>,
  shouldHideFindPsychologistButton: boolean
) => {
  const signOutButton = (
    <button
      className={cn(getDefaultOutlinedButtonStyles(), "w-full")}
      onClick={() => {
        logout({ logoutParams: { returnTo: window.location.origin } });

        cleanupStoredUserSessionLogout();
      }}
    >
      Sign out
    </button>
  );

  return shouldHideFindPsychologistButton ? (
    <div className="flex">
      {signOutButton}

      <div className="w-[90px]">{/* PLACEHOLDER FOR CHAT ICON */}</div>
    </div>
  ) : (
    <div>
      {signOutButton}

      <div className="h-[70px]">
        {/* PLACEHOLDER FOR FIND A PSYCHOLOGIST BUTTON*/}
      </div>
    </div>
  );
};
