import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { Props } from "@/types/core";
import { StatsStoryblok } from "@/types/component-types-sb";
import { cn, getStatLayoutMode } from "utils";

export const Stats = ({ blok }: Props<StatsStoryblok>) => {
  const layoutMode = getStatLayoutMode(blok.layoutMode);

  return (
    <div {...storyblokEditable(blok)} className={cn("mb-12")}>
      <div className={cn("grid gap-30 justify-evenly py-5", layoutMode)}>
        {blok.item?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  );
};
