import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { JourneyItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const JourneyItem = ({
  blok,
  otherProps,
}: Props<JourneyItemStoryblok>) => {
  const isMobile = Boolean(otherProps?.isMobile);

  return (
    <div className={cn({ "mb-[68px]": isMobile })}>
      <div
        className={cn({
          flex: blok.image?.filename,
          "flex-row-reverse": !isMobile && blok.isReverseContent,
          "min-w-[630px] max-w-[800px] gap-4": !isMobile,
          "flex flex-col": isMobile,
        })}
        {...storyblokEditable(blok)}
      >
        {blok.image?.filename && (
          <img
            src={blok.image?.filename}
            alt={blok.image?.alt}
            className={cn({ "max-w-[250px] self-center": isMobile })}
          />
        )}

        <div
          className={cn("flex flex-col self-center gap-2", {
            "bg-neutral rounded-2xl p-4 md:p-8": blok.isHighlight,
          })}
        >
          {blok.content?.map((content) => (
            <StoryblokComponent key={content._uid} blok={content} />
          ))}
        </div>
      </div>

      {isMobile && (
        <div className="absolute block left-1/2 w-[2px] h-[68px] p-0 bg-secondary-darker"></div>
      )}
    </div>
  );
};
