import * as Yup from "yup";

import { ONLY_DIGITS_REGEX, WORKCOVER_NUMBER_REGEX } from "core/regex.constant";
import { toRequiredMessage, validateBehalfOfSomeOne } from "utils";
import { getAddressFormValidation } from "../shared/shared.resolver";

export const getBookWorkCoverResolver = (accessToken: string) =>
  Yup.object({
    behalfOf: Yup.string().required(toRequiredMessage("Behalf of")),
    bookerName: Yup.string()
      .trim()
      .when("behalfOf", ([behalfOf], field) =>
        validateBehalfOfSomeOne(behalfOf)
          ? field.required(toRequiredMessage("Your name"))
          : field.optional()
      ),
    bookerPhone: Yup.string()
      .trim()
      .when("behalfOf", ([behalfOf], field) =>
        validateBehalfOfSomeOne(behalfOf)
          ? field.required(toRequiredMessage("Your contact"))
          : field.optional()
      ),
    workCoverNumber: Yup.string()
      .required(toRequiredMessage("WorkCover number"))
      .matches(
        WORKCOVER_NUMBER_REGEX,
        "WorkCover number must be between 7 and 12 characters long and contain only alphanumeric characters"
      ),
    managerDetail: Yup.string()
      .trim()
      .required(toRequiredMessage("Manager detail")),
    totalApproved: Yup.string()
      .required(toRequiredMessage("Appointments approved"))
      .matches(ONLY_DIGITS_REGEX, "Must be only digits"),
    isClaimInNSW: Yup.string().required(toRequiredMessage("WorkCover claim")),
    uploadedFiles: Yup.array().max(6, "The maximum file is six"),
  }).concat(getAddressFormValidation(accessToken));
