type Props = {
  className: string;
  color?: string;
};

export const CloseSvg = ({ className, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      className={className}
      color={color}
    >
      <path
        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
        stroke="black"
        strokeWidth="20"
      />
    </svg>
  );
};
