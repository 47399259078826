/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { isValidPhoneNumber } from "libphonenumber-js";
import { defaultTo, get, last } from "lodash";
import {
  CheckboxField,
  CheckboxInputFormData,
  ContactInformationFormData,
  GetMatchedData,
  HubspotObjectTypeId,
  HubspotSubmissionDataField,
  PsychologistRequestQueries,
  QuestionStepperFormData,
  StepGroupName,
  StepUsedFor,
  questionStepFieldName,
} from "models";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffectOnce, useToggle } from "react-use";
import { Routes } from "routes/main.routes";
import { submitGetMatchedToHubspot } from "services/hubspot.service";
import { match } from "ts-pattern";
import {
  CheckboxInputFormStoryblok,
  ContactInformationFormStoryblok,
  QuestionStepStoryblok,
  QuestionStepperStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";
import { cn, getDefaultButtonStyles, redirectTo, stepperStyles } from "utils";
import { setStoredGetMatched } from "utils/storage.util";
import * as Yup from "yup";

export const QuestionStepper = ({ blok }: Props<QuestionStepperStoryblok>) => {
  const [isLoading, toggleLoading] = useToggle(false);
  const [currentStep, setCurrentStep] = useState(0);
  const numberOfStep = Number(blok.steps?.length);

  const stepperFields = new Map(
    blok.steps.map((step, index) => [`${index}_${step.groupName}`, step])
  );

  const getStepperFormResolver = () => {
    return Yup.object().shape(
      [...stepperFields.entries()].reduce(
        (schema, [fieldName, step]) => ({
          ...schema,
          [fieldName]: getStepFormResolver(step),
        }),
        {}
      )
    );
  };

  const getStepFormResolver = (step: QuestionStepStoryblok) => {
    const answerDataSchema = getAnswerFormResolver(
      step.answer[0] as CheckboxInputFormStoryblok,
      Boolean(step.isOptional)
    );

    if (answerDataSchema) {
      return Yup.object().shape({
        [questionStepFieldName]: answerDataSchema,
      });
    }
  };

  const getAnswerFormResolver = (
    answer: CheckboxInputFormStoryblok | ContactInformationFormStoryblok,
    isOptional: boolean
  ) => {
    if (answer.component === "checkboxInputForm") {
      const answerFormFields: CheckboxField[] =
        answer.items?.map((item) => ({
          name: item._uid,
          value: item.value,
        })) ?? [];

      const answerCheckboxDataSchema = Yup.object().shape(
        answerFormFields.reduce(
          (schema, field) => ({ ...schema, [field.name]: Yup.string() }),
          {}
        )
      );

      if (!isOptional) {
        return answerCheckboxDataSchema.test({
          name: "atLeastOneOfAnswers",
          message: "Must have at least one of answers",
          exclusive: true,
          params: { keys: answerFormFields.join(", ") },
          test: (value: CheckboxInputFormData) =>
            value && answerFormFields.some((field) => value[field.name]),
        });
      }

      return answerCheckboxDataSchema;
    }

    if (answer.component === "contactInformationForm") {
      const answerContactDataSchema = Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        phoneNumber: Yup.string().test({
          name: "phone",
          message: "Invalid phone number",
          test: (phoneNumber) => {
            if (isOptional) return true;

            if (
              !phoneNumber ||
              !isValidPhoneNumber(phoneNumber || "", DEFAULT_PHONE_COUNTRY_CODE)
            )
              return false;

            return true;
          },
        }),
        email: Yup.string().email("Invalid email"),
        consentPrivacy: Yup.boolean().optional(),
      });

      if (!isOptional) {
        return answerContactDataSchema.test({
          name: "allOfAnswers",
          message: `This section requires all fields to be filled out`,
          exclusive: true,
          params: { keys: "firstName, lastName, phoneNumber, email" },
          test: (value: ContactInformationFormData) => {
            return value && Object.values(value).every((_) => _);
          },
        });
      }

      return answerContactDataSchema;
    }
  };

  const getStepperFormDefaultData = () => {
    const stepperFormDefaultData = [...stepperFields.entries()].reduce(
      (defaultData, [fieldName, step]) => ({
        ...defaultData,
        [fieldName]: getStepFormDefaultData(step),
      }),
      {}
    );

    return stepperFormDefaultData;
  };

  const getStepFormDefaultData = (step: QuestionStepStoryblok) => ({
    [questionStepFieldName]: getAnswerFormDefaultData(
      step.answer[0] as CheckboxInputFormStoryblok
    ),
  });

  const getAnswerFormDefaultData = (
    answer: CheckboxInputFormStoryblok | ContactInformationFormStoryblok
  ) => {
    if (answer.component === "checkboxInputForm") {
      const formFields: CheckboxField[] =
        answer.items?.map((item) => ({
          name: item._uid,
          value: item.value,
        })) ?? [];

      const checkboxInputFormData = formFields.reduce(
        (formData, field) => ({ ...formData, [field.name]: "" }),
        {}
      ) as CheckboxInputFormData;

      return checkboxInputFormData;
    }

    if (answer.component === "contactInformationForm") {
      const contactFormData: ContactInformationFormData = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      };

      return contactFormData;
    }
  };

  const methods = useForm<QuestionStepperFormData>({
    mode: "onChange",
    defaultValues: getStepperFormDefaultData(),
    resolver: yupResolver(getStepperFormResolver()),
  });

  const {
    control,
    getFieldState,
    handleSubmit,
    formState: { isValid, isValidating },
    trigger,
  } = methods;

  useEffectOnce(() => {
    trigger();
  });

  useEffect(() => {
    if (!isValidating) return;

    trigger();
  }, [isValidating]);

  const handleChangeStep = (change: number) => {
    setCurrentStep(currentStep + change);
  };

  const handleFormSubmit = async (formData: QuestionStepperFormData) => {
    try {
      toggleLoading(true);

      const extractedHubspotData = extractHubspotData(formData);
      const hasConsent = get(formData, "consentPrivacy", undefined);

      await submitGetMatchedToHubspot(extractedHubspotData, hasConsent);

      const extractedFormData = extractFormData(formData);
      setStoredGetMatched(extractedFormData);

      if (extractedFormData.getGpReferral) {
        redirectTo(Routes.GET_MATCHED_RESULTS_GP);

        return;
      }

      redirectTo(Routes.GET_MATCHED_RESULTS);
    } catch (err) {
      console.log("[error] :>> ", err);
    } finally {
      toggleLoading(false);
    }
  };

  const extractFormData = (formData: QuestionStepperFormData) => {
    let getMatchedData: Partial<GetMatchedData> = {};

    if (formData) {
      Object.keys(formData).forEach((key) => {
        if (!stepperFields.has(key)) return;

        if (
          !getMatchedData.getGpReferral &&
          stepperFields.get(key)?.usedFor === StepUsedFor.GP_FLOW
        ) {
          getMatchedData.getGpReferral = !!Object.values(
            formData[key][questionStepFieldName]
          ).find((value) => value === "getGpReferral");

          return;
        }

        const groupName = last(key.split("_"));

        if (
          stepperFields.get(key)?.usedFor === StepUsedFor.API_FILTER &&
          groupName
        ) {
          getMatchedData.apiQueries = {
            ...getMatchedData.apiQueries,
            [groupName]: Object.values(formData[key][questionStepFieldName])
              .filter((value: any) => value && value?.trim())
              .join(",")
              .trim(),
          } as PsychologistRequestQueries;
        }
      });
    }

    return getMatchedData;
  };

  const extractHubspotData = (
    formData: QuestionStepperFormData
  ): HubspotSubmissionDataField[] => {
    let hubspotSubmissionFields: HubspotSubmissionDataField[] = [];

    if (formData) {
      Object.keys(formData).forEach((key) => {
        if (!stepperFields.has(key)) return;

        const groupName = last(key.split("_"));

        if (groupName === StepGroupName.CONTACT_INFORMATION) {
          const contactInformation: ContactInformationFormData =
            formData[key][questionStepFieldName];

          hubspotSubmissionFields = hubspotSubmissionFields.concat([
            {
              objectTypeId: HubspotObjectTypeId.CONTACTS,
              name: "firstname",
              value: defaultTo(contactInformation.firstName, ""),
            },
            {
              objectTypeId: HubspotObjectTypeId.CONTACTS,
              name: "lastname",
              value: defaultTo(contactInformation.lastName, ""),
            },
            {
              objectTypeId: HubspotObjectTypeId.CONTACTS,
              name: "email",
              value: defaultTo(contactInformation.email, ""),
            },
            {
              objectTypeId: HubspotObjectTypeId.CONTACTS,
              name: "mobilephone",
              value: defaultTo(contactInformation.phoneNumber, ""),
            },
          ]);

          return;
        }

        const checkboxValue = Object.values(
          formData[key][questionStepFieldName]
        )
          .filter((value: any) => value && value?.trim())
          .join(";");

        const dataFieldName = match(groupName)
          .with(
            StepGroupName.CLIENT_MENTAL_HEALTH_CONCERN,
            () => "reason_for_therapy"
          )
          .with(
            StepGroupName.CLIENT_OTHER_CONCERNS,
            () => "other_concerns_to_consider"
          )
          .with(StepGroupName.GENDER, () => "therapist_gender")
          .with(StepGroupName.DAYS, () => "preferred_day")
          .with(StepGroupName.TIME, () => "preferred_time")
          .with(StepGroupName.SCHEDULE, () => "preferred_start")
          .with(StepGroupName.GP_REFERRAL, () => "mhtp")
          .otherwise(() => null);

        dataFieldName &&
          hubspotSubmissionFields.push({
            objectTypeId: HubspotObjectTypeId.CONTACTS,
            name: dataFieldName,
            value: defaultTo(checkboxValue, ""),
          });
      });
    }

    return hubspotSubmissionFields;
  };

  return (
    <div {...storyblokEditable(blok)}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div>
            {[...stepperFields.entries()].map(([fieldName, step], index) => (
              <Controller
                key={step._uid}
                name={fieldName}
                control={control}
                render={() => (
                  <StoryblokComponent
                    key={step._uid}
                    blok={step}
                    name={fieldName}
                    isDisplay={index === currentStep}
                  />
                )}
              />
            ))}
          </div>

          <div className={cn(stepperStyles, "max-md:px-0 lg:gap-8 xl:px-12")}>
            <div
              className={cn(
                "flex justify-between gap-5 col-start-2 w-full max-w-[650px]",
                currentStep > 0 ? "flex-row" : "flex-row-reverse"
              )}
            >
              <button
                type="button"
                className={cn("h-[50px]", getDefaultButtonStyles(), {
                  hidden: !(currentStep > 0),
                })}
                onClick={() => {
                  handleChangeStep(-1);
                }}
              >
                &lt; Back
              </button>

              <div className="flex flex-row items-center gap-3">
                {currentStep !== numberOfStep - 1 && (
                  <button
                    onClick={() => {
                      handleChangeStep(1);
                    }}
                    disabled={
                      getFieldState(
                        Array.from(stepperFields.keys())[currentStep]
                      ).invalid
                    }
                    type="button"
                    className={getDefaultButtonStyles()}
                  >
                    Next &gt;
                  </button>
                )}

                {currentStep === numberOfStep - 1 && (
                  <button
                    disabled={isLoading || !isValid}
                    type="submit"
                    className={getDefaultButtonStyles(isLoading)}
                  >
                    Show results
                  </button>
                )}
                {isLoading && <SpinnerLoading className="w-12 h-12" />}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
