import { StoryblokComponent } from "@storyblok/react";
import { isArray } from "lodash";
import { AboutValueContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const AboutValueContainer = ({
  blok,
}: Props<AboutValueContainerStoryblok>) => {
  return (
    <div className="flex flex-col gap-y-5 my-6">
      <h2 className="text-primary text-3xl lg:text-4xl text-center">
        {blok.title}
      </h2>

      {isArray(blok?.items) && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 xl:max-w-[1400px] px-6 xl:mx-auto">
          {blok.items.map((item) => (
            <StoryblokComponent key={item._uid} blok={item} />
          ))}
        </div>
      )}
    </div>
  );
};
