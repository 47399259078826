import { PropsWithChildren } from "react";

import { Toaster } from "react-hot-toast";
import { Duration } from "utils";

export const ToasterProvider = ({ children }: PropsWithChildren) => {
  return (
    <>
      <>{children}</>

      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: Duration.minutes(1) }}
      />
    </>
  );
};
