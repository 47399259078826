import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEqual } from "lodash";
import { FinaliseBookingContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { useAuth0 } from "@auth0/auth0-react";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { Funding } from "models";
import { useEffect, useState } from "react";
import {
  renderSessionBookingWarning,
  validateStoredBookingData,
} from "utils/warning.util";
import {
  renderConfirmBookingContainerHeading,
  renderConfirmBookingForm,
  renderConfirmBookingSummarySection,
} from "./booking-finalise.util";

export const ConfirmBookingContainer = ({
  blok,
}: Props<FinaliseBookingContainerStoryblok>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  useSwrMyProfile();

  const validateStoredDataResults = validateStoredBookingData({
    shouldCheckSignedUpData: !isAuthenticated,
  });

  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  const isFundingSelfFund = isEqual(
    validateStoredDataResults.fundingMethod,
    Funding.SELF_FUNDED
  );

  const reserveId = validateStoredDataResults.appointmentData?.reserveId;

  if (!validateStoredDataResults.isEligible) {
    return renderSessionBookingWarning(validateStoredDataResults);
  }

  return (
    <div {...storyblokEditable(blok)} className="flex flex-col">
      {isFundingSelfFund && (
        <div className="flex flex-1 mt-14 lg:mx-14 xl:mx-28 2xl:mx-36">
          {blok.selfFundNotice?.map((selfFundNoticeBlok) => (
            <StoryblokComponent
              key={selfFundNoticeBlok._uid}
              blok={selfFundNoticeBlok}
            />
          ))}
        </div>
      )}

      <div className="block lg:flex my-14 lg:my-24 lg:mx-7 2lg:mx-14 lg:gap-14">
        <div className="mb-14 lg:mb-0 lg:flex lg:flex-1 lg:order-last">
          {/* Appointment summary */}
          <div className="w-full">{renderConfirmBookingSummarySection()}</div>
        </div>

        <div className="lg:flex lg:flex-[2]">
          <div className="flex flex-col gap-y-8">
            {/* Content */}
            <div>{renderConfirmBookingContainerHeading()}</div>

            {/* Form */}
            {reserveId &&
              renderConfirmBookingForm(blok, reserveId, accessToken)}
          </div>
        </div>
      </div>
    </div>
  );
};
