type Props = {
  pathProps?: React.SVGProps<SVGPathElement>;
};

export const WarningToastSvg = ({ pathProps }: Props) => (
  <svg
    height="24"
    viewBox="0 0 32 32"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="_48_Warning" data-name="48 Warning">
      <path
        d="m29.764 23.453-11.188-17.933a3.027 3.027 0 0 0 -5.146-.009l-11.206 17.963a3.027 3.027 0 0 0 2.583 4.526h22.386a3.028 3.028 0 0 0 2.571-4.547z"
        fill="#ffd54f"
      />
      <path
        d="m15.087 18.624-.266-3.976c-.052-.8-.325-2.093.286-2.743.465-.5 1.566-.586 1.9.107a4.873 4.873 0 0 1 .182 2.536l-.356 4.093a3.221 3.221 0 0 1 -.249 1.12.708.708 0 0 1 -1.254.013 3.763 3.763 0 0 1 -.243-1.15zm.921 5.463a1.24 1.24 0 0 1 -.142-2.471 1.24 1.24 0 1 1 .142 2.471z"
        fill="#596c76"
        {...pathProps}
      />
    </g>
  </svg>
);
