import { ButtonStoryblok } from "@/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { isEmpty } from "lodash";
import { Routes } from "routes/main.routes";
import { Props } from "types/core";
import {
  cn,
  getButtonStyles,
  openInNewTab,
  redirectTo,
  toNoCachedLink,
  toSafeLink,
} from "utils";

type ButtonCustomProps = Props<ButtonStoryblok> & {
  onClick?: () => void;
};

export const Button = ({ blok, onClick }: ButtonCustomProps) => {
  const { loginRedirectSOH } = useLoginNavigate();

  const buttonStyles = getButtonStyles(blok);
  const link = toNoCachedLink(blok.link);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      e.stopPropagation();
      e.preventDefault();

      onClick();

      return;
    }

    if (blok.isLoginButton) {
      e.stopPropagation();

      loginRedirectSOH();

      return;
    }

    if (!link) return;

    if (blok?.link?.target === "_blank") {
      openInNewTab(link);
      return;
    }

    redirectTo(toSafeLink(link));
  };

  return (
    <LinkWrapper
      disabled={isEmpty(link)}
      to={toSafeLink(link) || Routes.PLACEHOLDER}
      target={blok?.link?.target}
    >
      <button
        onClick={handleOnClick}
        className={buttonStyles}
        {...storyblokEditable(blok)}
      >
        <div
          className={cn("flex items-center justify-center px-3", {
            "flex-row-reverse": blok.isReverseContent,
          })}
        >
          {blok.customIcon?.filename && (
            <img
              src={blok.customIcon.filename}
              alt={blok.customIcon.alt}
              className={cn("w-4 h-4", blok.isReverseContent ? "ml-2" : "mr-2")}
            />
          )}

          <span className="font-semibold text-center truncate">
            {blok.name}
          </span>
        </div>
      </button>
    </LinkWrapper>
  );
};
