import { DescriptionStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { storyblokEditable } from "@storyblok/react";
import { cn } from "utils";

export const Description = ({ blok }: Props<DescriptionStoryblok>) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "leading-tight break-words text-primary",
        "text-sm sm:text-base md:text-lg lg:text-xl"
      )}
    >
      {blok.text}
    </div>
  );
};
