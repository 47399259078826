import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffectOnce } from "react-use";

import { useTimeLogStore } from "stores/timelog.store";
import { SignupSectionStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { getSignUpFormType } from "utils";

export const SignupWithInviteContainer = ({
  blok,
}: Props<SignupSectionStoryblok>) => {
  const { reset } = useTimeLogStore();

  useEffectOnce(() => {
    reset();
  });

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col max-md:gap-8 gap-16 py-16 mx-0 md:mx-7 lg:mx-14 xl:mx-28"
    >
      <div className="flex flex-col lg:flex-row gap-4 lg:gap-14">
        {blok.sections?.map((section) => (
          <div key={section._uid}>
            <StoryblokComponent
              blok={section}
              formType={getSignUpFormType(blok?.signUpFormTypes)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
