/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo, isEmpty, isEqual, isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParam, useToggle } from "react-use";
import { OurPsychologistsSearchBarStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { WarningModal } from "components/forms/WarningModal";
import { EMAIL_SUPPORT_TEAM } from "core/booking.constant";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { useSwrLastAppointment } from "hooks/useSwrLastAppointment";
import { useSwrMinifiedCliniciansQuery } from "hooks/useSwrMinifiedCliniciansQuery";
import { Funding, OurPsychologistsSearchType, SelectionOption } from "models";
import {
  cn,
  extract,
  fundingMethodOptions,
  getAttachedPsychologistAuth0,
  getDefaultButtonStyles,
  getDefaultOutlinedButtonStyles,
  getMultiSelectedOptions,
  getSingleSelectedOption,
  specializationsCombinedValue,
  toFundingType,
  toPsychologistDetailsPath,
} from "utils";
import { getStoredGetMatched } from "utils/storage.util";

type CustomProps = Props<OurPsychologistsSearchBarStoryblok> & {
  isSearching: boolean;
  selectedSearch: OurPsychologistsSearchType;

  onClickSearch: (search: OurPsychologistsSearchType) => void;
};

export const OurPsychologistsSearchBar = ({
  blok,
  selectedSearch,
  ...props
}: CustomProps) => {
  const { isAuthenticated } = useAuth0();

  const getMatchedFilterData = getStoredGetMatched();
  const getMatchedApiQueries = getMatchedFilterData?.apiQueries;

  const searchedSpecializationsString = useSearchParam("specializations");
  const searchedFundingString = useSearchParam("fundingMethods");
  const searchedPsychologistIdString = useSearchParam("psychologistId");

  const { data, isLoading } = useSwrMinifiedCliniciansQuery({});
  const { data: attachedClinician } = useSwrAttachedClinician();
  const { data: appointmentHistory } = useSwrLastAppointment();

  const { lastPsychologistAppointment } = appointmentHistory || {};

  const specializationOptions = specializationsCombinedValue;

  const [showWarning, toggleWarning] = useToggle(false);
  const [tempFunding, setTempFunding] = useState<SelectionOption>();

  const minifiedClinicianOptions: SelectionOption[] = useMemo(() => {
    if (!data?.clinicians) return [];

    return data.clinicians.map((clinician) => ({
      label: clinician.name,
      value: clinician._id,
    }));
  }, [data]);

  useEffect(() => {
    const lastAppointmentClaimType = lastPsychologistAppointment?.claimType;

    if (!lastAppointmentClaimType) return;

    const fundingType = toFundingType(lastAppointmentClaimType);
    const searchedFunding = searchedFundingString as Funding;

    const validSearchFunding = Object.values(Funding).includes(searchedFunding);
    const matchedFunding = isEqual(fundingType, searchedFunding);

    if (validSearchFunding && !matchedFunding) {
      setTempFunding({
        label: defaultTo(searchedFundingString, searchedFunding),
        value: searchedFunding,
      });

      toggleWarning(true);

      return;
    }

    const isNoHaveGetMatched = isNil(getMatchedApiQueries);

    const isNoHaveSearchParameters =
      isEmpty(searchedSpecializationsString) &&
      isEmpty(searchedFundingString) &&
      isEmpty(searchedPsychologistIdString);

    const shouldPopulateLastAppointmentFunding =
      !validSearchFunding && isNoHaveGetMatched && isNoHaveSearchParameters;

    if (shouldPopulateLastAppointmentFunding) {
      handleChangeSearchValues("funding", fundingType);
    }
  }, [lastPsychologistAppointment?.claimType]);

  const handleChangeSearchValues = <K extends keyof OurPsychologistsSearchType>(
    key: K,
    values: OurPsychologistsSearchType[K]
  ) => {
    props.onClickSearch({ ...selectedSearch, [key]: values });
  };

  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    props.onClickSearch(getExtractSearchValues());
  };

  const handleChangeFunding = (option: SelectionOption) => {
    const newFunding = option.value as Funding;
    setTempFunding(option);

    if (
      isAuthenticated &&
      !isEmpty(attachedClinician?.clinician) &&
      !isEmpty(lastPsychologistAppointment)
    ) {
      const storedFunding = toFundingType(
        lastPsychologistAppointment.claimType
      );

      const isValidStoredFunding = isEqual(storedFunding, newFunding);

      if (!isValidStoredFunding) {
        toggleWarning(true);

        return;
      }
    }

    if (!isNewSelectedFunding(newFunding)) return;

    handleChangeSearchValues("funding", newFunding);
  };

  const handleClickContinueBrowsing = () => {
    toggleWarning(false);
    const newFunding = tempFunding?.value as Funding;

    if (!isNewSelectedFunding(newFunding)) return;

    newFunding && handleChangeSearchValues("funding", newFunding);
  };

  const isNewSelectedFunding = (funding: Funding) =>
    !isEqual(funding, selectedSearch.funding);

  const getExtractSearchValues = (): OurPsychologistsSearchType => {
    return {
      ...selectedSearch,
      helpWithList: selectedSearch.helpWithList.reduce(
        (helpWithList: string[], specialization: string) =>
          helpWithList.concat(specialization),
        []
      ),
    };
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-5"
    >
      {/* OUR SERVICES */}
      <div className="flex items-end justify-center flex-1">
        {blok.payingSelection?.map((payingSelectionBlok) => (
          <StoryblokComponent
            key={payingSelectionBlok._uid}
            blok={payingSelectionBlok}
            isLoading={false}
            selectedOption={getSingleSelectedOption(
              fundingMethodOptions,
              selectedSearch.funding
            )}
            options={fundingMethodOptions}
            onChangeSingleSelection={handleChangeFunding}
            isRequired
          />
        ))}
      </div>

      {/* I NEED HELP WITH */}
      <div className="flex items-end justify-center flex-1">
        {blok.helpWithSelection?.map((helpWithSelectionBlok) => (
          <StoryblokComponent
            key={helpWithSelectionBlok._uid}
            blok={helpWithSelectionBlok}
            isLoading={false}
            isMultiple
            options={specializationOptions}
            selectedOptions={getMultiSelectedOptions(
              specializationOptions,
              selectedSearch.helpWithList
            )}
            onChangeMultiSelection={(selectedOptions: SelectionOption[]) =>
              handleChangeSearchValues(
                "helpWithList",
                extract(selectedOptions, "value")
              )
            }
          />
        ))}
      </div>

      {/* PARTICULAR PSYCHOLOGIST */}
      <div className="flex items-end justify-center flex-1">
        {blok.psychologistsSelection?.map((psychologistsSelectionBlok) => (
          <StoryblokComponent
            key={psychologistsSelectionBlok._uid}
            blok={psychologistsSelectionBlok}
            isLoading={isLoading}
            options={minifiedClinicianOptions}
            selectedOption={getSingleSelectedOption(
              minifiedClinicianOptions,
              selectedSearch.particularPsychologist
            )}
            onChangeSingleSelection={(selectedOption: SelectionOption) =>
              handleChangeSearchValues(
                "particularPsychologist",
                selectedOption.value
              )
            }
          />
        ))}
      </div>

      <div className="flex items-end justify-center">
        <button
          onClick={handleClickSearch}
          className={cn(
            getDefaultButtonStyles(),
            "w-full disabled:cursor-wait"
          )}
          disabled={props.isSearching}
        >
          Search
        </button>
      </div>

      <>
        <WarningModal
          isVisible={showWarning}
          redirectUrl={toPsychologistDetailsPath(
            getAttachedPsychologistAuth0()
          )}
          heading={`Want to book with ${tempFunding?.label}?`}
          content={[
            <>
              To change your psychologist or appointment type, please email{" "}
              <a className="underline" href={`mailto:${EMAIL_SUPPORT_TEAM}`}>
                {EMAIL_SUPPORT_TEAM}
              </a>{" "}
              with your preferred updates. You're welcome to explore other
              options, but direct booking is currently restricted.
            </>,
          ]}
          buttonContent="Go back to my psychologist"
          additionButtons={[
            <button
              className={cn(
                getDefaultOutlinedButtonStyles(),
                "w-full md:w-fit"
              )}
              onClick={handleClickContinueBrowsing}
            >
              Continue browsing
            </button>,
          ]}
          showCloseButton
          onClose={() => toggleWarning(false)}
        />
      </>
    </div>
  );
};
