import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { BookGpFormStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { BookBehalfOfType } from "enums";
import { SelectionOption } from "models";
import { useProfileStore } from "stores/profile.store";
import { getDefaultBehalfOfOptions, getFormHeadingBlok } from "utils";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { CountDownClock } from "../CountDownClock";
import { GPBehalfOfChildForm } from "./GPBehalfOfChildForm";
import { GPBehalfOfMySelfForm } from "./GPBehalfOfMySelfForm";
import { GPBehalfOfSomeOneForm } from "./GPBehalfOfSomeOneForm";

export const BookGPForm = ({ blok }: Props<BookGpFormStoryblok>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const profile = useProfileStore((state) => state.profile);

  const hasGPNoticesBlok = !isEmpty(blok?.gpNotices);

  const [accessToken, setAccessToken] = useState<string>("");
  const [behalfOfType, setBehalfOfType] = useState<BookBehalfOfType>(
    BookBehalfOfType.MY_SELF
  );

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  const behalfOfOptions = getDefaultBehalfOfOptions(profile);

  const handleChangeBehalfOf = (selectedOption: SelectionOption) => {
    setBehalfOfType(selectedOption.value as BookBehalfOfType);
  };

  return (
    <div
      className="flex flex-col gap-12 px-0 py-10 lg:px-20"
      {...storyblokEditable(blok)}
    >
      {hasGPNoticesBlok && (
        <div className="flex flex-1">
          {blok?.gpNotices?.map((gpNoticeBlok) => (
            <StoryblokComponent key={gpNoticeBlok._uid} blok={gpNoticeBlok} />
          ))}
        </div>
      )}

      <div className="flex flex-col w-full gap-12 md:flex-row">
        <div className="flex flex-[2] gap-y-5 flex-col order-2 md:order-1">
          <StoryblokComponent blok={getFormHeadingBlok(blok?.title)} />

          <SelectionDropdown
            isLoading={false}
            title="I am booking on behalf of:"
            placeHolder={"Select..."}
            hintComponent={getHintFromStoryBlok(blok?.behalfOfHints)}
            selectedValues={behalfOfOptions.filter(
              ({ value }) => value === behalfOfType
            )}
            options={behalfOfOptions}
            onChangeSingleSelection={handleChangeBehalfOf}
            isShowTitle
            isShowHint
            isRequired
          />

          {behalfOfType === BookBehalfOfType.MY_SELF && (
            <GPBehalfOfMySelfForm
              bookGPFormBlok={blok}
              profile={profile}
              accessToken={accessToken}
            />
          )}

          {behalfOfType === BookBehalfOfType.SOMEONE_ELSE_ADULT && (
            <GPBehalfOfSomeOneForm
              bookGPFormBlok={blok}
              profile={profile}
              accessToken={accessToken}
            />
          )}

          {behalfOfType === BookBehalfOfType.MY_CHILD && (
            <GPBehalfOfChildForm
              bookGPFormBlok={blok}
              profile={profile}
              accessToken={accessToken}
            />
          )}
        </div>

        <div className="flex flex-1 flex-col gap-5 order-1 h-fit md:order-2">
          {blok?.actions?.map((actionBlok) => (
            <StoryblokComponent key={actionBlok._uid} blok={actionBlok} />
          ))}

          {!isAuthenticated && (
            <div className="flex bg-light-grey p-7 min-w-[280px] w-full rounded-xl">
              <CountDownClock isVisible isGPBooking />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
