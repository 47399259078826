import { storyblokEditable } from "@storyblok/react";

import { FeedbackCardStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { cn } from "utils";
import { ChevronSvg } from "assets/icons/ChevronSvg";
import { useToggle } from "react-use";

type CustomProps = Props<FeedbackCardStoryblok> & { index: number };

export const FeedbackCard = ({ blok, index }: CustomProps) => {
  const [isExpandText, toggleExpandText] = useToggle(false);
  const isReversedOrder = index % 2 === 0;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "px-2 sm:px-0 sm:mx-1.5 md:mx-2 lg:mx-3 xl:mx-[15px] max-h-[660px] xl:h-[660px] max-w-[300px] sm:max-w-[350px] mx-auto scrollbar-3",
        isReversedOrder ? "flex flex-col-reverse" : "flex flex-col"
      )}
    >
      <div className="relative">
        <img
          src={blok.userImage?.filename}
          alt={blok.userImage?.alt}
          className={cn(
            "object-cover h-[400px] w-full",
            isReversedOrder
              ? "rounded-b-2xl lg:rounded-b-3xl"
              : "rounded-t-2xl lg:rounded-t-3xl"
          )}
        />

        <div
          className={cn(
            "absolute top-0 xl:mt-4 xl:ml-5 lg:mt-3 lg:ml-4 mt-2 ml-3 font-semibold text-18",
            blok.userNameColor ?? "text-primary"
          )}
        >
          {blok.userName}
        </div>
      </div>

      <div
        className={cn(
          "px-7 pt-2 sm:pt-3 md:pt-4 lg:pt-3 xl:px-[30px] xl:pt-[15px] xl:pb-[20px] bg-highlight",
          "h-[220px] xl:h-[260px]",
          isReversedOrder ? "rounded-t-3xl" : "rounded-b-3xl"
        )}
      >
        <div
          className={cn(
            "mt-6 sm:mt-3 sm:text-sm lg:text-15 text-left sm:leading-relaxed lg:leading-[25px] xl:leading-[30px] h-[140px] xl:h-[190px]",
            {
              "overflow-y-scroll": isExpandText,
            }
          )}
        >
          <div
            className={cn({
              "line-clamp-6": !isExpandText,
            })}
          >
            {renderPlainText(blok.content)}
          </div>
        </div>

        <div
          className="flex items-center pt-2 text-sm text-left cursor-pointer"
          onClick={toggleExpandText}
        >
          <span className="xl:text-sm text-primary font-medium">
            {isExpandText ? "Read less" : "Read more"}
          </span>
          <span
            className={cn(
              "text-13 text-secondary-darker ml-1.5",
              isExpandText ? "rotate-180" : "-rotate-90"
            )}
          >
            <ChevronSvg />
          </span>
        </div>
      </div>
    </div>
  );
};

const renderPlainText = (content?: string): JSX.Element => {
  if (!content) {
    return <p></p>;
  }

  const lines = content.split("\n");
  return (
    <>
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};
