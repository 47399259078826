import { cn, getPasswordBarStyles } from "utils";

type Props = {
  strengthLevel: number;
};

export const PasswordStrengthIndicator = ({ strengthLevel }: Props) => {
  const NUMBER_OF_LEVEL = 4;

  return (
    <div className="flex gap-3 max-sm:justify-start max-sm:pl-4 justify-center">
      <div className="flex h-4 w-32 gap-1 border-2 rounded-full p-0.5 mt-1 shrink-0">
        {[...new Array(NUMBER_OF_LEVEL)].map((_, index) => (
          <div
            key={index}
            className={cn(
              "flex-1 rounded-full",
              getPasswordBarStyles(index + 1, Math.max(strengthLevel, 1))
            )}
          />
        ))}
      </div>
    </div>
  );
};
