import { StoryblokComponent } from "@storyblok/react";
import { ServiceHeadingStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const ServiceHeading = ({ blok }: Props<ServiceHeadingStoryblok>) => {
  return (
    <div
      className={cn(
        "text-primary text-center my-6 lg:my-24 space-y-4 w-full lg:w-[60%] mx-auto"
      )}
    >
      {blok?.content?.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </div>
  );
};
