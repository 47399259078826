import { WarningSvg } from "assets/icons/WarningSvg";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { Routes } from "routes/main.routes";

type Props = {};

export const ClinicianBookingNotice = (props: Props) => {
  return (
    <div className="flex max-sm:flex-col flex-row gap-5 p-7 bg-alerts-warning rounded-lg mx-auto w-full text-15">
      <div className="mt-2">
        <WarningSvg />
      </div>

      <div>
        You are currently logged in as a psychologist and are not able to book
        appointments via the website.
        <br />
        <LinkWrapper
          to={Routes.CONTACT}
          target="_blank"
          className="inline font-bold underline"
        >
          Contact us
        </LinkWrapper>{" "}
        if you require assistance
      </div>
    </div>
  );
};
