import { storyblokEditable } from "@storyblok/react";
import { AboutValueItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const AboutValueItem = ({ blok }: Props<AboutValueItemStoryblok>) => {
  return (
    <div
      className="flex flex-col justify-between border border-r-light-grey-2 rounded-2xl overflow-hidden"
      {...storyblokEditable(blok)}
    >
      {/* IMAGE */}
      <div className="pl-6 pt-5 mb-3.5">
        <img src={blok.image?.filename} alt={blok.image?.alt} />
      </div>

      {/* TEXT */}
      <div className="flex flex-col gap-y-1.5 bg-neutral-50 px-6 pt-2 pb-3">
        <h3 className="text-primary text-xl line-clamp-1">{blok.title}</h3>
        <p
          className="text-dark-grey text-md line-clamp-3 min-h-[72px]"
          title={blok.description}
        >
          {blok.description}
        </p>
      </div>
    </div>
  );
};
