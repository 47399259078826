import { ceil } from "lodash";

import { BackSvg } from "assets/icons/BackSvg";
import { NextSvg } from "assets/icons/NextSvg";
import { cn } from "utils";

type Props = {
  totalItem: number;
  perPage: number;
  page: number;

  onClickChangePage: (step: number) => void;
};

export const Pagination = ({ totalItem, perPage, page, ...props }: Props) => {
  const totalPageIndex = Math.max(ceil(totalItem / perPage), 1);

  const hasPrevPage = page > 1;
  const hasNextPage = page < totalPageIndex;

  const getPaginationButtonClassnames = (isEnabled: boolean) => {
    return cn(
      "flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-primary",
      {
        "pointer-events-none opacity-40": !isEnabled,
      }
    );
  };

  return (
    <div className="flex flex-row items-center justify-center gap-x-5">
      <button
        className={getPaginationButtonClassnames(hasPrevPage)}
        disabled={!hasPrevPage}
        onClick={() => {
          props.onClickChangePage(-1);
        }}
      >
        <BackSvg fillColor="white" />
      </button>

      <div className="flex flex-row items-center justify-center gap-x-2">
        <div className="flex items-center justify-center w-10 h-10 border rounded-lg border-light-grey-3">
          {page}
        </div>
        <div>of {totalPageIndex}</div>
      </div>

      <button
        className={getPaginationButtonClassnames(hasNextPage)}
        disabled={!hasNextPage}
        onClick={() => {
          props.onClickChangePage(1);
        }}
      >
        <NextSvg fillColor="white" />
      </button>
    </div>
  );
};
