import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ListServiceStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const ListService = ({ blok }: Props<ListServiceStoryblok>) => {
  return (
    <div
      className={cn(
        "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 lg:gap-8 py-[60px]"
      )}
      {...storyblokEditable(blok)}
    >
      {blok.serviceItems?.map(card => (
        <StoryblokComponent key={card._uid} blok={card} />
      ))}
    </div>
  );
};
