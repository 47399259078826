import { isEmpty } from "lodash";
import { ReactElement } from "react";

type Props = {
  message: string | ReactElement;
};

export const CardListingItemErrorMsg = ({ message }: Props) => {
  if (isEmpty(message)) return <></>;

  return (
    <div className="flex items-center text-xs md:text-sm font-medium gap-x-2 text-dark">
      <span className="w-2 h-2 bg-red-400 rounded-full ring-2 ring-red-200 aspect-square"></span>
      <span className="line-clamp-1">{message}</span>
    </div>
  );
};
