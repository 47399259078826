import { Controller, useForm } from "react-hook-form";
import { getContactUsFormResolver } from "./contact-us.resolver";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from "../TextInput";
import { TextError } from "../TextError";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { ContactUsFormData, SelectionOption } from "models";
import { RadioInput } from "../RadioInput";
import { PreferredContactMethodType } from "enums";
import { CheckboxInput } from "../CheckboxInput";
import { TextAreaInput } from "../TextAreaInput";
import { submitContactUsToHubspot } from "services/hubspot.service";
import { Routes } from "routes/main.routes";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { getDefaultButtonStyles, redirectTo } from "utils";
import { useToggle } from "react-use";
import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { AsYouType } from "libphonenumber-js";

export const ContactUsForm = () => {
  const [isLoading, toggleLoading] = useToggle(false);

  const subjectOptions: SelectionOption[] = [
    { label: "Another question", value: "Another question" },
    { label: "Help with an appointment", value: "Help with an appointment" },
    { label: "Help with signing up", value: "Help with signing up" },
    { label: "I have feedback", value: "I have feedback" },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isValidating },
  } = useForm<ContactUsFormData>({
    mode: "onChange",
    defaultValues: {
      isReceiveUpdates: false,
    },
    resolver: yupResolver(getContactUsFormResolver()),
  });

  const submitToHubspot = async (submissionData: ContactUsFormData) => {
    try {
      toggleLoading(true);
      const { data } = await submitContactUsToHubspot(submissionData);

      if (data) {
        toggleLoading(false);

        redirectTo(Routes.CONTACT_US_THANK_YOU);
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  const handleFormSubmit = (submissionData: ContactUsFormData) => {
    submitToHubspot(submissionData);
  };

  return (
    <div className="max-w-[750px]">
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex flex-col gap-8"
      >
        <div>
          {/* Subject */}
          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col gap-1.5">
                <SelectionDropdown
                  title="Subject"
                  placeHolder="Please select"
                  options={subjectOptions}
                  onChangeSingleSelection={(
                    selectedOption: SelectionOption
                  ) => {
                    field.onChange(selectedOption.value);
                  }}
                  isShowTitle
                  isShowHint={false}
                />
              </div>
            )}
          />
        </div>

        <div className="flex flex-row w-full gap-4">
          {/* First name */}
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-1.5">
                <TextInput
                  name={field.name}
                  title={"First name"}
                  onChangeValue={field.onChange}
                />

                <TextError fieldError={errors.firstName} />
              </div>
            )}
          />

          {/* Last name */}
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-1.5">
                <TextInput
                  name={field.name}
                  title={"Last name"}
                  onChangeValue={field.onChange}
                />

                <TextError fieldError={errors.lastName} />
              </div>
            )}
          />
        </div>

        <div className="flex flex-row gap-4">
          {/* Email */}
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-1.5">
                <TextInput
                  name={field.name}
                  title={"Email"}
                  onChangeValue={field.onChange}
                />

                <TextError fieldError={errors.email} />
              </div>
            )}
          />

          {/* Phone number */}
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-1.5">
                <TextInput
                  name={field.name}
                  title={"Phone number"}
                  onChangeValue={(event) => {
                    const value = event.target.value;

                    const formattedValue = new AsYouType(
                      DEFAULT_PHONE_COUNTRY_CODE
                    ).input(value);

                    event.target.value = formattedValue;

                    field.onChange(event);
                  }}
                />

                <TextError fieldError={errors.phoneNumber} />
              </div>
            )}
          />
        </div>

        <div>
          <Controller
            name="comments"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-1.5">
                <TextAreaInput
                  name={field.name}
                  title={"Comments"}
                  numberRows={3}
                  onChangeValue={field.onChange}
                />

                <TextError fieldError={errors.comments} />
              </div>
            )}
          />
        </div>

        <div className="flex flex-col gap-1.5 p-8 bg-light-grey rounded-2xl">
          <div>Preferred contact method?</div>
          <Controller
            name="preferredContactMethod"
            control={control}
            render={({ field }) => (
              <div className="flex flex-row gap-x-2">
                <RadioInput
                  {...field}
                  label="By phone"
                  value={PreferredContactMethodType.BY_PHONE}
                  checked={field.value === PreferredContactMethodType.BY_PHONE}
                />

                <RadioInput
                  {...field}
                  label="By email"
                  value={PreferredContactMethodType.BY_EMAIL}
                  checked={field.value === PreferredContactMethodType.BY_EMAIL}
                />
              </div>
            )}
          />
        </div>

        <div>
          <Controller
            name="isReceiveUpdates"
            control={control}
            render={({ field }) => (
              <CheckboxInput
                isChecked={field.value}
                title="I would like to receive occasional updates from Someone.health, providing mental health tips and information."
                onCheck={field.onChange}
              />
            )}
          />
        </div>

        <div className="flex flex-col items-center gap-5 md:flex-row">
          {/* Submit button */}
          <button
            type="submit"
            disabled={!isValid || isValidating}
            className={getDefaultButtonStyles()}
          >
            Submit
          </button>
          {isLoading && <SpinnerLoading className="w-12 h-12" />}
        </div>
      </form>
    </div>
  );
};
