import { StoryblokComponent } from "@storyblok/react";
import { MedicareRole, ScreenBreakpoints } from "enums";
import { toNumber } from "lodash";
import { Funding } from "models";
import { match } from "ts-pattern";
import { BookMedicareFormStoryblok } from "types/component-types-sb";
import {
  getStoredFundingMethod,
  getStoredReserveData,
} from "utils/storage.util";

export const renderMedicareNotice = (blok: BookMedicareFormStoryblok) => {
  const currentFundingMethod = getStoredFundingMethod();

  return match(currentFundingMethod)
    .with(Funding.REBATE, () => {
      const reserveData = getStoredReserveData();
      const psychologistRole = reserveData?.psychologist?.medicare
        ?.role as MedicareRole;

      return match(psychologistRole)
        .with(MedicareRole.ClinicalPsychologists, () => (
          <div>
            {blok.rebateClinicalNotice?.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}
          </div>
        ))
        .with(MedicareRole.RegisteredPsychologists, () => (
          <div>
            {blok.rebateGeneralNotice?.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}
          </div>
        ))
        .otherwise(() => <></>);
    })
    .otherwise(() => (
      <div>
        {blok.bulkBillNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ));
};

export const calculateAdditionStickyHeight = (
  windowWidth: number,
  stickyHeight: number
) => {
  const breakpoints = [
    { width: ScreenBreakpoints.SM, height: 0 },
    { width: ScreenBreakpoints.LG, height: 70 },
    { width: ScreenBreakpoints.XL, height: 80 },
    { width: ScreenBreakpoints.XXL, height: 90 },
  ];

  const foundBp = breakpoints.findLast((bp) => bp.width <= windowWidth);

  // Avoid value with NaN
  return foundBp ? foundBp.height - stickyHeight : -toNumber(stickyHeight);
};
