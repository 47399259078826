import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { BookDvaContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import {
  renderSessionBookingWarning,
  validateStoredBookingData,
} from "utils/warning.util";

export const BookDVAContainer = ({
  blok,
}: Props<BookDvaContainerStoryblok>) => {
  useSwrMyProfile();
  const { isAuthenticated } = useAuth0();

  const storedBookingDataResult = validateStoredBookingData({
    shouldCheckSignedUpData: !isAuthenticated,
  });

  if (!storedBookingDataResult.isEligible) {
    return renderSessionBookingWarning(storedBookingDataResult);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-12 px-0 py-10 lg:px-20 xl:px-24 max-md:pt-0"
    >
      {/* DVA FORM */}
      <div className="flex flex-1">
        {blok.forms?.map((formBlok) => (
          <StoryblokComponent key={formBlok._uid} blok={formBlok} />
        ))}
      </div>
    </div>
  );
};
