import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { globalConfig } from "core/config";
import { useGtmDataLayer } from "hooks/useGtmDataLayer";
import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";
import { useMount } from "react-use";

type Props = {} & PropsWithChildren;

export const ScriptsProvider = ({ children }: Props) => {
  const { initializeGtmScript } = useGtmDataLayer();

  const hubspotLocalScript = `//js-eu1.hs-scripts.com/${globalConfig.HUBSPOT_PORTAL_ID}.js`;
  const defaultDescription =
    "Access affordable Counselling Psychology with Bulk Billing Psychologists at someone.health. " +
    "Expert support for mental well-being. " +
    "Book your session today!";
  const defaultTitle =
    "Bulk Billing Psychologist | Counselling Psychology someone.health";
  const defaultLogoUrl =
    "https://someone.health/wp-content/uploads/2023/08/Australia-Map-150x150.png";

  useMount(() => {
    initializeGtmScript();
  });

  return (
    <div>
      {children}

      <Helmet>
        <script
          src={hubspotLocalScript}
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
        ></script>

        {/* <title>{defaultTitle}</title> */}
        <meta
          key="description"
          name="description"
          content={defaultDescription}
        />

        <link key="canonical" rel="canonical" href="https://someone.health/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://someone.health/" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:description" content={defaultDescription} />
        <meta property="og:image" content={defaultLogoUrl} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={defaultTitle} />
        <meta name="twitter:description" content={defaultDescription} />
        <meta name="twitter:image" content={defaultLogoUrl} />
      </Helmet>

      <VercelAnalytics debug={false} />
    </div>
  );
};
