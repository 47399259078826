import { Props } from "@/types/core";
import { TitleStoryblok } from "@/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react";
import { cn } from "utils";

export const Title = ({ blok }: Props<TitleStoryblok>) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "font-medium leading-tight break-words text-primary",
        "text-base lg:text-58"
      )}
    >
      {blok.title}
    </div>
  );
};
