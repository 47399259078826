import { Helmet } from "react-helmet";

type Props = {
  breadcrumbs: { name: string; url: string }[];
};

export const SeoBreadcrumbTrail = ({ breadcrumbs }: Props) => {
  const breadcrumbList = breadcrumbs.map((breadcrumb, index) => ({
    "@type": "ListItem",
    position: index + 1,
    name: breadcrumb.name,
    item: breadcrumb.url,
  }));

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbList,
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbSchema, null, 2)}
      </script>
    </Helmet>
  );
};
