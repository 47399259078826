import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ScreenBreakpoints } from "enums";
import { useRef } from "react";
import { useEvent, useWindowSize } from "react-use";
import { GetAGpReferralJourneyStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { DOT_RADIUS, updateDotPosition } from "utils";

export const GetAGpReferralJourney = ({
  blok,
}: Props<GetAGpReferralJourneyStoryblok>) => {
  const { width } = useWindowSize();
  const isMobile = width < ScreenBreakpoints.MD;
  
  const pathRef = useRef<SVGGeometryElement>(null);
  const startPointRef = useRef<SVGCircleElement>(null);
  const dotRef1 = useRef<SVGCircleElement>(null);
  const dotRef2 = useRef<SVGCircleElement>(null);
  const dotRef3 = useRef<SVGCircleElement>(null);
  const dotRef4 = useRef<SVGCircleElement>(null);

  const handleScrollEvent = () => {
    updateDotPosition(
      pathRef.current,
      startPointRef.current,
      dotRef1.current,
      dotRef2.current,
      dotRef3.current,
      dotRef4.current
    );
  };
  useEvent("scroll", handleScrollEvent);

  return (
    <div {...storyblokEditable(blok)}>
      {blok.title?.map((title) => (
        <StoryblokComponent key={title._uid} blok={title} />
      ))}
      <div className="flex justify-center my-8">
        {!isMobile && <svg
          width="1267"
          viewBox="0 0 1267 1952"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            ref={pathRef}
            d="M633 5V105 M633 105V105C633 175.692 575.692 233 505 233H368C262.514 233 177 318.514 177 424V424C177 529.486 262.514 615 368 615H927.5C1016.14 615 1088 686.858 1088 775.5V775.5C1088 864.142 1016.14 936 927.5 936H366.5C261.842 936 177 1020.84 177 1125.5V1125.5C177 1230.16 261.842 1315 366.5 1315H882.5C995.995 1315 1088 1407.01 1088 1520.5V1520.5C1088 1633.99 995.995 1726 882.5 1726H662V1726C619.198 1726 584.5 1760.7 584.5 1803.5V1951.25"
            stroke="#CBAAC3"
            strokeWidth="2"
          />
          <circle
            ref={startPointRef}
            cx="633"
            cy="6"
            r={DOT_RADIUS}
            fill="#502334"
          />
          <circle ref={dotRef1} cx="633" cy="6" r={DOT_RADIUS} fill="#502334" />
          <circle
            ref={dotRef2}
            cx="633"
            cy="6"
            r={DOT_RADIUS}
            fill="#502334"
            opacity="0.85"
          />
          <circle
            ref={dotRef3}
            cx="633"
            cy="6"
            r={DOT_RADIUS}
            fill="#502334"
            opacity="0.7"
          />
          <circle
            ref={dotRef4}
            cx="633"
            cy="6"
            r={DOT_RADIUS}
            fill="#502334"
            opacity="0.55"
          />

          {blok.items.map((item, index) => (
            <foreignObject
              key={`${index}_${item._uid}`}
              x={item.pointX}
              y={item.pointY}
              width="100%"
              height="100%"
            >
              <StoryblokComponent
                key={item._uid}
                blok={item}
                otherProps={{ index }}
              />
            </foreignObject>
          ))}
        </svg>}

        {isMobile && (
        <div>
          {blok.items.map((item) => (
            <StoryblokComponent
              key={item._uid}
              blok={item}
              otherProps={{ isMobile }}
            />
          ))}
        </div>
      )}
      </div>
    </div>
  );
};
