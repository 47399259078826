import { useAuth0 } from "@auth0/auth0-react";
import { globalConfig } from "core/config";

export const useLoginNavigate = () => {
  const { loginWithRedirect } = useAuth0();

  const loginRedirectSOH = () => {
    loginWithRedirect({ appState: { returnTo: window.location.pathname } });
  };

  const loginRedirectTacklit = (redirectUrl?: string) => {
    loginWithRedirect({
      appState: {
        returnTo: redirectUrl || globalConfig.TACKLIT_CLIENT_PORTAL_URL,
      },
    });
  };

  return {
    loginRedirectSOH,
    loginRedirectTacklit,
  };
};
