import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Props } from "@/types/core";
import { ReferralTemplatesItemsStoryblok } from "@/types/component-types-sb";
import { cn } from "utils";

type CustomProps = Props<ReferralTemplatesItemsStoryblok> & {
  index: number;
  hasBackgroundImage: boolean;
};

export const ReferralTemplatesItems = ({
  blok,
  index,
  hasBackgroundImage,
}: CustomProps) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "rounded-3xl w-full md:w-[300px] lg:w-[270px] xl:w-[230px] 2xl:w-[300px] h-min-[274px]",
        hasBackgroundImage ? "bg-white" : "bg-light-grey"
      )}
    >
      <div
        className={cn(
          "flex flex-col gap-y-3 justify-center items-center px-8 py-10"
        )}
      >
        {blok.icon && blok.iconHasIndex && (
          <span
            className={cn(
              "flex items-center justify-center font-bold w-12 h-12 bg-cover text-primary"
            )}
            style={{ backgroundImage: `url(${blok.icon.filename})` }}
          >
            {index + 1}
          </span>
        )}

        {blok.icon && !blok.iconHasIndex && (
          <img
            src={blok.icon.filename}
            alt={blok.icon.alt}
            className="h-[50px]"
          />
        )}

        <div className="flex flex-col gap-3">
          {blok.content?.map((content) => (
            <StoryblokComponent key={content._uid} blok={content} />
          ))}
        </div>

        <div>
          {blok.buttons?.map((button) => (
            <StoryblokComponent key={button._uid} blok={button} />
          ))}
        </div>
      </div>
    </div>
  );
};
