import { StoryblokComponent } from "@storyblok/react";
import { PricingTableStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const PricingTable = ({ blok }: Props<PricingTableStoryblok>) => {
  return (
    <div
      className={cn(
        "rounded-2xl bg-secondary text-primary py-[60px] max-w-[1220px] mx-auto px-3 md:px-8 lg:px-32"
      )}
    >
      <div className="text-center mt-[20px] mb-[60px]">
        {blok.title?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </div>

      <div className="flex justify-start lg:justify-center overflow-x-auto lg:overflow-x-visible">
        <table className={cn("text-left bg-white text-14 w-full")}>
          <thead>
            {blok.rows
              ?.filter((row) => row?.isHeader)
              ?.map((row) => (
                <StoryblokComponent key={row._uid} blok={row} />
              ))}
          </thead>

          <tbody>
            {blok.rows
              ?.filter((row) => !row?.isHeader)
              ?.map((row) => (
                <StoryblokComponent key={row._uid} blok={row} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
