import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { FileType } from "enums";
import { FileItem } from "models";
import { HintStoryblok } from "types/component-types-sb";
import { validateFileType } from "utils";
import { getHintFromStoryBlok } from "./book-medicare/BookMedicareForm";

const fileTypeToMime = (type: FileType) => {
  switch (type) {
    case FileType.PDF:
      return "application/pdf";
    case FileType.DOCX:
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case FileType.PNG:
      return "image/png";
    case FileType.JPG:
      return "image/jpeg";
    case FileType.DOC:
      return "application/msword";
    default:
      return "";
  }
};

type Props = {
  title: string;
  validFileTypes: FileType[];
  selectedFiles: FileItem[];
  maxUploaded: number;
  blokHints?: HintStoryblok[];

  onChangeFiles: (fileItems: FileItem[]) => void;
  onRemoveFile: (fileId: string) => void;
};

export const FileInput = ({
  title,
  validFileTypes,
  selectedFiles,
  maxUploaded,
  blokHints,
  ...props
}: Props) => {
  const dynamicId = v4();

  const validFileTypesString = validFileTypes.join(", ");
  const validFileMimeTypes = validFileTypes.map(fileTypeToMime).join(", ");

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    const filteredFiles = files.filter((file) =>
      validateFileType(file.name, validFileTypes)
    );

    const canUploaded = maxUploaded - selectedFiles.length;

    if (canUploaded < 0) return;

    const limitedUploadedFiles = filteredFiles.slice(0, canUploaded);

    if (isEmpty(limitedUploadedFiles)) return;

    const fileItems: FileItem[] = limitedUploadedFiles.map((file) => ({
      id: v4(),
      file: file,
    }));

    props.onChangeFiles(fileItems);

    // Reset input value to re-add the same file
    e.target.value = "";
  };

  const removeFile = (fileId: string) => {
    props.onRemoveFile(fileId);
  };

  return (
    <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-3">
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-row items-center justify-between">
          <div className="text-xl font-medium text-primary">{title}</div>
          {getHintFromStoryBlok(blokHints)}
        </div>
        <div>{!isEmpty(validFileTypes) && `(${validFileTypesString})`}</div>
      </div>

      <div className="flex flex-col gap-y-3">
        {selectedFiles.map((selectedFile, index) => (
          <div key={index} className="flex flex-row items-center gap-x-3">
            <div className="flex flex-1 px-5 py-2 border rounded-lg border-light-grey-2 break-all">
              <span className="line-clamp-5">{selectedFile.file.name}</span>
            </div>

            <div
              className="flex items-center justify-center px-5 py-2 font-medium border-2 rounded-full cursor-pointer w-36 border-primary text-primary"
              onClick={() => {
                removeFile(selectedFile.id);
              }}
            >
              Remove file
            </div>
          </div>
        ))}
      </div>

      <input
        id={dynamicId}
        type="file"
        className="hidden"
        multiple
        onChange={uploadFile}
        accept={validFileMimeTypes}
      />

      <label htmlFor={dynamicId} className="w-fit">
        <div className="flex px-5 py-2 font-medium border-2 rounded-full cursor-pointer w-fit border-primary text-primary">
          Select file
        </div>
      </label>
    </div>
  );
};
