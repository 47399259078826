import { PsychologistCardSectionStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Slider from "react-slick";
import { useWindowSize } from "react-use";
import useSWR from "swr";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { ScreenBreakpoints } from "enums";
import { Funding, PsychologistRequestQueries } from "models";
import { getCliniciansAsync } from "services/psychologist.service";
import {
  getPsychologistCardCarouselSettings,
  redirectTo,
  specializationsCombinedValue,
  toClaimType,
} from "utils";
import { customToast } from "utils/toast.util";

import {
  DEFAULT_APPOINTMENT_TYPE,
  DEFAULT_PSYCHOLOGIST_CAROUSEL_PER_PAGE,
} from "core/booking.constant";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { first } from "lodash";
import { Routes } from "routes/main.routes";
import "styles/carousel.scss";
import { filterOpenToNewClients } from "utils/booking.util";

export const PsychologistCardSection = ({
  blok,
}: Props<PsychologistCardSectionStoryblok>) => {
  const { data: clinicians, isLoading } = useSWR("/clinicians", getClinicians);

  const { data: attachedClinician } = useSwrAttachedClinician();

  const { width } = useWindowSize();

  async function getClinicians() {
    try {
      const funding = (blok?.claimType as Funding) || DEFAULT_APPOINTMENT_TYPE;
      const claimType = toClaimType(blok?.claimType as Funding);

      const params: PsychologistRequestQueries = {
        funding: funding,
        claimType: claimType,
        page: 1,
        perPage: DEFAULT_PSYCHOLOGIST_CAROUSEL_PER_PAGE,
        clientMentalHealthConcern: blok.specialization
          ? String(blok.specialization)
          : undefined,
      };

      const { data } = await getCliniciansAsync(params);

      return filterOpenToNewClients({
        clinicians: data.clinicians,
        attachedPsyId: attachedClinician?.clinician?._id,
      });
    } catch (err) {
      customToast.error(
        "Failed to get psychologists's data, please try again later"
      );

      console.log("err :>> ", err);
    }
  }

  const carouselSettings = getPsychologistCardCarouselSettings(
    clinicians?.length ?? 0
  );

  const handleBrowsePsychologistsButtonOnClick = () => {
    if (!blok.specialization && !blok.claimType) {
      redirectTo(Routes.OUR_PSYCHOLOGISTS);

      return;
    }

    const searchParams = new URLSearchParams();

    if (blok.specialization) {
      const specialization = JSON.stringify([blok.specialization]);
      searchParams.append("specializations", specialization);
    }

    const isValidFunding = Object.entries(Funding)
      .map(([, value]) => value)
      .includes(blok.claimType as Funding);

    if (blok.claimType && isValidFunding) {
      searchParams.append("fundingMethods", blok.claimType);
    }

    const queryString = searchParams.toString();

    redirectTo(`${Routes.OUR_PSYCHOLOGISTS}?${queryString}`);
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-no-repeat bg-cover text-primary -mx-4 sm:-mx-6 md:mx-0 my-32 py-[50px] md:rounded-30"
      style={{
        backgroundImage: `url(${blok.backgroundImage.filename})`,
      }}
    >
      <div className="mx-5">
        {/* Title */}
        <div className="flex justify-center">
          <span className="leading-9 text-center text-24 md:text-30 md:leading-10 xl:text-40 xl:leading-48">
            {renderSectionTitle(blok)}
          </span>
        </div>

        {/* Cards */}
        <div className="pt-8 mx-3 lg:mx-10">
          {clinicians && (
            <div>
              {blok.sectionCard?.map((card) => (
                <div key={card._uid}>
                  <Slider {...carouselSettings}>
                    {clinicians?.map((psychologist, index) => (
                      <div key={`${card._uid}_${psychologist._id}`}>
                        <StoryblokComponent
                          blok={card}
                          otherProps={{
                            ...psychologist,
                            index,
                          }}
                          claimTypeQuery={blok.claimType}
                          shouldCalculateWidth
                          isCarousel
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              ))}
            </div>
          )}

          {isLoading && (
            <div className="w-full min-h-[360px] max-h-[70vh] flex justify-center items-center">
              <SpinnerLoading />
            </div>
          )}

          {clinicians?.length === 0 && !isLoading && (
            <div className="flex justify-center">
              <span className="leading-9 text-24 md:text-30 md:leading-10 xl:text-40 xl:leading-48">
                There is no psychologist available for this specialization
              </span>
            </div>
          )}
        </div>

        {/* Buttons */}
        <div className="pt-8 md:flex md:justify-center">
          {blok.sectionButtons?.map((button) => {
            button.isFullWidth = width < ScreenBreakpoints.MD;

            return (
              <div key={button._uid}>
                <StoryblokComponent
                  blok={button}
                  onClick={handleBrowsePsychologistsButtonOnClick}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const renderSectionTitle = (blok: PsychologistCardSectionStoryblok) => {
  if (blok.specialization) {
    const selectedSpecItems = specializationsCombinedValue.filter(
      (spec) => blok.specialization === spec.value
    );

    const label = first(selectedSpecItems)?.label;

    return (
      <span>
        Our psychologists who specialise in <strong>{label}</strong>
      </span>
    );
  }

  return <div>{blok.sectionName}</div>;
};
