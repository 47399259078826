import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ScreenBreakpoints } from "enums";
import { useWindowSize } from "react-use";
import { GridStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const Grid = ({ blok }: Props<GridStoryblok>) => {
  const { width } = useWindowSize();

  const isMobileScreen = width < ScreenBreakpoints.MD;
  const numOfCols = `repeat(${blok.numOfCols}, minmax(0, 1fr))`;
  const numOfRows = blok.numOfRows
    ? `repeat(${blok.numOfRows}, minmax(0, 1fr))`
    : "";
  const gap = `${blok.gap}px`;
  const containerPadding = blok.containerPadding ?? "0px 0px 0px 0px";
  const cellPadding = blok.cellPadding ?? "0px 0px 0px 0px";

  return (
    <div {...storyblokEditable(blok)}>
      <div
        className={cn("grid")}
        style={{
          padding: containerPadding,
          gap: gap,
          gridTemplateColumns: isMobileScreen
            ? "repeat(1, minmax(0, 1fr))"
            : numOfCols,
          gridTemplateRows: numOfRows,
        }}
      >
        {blok.content?.map((blok) => (
          <div
            key={blok._uid}
            style={{
              padding: cellPadding,
            }}
          >
            <StoryblokComponent blok={blok} />
          </div>
        ))}
      </div>
    </div>
  );
};
