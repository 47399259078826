import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { BookMedicareActionsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const BookMedicareActions = ({
  blok,
}: Props<BookMedicareActionsStoryblok>) => {
  return (
    <div {...storyblokEditable(blok)} className="flex flex-col gap-y-5">
      {blok.items?.map((itemBlok) => (
        <StoryblokComponent key={itemBlok._uid} blok={itemBlok} />
      ))}
    </div>
  );
};
