/* eslint-disable react-hooks/exhaustive-deps */
import {
  BadgeStoryblok,
  PsychologistCardItemStoryblok,
  PsychologistDetailFunctionsStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo, isEmpty, isEqual } from "lodash";
import { useToggle, useUnmount, useWindowSize } from "react-use";

import { useAuth0 } from "@auth0/auth0-react";
import { ChevronSvg } from "assets/icons/ChevronSvg";
import CalendarSvg from "assets/icons/calendar.svg";
import { CardListingItemErrorMsg } from "components/card/psychologist/CardListingItemErrorMsg";
import { WarningModal } from "components/forms/WarningModal";
import {
  renderPsychologistDisplayRole,
  renderPsychologistPronouns,
} from "components/forms/book-finalise/booking-finalise.util";
import { PsychologistDetailFunctions } from "components/psychologist-detail/PsychologistDetailFunctions";
import { AVATAR_FALLBACK, EMAIL_SUPPORT_TEAM } from "core/booking.constant";
import {
  BadgeSizes,
  BadgeTypes,
  BookingRuleType,
  ScreenBreakpoints,
} from "enums";
import { motion } from "framer-motion";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useResizedFn } from "hooks/useResizeFn";
import { useSwrBookingRuleType } from "hooks/useSwrBookingRuleType";
import { useSwrLastAppointment } from "hooks/useSwrLastAppointment";
import { Funding } from "models";
import { useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { PsychologistResponse } from "services/psychologist.service";
import { useBookingStore } from "stores/booking.store";
import {
  cn,
  getAttachedPsychologistAuth0,
  getDefaultButtonStyles,
  getDefaultOutlinedButtonStyles,
  getFundingMethodDisplayLabel,
  getNextTimeSlot,
  isValidFunding,
  redirectToPsychologistDetails,
  sortFunding,
  specializationsSingleValue,
  toAvailabilityDate,
  toPsychologistDetailsPath,
} from "utils";
import {
  getStoredFundingMethod,
  setStoredFundingMethod,
} from "utils/storage.util";
import { renderFeeWarningModal } from "utils/warning.util";
import { v4 as GUID, v4 } from "uuid";
import React from "react";

type CustomProps = Props<PsychologistCardItemStoryblok> & {
  shouldCalculateWidth?: boolean;
  backgroundCardColor?: string;
  claimTypeQuery?: Funding;
  isCarousel?: boolean;
  detailFunctionBlok: PsychologistDetailFunctionsStoryblok;
};

export const PsychologistCardItem = ({
  blok,
  detailFunctionBlok,
  otherProps,
  shouldCalculateWidth,
  backgroundCardColor,
  claimTypeQuery,
  isCarousel = false,
}: CustomProps) => {
  const { isAuthenticated } = useAuth0();
  const { data: appointmentHistory } = useSwrLastAppointment();
  const { data: bookingRuleType } = useSwrBookingRuleType();

  const { focusedPsyId, setFocusedPsyId, isSubmittingBookNow } =
    useBookingStore();

  const { lastPsychologistAppointment } = appointmentHistory || {};

  const isNewClient =
    bookingRuleType?.bookingRuleType === BookingRuleType.NEW ||
    !isAuthenticated;

  const psychologist = otherProps as PsychologistResponse;

  const isOpenToNewClients = !!psychologist?.caseLoad?.isOpenToNewClients;
  // const hasAvailability = !!psychologist?.hasMatchedNextAvailabilities;

  const { width } = useWindowSize();
  const breakpoint = useBreakpoint();

  const [showUnmatchedWarning, toggleUnmatchedWarning] = useToggle(false);
  const [showFeeWarning, toggleFeeWarning] = useToggle(false);
  const [showBookingPanel, toggleBookingPanel] = useToggle(false);

  const bookingPanelBlokMemo = useMemo(() => detailFunctionBlok, [otherProps]);
  const bookingPanelRef = useRef<HTMLDivElement>(null);

  const nextTimeSlot = useMemo(() => {
    const nextMatched = psychologist?.matchedNextAvailabilities;

    const nextSlots = getNextTimeSlot(nextMatched, isNewClient);

    return nextSlots;
  }, [psychologist?.matchedNextAvailabilities, isNewClient]);

  const isHaveClaimTypeQuery = claimTypeQuery && !isEmpty(claimTypeQuery);

  const expertise = psychologist?.helmControl?.primarySpecialisations
    ?.map((priSpec) =>
      defaultTo(specializationsSingleValue[priSpec], "Unknown")
    )
    .join(", ");

  const clipPathIndex = (psychologist.index ?? 0) % 4;

  const breakpoints = [
    { breakpoint: ScreenBreakpoints.XXL, widthRatio: 6.2 },
    { breakpoint: ScreenBreakpoints.XL, widthRatio: 5.2 },
    { breakpoint: ScreenBreakpoints.LG, widthRatio: 4 },
    { breakpoint: ScreenBreakpoints.MD, widthRatio: 3 },
    { breakpoint: ScreenBreakpoints.SM, widthRatio: 2.5 },
    { breakpoint: 0, widthRatio: 1.3 },
  ];

  const currentSelectedFundingMethod =
    claimTypeQuery ?? getStoredFundingMethod();

  useEffect(() => {
    if (showBookingPanel === false) return;

    if (focusedPsyId && focusedPsyId !== psychologist._id) {
      toggleBookingPanel(false);
    }
  }, [focusedPsyId]);

  useResizedFn(() => {
    if (showBookingPanel === false) return;

    toggleBookingPanel(false);
  });

  useUnmount(() => {
    toggleUnmatchedWarning(false);
    toggleFeeWarning(false);
  });

  const calculateCardWidth = () => {
    if (!shouldCalculateWidth) return "100%";

    const foundBp = breakpoints.find((bp) => width >= bp.breakpoint);

    return foundBp ? width / foundBp.widthRatio : "100%";
  };

  const handleClickCardItem = () => {
    const isRebateOrSelfFund = [Funding.REBATE, Funding.SELF_FUNDED].includes(
      currentSelectedFundingMethod as Funding
    );

    const shouldShowFeeWarning =
      isRebateOrSelfFund &&
      (!isAuthenticated ||
        (isAuthenticated && isEmpty(lastPsychologistAppointment)));

    if (shouldShowFeeWarning && !showBookingPanel) {
      toggleFeeWarning(true);
      return;
    }

    const isValid = isValidAttachedPsychologist();

    if (!isValid && !showBookingPanel) {
      toggleUnmatchedWarning(true);
      return;
    }

    if (isHaveClaimTypeQuery) {
      setStoredFundingMethod(claimTypeQuery);
    }

    handleBookNowMobileView();
  };

  const handleBookNowMobileView = () => {
    // Direct link - Book now
    if (!breakpoint.isXs || isCarousel) {
      redirectToPsychologistDetails(psychologist.slugUrl);

      return;
    }

    // Panel - Book now
    toggleBookingPanel();
    setFocusedPsyId(psychologist._id);

    setTimeout(() => {
      bookingPanelRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 300);
  };

  const isValidAttachedPsychologist = () => {
    // Anonymous user is allowed to navigate
    if (!isAuthenticated) return true;

    const attachedPsychologistId = getAttachedPsychologistAuth0();

    if (!attachedPsychologistId) return true;

    return isEqual(attachedPsychologistId, psychologist?._id);
  };

  const handleFeeWarningContinue = () => {
    toggleFeeWarning(false);

    if (currentSelectedFundingMethod) {
      setStoredFundingMethod(currentSelectedFundingMethod);
    }

    handleBookNowMobileView();
  };

  const handleFeeWarningGoBack = () => {
    toggleFeeWarning(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        {...storyblokEditable(blok)}
        className={cn(
          "rounded-[20px] mx-auto pt-8 pb-3 px-6 h-full",
          "group/psychologist-card-item",
          backgroundCardColor ?? "bg-white",
          "max-md:bg-light-grey max-md:pt-5 max-md:pb-0 max-md:shadow-sm"
        )}
        style={{
          width: calculateCardWidth(),
        }}
      >
        <div className="flex flex-col items-center justify-center h-full gap-y-1.5 text-dark">
          <div className="flex flex-row md:flex-col md:items-center gap-x-3">
            {/* Avatar */}
            <div className="w-[40%] md:w-[85%] lg:w-[80%] min-w-[96px] max-md:max-w-[150px]">
              <div className="flex w-full aspect-square">
                <img
                  src={defaultTo(psychologist.avatar, AVATAR_FALLBACK)}
                  alt={psychologist.name}
                  className={cn(
                    "justify-self-center bg-neutral transition-all duration-300 w-full h-full object-contain",
                    "group-hover/psychologist-card-item:bg-secondary group-hover/psychologist-card-item:scale-[1.03]",
                    `clip-path__avatar-${clipPathIndex}`
                  )}
                />
              </div>
            </div>

            <div>
              {/* Name */}
              <p
                className={cn(
                  "pb-2 md:pt-5 md:pb-0 font-medium text-left text-xl w-full",
                  "md:text-lg md:font-normal md:text-center",
                  "max-md:pt-1 max-md:line-clamp-3",
                  {
                    "line-clamp-1 max-h-[2.2rem] md:!pt-2 mt-1":
                      isCarousel && !breakpoint.isMobile,
                  }
                )}
                title={psychologist.name}
              >
                {psychologist.name}
              </p>

              {/* Pronunciation */}
              <p className={cn("text-11 font-medium md:hidden")}>
                {renderPsychologistPronouns(psychologist)}
              </p>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex flex-col justify-between h-full">
              {/* Funding methods */}
              <div className="flex items-center gap-x-1 justify-center min-h-[60px]">
                <div className="text-center">
                  {sortFunding(psychologist?.helmControl?.fundingMethods)
                    ?.filter(isValidFunding)
                    .map((tag) => {
                      const badge: BadgeStoryblok = {
                        _uid: GUID(),
                        component: "badge",
                        type: BadgeTypes.PLAIN,
                        size: BadgeSizes.SMALL,
                        name: getFundingMethodDisplayLabel(tag),
                        isUpperCase: true,
                      };

                      return (
                        <span key={badge._uid} className="mr-1">
                          <StoryblokComponent blok={badge} />
                        </span>
                      );
                    })}
                </div>
              </div>

              {/* Expertise */}
              <div className="flex-1 pt-2 mb-3 lg:mb-6">
                <p className="py-3 text-xs font-semibold text-center uppercase">
                  Main areas of expertise
                </p>
                <div
                  className={cn(
                    "min-h-[60px] lg:min-h-[70px] font-normal text-xs lg:text-sm !leading-relaxed text-center mx-3"
                  )}
                >
                  <p className="line-clamp-3">{expertise}</p>
                </div>
              </div>

              {/* Role */}
              <div
                className={cn(
                  "font-medium text-primary text-base md:text-lg text-center mb-1.5",
                  isCarousel && "empty:h-[1.5em]"
                )}
              >
                {renderPsychologistDisplayRole(psychologist?.medicare?.role)}
              </div>

              {/* Next available */}
              <div className="flex justify-center items-end min-h-[20px]">
                {isOpenToNewClients && nextTimeSlot && (
                  <div className="flex items-center justify-center gap-x-1.5 text-sm font-medium">
                    <img
                      src={blok.icon?.filename ?? CalendarSvg}
                      alt={blok.icon?.alt || "Calendar icon"}
                      className="max-w-4 max-h-4 mt-[-2px]"
                    />
                    <div className="text-xs lg:text-sm lg:whitespace-nowrap">
                      Next available: {toAvailabilityDate(nextTimeSlot.date)}
                    </div>
                  </div>
                )}

                {isOpenToNewClients && !nextTimeSlot && (
                  <CardListingItemErrorMsg
                    message={
                      <>
                        My next <strong>4 weeks</strong> is fully booked.
                      </>
                    }
                  />
                )}

                {!isOpenToNewClients && (
                  <CardListingItemErrorMsg message="Not accepting new clients" />
                )}

                {/* {!nextTimeSlot && hasAvailability && (
                <CardListingItemErrorMsg message="Not available to book" />
              )} */}
              </div>
            </div>
          </div>

          <div className="flex items-end w-full py-3">
            <div className="w-full">
              <button
                onClick={handleClickCardItem}
                className={cn("w-full", getDefaultButtonStyles())}
                disabled={
                  !nextTimeSlot || !isOpenToNewClients || isSubmittingBookNow
                }
              >
                Book now
              </button>

              <button
                onClick={() => {
                  if (breakpoint.isXs) {
                    redirectToPsychologistDetails(psychologist.slugUrl);
                  } else {
                    handleClickCardItem();
                  }
                }}
                className="text-primary hover:underline px-5 h-[50px] text-sm font-semibold cursor-pointer rounded-full w-full"
              >
                <span className="flex items-center justify-center px-3">
                  View profile
                  <span className="ml-2 -rotate-90">
                    <ChevronSvg />
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>

        {createPortal(
          <WarningModal
            isVisible={showUnmatchedWarning}
            redirectUrl={toPsychologistDetailsPath(
              getAttachedPsychologistAuth0()
            )}
            heading={`Want to book with ${psychologist?.name}?`}
            content={[
              <React.Fragment key={v4()}>
                To change your psychologist or appointment type, please email{" "}
                <a className="underline" href={`mailto:${EMAIL_SUPPORT_TEAM}`}>
                  {EMAIL_SUPPORT_TEAM}
                </a>{" "}
                with your preferred updates. You're welcome to explore other
                options, but direct booking is currently restricted.
              </React.Fragment>,
            ]}
            buttonContent="Go back to my psychologist"
            additionButtons={[
              <button
                key={v4()}
                className={cn(
                  getDefaultOutlinedButtonStyles(),
                  "w-full md:w-fit"
                )}
                onClick={() => {
                  if (isHaveClaimTypeQuery) {
                    setStoredFundingMethod(claimTypeQuery);
                  }

                  handleBookNowMobileView();

                  toggleUnmatchedWarning();
                }}
              >
                Continue browsing
              </button>,
            ]}
            showCloseButton
            onClose={() => toggleUnmatchedWarning(false)}
          />,
          document.body
        )}

        {renderFeeWarningModal({
          isVisible: showFeeWarning,
          fundingMethod: currentSelectedFundingMethod,
          psychologistData: psychologist,
          onClose: () => toggleFeeWarning(false),
          onContinue: handleFeeWarningContinue,
          onGoBack: handleFeeWarningGoBack,
        })}
      </div>

      {showBookingPanel && (
        <motion.div
          ref={bookingPanelRef}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.6, type: "tween" }}
        >
          <PsychologistDetailFunctions
            blok={{
              ...bookingPanelBlokMemo,
              title: `Book with ${psychologist.name}`,
            }}
            psychologist={psychologist}
            showClosePanelButton
            onClosePanel={() => toggleBookingPanel(false)}
          />
        </motion.div>
      )}
    </>
  );
};
