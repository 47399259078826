import { Skeleton } from "antd";
import { useBreakpoint } from "hooks/useBreakpoint";

type Props = {};

export const PsychologistCardsSkeleton = (props: Props) => {
  const breakpoint = useBreakpoint();

  return (
    <div className="max-md:bg-light-grey rounded-xl flex flex-col gap-y-5 max-md:gap-y-3 justify-between items-center px-5 py-8 bg-white">
      <div className="flex gap-x-3">
        <Skeleton.Avatar
          shape="circle"
          size={breakpoint.isMobile ? 120 : 225}
          active
        />

        <div className="flex flex-col gap-y-3 md:hidden">
          <Skeleton.Input active size="default" />
          <Skeleton.Input active size="small" />
        </div>
      </div>

      <div className="max-md:hidden">
        <Skeleton.Input active />
      </div>

      <Skeleton.Input active size="small" className="mt-2.5" />

      <Skeleton.Input active size="small" className="mt-1.5" />

      <Skeleton.Input active size="default" className="flex-1" />

      <Skeleton.Input size="small" />

      <Skeleton.Button active block shape="round" size="large" />

      <Skeleton.Input size="small" className="mt-0" />
    </div>
  );
};
