import { FaqStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent } from "@storyblok/react";

export const Faq = ({ blok }: Props<FaqStoryblok>) => {
  return (
    <div className="flex items-center justify-center py-20 md:py-32 text-primary">
      <div className="flex flex-col items-start justify-center gap-6 md:flex-row">
        <div className="flex items-center justify-center w-full md:w-2/5">
          <h2 className="text-2xl text-[32px] leading-snug">
            {blok.shouldDisplayCustomTitle ? (
              <>{blok.title}</>
            ) : (
              <>Frequently ask questions</>
            )}
          </h2>
        </div>

        <div className="w-full border-t-2 border-secondary md:w-3/5">
          {blok.questions?.map((question) => (
            <StoryblokComponent
              key={question._uid}
              blok={question}
              contentLineClamp={blok.contentLineClamp}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
