import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import { create, StateCreator } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type State = {
  auth0: ReturnType<typeof useAuth0> | null;
};

type Actions = {
  setSession: (session: ReturnType<typeof useAuth0>) => void;
  reset: () => void;
};

const initialState: State = { auth0: null };

const middleware = <T>(fn: StateCreator<T>) =>
  devtools(
    persist(fn, {
      name: "sessionStore",
      storage: createJSONStorage(() => sessionStorage),
    })
  );

export const useSessionStore = create<State & Actions>()(
  middleware((set) => ({
    auth0: null,

    setSession: (session) => set({ auth0: session }),
    reset: () => set(initialState),
  }))
);

export const getSessionStoreData = (): Auth0ContextInterface<User> | null => {
  return useSessionStore.getState().auth0;
};
