import { PsychologistDetailInfoStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";

import { PsychologistResponse } from "@/services/psychologist.service";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { CheckSvg } from "assets/icons/CheckSvg";
import { CloseSvg } from "assets/icons/CloseSvg";
import { ForumSvg } from "assets/icons/ForumSvg";

import { ClientAgeRange, DeliveryModes } from "enums";
import { cn, languages } from "utils";

type CustomProps = Props<PsychologistDetailInfoStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailInfo = ({ blok, psychologist }: CustomProps) => {
  const { helmControl } = psychologist;

  const psychologistLanguages =
    helmControl?.bonaFides?.languages?.value
      .map((language) => languages?.[language])
      .join(", ") || "Unknown";

  const getClientAgeRangeClassName = (range: ClientAgeRange) => {
    const validHelmControl = helmControl?.clientAgeRange?.includes(range);

    return cn(
      "flex flex-row items-center justify-start w-full gap-2 pb-2 border-b border-secondary-darker md:border-none md:w-fit md:justify-center md:pb-0",
      {
        "text-disabled pointer-events-none bg-transparent opacity-40":
          !validHelmControl,
      }
    );
  };

  const getClientAgeRangeIcon = (range: ClientAgeRange) => {
    const isRangeIncluded = helmControl?.clientAgeRange?.includes(range);
    const SvgIcon = isRangeIncluded ? CheckSvg : CloseSvg;

    return (
      <SvgIcon className="w-10 h-10 p-2 rounded-full fill-primary bg-secondary" />
    );
  };

  const getDeliveryModesClassName = (mode: DeliveryModes) => {
    const validDeliveryMode = helmControl?.deliveryModes?.includes(mode);

    return cn(
      "flex flex-row items-center justify-start w-full gap-2 pb-2 border-b border-secondary-darker md:border-none md:w-fit md:justify-center md:pb-0",
      {
        "text-disabled pointer-events-none bg-transparent opacity-40":
          !validDeliveryMode,
      }
    );
  };

  const getDeliveryModesIcon = (mode: DeliveryModes) => {
    const validDeliveryMode = helmControl?.deliveryModes?.includes(mode);

    const SvgIcon = validDeliveryMode ? CheckSvg : CloseSvg;

    return (
      <SvgIcon className="w-10 h-10 p-2 rounded-full fill-primary bg-secondary" />
    );
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5"
    >
      <div className="flex gap-y-5 gap-x-8 py-3 text-sm flex-row flex-wrap w-auto justify-start items-center lg:flex-col lg:items-start xl:flex-row">
        <div className={getClientAgeRangeClassName(ClientAgeRange.ADULTS)}>
          {getClientAgeRangeIcon(ClientAgeRange.ADULTS)}
          <span className="font-medium text-primary">Adults</span>
        </div>
        <div className={getClientAgeRangeClassName(ClientAgeRange.YOUTH)}>
          {getClientAgeRangeIcon(ClientAgeRange.YOUTH)}
          <span className="font-medium text-primary">Young people (13-17)</span>
        </div>
        <div className={getDeliveryModesClassName(DeliveryModes.PHONE)}>
          {getDeliveryModesIcon(DeliveryModes.PHONE)}
          <span className="font-medium text-primary">Phone consults</span>
        </div>
        <div className="flex flex-row items-center justify-start w-full gap-2 pb-4 border-b border-secondary-darker md:border-none md:w-fit md:justify-center md:pb-0">
          <span>
            <ForumSvg className="w-9 h-9 fill-primary" />
          </span>
          <div className="font-medium text-primary">
            {psychologistLanguages}
          </div>
        </div>
      </div>

      {blok.body?.map((blokItem) => (
        <StoryblokComponent
          key={blokItem._uid}
          blok={blokItem}
          psychologist={psychologist}
        />
      ))}
    </div>
  );
};
