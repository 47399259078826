type Props = {
  className: string;
  color?: string;
};

export const CheckSvg = ({ className, color = "black" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      color={color}
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path
        d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"
        stroke="black"
        strokeWidth="20"
      />
    </svg>
  );
};

export const CheckSvgDataUrl =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24' %3E%3Cpath d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z' stroke='black' strokeWidth='20' /%3E%3C/svg%3E";
