import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, useStoryblokState } from "@storyblok/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { LoadingScreen } from "components/shared/LoadingScreen";
import { isProdMode } from "core/config";
import { injectAuth0InterceptorToken } from "services/axios.service";
import { useHandlerStoryblok } from "useHandlerStoryblok";

function App() {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) return;

    injectAuth0InterceptorToken(getAccessTokenSilently);
  }, [isAuthenticated, getAccessTokenSilently]);

  const slug = pathname === "/" ? "home" : pathname.replace("/", "");
  const initialStory = useHandlerStoryblok(slug);

  const story = useStoryblokState(initialStory);

  const isAuth0Redirection = isLoading && ["", "/", "/home"].includes(pathname);

  if (!story || isLoading || isAuth0Redirection || !story.content) {
    return <LoadingScreen />;
  }

  return (
    <div className={isProdMode ? "" : "debug-screens"}>
      <StoryblokComponent blok={story.content} />
    </div>
  );
}

export default App;
