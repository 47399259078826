/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useToggle } from "react-use";
import { SignupSectionStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { LoadingScreen } from "components/shared/LoadingScreen";
import { SignUpFormType } from "enums";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { useSwrLastAppointment } from "hooks/useSwrLastAppointment";
import { ExternalRoutes, Routes } from "routes/main.routes";
import { useProfileStore } from "stores/profile.store";
import { getSignUpFormType, isFilledRequiredFieldsGP, redirectTo } from "utils";
import {
  renderSessionBookingWarning,
  validateStoredBookingData,
} from "utils/warning.util";
import { SignupWithFundingContainer } from "./SignupWithFundingContainer";
import { SignupWithGPContainer } from "./SignupWithGPContainer";
import { SignupWithInviteContainer } from "./SignupWithInviteContainer";
import { isEqual } from "lodash";

export const SignupSection = ({ blok }: Props<SignupSectionStoryblok>) => {
  const { isAuthenticated, isLoading: isLoadingAuthenticated } = useAuth0();

  const { data: appointmentHistory, isLoading: isLoadingAppointmentHistory } =
    useSwrLastAppointment();
  const { lastGpAppointment } = appointmentHistory || {};

  const profile = useProfileStore((state) => state.profile);

  const { loginRedirectTacklit } = useLoginNavigate();

  const [isRedirecting, toggleIsRedirecting] = useToggle(false);

  const isLoadingData =
    isLoadingAuthenticated || isLoadingAppointmentHistory || isRedirecting;

  const formType = getSignUpFormType(blok?.signUpFormTypes);

  const isSignupFundingForm = isEqual(formType, SignUpFormType.FUNDING);
  const isSignupGpForm = isEqual(formType, SignUpFormType.GP);
  const isSignupInviteForm = isEqual(formType, SignUpFormType.INVITE);

  useEffect(() => {
    if (isLoadingAppointmentHistory || isLoadingAuthenticated) return;

    if (isSignupGpForm) {
      handleGpRedirect();
    }

    if (isSignupFundingForm || isSignupInviteForm) {
      handleSignupFormRedirect();
    }
  }, [appointmentHistory, isAuthenticated]);

  const handleGpRedirect = () => {
    if (!isAuthenticated) return;

    const isCompletedGPBooking =
      lastGpAppointment && isFilledRequiredFieldsGP(profile);

    toggleIsRedirecting(true);

    if (isCompletedGPBooking) {
      loginRedirectTacklit(ExternalRoutes.GP_BOOKING);
    } else {
      redirectTo(Routes.BOOKING_GP);
    }
  };

  const handleSignupFormRedirect = () => {
    if (!isAuthenticated) return;

    toggleIsRedirecting(true);

    redirectTo(Routes.ROOT);
  };

  if (isLoadingData) {
    return <LoadingScreen />;
  }

  if (isSignupInviteForm) {
    return <SignupWithInviteContainer key={blok._uid} blok={blok} />;
  }

  if (isSignupGpForm) {
    return <SignupWithGPContainer key={blok._uid} blok={blok} />;
  }

  const storedBookingDataResult = validateStoredBookingData({
    shouldCheckFundingMethod: true,
    shouldCheckAppointmentData: true,
    shouldCheckSignedUpData: false,
  });

  if (!storedBookingDataResult.isEligible) {
    return renderSessionBookingWarning(storedBookingDataResult);
  }

  return <SignupWithFundingContainer key={blok._uid} blok={blok} />;
};
