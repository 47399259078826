import { storyblokEditable } from "@storyblok/react";
import Plyr, { PlyrSource } from "plyr-react";
import { EmbedVideoStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const EmbedVideo = ({ blok }: Props<EmbedVideoStoryblok>) => {
  const videoSrc: PlyrSource | null = {
    type: "video",
    sources: [
      {
        src: blok.link || "",
        provider: blok.provider || "youtube",
        type: "video/mp4",
        size: 720,
      },
    ],
  };

  const margin = blok.margin || "0px 0px 0px 0px";
  const padding = blok.padding || "0px 0px 0px 0px";

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex justify-center items-center"
    >
      <div
        style={{
          padding: padding,
          margin: margin,
          width: `${blok.width}%`,
        }}
      >
        <Plyr
          options={{
            controls: [
              "play-large",
              "rewind",
              "play",
              "fast-forward",
              "progress",
              "current-time",
              "mute",
              "volume",
              "fullscreen",
            ],
            captions: { active: true, language: "auto", update: true },
            previewThumbnails: { enabled: false, src: "" },
            ratio: blok.ratio || "16:9",
          }}
          source={videoSrc}
        />

        <div
          className={cn(
            "text-12 font-semibold text-primary mt-2 uppercase flex",
            blok.captionAlignment
          )}
        >
          {blok.caption}
        </div>
      </div>
    </div>
  );
};
