import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEmpty } from "lodash";
import { useState } from "react";

import { SelectionOption } from "models";
import { Routes } from "routes/main.routes";
import { SearchedHelpWithPsychologistsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import {
  extract,
  getMultiSelectedOptions,
  redirectTo,
  specializationsCombinedValue,
} from "utils";

export const SearchedHelpWithPsychologists = ({
  blok,
}: Props<SearchedHelpWithPsychologistsStoryblok>) => {
  const [searchedSpecializations, setSearchedSpecializations] = useState<
    string[]
  >([]);

  const specializationOptions = specializationsCombinedValue;

  const handleChangeSearchedSpecializations = (
    selectedSpecializations: SelectionOption[]
  ) => {
    setSearchedSpecializations(extract(selectedSpecializations, "value"));
  };

  const handleClickSearch = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (isEmpty(searchedSpecializations)) return;

    const searchedSpecializationsString = JSON.stringify(
      searchedSpecializations
    );

    const searchQuery = encodeURIComponent(searchedSpecializationsString);

    redirectTo(`${Routes.OUR_PSYCHOLOGISTS}?specializations=${searchQuery}`);
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-3 lg:flex-row"
    >
      <div className="flex w-full lg:w-[20rem] xl:w-[25rem]">
        {blok.selections?.map((selectionDropdownBlok) => (
          <StoryblokComponent
            key={selectionDropdownBlok._uid}
            blok={selectionDropdownBlok}
            isLoading={false}
            options={specializationOptions}
            isMultiple
            selectedOptions={getMultiSelectedOptions(
              specializationOptions,
              searchedSpecializations
            )}
            onChangeMultiSelection={handleChangeSearchedSpecializations}
          />
        ))}
      </div>

      <div onClick={handleClickSearch}>
        {blok.buttons?.map((buttonBlok) => (
          <StoryblokComponent key={buttonBlok._uid} blok={buttonBlok} />
        ))}
      </div>
    </div>
  );
};
