import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { isEmpty } from "lodash";
import { Routes } from "routes/main.routes";
import { getCliniciansAsync } from "services/psychologist.service";
import useSWR from "swr";
import {
  PsychologistCardItemStoryblok,
  ResultsStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";
import { cn, redirectTo } from "utils";
import { filterOpenToNewClients } from "utils/booking.util";
import {
  getMatchedPsychologistRequestQueries,
  getStoredGetMatched,
} from "utils/storage.util";

export const Results = ({ blok }: Props<ResultsStoryblok>) => {
  const getMatchedData = getStoredGetMatched();
  const { data: attachedClinician } = useSwrAttachedClinician();

  const { data: clinicians, isLoading } = useSWR(
    "/get-filtered-clinicians",
    getAllCliniciansAsync
  );

  async function getAllCliniciansAsync() {
    try {
      const { data } = await getCliniciansAsync(
        getMatchedPsychologistRequestQueries(getMatchedData)
      );

      return filterOpenToNewClients({
        clinicians: data.clinicians,
        attachedPsyId: attachedClinician?.clinician?._id,
      });
    } catch (err) {
      console.log("[error]: >>", err);
    }
  }

  return (
    <div
      className="flex flex-col items-center my-8 md:my-16"
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col w-full gap-4 md:w-4/5 lg:3/5 md:gap-6">
        {blok.titles?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </div>

      {/* Results */}
      {!isLoading && (
        <div
          className={cn(
            "bg-no-repeat bg-cover w-[110%] max-w-[calc(100vw-15px)]",
            isEmpty(clinicians) ? "mt-5" : "mt-24 h-full md:h-[900px]"
          )}
          style={{
            backgroundImage: `url(${blok.backgroundImage?.filename})`,
          }}
        >
          <div
            className={cn(
              "flex flex-col items-center",
              isEmpty(clinicians) ? "" : "-mt-16"
            )}
          >
            {!isEmpty(clinicians) ? (
              <div className="flex flex-col md:flex-row gap-8 max-w-[1100px]">
                {clinicians?.slice(0, 3).map((clinician, index) => {
                  const cardBlok = {
                    component: "psychologistCardItem",
                  } as PsychologistCardItemStoryblok;

                  return (
                    <div
                      key={`${index}_${clinician._id}`}
                      className="max-w-[336px] max-h-[740px]"
                    >
                      <StoryblokComponent
                        key={`${clinician._id}`}
                        blok={cardBlok}
                        otherProps={{ ...clinician, index }}
                        backgroundCardColor={"bg-light-grey"}
                        isCarousel
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="w-full text-center text-primary relative top-[3rem] max-md:mb-5">
                <h3 className="mb-5 text-xl font-medium">
                  Can’t find what you’re looking for?
                </h3>
                <div className="text-15">
                  Sorry, we’re unable to provide a match. Please adjust your
                  criteria or{" "}
                  <strong
                    className="hover:underline cursor-pointer"
                    onClick={() => {
                      redirectTo(Routes.GET_MATCHED);
                    }}
                  >
                    try again
                  </strong>
                </div>
              </div>
            )}

            <div className="flex flex-col items-center gap-4 p-12 mb-16 md:p-0 md:my-24 md:flex-row">
              {blok.actions?.map((action) => (
                <StoryblokComponent key={action._uid} blok={action} />
              ))}
            </div>
          </div>
        </div>
      )}

      {isLoading && <SpinnerLoading className="w-12 h-12 my-20" />}
    </div>
  );
};
