import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { useEffect, useState } from "react";
import { BookNdisContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import {
  renderSessionBookingWarning,
  validateStoredBookingData,
} from "utils/warning.util";

export const BookNDISContainer = ({
  blok,
}: Props<BookNdisContainerStoryblok>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  useSwrMyProfile();

  const validateStoredDataResults = validateStoredBookingData({
    shouldCheckSignedUpData: !isAuthenticated,
  });

  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  if (!validateStoredDataResults.isEligible) {
    return renderSessionBookingWarning(validateStoredDataResults);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-12 px-0 py-10 lg:px-20 xl:px-24 max-md:pt-0"
    >
      {/* NDIS FORM */}
      <div className="flex flex-1">
        {blok.forms?.map((formBlok) => (
          <StoryblokComponent
            key={formBlok._uid}
            blok={formBlok}
            isAuthenticated={isAuthenticated}
            accessToken={accessToken}
          />
        ))}
      </div>
    </div>
  );
};
