import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo, isEmpty } from "lodash";

import { HeaderNavItemDropdownStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { ChevronSvg } from "assets/icons/ChevronSvg";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { ScreenBreakpoints } from "enums";
import { useToggle, useWindowSize } from "react-use";
import { Routes } from "routes/main.routes";
import { cn, isMatchedUrl, toSafeLink } from "utils";

const DEFAULT_NUM_OF_ROWS = 1;

export const NavItemDropdown = ({
  blok,
}: Props<HeaderNavItemDropdownStoryblok>) => {
  const [isShowItems, toggleShowItems] = useToggle(false);
  const { width } = useWindowSize();

  const isContainItems = !isEmpty(blok?.items);
  const isDesktopScreen = width >= ScreenBreakpoints.XL;
  const numOfRows = Boolean(blok?.numOfRows)
    ? blok.numOfRows
    : defaultTo(blok?.items?.length, DEFAULT_NUM_OF_ROWS);

  const handleClickDropdown = () => {
    if (!isDesktopScreen && !isContainItems) return;

    toggleShowItems(!isShowItems);
  };

  const handleMouseEnter = () => {
    if (!isDesktopScreen) return;

    toggleShowItems(true);
  };

  const handleMouseLeave = () => {
    if (!isDesktopScreen) return;

    toggleShowItems(false);
  };

  const navigateLink = isEmpty(blok?.link?.cached_url)
    ? Routes.PLACEHOLDER
    : blok?.link?.cached_url;

  const isMatchedLink = isMatchedUrl(navigateLink);

  return (
    <div {...storyblokEditable(blok)}>
      {/* Mobile */}
      <div className="block xl:hidden">
        {/* Title */}
        <div className="flex justify-between items-center cursor-pointer">
          <LinkWrapper
            to={toSafeLink(navigateLink)}
            className={cn(
              "flex justify-center items-center text-14 text-primary py-2 duration-500",
              {
                "text-15 font-semibold ease-in-out duration-500": isShowItems,
              }
            )}
          >
            {blok.customIcon?.filename && (
              <img
                src={blok.customIcon.filename}
                alt={blok.customIcon.alt}
                className="w-auto h-auto mr-2"
              />
            )}

            {blok.name}
          </LinkWrapper>
          <div>
            <span
              className={cn(
                isShowItems ? "-rotate-180" : "rotate-0",
                isContainItems
                  ? "block transition-opacity duration-700 ease-in-out"
                  : "hidden"
              )}
              onClick={handleClickDropdown}
            >
              <ChevronSvg
                className={cn({
                  "fill-primary": isShowItems,
                })}
              />
            </span>
          </div>
        </div>

        {/* Dropdown */}
        {isContainItems && (
          <div
            className={cn(
              "grid overflow-hidden duration-300 ease-in-out bg-light-grey",
              isShowItems
                ? "grid-rows-[1fr] opacity-100"
                : "grid-rows-[0fr] opacity-0"
            )}
          >
            <div
              className={cn(
                "flex flex-col justify-center items-start overflow-hidden"
              )}
            >
              <div className="text-14 p-3">
                {blok?.items?.map((item) => (
                  <div key={item._uid} className="py-2">
                    <StoryblokComponent blok={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Desktop */}
      <div
        className="hidden xl:block cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Title */}
        <LinkWrapper to={toSafeLink(navigateLink)}>
          <div
            className={cn(
              "flex justify-center items-center h-[30px] duration-300",
              {
                "bg-secondary rounded-full": isShowItems || isMatchedLink,
              }
            )}
          >
            <span
              className={cn(
                "flex items-center justify-center mx-1 text-sm text-primary ml-[13px]",
                {
                  "mr-[13px]": !isContainItems,
                }
              )}
            >
              {((blok.customIcon?.filename && !isShowItems) ||
                (blok.customIcon?.filename &&
                  isShowItems &&
                  !blok.customHoverIcon?.filename)) && (
                <img
                  src={blok.customIcon?.filename}
                  alt={blok.customIcon?.alt}
                  className="w-3 h-3 mr-2 duration-300"
                />
              )}

              {blok.customHoverIcon?.filename && isShowItems && (
                <img
                  src={blok.customHoverIcon.filename}
                  alt={blok.customHoverIcon.alt}
                  className="w-3 h-3 mr-2 duration-300"
                />
              )}

              {blok.name}
            </span>
            <span
              className={cn(
                "mr-[13px]",
                isShowItems ? "-rotate-180" : "rotate-0",
                isContainItems
                  ? "block transition-opacity duration-700 ease-in-out"
                  : "hidden"
              )}
            >
              <ChevronSvg
                className={cn({
                  "fill-primary": isShowItems || isMatchedLink,
                })}
              />
            </span>
          </div>
        </LinkWrapper>

        {/* Dropdown */}
        {isContainItems && (
          <div
            className={cn(
              "fixed",
              isShowItems ? "visible" : "invisible duration-100"
            )}
          >
            <div
              className="grid mt-[10px] p-[30px] gap-[10px] rounded-[30px] bg-white shadow-bottom grid-flow-col"
              style={{
                gridTemplateRows: `repeat(${numOfRows}, minmax(0, 1fr))`,
              }}
            >
              {blok.items?.map((item) => (
                <div key={item._uid} className="mr-[60px] font-normal">
                  <StoryblokComponent blok={item} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
