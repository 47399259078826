import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEmpty } from "lodash";
import { SignupActionsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const SignupActions = ({ blok }: Props<SignupActionsStoryblok>) => {
  const isShowActionsBlok =
    !isEmpty(blok.heading) || !isEmpty(blok.actionButtons);

  return (
    <div
      className="flex flex-col gap-6 lg:max-w-[400px]"
      {...storyblokEditable(blok)}
    >
      {isShowActionsBlok && (
        <div>
          <p className="text-lg text-primary mb-2">{blok?.heading}</p>

          {blok?.actionButtons?.map((button) => (
            <StoryblokComponent key={button._uid} blok={button} />
          ))}
        </div>
      )}

      <div>
        {blok?.guards?.map((guard) => (
          <StoryblokComponent key={guard._uid} blok={guard} />
        ))}
      </div>
    </div>
  );
};
