/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import AOS from "aos";
import { cloneDeep, isEmpty, isEqual, isNil, max, min, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import {
  useFirstMountState,
  useList,
  useSearchParam,
  useToggle,
} from "react-use";
import useSWR from "swr";
import { OurPsychologistsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import {
  DEFAULT_APPOINTMENT_TYPE,
  DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE,
} from "core/booking.constant";
import { ClientAgeRange, DeliveryModes } from "enums";
import { useCancelQuery } from "hooks/useCancelQuery";
import {
  FetchPsychologistParamsType,
  Funding,
  Gender,
  OurPsychologistsFilterType,
  OurPsychologistsSearchType,
} from "models";
import {
  getAvailableTime,
  getCliniciansAsync,
  PsychologistResponse,
} from "services/psychologist.service";
import {
  defaultOurPsychologistsFilter,
  defaultOurPsychologistsSearch,
  getAttachedPsychologistAuth0,
  joinQuery,
  safeBoolUndefined,
  safeUndefined,
  toClaimType,
} from "utils";
import {
  clearStoredGetMatched,
  getMatchedHelpWithList,
  getStoredFundingMethod,
  getStoredGetMatched,
  setStoredFundingMethod,
} from "utils/storage.util";

export const OurPsychologists = ({
  blok,
}: Props<OurPsychologistsStoryblok>) => {
  const { isAuthenticated } = useAuth0();
  const queryCliniciansCancellable = useCancelQuery({
    queryFn: getCliniciansAsync,
  });

  const searchedSpecializationsString = useSearchParam("specializations");
  const searchedFundingString = useSearchParam("fundingMethods");
  const searchedPsychologistIdString = useSearchParam("psychologistId");
  const searchedInvitedString = useSearchParam("invited");

  const isInvitedUserLoggedIn =
    isAuthenticated && searchedInvitedString === "true";

  const storedFunding = getStoredFundingMethod();

  const getMatchedData = getStoredGetMatched();
  const getMatchedApiQueries = getMatchedData?.apiQueries;

  const isFirstMount = useFirstMountState();

  const attachedPsyId = getAttachedPsychologistAuth0();

  const hasSearchParameters = () =>
    !isEmpty(searchedSpecializationsString) ||
    !isEmpty(searchedFundingString) ||
    !isEmpty(searchedPsychologistIdString);

  const isFirstMountWithGetMatched = isFirstMount && getMatchedData;

  const [loadedPageNumber, setLoadedPageNumber] = useState<number | null>(null);

  const [fetchParams, setFetchParams] = useState<FetchPsychologistParamsType>({
    search: defaultOurPsychologistsSearch,
    filter: defaultOurPsychologistsFilter,
    currentPageIndex: 1,
  });

  const [showFilter, toggleShowFilter] = useToggle(false);
  const [isBackFromGetMatched, toggleBackFromGetMatched] = useToggle(false);

  const [items, { reset: resetItems, set: setItems }] =
    useList<PsychologistResponse>([]);

  const { data, isLoading } = useSWR(
    ["/get-filtered-clinicians", fetchParams],
    getAllCliniciansAsync
  );

  useEffect(() => {
    AOS.refresh();
  }, [data, isLoading]);

  // Proceed with search query if there are search parameters
  useEffect(() => {
    const clonedFetchParams = cloneDeep(fetchParams);

    const searchedSpecializations = getSpecializationOptions(
      searchedSpecializationsString
    );
    const searchedFunding = getFundingOptions(searchedFundingString);

    const isHaveSearchParameters =
      !isEmpty(searchedSpecializations) ||
      !isEmpty(searchedFunding) ||
      !isEmpty(searchedPsychologistIdString);

    if (!isHaveSearchParameters) return;

    if (searchedSpecializations) {
      clonedFetchParams.search.helpWithList = searchedSpecializations;
    }

    if (!isEmpty(searchedFunding)) {
      clonedFetchParams.search.funding = searchedFunding as Funding;
    }

    if (searchedPsychologistIdString) {
      clonedFetchParams.search.particularPsychologist =
        searchedPsychologistIdString;
    }

    clonedFetchParams.filter = defaultOurPsychologistsFilter;
    clonedFetchParams.currentPageIndex = 1;

    setFetchParams(clonedFetchParams);
  }, [
    searchedFundingString,
    searchedSpecializationsString,
    searchedPsychologistIdString,
  ]);

  // Proceed with stored funding
  useEffect(() => {
    const clonedFetchParams = cloneDeep(fetchParams);

    const isNoPopulateStoredFunding =
      isNil(storedFunding) ||
      getMatchedApiQueries ||
      hasSearchParameters() ||
      isBackFromGetMatched;

    if (isNoPopulateStoredFunding) return;

    clonedFetchParams.search.funding = storedFunding;

    setFetchParams(clonedFetchParams);
  }, [storedFunding, getMatchedApiQueries]);

  // Proceed with get matched data
  useEffect(() => {
    if (!getMatchedApiQueries) return;

    toggleBackFromGetMatched(true);

    const matchedSearchValues = {
      ...defaultOurPsychologistsSearch,
      funding: DEFAULT_APPOINTMENT_TYPE,
      helpWithList: getMatchedHelpWithList(getMatchedData),
    };

    const matchedFilterValues = {
      ...defaultOurPsychologistsFilter,
      psychologistGender: getMatchedApiQueries.gender?.toString()?.trim() ?? "",
      preferredDays: getMatchedApiQueries.days?.trim()?.split(",") ?? [],
      availableTimes: getMatchedApiQueries.time?.trim()?.split(",") ?? [],
    };

    const newFetchParams = {
      search: matchedSearchValues,
      filter: matchedFilterValues,
      currentPageIndex: 1,
    };

    setFetchParams(newFetchParams);

    handleShowFilter(true);
  }, [getMatchedApiQueries]);

  // Proceed with search query if user login
  useEffect(() => {
    const isNoPopulateAttachedPsyId =
      !attachedPsyId || getMatchedApiQueries || hasSearchParameters();

    if (isNoPopulateAttachedPsyId) return;

    const clonedFetchParams = cloneDeep(fetchParams);

    clonedFetchParams.search.particularPsychologist = attachedPsyId;

    setFetchParams(clonedFetchParams);
  }, [attachedPsyId]);

  async function getAllCliniciansAsync() {
    if (isFirstMountWithGetMatched) return;

    const { search, filter, currentPageIndex } = fetchParams;

    const availableTimes = getAvailableTime(filter.availableTimes);

    try {
      const data = await queryCliniciansCancellable.queryAsync({
        funding: search.funding || undefined,
        claimType: search?.funding ? toClaimType(search.funding) : undefined,
        // isNewClient: true,
        page: currentPageIndex,
        perPage: DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE,

        // Params sure run
        days: joinQuery(filter.preferredDays),
        gender: safeUndefined(filter.psychologistGender) as Gender,
        clientMentalHealthConcern: joinQuery(search.helpWithList),
        clinicianIds: safeUndefined(search.particularPsychologist),

        startTime: min(availableTimes),
        endTime: max(availableTimes),
        language: safeUndefined(filter.languagesSpoken),
        style: joinQuery(filter.treatmentStyles),

        // Params missing fields
        nextAvailable: safeUndefined(filter.nextAvailable),
        deliveryMode: safeBoolUndefined(filter.isDoPhoneConsults)
          ? DeliveryModes.PHONE
          : undefined,
        clientAgeRange: safeBoolUndefined(filter.isDoTreatTeens)
          ? ClientAgeRange.TEENAGERS
          : undefined,
      });

      if (search?.funding) {
        setStoredFundingMethod(search.funding);
      }

      // Only clear stored get matched data query clinicians success
      if (getMatchedData) {
        clearStoredGetMatched();
      }

      const combinedClinicians =
        currentPageIndex > 1
          ? uniqBy([...items, ...data.clinicians], "_id")
          : data.clinicians;

      // const clinicians = filterOpenToNewClients({
      //   clinicians: combinedClinicians,
      //   attachedPsyId: attachedPsyId,
      // });

      setItems(combinedClinicians);

      setLoadedPageNumber(currentPageIndex);

      return data;
    } catch (err) {
      console.log("[error]: >>", err);
    } finally {
      toggleBackFromGetMatched(false);
    }
  }

  const getSpecializationOptions = (
    searchedSpecializationsString: string | null
  ): string[] => {
    if (!searchedSpecializationsString) return [];

    const searchedSpecializations = JSON.parse(searchedSpecializationsString);

    if (isEmpty(searchedSpecializations)) return [];

    return searchedSpecializations;
  };

  const getFundingOptions = (searchedFundingString: string | null): string => {
    if (!searchedFundingString) return "";

    const searchedFunding = searchedFundingString as Funding;

    if (Object.values(Funding).includes(searchedFunding)) {
      return searchedFunding;
    }

    return "";
  };

  const handleChangeFilter = (selectedFilter: OurPsychologistsFilterType) => {
    const newFetchParams = cloneDeep(fetchParams);

    newFetchParams.filter = selectedFilter;
    newFetchParams.currentPageIndex = 1;

    if (isEqual(newFetchParams, fetchParams)) return;

    resetQuery();

    setFetchParams(newFetchParams);
  };

  const handleShowFilter = (shouldShowFilter: boolean) => {
    toggleShowFilter(shouldShowFilter);
  };

  const handleClickSearch = (selectedSearch: OurPsychologistsSearchType) => {
    // Prevent populate last appointment funding when back from get matched
    if (isBackFromGetMatched) return;

    const newFetchParams = cloneDeep(fetchParams);

    newFetchParams.search = selectedSearch;
    newFetchParams.currentPageIndex = 1;

    if (isEqual(newFetchParams, fetchParams)) return;

    resetQuery();

    setFetchParams(newFetchParams);

    toggleShowFilter(false);
  };

  const handleChangePage = (step: number) => {
    if (isNil(loadedPageNumber) || isLoading) return;

    const newPageIndex = loadedPageNumber + step;

    const newFetchParams = cloneDeep(fetchParams);

    newFetchParams.currentPageIndex = newPageIndex;

    if (isEqual(newFetchParams, fetchParams)) return;

    setFetchParams(newFetchParams);
  };

  const resetQuery = () => {
    setLoadedPageNumber(null);

    resetItems();
  };

  return (
    <div {...storyblokEditable(blok)}>
      {/* BANNER */}
      {isAuthenticated && !isInvitedUserLoggedIn ? (
        <div>
          {blok.signedInBanner?.map((signedInBannerBlok) => (
            <StoryblokComponent
              key={signedInBannerBlok._uid}
              blok={signedInBannerBlok}
            />
          ))}
        </div>
      ) : (
        <div>
          {blok.banner?.map((bannerBlok) => (
            <StoryblokComponent key={bannerBlok._uid} blok={bannerBlok} />
          ))}
        </div>
      )}

      <div className="flex flex-col px-0 py-3 lg:px-6 xl:px-8 gap-y-5">
        {/* SEARCH BAR */}
        <div>
          {blok.searchBar?.map((searchBarBlok) => (
            <StoryblokComponent
              key={searchBarBlok._uid}
              blok={searchBarBlok}
              selectedSearch={fetchParams.search}
              onClickSearch={handleClickSearch}
              isSearching={isLoading}
            />
          ))}
        </div>

        {/* FILTER BAR */}
        <div>
          {blok.filterBar?.map((filterBarBlok) => (
            <StoryblokComponent
              key={filterBarBlok._uid}
              blok={filterBarBlok}
              selectedFilter={fetchParams.filter}
              onChangeFilter={handleChangeFilter}
              onShowFilter={handleShowFilter}
              shouldShowFilter={showFilter}
            />
          ))}
        </div>

        {/* PSYCHOLOGIST LISTING */}
        <div>
          {blok.list?.map((listBlok) => (
            <StoryblokComponent
              key={listBlok._uid}
              blok={listBlok}
              cliniciansResponse={data}
              items={items}
              isLoading={isLoading}
              onPageChange={handleChangePage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
