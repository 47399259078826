import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Banner } from "components/stats/Banner";
import { get, isEmpty } from "lodash";
import { SeoBreadcrumbTrail } from "provider/SeoBreadcrumbTrail";
import { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import { getCurrentStoryData } from "services/storyblok.service";
import { ArticleContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { toBlogDateFormat } from "utils";
import {
  getStoryBlokFirstPublishedDate,
  renderBlogBannerWithPublishedDate,
} from "utils/blog.util";

export const ArticleContainer = ({
  blok,
}: Props<ArticleContainerStoryblok>) => {
  const [publishDate, setPublishDate] = useState<string>();

  const gap = `${blok.gap}px`;

  useEffectOnce(() => {
    const customBlogPublishedDate = blok.customPublishedDate;

    if (!isEmpty(customBlogPublishedDate)) {
      setPublishDate(customBlogPublishedDate);

      return;
    }

    getCurrentStoryData()
      .then((data) => {
        const firstPublishedDate = getStoryBlokFirstPublishedDate(data);
        const publishedDateString = String(firstPublishedDate);

        setPublishDate(publishedDateString);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  });

  const breadcrumbs = useMemo(() => {
    const hierarchyBreadcrumbs = [
      { name: "Home", url: window.location.origin },
      { name: "Blog", url: window.location.origin + "/blog" },
    ];

    const blogName = get(blok, "banner[0].content[0].content");

    if (blogName) {
      hierarchyBreadcrumbs.push({
        name: blogName,
        url: window.location.href,
      });
    }

    return hierarchyBreadcrumbs;
  }, [blok]);

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col justify-center gap-3"
      style={{
        gap: gap,
      }}
    >
      <SeoBreadcrumbTrail breadcrumbs={breadcrumbs} />

      {/* BANNER */}
      {blok.banner?.map((banner) => {
        if (publishDate) {
          banner = renderBlogBannerWithPublishedDate(
            banner,
            toBlogDateFormat(publishDate)
          );
        }

        return (
          <div key={banner._uid}>
            <Banner blok={banner} isArticleBanner />
          </div>
        );
      })}

      {/* BLOG CONTENT */}
      <div className="mx-0 xl:mx-48">
        {blok.content?.map((blok) => (
          <div key={blok._uid}>
            <StoryblokComponent blok={blok} />
          </div>
        ))}
      </div>
    </div>
  );
};
