import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Dropdown } from "flowbite-react";
import { isEmpty } from "lodash";
import { useToggle } from "react-use";

import { ArrowSvg } from "assets/icons/ArrowSvg";

import { LinkWrapper } from "components/shared/LinkWrapper";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { ExternalRoutes } from "routes/main.routes";
import { cn, toPsychologistDetailsPath } from "utils";
import {
  cleanupStoredUserSessionLogout,
  removeStoredFundingMethod,
} from "utils/storage.util";

type Props = {
  isSmallScreen: boolean;

  onSmallScreenAvatarClick: () => void;
};

export const ProfileMenuDropdown = (props: Props) => {
  const { user, logout } = useAuth0();
  const { data: attachedClinician } = useSwrAttachedClinician();

  const hasAttachedClinician = !isEmpty(attachedClinician?.clinician);

  const navClasses = cn(
    "text-primary rounded-lg text-base font-normal p-1 px-2 bg-none"
  );

  const [isMenuExpanded, toggleMenuExpanded] = useToggle(false);

  const handleClickSmallScreenAvatar = () => {
    props.onSmallScreenAvatarClick();

    toggleMenuExpanded();
  };

  if (props.isSmallScreen) {
    return (
      <div
        className="flex flex-row justify-between items-center border-light-grey border-[1px] bg-light shadow rounded-full p-1 cursor-pointer"
        onClick={handleClickSmallScreenAvatar}
      >
        <div className="flex flex-row justify-start items-center">
          <Avatar
            img={user?.picture}
            size={"sm"}
            className="min-w-[32px]"
            alt={user?.name}
            rounded
          />
        </div>

        <div className="p-2">
          <ArrowSvg
            className={cn(
              "w-2 h-2 duration-300 ease-in-out shrink-0",
              isMenuExpanded
                ? "rotate-0 text-primary"
                : "rotate-180 text-secondary"
            )}
            color="black"
          />
        </div>
      </div>
    );
  }

  return (
    <Dropdown
      label=""
      dismissOnClick
      renderTrigger={() => (
        <span>
          <Avatar
            img={user?.picture}
            size="sm"
            className="cursor-pointer"
            alt={user?.name}
            title={`${user?.name}\n(${user?.email})`}
            rounded
          />
        </span>
      )}
      className={cn("px-6 py-3 rounded-3xl")}
    >
      <div className={cn("flex flex-col gap-3.5")}>
        <Dropdown.Item className={cn(navClasses)}>
          <LinkWrapper to={ExternalRoutes.MY_APPOINTMENTS}>
            Manage my appointments
          </LinkWrapper>
        </Dropdown.Item>
        <Dropdown.Item className={cn(navClasses)}>
          <LinkWrapper to={ExternalRoutes.REFERRAL}>
            Add referral details
          </LinkWrapper>
        </Dropdown.Item>
        <Dropdown.Item className={cn(navClasses)}>
          <LinkWrapper to={ExternalRoutes.MY_PROFILE}>
            Manage my profile
          </LinkWrapper>
        </Dropdown.Item>

        <Dropdown.Item className="p-0 rounded-full">
          <LinkWrapper
            to={toPsychologistDetailsPath(attachedClinician?.clinician?._id)}
            className="w-full"
            disabled={!hasAttachedClinician}
          >
            <button
              className={cn(
                "w-full font-semibold cursor-pointer rounded-full bg-primary text-light duration-300 px-2 h-[40px] text-sm",
                "hover:bg-secondary-darker hover:text-primary disabled:bg-neutral disabled:text-dark-grey disabled:cursor-not-allowed"
              )}
              disabled={!hasAttachedClinician}
              onClick={removeStoredFundingMethod}
            >
              Book my psychologist
            </button>
          </LinkWrapper>
        </Dropdown.Item>
        <Dropdown.Item className="p-0 rounded-full">
          <LinkWrapper to={ExternalRoutes.GP_BOOKING} className="w-full">
            <button
              className={cn(
                "w-full font-semibold cursor-pointer rounded-full bg-secondary hover:bg-secondary-darker text-primary  px-5 h-[40px] text-sm"
              )}
            >
              Book a GP
            </button>
          </LinkWrapper>
        </Dropdown.Item>

        <hr className="w-[80%] mx-auto border-1 border-secondary my-0.5" />

        <Dropdown.Item
          className="p-0 mb-1 rounded-full"
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });

            cleanupStoredUserSessionLogout();
          }}
        >
          <button
            className={cn(
              "text-center w-full p-0 font-semibold cursor-pointer rounded-full bg-none border-2 border-primary text-primary px-5 h-[40px] text-sm"
            )}
          >
            Sign out
          </button>
        </Dropdown.Item>
      </div>
    </Dropdown>
  );
};
