import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Props } from "@/types/core";
import { ReferralTemplatesStoryblok } from "@/types/component-types-sb";
import { cn } from "utils";

export const ReferralTemplates = ({
  blok,
}: Props<ReferralTemplatesStoryblok>) => {
  const { contentWidth } = blok;

  const numberOfItems = blok.items?.length;
  const hasBackgroundImage = Boolean(blok.backgroundImage?.filename);

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "flex flex-col bg-cover bg-no-repeat",
        hasBackgroundImage
          ? "-mt-32 md:-mt-20 xl:-mt-10 relative z-[1]"
          : "mt-8 xl:mt-16"
      )}
      style={{ backgroundImage: `url(${blok.backgroundImage?.filename})` }}
    >
      <div
        className={cn("flex flex-row justify-center mb-[60px] mx-4", {
          "mt-40": hasBackgroundImage,
        })}
      >
        <div
          className="flex flex-col gap-2 text-center"
          style={{ maxWidth: contentWidth ? `${contentWidth}px` : "unset" }}
        >
          {blok.contents?.map((content) => (
            <StoryblokComponent key={content._uid} blok={content} />
          ))}
        </div>
      </div>

      {numberOfItems && (
        <div
          className={cn(
            `flex flex-wrap justify-center gap-x-[36px] gap-y-[36px] self-center mx-4`,
            { "mb-36": blok.backgroundImage?.filename }
          )}
        >
          {blok.items?.map((item, index) => (
            <StoryblokComponent
              key={item._uid}
              blok={item}
              index={index}
              hasBackgroundImage={hasBackgroundImage}
            />
          ))}
        </div>
      )}
    </div>
  );
};
