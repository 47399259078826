import {
  BadgeSizes,
  BadgeTypes,
  HeadingLevels,
  ScreenBreakpoints,
} from "enums";
import { cloneDeep, defaultTo, first, isEmpty, isNil } from "lodash";
import { Story } from "services/storyblok.service";
import {
  ArticleContainerStoryblok,
  BadgeStoryblok,
  BannerStoryblok,
  BlogItemStoryblok,
  HeadingStoryblok,
  TextStoryblok,
} from "types/component-types-sb";
import { v4 } from "uuid";
import { toSafeLink } from "./common.util";
import { toBlogDateFormat } from "./date.util";
import { blogCategories } from "./styles.util";
import { Routes } from "routes/main.routes";

export const renderBlogItem = (
  blogItem: Story,
  backgroundColor?: string
): BlogItemStoryblok | undefined => {
  const article = getArticleContainer(blogItem);

  if (!article || article.shouldHidden) {
    return;
  }

  const publishedDate = isEmpty(article?.customPublishedDate)
    ? getStoryBlokFirstPublishedDate(blogItem)
    : defaultTo(article?.customPublishedDate, new Date());

  return {
    component: "blogItem",
    _uid: blogItem.uuid,
    image: article?.imageHolder,
    title: [renderBlogItemTitle(blogItem?.name)],
    publishDate: String(publishedDate),
    category: renderBlogItemCategories(article?.articleCategories),
    tags: renderBlogItemTags(article?.tags),
    backgroundColor: backgroundColor ?? article.backgroundColor,
  };
};

export const getArticleContainer = (
  blogItem: Story
): ArticleContainerStoryblok | undefined => {
  return first(
    blogItem.content.body.filter(
      (c: ArticleContainerStoryblok) => c.component === "articleContainer"
    )
  );
};

export const renderBlogItemTitle = (title: string): HeadingStoryblok => ({
  component: "heading",
  _uid: v4(),
  content: title,
  level: HeadingLevels.H4,
});

export const renderBlogItemCategories = (
  categories: (string | number)[] | undefined
): BadgeStoryblok[] => {
  if (!categories || isEmpty(categories)) {
    return [];
  }

  return categories.map((category) => ({
    component: "badge",
    _uid: v4(),
    name: String(
      first(blogCategories.filter((spec) => category === spec.value))?.label
    ),
    type: BadgeTypes.PLAIN,
    size: BadgeSizes.MEDIUM,
  }));
};

export const renderBlogItemTags = (
  tags: string | undefined
): BadgeStoryblok[] => {
  if (!tags || isEmpty(tags)) {
    return [];
  }

  return tags.split(",").map((tag) => ({
    component: "badge",
    _uid: v4(),
    name: tag,
    type: BadgeTypes.SECONDARY_LIGHT,
    size: BadgeSizes.SMALL,
  }));
};

export const renderArticleBanner = (
  blog?: Story
): BannerStoryblok | undefined => {
  if (!blog) return;

  const article = getArticleContainer(blog);

  if (!article) return;

  const banner = first(article.banner) as BannerStoryblok;

  if (!banner) return;

  const firstPublishedDate = isEmpty(article?.customPublishedDate)
    ? getStoryBlokFirstPublishedDate(blog)
    : new Date(String(article?.customPublishedDate));

  const publishedDate = toBlogDateFormat(String(firstPublishedDate));

  return renderBlogBannerWithPublishedDate(banner, publishedDate);
};

export const renderBlogBannerWithPublishedDate = (
  banner: BannerStoryblok,
  publishDateString: string
) => {
  const publishDateText: TextStoryblok = {
    component: "text",
    _uid: v4(),
    text: publishDateString,
    fontSize: "14",
    fontWeight: "font-semibold",
  };

  const clonedBanner = cloneDeep(banner);

  clonedBanner.content = [publishDateText, ...(banner.content || [])];

  return clonedBanner;
};

export const filterSearchStoriesDataByVisibility = (
  response: Story[]
): Story[] => {
  return response.filter((blogItem: Story) => {
    const article = getArticleContainer(blogItem);

    return !Boolean(article?.shouldHidden);
  });
};

export const filterSearchStoriesDataByCategories = (
  response: Story[],
  searchCategories: (string | number)[]
): Story[] => {
  return response.filter((blogItem: Story) => {
    const article = getArticleContainer(blogItem);

    return article?.articleCategories?.some((category) =>
      searchCategories.includes(category)
    );
  });
};

export const filterSelfSearchStoriesData = (response: Story[]): Story[] => {
  return response.filter(
    (blogItem: Story) =>
      toSafeLink(blogItem.full_slug) !== window.location.pathname
  );
};

const articleMaxWidthScreenBreakpoint = [
  { breakpoint: ScreenBreakpoints.XXL, maxWidth: 600 },
  { breakpoint: ScreenBreakpoints.XL, maxWidth: 480 },
  { breakpoint: ScreenBreakpoints.LG, maxWidth: 400 },
  { breakpoint: ScreenBreakpoints.MD, maxWidth: 300 },
];

export const calculateArticleBannerImageMaxWidth = (
  windowWidth: number
): string | undefined => {
  const foundBreakpoint = articleMaxWidthScreenBreakpoint.find(
    (bp) => windowWidth >= bp.breakpoint
  );
  return foundBreakpoint ? `${foundBreakpoint.maxWidth}px` : undefined;
};

export const calculateArticleBannerContentMaxWidth = (params: {
  windowWidth: number;
  contentWidth: number;
}): string | undefined => {
  const { windowWidth, contentWidth } = params || {};

  if (windowWidth >= ScreenBreakpoints.XXL) {
    return `${contentWidth}px`;
  }

  return undefined;
};

export const sortSearchStoriesDataByPublishedDate = (
  stories: Story[]
): Story[] => {
  const clonedData = cloneDeep(stories);

  const storiesWithTimestamps = clonedData.map((story: Story) => {
    const article = getArticleContainer(story);

    const customDate = article?.customPublishedDate
      ? new Date(article.customPublishedDate).getTime()
      : null;

    const publishDate = isNil(customDate)
      ? getStoryBlokFirstPublishedDate(story).getTime()
      : customDate;

    return { ...story, publishDate };
  });

  storiesWithTimestamps.sort((a, b) => b?.publishDate - a?.publishDate);

  return storiesWithTimestamps;
};

export const getStoryBlokFirstPublishedDate = (story?: Story): Date => {
  if (isEmpty(story)) return new Date();

  return isEmpty(story?.first_published_at)
    ? new Date()
    : new Date(story?.first_published_at);
};

export const isBlogPage = () => {
  return window.location.pathname.startsWith(Routes.BLOG);
};

export const isEngagePages = () => {
  return (
    window.location.pathname.startsWith(Routes.OUR_PSYCHOLOGISTS) ||
    window.location.pathname.startsWith(Routes.PSYCHOLOGIST_DETAILS)
  );
};
