import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { RichTextPolicyContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const RichTextPolicyContainer = ({
  blok,
}: Props<RichTextPolicyContainerStoryblok>) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok?.content?.map((contentItem) => (
        <StoryblokComponent key={contentItem._uid} blok={contentItem} />
      ))}
    </div>
  );
};
