import {
  StoryblokComponent,
  renderRichText,
  storyblokEditable,
} from "@storyblok/react";
import { ServicePricingItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const ServicePricingItem = ({
  blok,
}: Props<ServicePricingItemStoryblok>) => {
  return (
    <div
      className="flex flex-col justify-start gap-5 bg-light hover:bg-light hover:ring-2 hover:ring-secondary-darker rounded-2xl p-6 w-[380px] shrink-0"
      {...storyblokEditable(blok)}
    >
      <div className="min-h-[24px]">
        {blok.badge?.map((badge) => (
          <StoryblokComponent key={badge._uid} blok={badge} />
        ))}
      </div>

      <h3 className="text-3xl font-normal text-primary">{blok.name}</h3>
      <p
        className="text-sm text-dark-grey leading-7"
        dangerouslySetInnerHTML={{
          __html:
            typeof blok?.description !== "string"
              ? renderRichText(blok.description)
              : String(blok.description),
        }}
      />

      <div className="flex flex-col font-medium border-y border-secondary-darker text-dark-grey whitespace-nowrap h-24">
        <div className="w-full">
          <div className="flex w-full">
            <div className="flex w-24">
              <div className="h-8">
                <span
                  className={cn("text-14 pr-1", {
                    "font-normal": blok?.priceWithoutDiscount,
                  })}
                >
                  {blok?.hasFromLabel && "FROM"}
                </span>

                <span className="text-14">
                  {blok?.priceWithoutDiscount && "$"}
                </span>
              </div>
              <span className="text-20">{blok?.priceWithoutDiscount}</span>
            </div>
            <div className="flex first-letter:items-center">
              <span className="flex text-14 items-center">
                {blok?.priceWithoutDiscount ? (
                  <span>
                    / SESSION
                    <span className="font-normal"> (UPFRONT FEE)</span>
                  </span>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>

          <div
            className={cn("flex items-end w-full", {
              "gap-5": !Boolean(blok?.priceWithoutDiscount),
            })}
          >
            <div
              className={cn("flex", {
                "w-28": Boolean(blok?.priceWithoutDiscount),
              })}
            >
              <span className="text-24">$</span>
              <span className="text-48">{blok.price}</span>
            </div>
            <div className="pb-3">
              <span className="text-14">
                {blok?.priceWithoutDiscount ? (
                  <span>
                    / OUT-OF-POCKET{" "}
                    <span className="font-normal">(AFTER REBATE)</span>
                  </span>
                ) : (
                  <span>/ SESSION</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* CHECK */}
      <div className="flex flex-col gap-5 justify-center items-start text-sm mb-6">
        {blok.benefits?.map((benefit) => (
          <StoryblokComponent key={benefit._uid} blok={benefit} />
        ))}
      </div>

      {/* ACTION BUTTON */}
      <div className="flex-1 flex items-end">
        {blok.actionButton?.map((button) => (
          <StoryblokComponent key={button._uid} blok={button} />
        ))}
      </div>
    </div>
  );
};
