import { storyblokEditable } from "@storyblok/react";
import { CardStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const Card = ({ blok }: Props<CardStoryblok>) => {
  return (
    <a
      href="#!"
      className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      {...storyblokEditable(blok)}
    >
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {blok.header}
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400">
        {blok.description}
      </p>
    </a>
  );
};
