import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PageStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";

export const Page = ({ blok }: Props<PageStoryblok>) => {
  return (
    <main
      {...storyblokEditable(blok)}
      className="px-4 sm:px-6 md:px-8 lg:px-12"
    >
      {blok.body?.map((blok: any) => (
        <StoryblokComponent key={blok._uid} blok={blok} />
      ))}
    </main>
  );
};
