import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { isEmpty, isEqual } from "lodash";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useToggle } from "react-use";
import { match } from "ts-pattern";
import { FinaliseBookingContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { TextInput } from "components/forms/TextInput";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { BookBehalfOfType } from "enums";
import { useClientType } from "hooks/useClientType";
import { AsYouType } from "libphonenumber-js";
import { AddressFieldsType } from "models";
import { Routes } from "routes/main.routes";
import {
  postEmergencyContactAsync,
  processReserveWithPaymentAsync,
} from "services/booking.service";
import { TacklitService } from "services/tacklit.service";
import { useProfileStore } from "stores/profile.store";
import {
  formatDollar,
  getAppointmentRate,
  getDefaultBehalfOfOptions,
  getDefaultButtonStyles,
  openInNewTab,
  redirectTo,
} from "utils";
import {
  getDefaultFinaliseBookingSelfFundFormData,
  handleUploadNoReferralAsync,
  reclaimReserveAppointment,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import { BookingInformation } from "../BookingInformation";
import { FilterCheckbox } from "../FilterCheckbox";
import { TextError } from "../TextError";
import { AddressFields } from "../shared/AddressFields";
import { getSelfFundConfirmBookingFormResolver } from "./booking.resolver";

export type SelfFundConfirmBookingFormData = {
  onBehalfOf: string;
  emergencyContactName: string;
  emergencyContactPhoneNumber: string;
  isAgreeCancellationPolicy: boolean;

  bookerName?: string;
  bookerPhone?: string;

  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;
} & AddressFieldsType;

type ConfirmBookingFormProps = Props<FinaliseBookingContainerStoryblok> & {
  reserveId: string;
  accessToken: string;
};

export const ConfirmBookingSelfFundForm = ({
  blok,
  reserveId,
  accessToken,
}: ConfirmBookingFormProps) => {
  const profile = useProfileStore((state) => state.profile);
  const { isAuthenticated } = useAuth0();

  const { isExistingClient } = useClientType();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SelfFundConfirmBookingFormData>({
    mode: "onChange",
    defaultValues: getDefaultFinaliseBookingSelfFundFormData(profile),
    resolver: yupResolver(getSelfFundConfirmBookingFormResolver(accessToken)),
  });

  const { onBehalfOf, isAgreeCancellationPolicy } = useWatch({ control });

  const [isLoading, toggleIsLoading] = useToggle(false);

  const isBehalfOfAdult = isEqual(
    onBehalfOf,
    BookBehalfOfType.SOMEONE_ELSE_ADULT
  );

  const isBehalfOfChild = isEqual(onBehalfOf, BookBehalfOfType.MY_CHILD);

  const isDisabledSubmit =
    isLoading || !isEmpty(errors) || !isAgreeCancellationPolicy;

  const handleFormSubmitAsync = async (
    formData: SelfFundConfirmBookingFormData
  ) => {
    if (!reserveId) {
      customToast.error(
        "Reservation ID not found, failed to claim appointment. Please try again later"
      );

      return;
    }

    toggleIsLoading(true);

    try {
      await postEmergencyContactAsync(
        {
          firstName: formData.emergencyContactName,
          mobileNumber: formData.emergencyContactPhoneNumber,
        },
        accessToken
      );

      await reclaimReserveAppointment(reserveId, accessToken);

      await match(formData.onBehalfOf)
        .with(BookBehalfOfType.MY_SELF, () => {
          return TacklitService.putContactAddressAsync(formData, accessToken);
        })
        .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () => {
          return Promise.all([
            TacklitService.putContactAddressAsync(formData, accessToken),
            TacklitService.putAdultContactAsync(formData, accessToken),
          ]);
        })
        .with(BookBehalfOfType.MY_CHILD, () => {
          return Promise.all([
            TacklitService.putContactAddressAsync(formData, accessToken),
            TacklitService.putChildGuardianProfileAsync(formData, accessToken),
          ]);
        })
        .otherwise(() => {});

      const isUploaded = await handleUploadNoReferralAsync(
        accessToken,
        isExistingClient
      );

      if (!isUploaded) return;

      if (isAuthenticated && Boolean(profile.hasSavedCard)) {
        await processReserveWithPaymentAsync(reserveId, accessToken);

        customToast.success("Appointment claimed successfully");

        redirectTo(Routes.BOOKING_THANK_YOU);

        return;
      }

      const { data } = await processReserveWithPaymentAsync(
        reserveId,
        accessToken
      );

      if (data?.checkoutUrl) {
        customToast.success("Appointment claimed successfully");
        redirectTo(data.checkoutUrl);
      }
    } catch (error) {
      customToast.error("Failed to claim appointment. Please try again later");
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleClickConsentAgreement = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();

    openInNewTab(Routes.CONSENT_AGREEMENT);
  };

  const handleClickCancellationPolicy = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();

    openInNewTab(Routes.CANCELLATION_POLICY);
  };

  const behalfOfOptions = getDefaultBehalfOfOptions(profile);

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-3">
        <span className="font-semibold text-12 text-primary">COST</span>
        <span className="leading-9 text-24 text-primary md:text-30 md:leading-10 xl:text-40 xl:leading-48">
          {formatDollar(getAppointmentRate())}
        </span>
        <span>
          <BookingInformation />
        </span>
      </div>

      <form
        onSubmit={handleSubmit(handleFormSubmitAsync)}
        className="flex flex-col gap-y-3"
      >
        <div className="pt-5">
          <Controller
            name="onBehalfOf"
            control={control}
            render={({ field }) => (
              <SelectionDropdown
                title="I am booking on behalf of:"
                placeHolder=""
                options={behalfOfOptions}
                selectedValues={behalfOfOptions.filter(
                  ({ value }) => value === onBehalfOf
                )}
                onChangeSingleSelection={({ value }) => {
                  field.onChange(value);
                }}
                isShowTitle
                isShowHint
                isRequired
              />
            )}
          />
        </div>

        {/* BEHALF OF SOMEONE */}
        {isBehalfOfAdult && (
          <div className="flex flex-col items-stretch xl:flex-row xl:items-start mb-1 gap-x-5 gap-y-3">
            <div className="flex flex-col flex-1">
              <Controller
                name="bookerName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    title="Your name"
                    onChangeValue={field.onChange}
                  />
                )}
              />
              <TextError fieldError={errors.bookerName} />
            </div>

            <div className="flex flex-col flex-1 lg:min-w-[308px]">
              <Controller
                name="bookerPhone"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    title="Your contact (either email or phone)"
                    onChangeValue={field.onChange}
                  />
                )}
              />
              <TextError fieldError={errors.bookerPhone} />
            </div>
          </div>
        )}

        {/* BEHALF OF CHILD */}
        {isBehalfOfChild && (
          <div className="flex flex-col gap-y-3">
            <div className="flex flex-col lg:flex-row items-stretch lg:items-start gap-x-5 gap-y-3">
              <div className="flex flex-col flex-1">
                <Controller
                  name="parentFirstName"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      title="Parent first name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />
                <TextError fieldError={errors.parentFirstName} />
              </div>

              <div className="flex flex-col flex-1">
                <Controller
                  name="parentLastName"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      title="Parent last name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />
                <TextError fieldError={errors.parentLastName} />
              </div>
            </div>

            <Controller
              name="parentEmail"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col flex-1">
                  <TextInput
                    title="Parent email address"
                    onChangeValue={field.onChange}
                  />
                  <TextError fieldError={errors.parentEmail} />
                </div>
              )}
            />
          </div>
        )}

        <div className="pt-5">
          {/* Emergency contact heading */}
          <div className="flex items-center justify-between gap-3 mb-3">
            {blok?.emergencyContactTitle?.map((blok) => (
              <StoryblokComponent key={blok._uid} blok={blok} />
            ))}

            {blok?.emergencyContactHint?.map((blok) => (
              <StoryblokComponent key={blok._uid} blok={blok} />
            ))}
          </div>

          {/* Emergency contact form */}
          <div className="flex flex-col lg:flex-row gap-x-5 gap-y-3">
            <div className="lg:flex-1">
              {/* Name */}
              <Controller
                name="emergencyContactName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    name={field.name}
                    title={"Name"}
                    onChangeValue={field.onChange}
                  />
                )}
              />

              <TextError fieldError={errors.emergencyContactName} />
            </div>
            <div className="lg:flex-1">
              {/* Phone number */}
              <Controller
                name="emergencyContactPhoneNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    name={field.name}
                    title={"Phone number"}
                    onChangeValue={(event) => {
                      const value = event.target.value;

                      const formattedValue = new AsYouType(
                        DEFAULT_PHONE_COUNTRY_CODE
                      ).input(value);

                      event.target.value = formattedValue;

                      field.onChange(event);
                    }}
                  />
                )}
              />

              <TextError fieldError={errors.emergencyContactPhoneNumber} />
            </div>
          </div>
        </div>

        {/* ADDRESS */}
        <AddressFields<SelfFundConfirmBookingFormData>
          control={control}
          errors={errors}
        />

        <div className="pt-5">
          <Controller
            name="isAgreeCancellationPolicy"
            control={control}
            render={({ field }) => (
              <div className="flex flex-row items-center justify-start">
                <FilterCheckbox
                  isChecked={isAgreeCancellationPolicy || false}
                  title={
                    <div className="min-xs:flex flex-row items-center justify-start lg:min-w-[345px] flex-wrap">
                      <span className="ml-1">I agree to the</span>
                      {isBehalfOfChild && (
                        <div className="inline-flex">
                          <span
                            onClick={handleClickConsentAgreement}
                            className="mx-1 text-sm underline cursor-pointer"
                          >
                            consent agreement
                          </span>
                          <span>and the</span>
                        </div>
                      )}
                      <span
                        onClick={handleClickCancellationPolicy}
                        className="ml-1 text-sm underline cursor-pointer"
                      >
                        cancellation policy
                      </span>
                    </div>
                  }
                  onCheck={field.onChange}
                />
              </div>
            )}
          />
        </div>

        {/* Submit button */}
        <div className="flex items-center justify-start mt-5">
          <button
            type="submit"
            disabled={isDisabledSubmit || !isValid}
            className={getDefaultButtonStyles(isLoading)}
          >
            Proceed to payment details to finalise your booking
          </button>
          {isLoading && <SpinnerLoading className="w-12 h-12 ml-3" />}
        </div>
      </form>
    </div>
  );
};
