type Props = {
  className?: string;
  pathProps?: React.SVGProps<SVGPathElement>;
};

export const UserSvg = (props: Props) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00586 7.125C4.83789 7.125 3.77148 6.51562 3.1875 5.5C2.60352 4.50977 2.60352 3.26562 3.1875 2.25C3.77148 1.25977 4.83789 0.625 6.00586 0.625C7.14844 0.625 8.21484 1.25977 8.79883 2.25C9.38281 3.26562 9.38281 4.50977 8.79883 5.5C8.21484 6.51562 7.14844 7.125 6.00586 7.125ZM4.83789 8.34375H7.14844C9.66211 8.34375 11.6934 10.375 11.6934 12.8887C11.6934 13.2949 11.3379 13.625 10.9316 13.625H1.05469C0.648438 13.625 0.318359 13.2949 0.318359 12.8887C0.318359 10.375 2.32422 8.34375 4.83789 8.34375Z"
      fill="#CBAAC3"
      {...props}
    />
  </svg>
);
