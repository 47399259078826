import { storyblokEditable } from "@storyblok/react";
import { LabelStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const Label = ({ blok }: Props<LabelStoryblok>) => {
  return (
    <label
      className={cn("font-poppins text-sm font-normal")}
      {...storyblokEditable(blok)}
    >
      {blok.label}
    </label>
  );
};
