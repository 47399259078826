export type ContactUsFormData = {
  subject?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  comments: string;
  preferredContactMethod?: string;
  isReceiveUpdates: boolean;
};

export type HubspotSubmission = {
  context: HubspotFormContext;
  fields: HubspotSubmissionDataField[];
  legalConsentOptions?: LegalConsentOptions | undefined;
};

export type LegalConsentOptions = {
  consent: {
    consentToProcess?: boolean;
    text?: string;
    communications: ConsentCommunication[];
  };
};

type ConsentCommunication = {
  value: boolean;
  subscriptionTypeId: string | number;
  text: string;
};

export type HubspotSubmissionDataField = {
  objectTypeId: HubspotObjectTypeId;
  name: string;
  value: string;
};

export type HubspotFormContext = {
  hutk?: string; // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission

  pageUri: string;
  pageName: string;
};

export enum HubspotObjectTypeId {
  CONTACTS = "0-1",
  TICKETS = "0-5",
}
