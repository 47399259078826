import { QuestionStepStoryblok } from "types/component-types-sb";
import { PsychologistRequestQueries } from "./psychologist.model";

export type QuestionStepperFormData = {
  [key: string]: any;
};

export type QuestionStepperField = {
  name: string;
  step: QuestionStepStoryblok;
};

export type CheckboxInputFormData = {
  [key: string]: string;
};

export type CheckboxField = {
  name: string;
  value: string;
};

export type ContactInformationFormData = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
}

export type GetMatchedData = {
  getGpReferral: boolean,
  apiQueries: PsychologistRequestQueries,
}

export const questionStepFieldName = "answer";

export enum StepUsedFor {
  API_FILTER = "apiFilter",
  HUBSPOT = "hubspot",
  GP_FLOW = "gpFlow",
  OTHER = "other",
}

export enum StepGroupName {
  CLIENT_MENTAL_HEALTH_CONCERN = "clientMentalHealthConcern",
  CLIENT_OTHER_CONCERNS = "clientOtherConcerns",
  GENDER = "gender",
  DAYS = "days",
  TIME = "time",
  SCHEDULE = "schedule",
  CONTACT_INFORMATION = "contactInformation",
  GP_REFERRAL = "gpReferral",
}
