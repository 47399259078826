import { AxiosResponse } from "axios";

import { globalConfig } from "core/config";
import {
  BookingRuleType,
  KeyClientContactType,
  SavedPaymentStatus,
  UrlParamType,
} from "enums";
import { ClientRecord } from "models/client.model";
import { Routes } from "routes/main.routes";
import {
  BillingApiClient,
  ClinicianApiClient,
  PatientProfileApiClient,
  ScheduleApiClient,
  getSessionAuthTokenOrDefault,
} from "./axios.service";

export type ReserveResponse = {
  reserveId: string;
  appointments: AppointmentResponse[];
};

export type AppointmentResponse = {
  _id: string;
  accountId: string;
  clinicianId: string;
  room: RoomResponse;
  sessionTypeId: string;
  sessionTypeName: string;
  type: string;
  dayOfWeek: string;
  date: string;
  endDate: string;
  startTime: string;
  endTime: string;
  startDateTime: string;
  endDateTime: string;
  timeZone: string;
  sendStatus: string;
  gap: number;
  rate: number;
  rateType: string;
  deliveryType: string;
  videoCallInstructions: string;
  phoneCallInstructions: string;
  phoneCallDialClientInstructions: string;
  otherInstructions: OtherInstructionsResponse;
  confirmation: ConfirmationResponse;
  deliveryOptions: string[];
  reserveId: string;
  telehealthRoomId: string;
  claimType: string;
  createdAt: Date;
  updatedAt: Date;
  clientRecord: ClientRecord;
  isHaveGPReferralAndTreatmentPlan: boolean | undefined;
  isNdisPlanManaged: boolean | undefined;
  isWorkCoverNsw: boolean | undefined;
};

export type RoomResponse = {
  roomId: string;
  roomName: string;
};

export type OtherInstructionsResponse = {
  title: string;
  instructions: string;
};

export type ConfirmationResponse = {
  clinician: boolean;
  patient: boolean;
  _id: string;
};

export type ClientRecordResponse = {
  _id: string;
  clientProfiles: ClientProfileResponse[];
};

export type ClientProfileResponse = {
  _id: string;
  initials: string;
  initialsBackgroundColor: string;
  name: string;
};

export type ProcessReserveWithPaymentResponse = {
  checkoutUrl: string;
};

export type ProcessReserveWithPaymentRequest = {
  mode: PaymentMode;
  cancelUrl: string;
  returnUrl: string;
};

export enum PaymentMode {
  SETUP = "setup",
  PAYMENT = "payment",
}

export const getReserveDetailsByIdAsync = async (
  reserveId: string
): Promise<AxiosResponse<ReserveResponse>> => {
  return ScheduleApiClient.get<ReserveResponse>(
    `/accounts/${globalConfig.CLIENT_ID}/reserved-appointments/${reserveId}`
  );
};

type ClaimReservationPayload = {
  mode: string;
  shouldGenerateCheckoutSession: boolean;
};

const getClaimReservationPayload = (): ClaimReservationPayload => {
  return {
    mode: "setup",
    shouldGenerateCheckoutSession: false,
  };
};

export const claimReserveAppointmentAsync = async (
  reserveId: string,
  accessToken?: string
) => {
  return ScheduleApiClient.put(
    `/accounts/${globalConfig.CLIENT_ID}/reserved-appointments/${reserveId}:claimReservation`,
    getClaimReservationPayload(),
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const processReserveWithoutPaymentAsync = async (
  reserveId: string,
  accessToken?: string
) => {
  return ScheduleApiClient.put(
    `/accounts/${globalConfig.CLIENT_ID}/reserved-appointments/${reserveId}:processWithoutPayment`,
    { mode: PaymentMode.SETUP },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const processReserveWithPaymentAsync = async (
  reserveId: string,
  accessToken?: string
): Promise<AxiosResponse<ProcessReserveWithPaymentResponse>> => {
  const data: ProcessReserveWithPaymentRequest = {
    mode: PaymentMode.SETUP,
    cancelUrl: window.location.href,
    returnUrl: window.location.origin + Routes.BOOKING_THANK_YOU,
  };

  return ScheduleApiClient.put(
    `/accounts/${globalConfig.CLIENT_ID}/reserved-appointments/${reserveId}:processWithPaymentMethod`,
    data,
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type KeyContactPayload = {
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
};

export const postEmergencyContactAsync = (
  payload: KeyContactPayload,
  accessToken?: string
) => {
  return ClinicianApiClient.post(
    "/client-records/me/key-client-contacts",
    {
      ...payload,
      tags: [KeyClientContactType.EMERGENCY],
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const postAdultContactAsync = (
  payload: KeyContactPayload,
  accessToken?: string
) => {
  return ClinicianApiClient.post(
    "/client-records/me/key-client-contacts",
    {
      ...payload,
      tags: [KeyClientContactType.ADULT],
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type AddressPayload = {
  country?: string;
  line1?: string;
  line2?: string;
  postcode?: string;
  state?: string;
  suburb?: string;
};

export const putContactAddressAsync = (
  payload: AddressPayload,
  accessToken?: string
) => {
  return ClinicianApiClient.put(
    "/client-records/me/address",
    payload,
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type GuardianProfilePayload = {
  email?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  role?: ProfileRole;
};

export type ProfileRole =
  | "mother"
  | "father"
  | "stepMother"
  | "stepFather"
  | "grandparent"
  | "guardian"
  | "sibling"
  | "teacher"
  | "caseWorker"
  | "other"
  | "son"
  | "husband"
  | "wife"
  | "partner"
  | "daughter"
  | "friend";

export const postGuardianAdditionalProfileAsync = (
  payload: GuardianProfilePayload,
  accessToken?: string
) => {
  return ClinicianApiClient.post(
    "/client-records/me/additional-profiles",
    { ...payload, role: "guardian" },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

type BookingRuleResponse = {
  bookingRuleType: BookingRuleType;
};

export const getBookingRuleType = (accessToken: string) => {
  return ScheduleApiClient.get<BookingRuleResponse>(
    "/client-records/me/appointments:getBookingRuleType",
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type AppointmentHistoryResponse = {
  _id: string;
  accountId: string;
  clinicianId: string;
  room: Room;
  sessionTypeId: string;
  sessionTypeName: string;
  type: string;
  isActivity: boolean;
  dayOfWeek: string;
  date: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
  timeZone: string;
  sendStatus: string;
  gap: number;
  rate: number;
  rateType: string;
  deliveryType: string;
  videoCallInstructions: string;
  phoneCallInstructions: string;
  otherInstructions: Instructions;
  confirmation: Confirmation;
  invitedEmails: any[];
  isGP: boolean;
  markedStatus: string[];
  deliveryOptions: string[];
  reserveId: string;
  telehealthRoomId: string;
  claimType: string;
  attendees: any[];
  invoices: any[];
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  clientRecord: ClientRecordResponse;
  paymentRequestIds: string[];
  stripeChargeIds: string[];
};

export type Room = {
  roomId: string;
  roomName: string;
};

export type Instructions = {
  title: string;
  instructions: string;
};

export type Confirmation = {
  clinician: boolean;
  patient: boolean;
  _id: string;
};

export const getAppointmentsHistory = (accessToken?: string) => {
  return ScheduleApiClient.get<AppointmentHistoryResponse[]>(
    "/client-records/me/appointments",
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const postClientGroupAsync = (groupId: string, accessToken?: string) => {
  return ClinicianApiClient.post(
    "/client-records/me/groups",
    {
      groupId: groupId,
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type savePaymentDetailRequest = {
  cancelUrl: string;
  returnUrl: string;
};

export const savePaymentDetailAsync = async (
  accessToken?: string
): Promise<AxiosResponse<string>> => {
  const request: savePaymentDetailRequest = {
    cancelUrl: `${window.location.href}?${UrlParamType.SAVE_PAYMENT_DETAIL_STATUS}=${SavedPaymentStatus.FAILED}`,
    returnUrl: `${window.location.href}?${UrlParamType.SAVE_PAYMENT_DETAIL_STATUS}=${SavedPaymentStatus.SUCCESS}`,
  };

  return BillingApiClient.post(
    "/client/me/stripe/payment-method",
    request,
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type CheckEmailMobileNumberInvitePayload = {
  email: string;
  mobileNumber: string;
  shouldSendOtp?: boolean;
};

export type CheckEmailMobileNumberInviteResponse = {
  invited: boolean;
  emailInvited: boolean;
  used: boolean;
  clientRecordId?: string;
};

export const checkEmailMobileNumberInviteAsync = async (
  payload: CheckEmailMobileNumberInvitePayload
): Promise<AxiosResponse<CheckEmailMobileNumberInviteResponse>> => {
  return ClinicianApiClient.post(
    `/accounts/${globalConfig.CLIENT_ID}/client-records:checkEmailMobileNumberInvite`,
    payload
  );
};

export type SignUpWithInvitePayload = {
  data: {
    communicationPreference: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    password: string;
  };
  smsOtp: string;
};

export const postPatientSignUpWithInvitationAsync = async (
  payload: SignUpWithInvitePayload
): Promise<AxiosResponse> => {
  return PatientProfileApiClient.post(
    `/accounts/${globalConfig.CLIENT_ID}/patients:invited`,
    payload
  );
};
