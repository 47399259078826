import {
  DEFAULT_PHONE_COUNTRY_CODE,
  EMAIL_ADDRESS_EXISTED_MESSAGE,
} from "core/booking.constant";
import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";

import AwesomeDebounce from "awesome-debounce-promise";
import {
  validateEmailByTacklitAsync,
  validatePhoneNumberByTacklitAsync,
} from "services/signup.service";
import { SignUpFormType } from "enums";

export const validateEmailAsync = AwesomeDebounce(
  validateEmailByTacklitAsync,
  400
);

export const validatePhoneAsync = AwesomeDebounce(
  validatePhoneNumberByTacklitAsync,
  400
);

export const GetSignupFormResolver = (formType: SignUpFormType) =>
  Yup.object().shape({
    firstName: Yup.string().trim().required("First name is required"),
    lastName: Yup.string().trim().required("Last name is required"),
    email: Yup.string()
      .email("Email is not valid")
      .test({
        name: "email",
        message: EMAIL_ADDRESS_EXISTED_MESSAGE,
        test: async (email, context) => {
          const isValid = Yup.string().trim().email().validateSync(email);

          if (!email || !isValid) return false;

          // If the invite form, email is created before
          if (formType === SignUpFormType.INVITE) return true;

          try {
            const isUsed = await validateEmailAsync(email);

            return !isUsed;
          } catch {
            return context.createError({
              path: "email",
              message: "Email address is not valid",
            });
          }
        },
      })
      .required("Email is required"),
    phone: Yup.string()
      .trim()
      .required("Phone number is required")
      .test({
        name: "phone",
        message: "Phone number is not found",
        test: async (phoneNumber, context) => {
          if (
            !isValidPhoneNumber(phoneNumber || "", DEFAULT_PHONE_COUNTRY_CODE)
          )
            return context.createError({
              path: "phone",
              message: "Phone number is not valid",
            });

          const isValid = await validatePhoneAsync(phoneNumber);

          return isValid;
        },
      }),
    dob: Yup.date().required("Date of birth is required"),
    password: Yup.string().trim().required("A secure password is required"),
    isPoliciesConfirmed: Yup.boolean()
      .default(false)
      .oneOf(
        [true],
        "Please accept the Privacy policy and Terms and conditions in order to continue"
      ),
    isAtsi: Yup.boolean().default(false),
    isRegistered: Yup.boolean().default(false),
  });
