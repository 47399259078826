import "react-datepicker/dist/react-datepicker.css";
import "styles/date-schedule.scss";

import { DateConfig } from "@/models/date-schedule.model";
import DatePicker from "react-datepicker";
import { DateScheduleStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { useState } from "react";
import { storyblokEditable } from "@storyblok/react";

export const DateSchedule = ({
  blok,
  otherProps,
}: Props<DateScheduleStoryblok>) => {
  // TODO include dates pass from props later
  const dateConfig = otherProps as DateConfig;

  const today = new Date();
  const includeDates = dateConfig?.includeDates ?? [
    new Date(today.getFullYear(), today.getMonth(), 11),
    new Date(today.getFullYear(), today.getMonth(), 12),
    new Date(today.getFullYear(), today.getMonth(), 15),
    new Date(today.getFullYear(), today.getMonth(), 20),
    new Date(today.getFullYear(), today.getMonth(), 21),
    new Date(today.getFullYear(), today.getMonth(), 25),
  ];

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    includeDates[0]
  );

  return (
    <div className="w-auto" {...storyblokEditable(blok)}>
      <DatePicker
        selected={selectedDate}
        includeDates={includeDates}
        onChange={setSelectedDate}
        dateFormatCalendar="MMM yyyy"
        useWeekdaysShort
        inline
      />
    </div>
  );
};
