import { ReactElement } from "react";
import { v4 } from "uuid";

import { CheckSvgDataUrl } from "assets/icons/CheckSvg";
import { cn } from "utils";

type Props = {
  title: string;
  hint?: ReactElement;
  isFullWidth?: boolean;

  isChecked: boolean;

  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxInput = ({
  isChecked,
  title,
  hint,
  isFullWidth = false,
  ...props
}: Props) => {
  const id = v4();

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onCheck(e);
  };

  return (
    <div className="flex items-center cursor-pointer me-2">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleChangeCheckbox}
        className="w-6 h-6 rounded-[4px] border border-secondary-darker focus:ring-2 focus:ring-secondary-darker checked:bg-light checked:border-secondary-darker"
        style={{
          backgroundImage: isChecked ? `url("${CheckSvgDataUrl}")` : "",
          backgroundSize: "15px 15px",
        }}
      />
      <label
        htmlFor={id}
        className={cn("font-normal text-15 ms-2 text-dark dark:text-neutral", {
          "flex-1": Boolean(isFullWidth),
        })}
      >
        {title}
      </label>

      <div className="flex items-center justify-center ml-1">{hint}</div>
    </div>
  );
};
