import { StoryblokComponent } from "@storyblok/react";
import { isEqual } from "lodash";
import { useState } from "react";
import { useToggle } from "react-use";
import {
  PricingCardItemStoryblok,
  PricingListStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";

export const PricingList = ({ blok }: Props<PricingListStoryblok>) => {
  const [pricingCardDetail, setPricingCardDetail] =
    useState<PricingCardItemStoryblok["pricingDetails"]>();
  const [currentActiveCardId, setCurrentActiveCardId] = useState<string>("");
  const [shouldHideAdditionContents, toggleHideAdditionContents] =
    useToggle(false);

  return (
    <div className="my-20">
      <div className="flex flex-col gap-3">
        {blok.title?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-16 md:my-24 md:gap-8 lg:gap-12">
        {blok.cards?.map((card) => (
          <StoryblokComponent
            key={card._uid}
            blok={card}
            onClickCard={(
              pricingCardDetail: PricingCardItemStoryblok["pricingDetails"],
              activeCardId: string,
              shouldHideParentContent: boolean
            ) => {
              setPricingCardDetail(pricingCardDetail);
              setCurrentActiveCardId(activeCardId);
              toggleHideAdditionContents(shouldHideParentContent);
            }}
            isActive={isEqual(currentActiveCardId, card._uid)}
          />
        ))}
      </div>

      <div className="w-full flex flex-row gap-x-3 justify-center mb-20">
        {blok.buttons?.map((button) => (
          <StoryblokComponent key={button._uid} blok={button} />
        ))}
      </div>

      <div id="tbl-pricing-detail">
        {pricingCardDetail && (
          <>
            {pricingCardDetail?.map((card) => (
              <StoryblokComponent key={card._uid} blok={card} />
            ))}
          </>
        )}
      </div>

      {!shouldHideAdditionContents &&
        blok.additionContents?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
    </div>
  );
};
