import { PsychologistResponse } from "@/services/psychologist.service";
import {
  BadgeStoryblok,
  PsychologistDetailBannerStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo } from "lodash";

import {
  renderPsychologistDisplayRole,
  renderPsychologistPronouns,
} from "components/forms/book-finalise/booking-finalise.util";
import { MedicareRole } from "enums";
import { specializationsSingleValue } from "utils";

type CustomProps = Props<PsychologistDetailBannerStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailBanner = ({
  blok,
  psychologist,
}: CustomProps) => {
  const { helmControl } = psychologist;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col w-full tracking-wide"
    >
      {/* Mobile view */}
      <div className="flex lg:hidden bg-highlight flex-col px-5 py-14 gap-3 -mx-4 sm:-mx-6 md:-mx-20">
        <div className="flex clip-path__avatar-0 max-h-[250px] max-w-[250px] bg-neutral">
          <img src={psychologist.avatar} alt="Avatar" className="w-full" />
        </div>

        <div className="flex">
          <div className="flex flex-1 flex-col justify-center text-primary gap-2">
            <span className="text-30 font-medium leading-10 md:text-40 md:leading-48 xl:text-58 xl:leading-61">
              {psychologist.name}
            </span>

            <span className="text-12 font-medium">
              {renderPsychologistPronouns(psychologist)}
            </span>

            <div className="font-semibold tracking-wide text-12 mb-2">
              {blok.title}
            </div>

            <div className="flex flex-wrap items-center justify-start gap-3">
              {helmControl?.primarySpecialisations?.map(
                (primarySpecialization, index) => {
                  const badge = {
                    name: defaultTo(
                      specializationsSingleValue[primarySpecialization],
                      "Unknown"
                    ),
                    type: "plain",
                    size: "md",
                    isUpperCase: false,
                    component: "badge",
                  } as BadgeStoryblok;

                  return <StoryblokComponent key={index} blok={badge} />;
                }
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Desktop view */}
      <div className="hidden lg:flex bg-highlight rounded-30 w-full justify-between px-24 overflow-hidden">
        <div className="flex flex-1 flex-col justify-center text-primary gap-5 z-[2]">
          <div>
            <span className="font-medium">
              <span className="text-30 leading-10 md:text-40 md:leading-48 xl:text-58 xl:leading-61 table-caption -mb-3">
                {psychologist?.name}
              </span>
              <span>{renderPsychologistPronouns(psychologist)}</span>
            </span>
          </div>

          <div className="text-12 font-semibold tracking-wide uppercase">
            {renderPsychologistDisplayRole(
              psychologist?.medicare?.role as MedicareRole
            )}
            , SPECIALISING IN:
          </div>

          <div className="flex flex-wrap items-center justify-start gap-3">
            {helmControl?.primarySpecialisations?.map(
              (primarySpecialization, index) => {
                const badge = {
                  name: defaultTo(
                    specializationsSingleValue[primarySpecialization],
                    "Unknown"
                  ),
                  type: "plain",
                  size: "md",
                  isUpperCase: false,
                  component: "badge",
                } as BadgeStoryblok;

                return <StoryblokComponent key={index} blok={badge} />;
              }
            )}
          </div>
        </div>

        <div className="flex flex-1 relative justify-center">
          <div className="h-full scale-125 z-[1] absolute -mt-14 aspect-square clip-path__avatar-0 bg-neutral"></div>
          <img
            src={psychologist.avatar}
            alt="Avatar"
            className="z-[2] mt-7 h-[350px] bottom-0 self-end"
          />
        </div>
      </div>
    </div>
  );
};
