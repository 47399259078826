import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { AdvantagesStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { cn } from "utils";

export const Advantages = ({ blok }: Props<AdvantagesStoryblok>) => {
  return (
    <div
      className={cn(
        "flex justify-center flex-wrap xl:flex-nowrap gap-x-12 gap-y-6 w-full lg:my-8 mb-5 min-w-[270px] p-8 md:py-12 md:px-24 mx-auto"
      )}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          "flex flex-col gap-4 w-full min-w-[270px] xl:max-w-[460px]"
        )}
      >
        {blok.content?.map((content) => (
          <StoryblokComponent key={content._uid} blok={content} />
        ))}

        <div>
          {blok.buttons?.map((button) => (
            <StoryblokComponent key={button._uid} blok={button} />
          ))}
        </div>
      </div>

      <div>
        {blok.items?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  );
};
