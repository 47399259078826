import { ReactElement } from "react";
import toast, { Toast } from "react-hot-toast";

import { CustomToast } from "components/shared/CustomToast";
import { CustomToastType } from "enums";

type ToastOptions =
  | Partial<
      Pick<
        Toast,
        | "id"
        | "icon"
        | "duration"
        | "ariaProps"
        | "className"
        | "style"
        | "position"
        | "iconTheme"
      >
    >
  | undefined;

export const customToast = {
  info: (message: string | ReactElement, title = "Info") => {
    toast.dismiss();
    toast(
      <CustomToast
        type={CustomToastType.INFO}
        title={title}
        message={message}
      />,
      { className: "!bg-pink-50" }
    );
  },
  success: (message: string | ReactElement, title = "Success") => {
    toast.dismiss();
    toast(
      <CustomToast
        type={CustomToastType.SUCCESS}
        title={title}
        message={message}
      />,
      { className: "!bg-highlight-green" }
    );
  },
  warning: (message: string | ReactElement, options?: ToastOptions) => {
    toast.dismiss();
    toast(
      <CustomToast
        type={CustomToastType.WARNING}
        title="Warning"
        message={message}
      />,
      {
        ...options,
        className: "!bg-amber-50",
      }
    );
  },
  error: (message: string | ReactElement, options?: ToastOptions) => {
    toast.dismiss();
    toast(
      <CustomToast
        type={CustomToastType.ERROR}
        title="Error"
        message={message}
      />,
      {
        ...options,
        className: "!bg-red-50",
      }
    );
  },
  dismiss: () => {
    toast.dismiss();
  },
};
