import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { first, isEmpty } from "lodash";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import { Routes } from "routes/main.routes";
import { Story, searchBlogsData } from "services/storyblok.service";
import { ArticleRelatedContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import {
  blogCategories,
  redirectTo
} from "utils";
import { renderBlogItem } from "utils/blog.util";

export const ArticleRelatedContainer = ({
  blok,
}: Props<ArticleRelatedContainerStoryblok>) => {
  const [articles, setArticles] = useState<Story[]>([]);
  const margin = blok.margin ?? "0px 0px 0px 0px";

  useEffectOnce(() => {
    getArticles();
  });

  const handleViewAllClick = () => {
    redirectTo(`${Routes.BLOG}?category=${blok.articleRelatedCategories}`);
  };

  async function getArticles() {
    const response = await searchBlogsData({
      startWith: "blog/",
      categories: [String(blok.articleRelatedCategories)],
      take: 3,
      excludingSlugs: "blog/",
      shouldRemoveSelfSearch: true,
    });

    setArticles(response.responses);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="-mx-4 sm:-mx-6 md:-mx-8 lg:-mx-12 px-7 sm:px-12 md:px-14 lg:px-24 py-32 my-14"
      style={{
        backgroundImage: `url(${blok.background?.filename})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        margin: margin,
      }}
    >
      <div className="flex flex-col gap-7">
        <div className="flex flex-col md:flex-row justify-between gap-5 md:gap-0">
          {renderTitle(blok)}

          <button
            className="bg-none ring-2 ring-primary text-primary px-5 h-[50px] text-sm font-semibold cursor-pointer rounded-full"
            onClick={handleViewAllClick}
          >
            View all
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 xl:gap-7">
          {articles?.map((article) => {
            const renderedItem = renderBlogItem(article, "bg-light");

            if (!renderedItem) {
              return <></>;
            }

            return !renderTitle ? (
              <></>
            ) : (
              <StoryblokComponent
                key={article.uuid}
                blok={renderedItem}
                fullSlug={article.full_slug}
              />
            );
          })}
        </div>

        {isEmpty(articles) && (
          <div className="flex justify-center items-center w-full text-primary">
            <span className="text-18 leading-6 md:text-24 md:leading-9 xl:text-30 xl:leading-10">
              There are no related articles matched
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const renderTitle = (
  blok: ArticleRelatedContainerStoryblok
): JSX.Element | undefined => {
  if (blok.shouldShowCustomTitle) {
    return (
      <>
        {blok.title?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </>
    );
  }

  const selectedSpecItems = blogCategories.filter(
    (spec) => blok.articleRelatedCategories === spec.value
  );

  const relatedSpecLabel = first(selectedSpecItems)?.label;

  return (
    <div className="text-primary text-24 leading-9 md:text-30 md:leading-10 xl:text-40 xl:leading-48">
      Reading about <strong>{relatedSpecLabel}</strong>
    </div>
  );
};
