import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ScreenBreakpoints } from "enums";
import { useRef } from "react";
import {
  useEvent,
  useWindowSize
} from "react-use";
import { HowItWorksJourneyStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { DOT_RADIUS, updateDotPosition } from "utils";

export const HowItWorksJourney = ({
  blok,
}: Props<HowItWorksJourneyStoryblok>) => {
  const { width } = useWindowSize();
  const isMobile = width < ScreenBreakpoints.MD;

  const journeyPathRef = useRef<SVGGeometryElement>(null);
  const startPointRef = useRef<SVGCircleElement>(null);

  const dotRef1 = useRef<SVGCircleElement>(null);
  const dotRef2 = useRef<SVGCircleElement>(null);
  const dotRef3 = useRef<SVGCircleElement>(null);
  const dotRef4 = useRef<SVGCircleElement>(null);

  const handleScrollEvent = () => {
    updateDotPosition(
      journeyPathRef.current,
      startPointRef.current,
      dotRef1.current,
      dotRef2.current,
      dotRef3.current,
      dotRef4.current
    );
  };

  useEvent("scroll", handleScrollEvent);

  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex justify-center">
        {!isMobile && (
          <svg
            width="1268"
            viewBox="0 0 1268 2780"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={journeyPathRef}
              d="M633 30V307 M633 306V306C633 376.692 575.692 434 505 434H368C262.514 434 177 519.514 177 625V625C177 730.486 262.514 816 368 816H927.5C1016.14 816 1088 887.858 1088 976.5V976.5C1088 1065.14 1016.14 1137 927.5 1137H366.5C261.842 1137 177 1221.84 177 1326.5V1326.5C177 1431.16 261.842 1516 366.5 1516H878C993.98 1516 1088 1610.02 1088 1726V1726C1088 1841.98 993.98 1936 878 1936H373.5C264.976 1936 177 2023.98 177 2132.5V2132.5C177 2241.02 264.976 2329 373.5 2329H913C1009.65 2329 1088 2407.35 1088 2504V2504C1088 2600.65 1009.65 2679 913 2679H734C679.324 2679 635 2723.32 635 2778V2778"
              stroke="#CBAAC3"
              strokeWidth="2"
            />
            <circle
              ref={startPointRef}
              cx="633"
              cy="35"
              r={DOT_RADIUS}
              fill="#502334"
            />
            <circle
              ref={dotRef1}
              cx="633"
              cy="35"
              r={DOT_RADIUS}
              fill="#502334"
            />
            <circle
              ref={dotRef2}
              cx="633"
              cy="35"
              r={DOT_RADIUS}
              fill="#502334"
              opacity="0.6"
            />
            <circle
              ref={dotRef3}
              cx="633"
              cy="35"
              r={DOT_RADIUS}
              fill="#502334"
              opacity="0.4"
            />
            <circle
              ref={dotRef4}
              cx="633"
              cy="35"
              r={DOT_RADIUS}
              fill="#502334"
              opacity="0.3"
            />

            {blok.items.map((item, index) => (
              <foreignObject
                key={`${index}_${item._uid}`}
                x={item.pointX}
                y={item.pointY}
                width="100%"
                height="100%"
              >
                <StoryblokComponent key={item._uid} blok={item} />
              </foreignObject>
            ))}
          </svg>
        )}

        {isMobile && (
          <div>
            {blok.items.map((item) => (
              <StoryblokComponent
                key={item._uid}
                blok={item}
                otherProps={{ isMobile }}
              />
            ))}
          </div>
        )}
      </div>

      {blok.banner?.map((banner) => (
        <StoryblokComponent key={banner._uid} blok={banner} />
      ))}

      {blok.advantages?.map((advantage) => (
        <StoryblokComponent key={advantage._uid} blok={advantage} />
      ))}

      {blok.faq?.map((faq) => (
        <StoryblokComponent key={faq._uid} blok={faq} />
      ))}
    </div>
  );
};
