import { StoryblokComponent } from "@storyblok/react";
import { toNumber } from "lodash";
import { StraightTimeLineSectionStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const StraightTimeLineSection = ({
  blok,
}: Props<StraightTimeLineSectionStoryblok>) => {
  return (
    <div>
      <div className="my-16 text-center">
        {blok.title?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </div>
      <div className="flex flex-row-reverse mx-0 mt-16 md:mt-24 md:mx-4 lg:mx-8 xl:mx-16">
        <ol className="relative w-1/2">
          {blok.items?.map((item, index) => (
            <StoryblokComponent
              key={item._uid}
              blok={item}
              index={index}
              maxIndex={toNumber(blok.items?.length) - 1}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};
