import { forwardRef } from "react";
import { v4 } from "uuid";

type Props = {
  label: string;
  value: string;
  checked?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const RadioInput = forwardRef<HTMLInputElement, Props>(
  ({ label, value, checked, ...props }, ref) => {
    const dynamicId = v4();

    return (
      <div className="flex items-center me-4">
        <input
          ref={ref}
          {...props}
          id={dynamicId}
          type="radio"
          value={value}
          checked={checked}
          className="w-4 h-4 text-primary bg-light border-neutral focus:ring-primary"
        />
        <label htmlFor={dynamicId} className="text-sm ms-2 text-dark">
          {label}
        </label>
      </div>
    );
  }
);
