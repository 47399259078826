import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Props } from "@/types/core";
import { ClinicalStandardsStoryblok } from "@/types/component-types-sb";
import { cn } from "utils";
import Plyr, { PlyrSource } from "plyr-react";

export const ClinicalStandards = ({
  blok,
}: Props<ClinicalStandardsStoryblok>) => {
  const videoSrc: PlyrSource | null = {
    type: "video",
    sources: [
      {
        src: blok.videoLink?.url,
        provider: "youtube",
        type: "video/mp4",
        size: 780,
      },
    ],
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "flex flex-col mt-28 xl:mt-52 bg-cover bg-no-repeat items-center"
      )}
      style={{ backgroundImage: `url(${blok.background?.filename})` }}
    >
      <div className={cn("w-[360px] md:w-[850px] mt-10 mb-[150px]")}>
        <Plyr
          options={{
            controls: [
              "play-large",
              "rewind",
              "play",
              "fast-forward",
              "progress",
              "current-time",
              "mute",
              "volume",
              "fullscreen",
            ],
            captions: { active: true, language: "auto", update: true },
            previewThumbnails: { enabled: false, src: "" },
          }}
          source={videoSrc}
        />
      </div>

      <div>
        <div>
          {blok.content?.map((content) => (
            <StoryblokComponent key={content._uid} blok={content} />
          ))}
        </div>
      </div>
    </div>
  );
};
