import { WarningSvg } from "assets/icons/WarningSvg";
import { EMAIL_SUPPORT_TEAM } from "core/booking.constant";

type Props = {};

export const UnMatchingPsychologistNotice = (props: Props) => {
  return (
    <div className="flex flex-row gap-5 p-4 md:p-7 bg-alerts-warning rounded-lg mx-auto w-full text-base">
      <div className="mt-2">
        <WarningSvg />
      </div>
      <div className="">
        Please contact us at{" "}
        <a
          href={`mailto:${EMAIL_SUPPORT_TEAM}`}
          className="inline font-bold underline"
        >
          {EMAIL_SUPPORT_TEAM}
        </a>{" "}
        if you would like to change your appointment type or your psychologist.
      </div>
    </div>
  );
};
