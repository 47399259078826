import { TagsFrameStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { getTagsFrameStyles } from "utils";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

export const TagsFrame = ({ blok }: Props<TagsFrameStoryblok>) => {
  const tagsFrameStyles = getTagsFrameStyles(blok);

  return (
    <div key={blok._uid} {...storyblokEditable(blok)}>
      <div
        className={`${tagsFrameStyles} py-5 mx-auto my-0 lg:my-5 w-full xl:w-[90%]`}
      >
        {blok.items?.map((item) => (
          <div key={item._uid} className="m-1">
            <StoryblokComponent blok={item} />
          </div>
        ))}
      </div>
    </div>
  );
};
