import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { HeadingLevels } from "enums";
import { isEmpty, upperFirst } from "lodash";
import { SeoBreadcrumbTrail } from "provider/SeoBreadcrumbTrail";
import { useMemo } from "react";
import {
  HeadingStoryblok,
  TreatmentDetailStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";
import { v4 as GUID } from "uuid";

export const TreatmentDetail = ({ blok }: Props<TreatmentDetailStoryblok>) => {
  const hasSymptoms = !isEmpty(blok.symptoms);

  const symptomsSectionTitle: HeadingStoryblok = {
    _uid: GUID(),
    component: "heading",
    content: `Symptoms or signs you may experiencing ${blok.name}:`,
    level: HeadingLevels.H5,
  };

  const breadcrumbs = useMemo(() => {
    const hierarchyBreadcrumbs = [
      { name: "Home", url: window.location.origin },
      { name: "Therapy", url: window.location.origin + "/therapy" },
    ];

    const therapyName = upperFirst(blok?.name);

    if (therapyName) {
      hierarchyBreadcrumbs.push({
        name: therapyName,
        url: window.location.href,
      });
    }

    return hierarchyBreadcrumbs;
  }, [blok?.name]);

  return (
    <div {...storyblokEditable(blok)} className="py-10 space-y-10">
      <SeoBreadcrumbTrail breadcrumbs={breadcrumbs} />

      {/* Banner */}
      <div className="section">
        {blok.banner.map((banner) => (
          <StoryblokComponent key={banner._uid} blok={banner} />
        ))}
      </div>

      {/* Detail */}
      <div className="block xl:grid xl:grid-cols-3 xl:px-[45px]">
        {/* Symptoms */}
        <div className="section xl:col-span-1 xl:order-last xl:relative">
          {hasSymptoms && (
            <div className="rounded-[30px] bg-light-grey xl:sticky xl:top-28">
              <div className="px-5 md:px-8 xl:px-10 py-8 mb-8 text-xs md:text-sm xl:text-base">
                <StoryblokComponent
                  key={symptomsSectionTitle._uid}
                  blok={symptomsSectionTitle}
                />

                {blok.symptoms?.map((symptom) => (
                  <div
                    key={symptom._uid}
                    className="mt-2 md:mt-3 xl:mt-4 2xl:mt-5"
                  >
                    <StoryblokComponent blok={symptom} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Information */}
        <div
          className={cn(
            "section px-3 lg:px-6",
            hasSymptoms
              ? "xl:col-span-2 xl:mr-[140px]"
              : "xl:col-span-3 xl:mr-0 xl:px-16 2xl:px-24"
          )}
        >
          {blok.detail?.map((detail) => (
            <div key={detail._uid}>
              <StoryblokComponent blok={detail} parentStyles="mx-auto" />
            </div>
          ))}
        </div>
      </div>

      {/* Psychologists */}
      <div className="section">
        {blok.psychologists?.map((psychologist) => (
          <StoryblokComponent key={psychologist._uid} blok={psychologist} />
        ))}
      </div>

      {/* FAQ */}
      <div className="section">
        {blok.faq?.map((faq) => (
          <StoryblokComponent key={faq._uid} blok={faq} />
        ))}
      </div>

      {/* Articles */}
      <div className="section">
        {blok.articles?.map((article) => (
          <StoryblokComponent key={article._uid} blok={article} />
        ))}
      </div>
    </div>
  );
};
