import { ComparisonTableStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent } from "@storyblok/react";
import "../../styles/table.scss";

export const ComparisonTable = ({ blok }: Props<ComparisonTableStoryblok>) => {
  return (
    <div className="flex flex-col w-full my-8 lg:my-10 overflow-x-auto mx-auto md:w-[90%] lg:w-[80%] xl:w-[75%]">
      <table className={blok.type}>
        <thead>
          {blok.header?.map((headerItem) => (
            <tr key={headerItem._uid}>
              {headerItem.items?.map((item) => (
                <th key={item._uid}>
                  <StoryblokComponent blok={item} />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {blok.row?.map((rowItem) => (
            <tr key={rowItem._uid}>
              {rowItem.items?.map((item) => (
                <td key={item._uid}>
                  <StoryblokComponent key={item._uid} blok={item} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
