import { InlineHeadingStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Alignment } from "enums";
import { cn } from "utils";

const aligns: Record<string, string> = {
  [Alignment.LEFT]: "justify-start",
  [Alignment.CENTER]: "justify-center",
  [Alignment.RIGHT]: "justify-end",
  [Alignment.BETWEEN]: "justify-between",
};

export const InlineHeading = ({ blok }: Props<InlineHeadingStoryblok>) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn("block lg:flex items-center gap-3", aligns[blok.align || ""])}
    >
      {blok.items?.map((item) => (
        <div key={item._uid} className="flex items-center justify-center">
          <StoryblokComponent blok={item} />
        </div>
      ))}
    </div>
  );
};
