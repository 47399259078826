import { PsychologistDetailStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isNil } from "lodash";
import React from "react";

import { useSwrClinicianDetails } from "hooks/useSwrClinicianDetails";
import { PsychologistDetailSkeleton } from "./PsychologistDetailSkeleton";

export const PsychologistDetail = ({
  blok,
}: Props<PsychologistDetailStoryblok>) => {
  const { data: psychologist, isLoading } = useSwrClinicianDetails();

  if (isLoading || isNil(psychologist)) {
    return <PsychologistDetailSkeleton />;
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-5 pb-10 border-b md:px-12 lg:pt-7 border-secondary-darker"
    >
      {/* BANNER */}
      <div className="flex flex-row">
        {blok.banners?.map((banner) => (
          <StoryblokComponent
            key={banner._uid}
            blok={banner}
            psychologist={psychologist}
          />
        ))}
      </div>

      {/* PSYCHOLOGIST INFORMATION */}
      <div className="flex flex-col-reverse gap-5 lg:flex-row">
        <div className="flex flex-1">
          {blok.information_details?.map((information_detail) => (
            <StoryblokComponent
              key={information_detail._uid}
              blok={information_detail}
              psychologist={psychologist}
            />
          ))}
        </div>

        {/* PSYCHOLOGIST FUNCTIONS */}
        <div className="flex flex-col h-fit lg:w-[460px] lg:sticky lg:top-[2.2rem]">
          <div className="flex items-center justify-end pb-1">
            {blok.functions?.map((functionBlok) => (
              <React.Fragment key={functionBlok?._uid}>
                {functionBlok.back_buttons?.map((backButton) => (
                  <StoryblokComponent key={backButton._uid} blok={backButton} />
                ))}
              </React.Fragment>
            ))}
          </div>

          {blok.functions?.map((blokFunctions) => (
            <StoryblokComponent
              key={blokFunctions._uid}
              blok={blokFunctions}
              psychologist={psychologist}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
