import { isNil } from "lodash";
import { ReactElement, useRef } from "react";
import Select, { GroupBase, SingleValue, StylesConfig } from "react-select";
import { useClickAway, useToggle } from "react-use";

import { SelectionOption } from "models";
import { defaultStyles } from "utils/styles.util";
import { ScreenBreakpoints } from "enums";

type Props = {
  children: ReactElement;
  options: SelectionOption[];
  onSelect: (option: SelectionOption) => void;

  noOptionsMessage?: string;
  searchValue?: string;
  isLoading?: boolean;
  hidden?: boolean;
};

export const DropdownSelectionWrapper = ({
  options,
  searchValue = "",
  noOptionsMessage = "No results found",
  isLoading,
  hidden = false,
  ...props
}: Props) => {
  const [isOpen, toggleOpen] = useToggle(false);

  const dropdownSelectionWrapperRef = useRef(null);

  useClickAway(dropdownSelectionWrapperRef, () => {
    closeMenu();
  });

  const handleChangeOption = (selectedOption: SingleValue<SelectionOption>) => {
    if (isNil(selectedOption)) return;

    props.onSelect(selectedOption);

    toggleOpen(false);
  };

  const openMenu = () => {
    if (isOpen) return;

    toggleOpen(true);
  };

  const closeMenu = () => {
    if (!isOpen) return;

    toggleOpen(false);
  };

  if (hidden) return <>{props.children}</>;

  return (
    <div
      ref={dropdownSelectionWrapperRef}
      className="relative"
      onClick={openMenu}
      onFocus={openMenu}
      onBlur={closeMenu}
    >
      {props.children}

      <div className="absolute min-w-[21rem] scrollbar-3 right-0">
        <Select
          menuIsOpen={isOpen}
          isLoading={isLoading}
          isSearchable={true}
          closeMenuOnSelect
          styles={customStyles}
          options={options}
          onChange={handleChangeOption}
          inputValue={searchValue}
          noOptionsMessage={() => noOptionsMessage}
        />
      </div>
    </div>
  );
};

const customStyles: StylesConfig<
  SelectionOption,
  false,
  GroupBase<SelectionOption>
> = {
  ...defaultStyles,
  container: (base) => ({
    ...base,
    height: "0",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
    marginTop: "4px",
    right: 0,
    [`@media (max-width: ${ScreenBreakpoints.SM}px)`]: {
      maxWidth: "20rem",
    },
  }),
  control: (base) => ({
    ...base,
    display: "none",
  }),
};
