import { useEffect } from "react";
import { redirectTo } from "utils";

type Props = {
  to: string;
};

export const ExternalRedirect = ({ to }: Props) => {
  useEffect(() => {
    redirectTo(to);
  }, [to]);

  return null;
};
