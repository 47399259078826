import { WorkItemStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

type CustomProps = Props<WorkItemStoryblok> & { index: number };

export const WorkItem = ({ blok, index }: CustomProps) => {
  return (
    <div
      className="flex flex-row w-full p-5 transition duration-300 ease-in-out md:mb-2 lg:mb-3 delay-50 hover:bg-highlight md:p-4 lg:p-7 md:mr-7 rounded-2xl"
      {...storyblokEditable(blok)}
    >
      <div className="items-center justify-center mr-4">
        <span
          className="flex items-center justify-center w-8 h-8 font-bold bg-cover rounded-full md:w-11 md:h-11 lg:w-14 lg:h-14 text-primary md:text-[20px]"
          style={{
            backgroundImage: `url(${blok.canvasBackground?.filename})`,
          }}
        >
          {index + 1}
        </span>
      </div>
      <div className="flex-col lg:ml-1">
        <div className="text-primary text-sm lg:text-lg">
          {blok.shouldUseRichText ? (
            <>
              {blok.richTextTitle?.map((richTextTitle) => (
                <StoryblokComponent
                  key={richTextTitle._uid}
                  blok={richTextTitle}
                />
              ))}
            </>
          ) : (
            <div>{blok.title}</div>
          )}
        </div>
        <div className="pt-0 text-xs font-normal md:pt-1 lg:pt-2 lg:text-[15px] lg:leading-[30px]">
          {blok.shouldUseRichText ? (
            <>
              {blok.richTextDescription?.map((richTextDescription) => (
                <StoryblokComponent
                  key={richTextDescription._uid}
                  blok={richTextDescription}
                />
              ))}
            </>
          ) : (
            <div>{blok.description}</div>
          )}
        </div>
      </div>
    </div>
  );
};
