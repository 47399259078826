import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { CardServiceStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const CardService = ({ blok }: Props<CardServiceStoryblok>) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-between gap-4 lg:gap-5 border border-neutral-200 flex-1 basis-1/3 p-6 lg:p-8 rounded-lg bg-white shadow-xs"
      )}
      {...storyblokEditable(blok)}
    >
      {blok.serviceLogo && (
        <div>
          <img
            className="h-[120px]"
            src={blok.serviceLogo.filename}
            alt={blok.serviceLogo.alt}
          />
        </div>
      )}
      <div className="flex flex-col h-full gap-2">
        {blok.serviceTitle && (
          <h4
            className="text-xl lg:text-2xl font-normal text-primary h-16 line-clamp-"
            title={blok.serviceTitle}
          >
            {blok.serviceTitle}
          </h4>
        )}

        {blok.serviceDescription && (
          <p className="text-sm lg:text-base font-normal text-dark">
            {blok.serviceDescription}
          </p>
        )}
      </div>
      <div className="mt-2.5">
        {blok.actionButton?.map((button) => (
          <StoryblokComponent key={button._uid} blok={button} />
        ))}
      </div>
    </div>
  );
};
