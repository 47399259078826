import { defaultTo } from "lodash";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

type TextErrorProps = {
  fieldError?:
    | FieldError
    | Merge<
        FieldError,
        (
          | Merge<FieldError, FieldErrorsImpl<{ id: string; file: File }>>
          | undefined
        )[]
      >
    | undefined;
  message?: string;
};

export const TextError = ({ fieldError, message }: TextErrorProps) => {
  if (!fieldError && !message) return null;

  return (
    <span className="text-alerts-error text-xs">
      {defaultTo(fieldError?.message, message)}
    </span>
  );
};
