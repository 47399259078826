import {
  ISbStoriesParams,
  ISbStoryData,
  getStoryblokApi,
} from "@storyblok/react";
import { HttpStatusCode } from "axios";
import { storyblokPublishedVersion } from "core/config";
import { useEffect, useState } from "react";
import { Routes } from "routes/main.routes";
import { redirectTo } from "utils";

type StoryType = ISbStoryData<
  import("@storyblok/js").StoryblokComponentType<string> & {
    [index: string]: any;
  }
>;

type ErrorResponse = { status: HttpStatusCode };

export const sbParams: ISbStoriesParams = {
  version: storyblokPublishedVersion, // Defaulted as 'draft'
  resolve_links: "url",
  resolve_relations: ["global_reference.references"],
};

export const useHandlerStoryblok = (slug: string) => {
  const [story, setStory] = useState<StoryType | null>(null);

  useEffect(() => {
    fetchStoryAsync(slug);
  }, [slug]);

  const fetchStoryAsync = async (slug: string) => {
    try {
      const { data } = await getStoryblokApi().get(
        `cdn/stories/${slug}`,
        sbParams
      );

      setStory(data.story);
    } catch (error) {
      const statusCode = (error as ErrorResponse)?.status;

      if (statusCode === HttpStatusCode.NotFound) {
        redirectTo(Routes.NOT_FOUND);
      }
    }
  };

  return story;
};
