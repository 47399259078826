import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  PathValue,
  useWatch,
} from "react-hook-form";
import { AssetStoryblok, HintStoryblok } from "types/component-types-sb";

import { BookBehalfOfType } from "enums";
import { MinifiedSignupUserData } from "models/client.model";
import { DateInput } from "../DateInput";
import { FilterCheckbox } from "../FilterCheckbox";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { BookMedicareGPSomeOneFormData } from "../book-gp/GPBehalfOfSomeOneForm";
import { BookMedicareSomeOneFormData } from "../book-medicare/BehalfOfSomeOneForm";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { AddressFields } from "./AddressFields";
import { MedicareMySelfFieldsType } from "./MedicareMySelfFields";

export type MedicareSomeOneFieldsType = {
  bookerName: string;
  bookerPhone: string;
} & MedicareMySelfFieldsType;

type Props<T extends FieldValues> = {
  control: Control<T>;
  errors: FieldErrors<T>;
  userInformation: MinifiedSignupUserData | null;

  medicareNumberHints?: HintStoryblok[];
  irnHints?: HintStoryblok[];
  expiryDateHints?: HintStoryblok[];
  streetAddressHints?: HintStoryblok[];

  medicareImageAsset?: AssetStoryblok;
  medicareImageAlt?: string;
};

export const MedicareSomeOneFields = <
  T extends BookMedicareSomeOneFormData | BookMedicareGPSomeOneFormData
>({
  control,
  errors,
  userInformation,
  medicareNumberHints,
  irnHints,
  expiryDateHints,
  streetAddressHints,
  medicareImageAsset,
  medicareImageAlt = "medicare-alt",
  ...props
}: Props<T>) => {
  const { isRegisteredWithMedicareName } = useWatch({ control });

  if (!userInformation) return <></>;

  const isNoUseNameInCard = !isRegisteredWithMedicareName;

  return (
    <div className="flex flex-col w-full gap-y-4">
      <div className="flex flex-col items-stretch xl:flex-row xl:items-start mb-1 gap-x-5 gap-y-3">
        <div className="flex flex-col flex-1">
          <Controller
            name={"bookerName" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <TextInput title="Your name" onChangeValue={field.onChange} />
            )}
          />
          <TextError fieldError={errors.bookerName as FieldError} />
        </div>

        <div className="flex flex-col flex-1 lg:min-w-[308px]">
          <Controller
            name={"bookerPhone" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <TextInput
                title="Your contact (either email or phone)"
                onChangeValue={field.onChange}
              />
            )}
          />
          <TextError fieldError={errors.bookerPhone as FieldError} />
        </div>
      </div>

      <Controller
        name={"isRegisteredWithMedicareName" as FieldPath<T>}
        control={control}
        render={({ field }) => (
          <FilterCheckbox
            isChecked={field.value as boolean}
            title="Their name registered with Medicare matches the name previously entered"
            onCheck={field.onChange}
          />
        )}
      />

      <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-5">
        {isNoUseNameInCard && (
          <div className="flex flex-col flex-1 gap-y-5">
            <div className="flex flex-row mb-1 gap-x-5">
              <div className="flex flex-col flex-1">
                <Controller
                  name={"firstName" as FieldPath<T>}
                  control={control}
                  defaultValue={
                    userInformation.firstName as PathValue<T, FieldPath<T>>
                  }
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      title="First name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />

                <TextError fieldError={errors.firstName as FieldError} />
              </div>

              <div className="flex flex-col flex-1">
                <Controller
                  name={"lastName" as FieldPath<T>}
                  control={control}
                  defaultValue={
                    userInformation.lastName as PathValue<T, FieldPath<T>>
                  }
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      title="Last name"
                      onChangeValue={field.onChange}
                    />
                  )}
                />

                <TextError fieldError={errors.lastName as FieldError} />
              </div>
            </div>

            <div className="flex flex-row mb-1 gap-x-5">
              <Controller
                name={"dateOfBirth" as FieldPath<T>}
                defaultValue={
                  userInformation.dateOfBirth as PathValue<T, FieldPath<T>>
                }
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col flex-1">
                    <DateInput
                      title="Date of birth"
                      maxDate={new Date()}
                      selectedDate={
                        field.value
                          ? new Date(field.value as string)
                          : undefined
                      }
                      onChangeValue={field.onChange}
                    />

                    <TextError fieldError={errors.dateOfBirth as FieldError} />
                  </div>
                )}
              />
              <div className="flex-1"></div>
            </div>
          </div>
        )}

        {/* MEDICARE CARD IMAGE */}
        <div>
          <img src={medicareImageAsset?.filename} alt={medicareImageAlt} />
        </div>

        <div className="flex flex-col flex-1">
          <Controller
            name={"medicareNumber" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                title="Their Medicare card number"
                onChangeValue={field.onChange}
                hintElement={getHintFromStoryBlok(medicareNumberHints)}
                numberOnly
              />
            )}
          />
          <TextError fieldError={errors.medicareNumber as FieldError} />
        </div>

        <div className="flex flex-col lg:flex-row items-stretch lg:items-start justify-center w-full gap-x-5 gap-y-3">
          <Controller
            name={"irnNumber" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-1 sm:min-w-[275px] flex-col">
                <TextInput
                  {...field}
                  title="Their individual reference (IRN)"
                  onChangeValue={field.onChange}
                  hintElement={getHintFromStoryBlok(irnHints)}
                />
                <TextError fieldError={errors.irnNumber as FieldError} />
              </div>
            )}
          />

          <Controller
            name={"expiryDate" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-col flex-1">
                <DateInput
                  type="month"
                  title="Expiry date"
                  selectedDate={
                    field.value ? new Date(field.value as string) : undefined
                  }
                  hintComponent={getHintFromStoryBlok(expiryDateHints)}
                  onChangeValue={field.onChange}
                  showMonthYearPicker
                  minDate={new Date()}
                  isShowHint
                />
                <TextError fieldError={errors.expiryDate as FieldError} />
              </div>
            )}
          />
        </div>

        {/* ADDRESS */}
        <AddressFields<T>
          streetAddressHints={streetAddressHints}
          control={control}
          errors={errors}
          behalfOfType={BookBehalfOfType.SOMEONE_ELSE_ADULT}
        />
      </div>
    </div>
  );
};
