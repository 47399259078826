import { PageAlias } from "enums";
import { toPageAlias } from "utils";
import { StateCreator, create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export type TimeLog = {
  section: PageAlias;
  startTimeString: string;
  maxResetReached: boolean;
};

type State = {
  timeLogs: TimeLog[];
};

type Actions = {
  toggleMaxResetReachedForSection: () => void;
  reset: () => void;
};

const defaultTimeLog: TimeLog = {
  section: PageAlias.OTHER,
  maxResetReached: false,
  startTimeString: new Date().toISOString(),
};

const initialState: State = {
  timeLogs: [
    {
      ...defaultTimeLog,
      section: PageAlias.SIGNUP,
    },
    {
      ...defaultTimeLog,
      section: PageAlias.FUNDING_BOOKING,
    },
    {
      ...defaultTimeLog,
      section: PageAlias.GP_BOOKING,
    },
    {
      ...defaultTimeLog,
      section: PageAlias.CONFIRM,
    },
  ],
};

const middleware = <T>(fn: StateCreator<T>) =>
  devtools(
    persist(fn, {
      name: "timeLogStore",
      storage: createJSONStorage(() => sessionStorage),
    })
  );

export const useTimeLogStore = create<State & Actions>()(
  middleware((set) => ({
    timeLogs: initialState.timeLogs,

    toggleMaxResetReachedForSection: () =>
      set(() => ({
        timeLogs: [
          {
            section: toPageAlias(),
            maxResetReached: true,
            startTimeString: new Date().toISOString(),
          },
        ],
      })),
    reset: () => set(initialState),
  }))
);
