import { PsychologistResponse } from "services/psychologist.service";
import { create, StateCreator } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type State = {
  focusedPsyId: PsychologistResponse["_id"] | null;
  isSubmittingBookNow: boolean;
};

type Actions = {
  setFocusedPsyId: (psyId: PsychologistResponse["_id"]) => void;
  toggleSubmittingBookNow: (isSubmitting?: boolean) => void;
  reset: () => void;
};

const initialState: State = { focusedPsyId: null, isSubmittingBookNow: false };

const middleware = <T>(fn: StateCreator<T>) =>
  devtools(
    persist(fn, {
      name: "bookingStore",
      storage: createJSONStorage(() => sessionStorage),
      skipHydration: true,
    })
  );

export const useBookingStore = create<State & Actions>()(
  middleware((set, getState) => ({
    focusedPsyId: null,
    isSubmittingBookNow: false,

    setFocusedPsyId: (psyId: string) => set({ focusedPsyId: psyId }),
    toggleSubmittingBookNow: (isSubmitting) => {
      if (typeof isSubmitting === "boolean") {
        set({ isSubmittingBookNow: isSubmitting });
      } else {
        set({ isSubmittingBookNow: !getState().isSubmittingBookNow });
      }
    },
    reset: () => set(initialState),
  }))
);
