import { storyblokEditable } from "@storyblok/react";
import { QuoteSvg } from "assets/icons/QuoteSvg";
import { ScreenBreakpoints } from "enums";
import { useWindowSize } from "react-use";
import { QuoteStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const Quote = ({ blok }: Props<QuoteStoryblok>) => {
  const { width } = useWindowSize();

  const isMobileScreen = width < ScreenBreakpoints.MD;
  const padding = blok.padding ?? "0px 0px 0px 0px";
  const margin = blok.margin ?? "0px 0px 0px 0px";

  return (
    <div className="flex justify-center px-3 md:px-6 py-6" {...storyblokEditable(blok)}>
      <div
        className="flex gap-x-6"
        style={{
          width: isMobileScreen ? "100%" : `${blok.width}%`,
          padding: padding,
          margin: margin,
        }}
      >
        <span className="mt-0.5">
          {blok.icon?.filename ? (
            <img
              src={blok.icon.filename}
              alt={blok.icon.alt}
              className="max-w-6 max-h-6"
            />
          ) : (
            <QuoteSvg />
          )}
        </span>
        <p className="text-15 md:text-20 text-dark font-normal flex flex-col">
          {blok?.content}
          {blok?.author && (
            <span className="text-primary text-12 font-semibold uppercase mt-3">
              - {blok.author}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
