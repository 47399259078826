import { StoryblokComponent } from "@storyblok/react";

import { GlobalReferenceStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { defaultTo, isEmpty, isNil } from "lodash";

const getComponents = ({ references }: GlobalReferenceStoryblok) => {
  const components = references
    ?.flatMap(item => item?.content)
    ?.flatMap(item => item?.body)
    ?.filter(Boolean);

  return defaultTo(components, []);
};

export const GlobalReference = ({ blok }: Props<GlobalReferenceStoryblok>) => {
  const components = getComponents(blok);

  if (isNil(components) || isEmpty(components)) return <></>;

  return components.map(component => (
    <StoryblokComponent key={component._uid} blok={component} />
  ));
};
