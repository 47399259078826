import { useAuth0 } from "@auth0/auth0-react";
import { getAttachedClinicianAsync } from "services/psychologist.service";
import useSWR from "swr";
import { isRoleClinician } from "utils";

export const useSwrAttachedClinician = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const fetcher = async () => {
    if (!isAuthenticated) return Promise.resolve(null);

    const accessToken = await getAccessTokenSilently();

    try {
      const { data } = await getAttachedClinicianAsync(accessToken);

      return data;
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const queryResult = useSWR(
    ["/client-records/me:getAttachedAccountAndClinician", user?.sub],
    fetcher,
    {
      isPaused: () => isRoleClinician(),
      errorRetryCount: 3,
    }
  );

  return queryResult;
};
