export type Psychologist = {
  id: number;
  index?: number;
  imageUrl: string;
  fullName: string;
  tags: string[];
  expertise: string[];
  nextAvailable: string;
};

export type PsychologistRequestQueries = {
  /**
   * `teenagers` for teen aged between 13-17
   */
  clientAgeRange?: ClientAgeRange;
  /**
   * support multiple with comma delimiter, eg `anxiousOrPanicky,difficultyInMyRelationship`
   */
  clientMentalHealthConcern?: ClientMentalHealthConcern | string;
  /**
   * support multiple with comma delimiter
   */
  clinicianIds?: string;
  /**
   * support multiple with comma delimiter, eg `monday,tuesday`
   */
  days?: Days | string;
  deliveryMode?: DeliveryMode;
  endTime?: string;
  funding?: Funding;
  gender?: Gender;
  /**
   * ISO 639 language codes
   */
  language?: Language | string;
  /**
   * pagination
   */
  page?: number | null;
  /**
   * pagination
   */
  perPage?: number | null;
  /**
   * Works together with endTime for timing, eg morning = startTime: 08:00, endTime: 12:00,
   * afternoon = startTime: 12:00, endTime: 18:00, evening = startTime: 18:00, endTime: 24:00
   */
  startTime?: string;
  style?: Style | string;
  // [property: string]: any;

  // particularPsychologist?: string;
  time?: string;
  nextAvailable?: string;
  claimType?: string;
};

/**
 * `teenagers` for teen aged between 13-17
 */
export type ClientAgeRange =
  | "adults"
  | "olderAdults"
  | "children"
  | "teenagers"
  | "youth";

/**
 * support multiple with comma delimiter, eg `anxiousOrPanicky,difficultyInMyRelationship`
 */
export type ClientMentalHealthConcern =
  | "anxiousOrPanicky"
  | "difficultyInMyRelationship"
  | "traumaticExperience"
  | "troubleSleeping"
  | "addictionOrDifficultyWithSubstanceAbuse"
  | "downOrDepressed"
  | "stressAtWorkOrSchool"
  | "justExploring"
  | "adjustmentDisorder"
  | "alcoholAndOtherDrugs"
  | "angerManagement"
  | "anxiety"
  | "attentionDeficitHyperactivityDisorder"
  | "autism"
  | "bipolarAffectiveDisorder"
  | "bodyDysmorphia"
  | "borderlinePersonalityDisorder"
  | "burnout"
  | "career"
  | "chronicPain"
  | "communication"
  | "confidence"
  | "depressionAndMood"
  | "dissociativeDisorders"
  | "domesticViolence"
  | "dyslexia"
  | "eatingDisorders"
  | "family"
  | "fertilityAPerinatal"
  | "financialStress"
  | "gamblingAddiction"
  | "genderAndSexualIdentity"
  | "griefAndBereavement"
  | "healthAndLifestyle"
  | "infantSleep"
  | "lifeTransition"
  | "loneliness"
  | "lowMood"
  | "meaningValue"
  | "narcissism"
  | "newParent"
  | "ocd"
  | "painManagement"
  | "panicAttacks"
  | "parentTherapy"
  | "perfectionism"
  | "performance"
  | "perinatalAndPostnatalDepressionAndAnxiety"
  | "personalityDisorder"
  | "phobias"
  | "pornographySexualDifficulties"
  | "ptsd"
  | "relationshipCounselling"
  | "riskyBehaviours"
  | "selfDevelopment"
  | "selfEsteem"
  | "sexualAbuse"
  | "sexualHealth"
  | "sleepInsomnia"
  | "smokingCessation"
  | "socialAnxiety"
  | "stressManagement"
  | "tinnitus"
  | "traumaAndPtsd"
  | "violence"
  | "weightManagement"
  | "workplaceIncidentTherapy"
  | "workplaceRelations"
  | "workStress";

/**
 * support multiple with comma delimiter, eg `monday,tuesday`
 */
export type Days =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "weekend"
  | "saturday"
  | "sunday";

export type DeliveryMode = "faceToFace" | "phone" | "video";

export enum Funding {
  BULK_BILL = "Bulk Bill",
  REBATE = "Rebate",
  NDIS = "NDIS",
  DVA = "DVA",
  WORK_COVER = "WorkCover",
  SELF_FUNDED = "Self fund",
  // OTHER = "Other",
}

export type Gender =
  | "woman"
  | "man"
  | "transgenderWoman"
  | "transgenderMan"
  | "nonBinary"
  | "agender";

/**
 * ISO 639 language codes
 */
export type Language =
  | "af"
  | "sq"
  | "ar"
  | "ar-dz"
  | "ar-bh"
  | "ar-eg"
  | "ar-iq"
  | "ar-jo"
  | "ar-kw"
  | "ar-lb"
  | "ar-ly"
  | "ar-ma"
  | "ar-om"
  | "ar-qa"
  | "ar-sa"
  | "ar-sy"
  | "ar-tn"
  | "ar-ae"
  | "ar-ye"
  | "an"
  | "hy"
  | "as"
  | "ast"
  | "az"
  | "eu"
  | "bg"
  | "be"
  | "bn"
  | "bs"
  | "br"
  | "my"
  | "ca"
  | "ch"
  | "ce"
  | "zh"
  | "zh-hk"
  | "zh-cn"
  | "zh-sg"
  | "zh-tw"
  | "cv"
  | "co"
  | "cr"
  | "hr"
  | "cs"
  | "da"
  | "nl"
  | "nl-be"
  | "en"
  | "en-au"
  | "en-bz"
  | "en-ca"
  | "en-ie"
  | "en-jm"
  | "en-nz"
  | "en-ph"
  | "en-za"
  | "en-tt"
  | "en-gb"
  | "en-us"
  | "en-zw"
  | "eo"
  | "et"
  | "fo"
  | "fj"
  | "fi"
  | "fr"
  | "fr-be"
  | "fr-ca"
  | "fr-fr"
  | "fr-lu"
  | "fr-mc"
  | "fr-ch"
  | "fy"
  | "fur"
  | "gd-ie"
  | "gl"
  | "ka"
  | "de"
  | "de-at"
  | "de-de"
  | "de-li"
  | "de-lu"
  | "de-ch"
  | "el"
  | "gu"
  | "ht"
  | "he"
  | "hi"
  | "hu"
  | "is"
  | "id"
  | "iu"
  | "ga"
  | "it"
  | "it-ch"
  | "ja"
  | "kn"
  | "ks"
  | "kk"
  | "km"
  | "ky"
  | "tlh"
  | "ko"
  | "ko-kp"
  | "ko-kr"
  | "la"
  | "lv"
  | "lt"
  | "lb"
  | "mk"
  | "ms"
  | "ml"
  | "mt"
  | "mi"
  | "mr"
  | "mo"
  | "nv"
  | "ng"
  | "ne"
  | "no"
  | "nb"
  | "nn"
  | "oc"
  | "or"
  | "om"
  | "fa"
  | "fa-ir"
  | "pl"
  | "pt"
  | "pt-br"
  | "pa"
  | "pa-in"
  | "pa-pk"
  | "qu"
  | "rm"
  | "ro"
  | "ro-mo"
  | "ru"
  | "ru-mo"
  | "sz"
  | "sg"
  | "sa"
  | "sc"
  | "gd"
  | "sd"
  | "si"
  | "sr"
  | "sk"
  | "sl"
  | "so"
  | "sb"
  | "es"
  | "es-ar"
  | "es-bo"
  | "es-cl"
  | "es-co"
  | "es-cr"
  | "es-do"
  | "es-ec"
  | "es-sv"
  | "es-gt"
  | "es-hn"
  | "es-mx"
  | "es-ni"
  | "es-pa"
  | "es-py"
  | "es-pe"
  | "es-pr"
  | "es-es"
  | "es-uy"
  | "es-ve"
  | "sx"
  | "sw"
  | "sv"
  | "sv-fi"
  | "sv-sv"
  | "ta"
  | "tt"
  | "te"
  | "th"
  | "tig"
  | "ts"
  | "tn"
  | "tr"
  | "tk"
  | "uk"
  | "hsb"
  | "ur"
  | "ve"
  | "vi"
  | "vo"
  | "wa"
  | "cy"
  | "xh"
  | "ji"
  | "zu";

export type Style =
  | "Emotion Focused Therapy"
  | "Interpersonal Psychotherapy"
  | "Mindfulness"
  | "Cognitive Behavioral Therapy (CBT)"
  | "Narrative Therapy"
  | "Positive Psychology"
  | "Psychodynamic Therapy"
  | "Solution-Focused Brief Therapy"
  | "Acceptance and Commitment Therapy (ACT)"
  | "Dialectical Behavior Therapy"
  | "Attachment-Based Therapy"
  | "Coaching"
  | "Motivational Interviewing"
  | "Person-Centered Therapy"
  | "Schema Therapy";
