import { useState } from "react";

import { EyeClosedSvg } from "assets/icons/EyeClosedSvg";
import { EyeSvg } from "assets/icons/EyeSvg";
import { InputType } from "enums";

type Props = {
  title: string;
  placeHolder?: string;

  onChangeValues: (newValues: string) => void;
};

export const PasswordField = ({ title, placeHolder = "", ...props }: Props) => {
  const [inputType, setInputType] = useState<InputType>(InputType.PASSWORD);

  const togglePasswordType = () => {
    if (inputType === InputType.PASSWORD) {
      setInputType(InputType.TEXT);
    } else {
      setInputType(InputType.PASSWORD);
    }
  };

  const handleChangePasswordValues = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.onChangeValues(e.target.value);
  };

  return (
    <label className="flex flex-col gap-y-2">
      <div>{title}</div>

      <div className="relative">
        <input
          type={inputType}
          className="w-full py-3 pl-3 border rounded-md pr-9 border-light-grey-3 focus:border-secondary-darker focus:ring-secondary-darker"
          onChange={handleChangePasswordValues}
          placeholder={placeHolder}
        />

        <div
          className="absolute cursor-pointer right-3 top-[.9rem] w-5 h-5 flex justify-center items-center"
          onClick={togglePasswordType}
        >
          {inputType === InputType.PASSWORD ? <EyeSvg /> : <EyeClosedSvg />}
        </div>
      </div>
    </label>
  );
};
