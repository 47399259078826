import { DatePickerSvg } from "assets/icons/DatePickerSvg";

export const DatePickerSkeleton = () => {
  return (
    <div
      role="status"
      className="flex items-center justify-center w-full rounded-lg bg-neutral h-80 animate-pulse dark:bg-secondary"
    >
      <DatePickerSvg />
      <span className="sr-only">Loading...</span>
    </div>
  );
};
