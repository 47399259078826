import { first, get, max, min, set } from "lodash";

import { TimeZoneType } from "enums";
import {
  Funding,
  GetMatchedData,
  PsychologistRequestQueries,
  ReserveAppointmentLocalStorageData,
} from "models";
import { MinifiedSignupUserData, ZonedTimeInfo } from "models/client.model";
import { getAvailableTime } from "services/psychologist.service";
import { extract, specializationsCombinedValue } from "utils";
import { DEFAULT_APPOINTMENT_TYPE } from "core/booking.constant";
import { AppointmentResponse } from "services/booking.service";

const KEY_SIGNUP_SESSION_USER = "SH_SIGN_UP_CREATED_USER";
const KEY_RESERVE_DATA = "SH_RESERVE_DATA";
const KEY_STORED_FUNDING = "SH_STORED_FUNDING";
const KEY_GET_MATCHED = "KEY_GET_MATCHED";
const KEY_STORED_TIMEZONE = "SH_STORED_TIMEZONE";

export const PATH_FIRST_RESERVE_APPOINTMENT_HAS_GP_REFERRAL =
  "reserveAppointment.appointments[0].isHaveGPReferralAndTreatmentPlan";
export const PATH_FIRST_RESERVE_APPOINTMENT_IS_NDIS_PLAN_MANAGED =
  "reserveAppointment.appointments[0].isNdisPlanManaged";
export const PATH_FIRST_RESERVE_APPOINTMENT_IS_WORK_COVER_NSW =
  "reserveAppointment.appointments[0].isWorkCoverNsw";

// Signup Session User
export const getStoredSignupSessionUser = (): MinifiedSignupUserData | null => {
  const user = window.sessionStorage.getItem(KEY_SIGNUP_SESSION_USER);

  if (!user) return null;

  return JSON.parse(user);
};

export const setStoredSignupSessionUser = (user: MinifiedSignupUserData) => {
  if (!user) return;

  window.sessionStorage.setItem(KEY_SIGNUP_SESSION_USER, JSON.stringify(user));
};

export const removeStoredSignupSessionUser = () => {
  window.sessionStorage.removeItem(KEY_SIGNUP_SESSION_USER);
};

export const cleanupStoredUserSessionLogout = () => {
  removeStoredSignupSessionUser();
  removeStoredReserveData();
  removeStoredFundingMethod();
  removeStoredTimeZone();

  clearStoredGetMatched();
};

export const cleanUpStorageAfterLogin = () => {
  removeStoredSignupSessionUser();
  removeStoredReserveData();
  removeStoredFundingMethod();
};

export const getSignupRecordType = (): string | null => {
  const user = getStoredSignupSessionUser();

  const recordType = user?.clientRecord?.recordType;

  if (!recordType) return null;

  return recordType;
};

// Reserve Data
export const getStoredReserveData =
  (): ReserveAppointmentLocalStorageData | null => {
    const data = window.localStorage.getItem(KEY_RESERVE_DATA);

    if (!data) return null;

    return JSON.parse(data);
  };

export const setStoredReserveData = (
  data: ReserveAppointmentLocalStorageData
) => {
  if (!data) return;

  window.localStorage.setItem(KEY_RESERVE_DATA, JSON.stringify(data));
};

export const removeStoredReserveData = () => {
  window.localStorage.removeItem(KEY_RESERVE_DATA);
};

// Stored Funding Method after selection
export const setStoredFundingMethod = (funding: Funding) => {
  window.localStorage.setItem(KEY_STORED_FUNDING, JSON.stringify({ funding }));
};

export const getStoredFundingMethod = (): Funding | null => {
  const storedFunding = window.localStorage.getItem(KEY_STORED_FUNDING);

  if (!storedFunding) return null;

  return JSON.parse(storedFunding)?.funding ?? null;
};

export const removeStoredFundingMethod = () => {
  window.localStorage.removeItem(KEY_STORED_FUNDING);
};

export const getStoredAppointment = (): AppointmentResponse | undefined => {
  const storedReservation = getStoredReserveData();

  return first(storedReservation?.reserveAppointment?.appointments);
};

export const getStoredAppointmentRate = (): number => {
  const appointment = getStoredAppointment();

  if (!appointment) return 0;

  return appointment?.rate ?? 0;
};

export const getStoredReserveId = (): string | null => {
  const appointment = getStoredAppointment();

  if (!appointment) return null;

  return get(appointment, "reserveId", null);
};

// Stored Get Matched
export const setStoredGetMatched = (getMatched: Partial<GetMatchedData>) => {
  window.sessionStorage.setItem(KEY_GET_MATCHED, JSON.stringify(getMatched));
};

export const getStoredGetMatched = (): Partial<GetMatchedData> | null => {
  const data = window.sessionStorage.getItem(KEY_GET_MATCHED);

  if (!data) return null;

  return JSON.parse(data);
};

export const clearStoredGetMatched = () => {
  window.sessionStorage.removeItem(KEY_GET_MATCHED);
};

export const getMatchedPsychologistRequestQueries = (
  getMatchedData: Partial<GetMatchedData> | null
): PsychologistRequestQueries => {
  let apiQueries: PsychologistRequestQueries = getMatchedData?.apiQueries ?? {};

  if (apiQueries?.clientMentalHealthConcern) {
    const concernsSelected =
      apiQueries.clientMentalHealthConcern?.trim()?.split(",") ?? [];

    const clientMentalHealthConcern = specializationsCombinedValue.filter(
      (spec) => concernsSelected.find((concern) => concern === spec.label)
    );

    const availableTimes = getAvailableTime(apiQueries.time?.split(",") ?? []);

    apiQueries = {
      ...apiQueries,
      funding: DEFAULT_APPOINTMENT_TYPE,
      clientMentalHealthConcern: clientMentalHealthConcern
        .map((concern) => concern.value)
        .join(","),
      startTime: min(availableTimes),
      endTime: max(availableTimes),
    };
  }

  return apiQueries;
};

export const getMatchedHelpWithList = (
  getMatchedFilterData: Partial<GetMatchedData> | null
): string[] => {
  if (getMatchedFilterData?.apiQueries?.clientMentalHealthConcern) {
    const concernsSelected =
      getMatchedFilterData?.apiQueries?.clientMentalHealthConcern
        ?.trim()
        ?.split(",") ?? [];

    const helpWithList = specializationsCombinedValue.filter((spec) =>
      concernsSelected.find((concern) => concern === spec.label)
    );

    return extract(helpWithList, "value");
  }

  return [];
};

export const getStoredTimeZone = (): TimeZoneType => {
  const storedTimeZone = window.sessionStorage.getItem(KEY_STORED_TIMEZONE);

  if (!storedTimeZone) {
    return TimeZoneType.AUSTRALIA_SYDNEY;
  }

  const zonedTimeInfo: ZonedTimeInfo = JSON.parse(storedTimeZone);

  return get(zonedTimeInfo, "timeZone", TimeZoneType.AUSTRALIA_SYDNEY);
};

export const setStoredTimeZone = (selectedTimeZone: ZonedTimeInfo) => {
  if (!selectedTimeZone) return;

  window.sessionStorage.setItem(
    KEY_STORED_TIMEZONE,
    JSON.stringify(selectedTimeZone)
  );
};

export const removeStoredTimeZone = () => {
  window.sessionStorage.removeItem(KEY_STORED_TIMEZONE);
};

export const setStoredAppointmentHasGpReferral = (
  isHaveGPReferral: boolean
): void => {
  const storedReserveData = getStoredReserveData();

  if (!storedReserveData) return;

  set(
    storedReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_HAS_GP_REFERRAL,
    isHaveGPReferral
  );

  setStoredReserveData(storedReserveData);
};

export const setStoredFirstNdisAppointmentIsPlanManaged = (
  isNdisPlanManaged: boolean
): void => {
  const storedReserveData = getStoredReserveData();

  if (!storedReserveData) return;

  set(
    storedReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_IS_NDIS_PLAN_MANAGED,
    isNdisPlanManaged
  );

  setStoredReserveData(storedReserveData);
};

export const setStoredFirstWorkCoverAppointmentIsFromNsw = (
  isWorkCoverNsw: boolean
): void => {
  const storedReserveData = getStoredReserveData();

  if (!storedReserveData) return;

  set(
    storedReserveData,
    PATH_FIRST_RESERVE_APPOINTMENT_IS_WORK_COVER_NSW,
    isWorkCoverNsw
  );

  setStoredReserveData(storedReserveData);
};
