import { StoryblokComponent } from "@storyblok/react";
import { defaultTo } from "lodash";
import { SingleSelectionDropdownStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { SelectionOption } from "models";

type CustomProps = Props<SingleSelectionDropdownStoryblok> & {
  isLoading?: boolean;
  isRequired?: boolean;
  options: SelectionOption[];
  selectedOption?: SelectionOption;
  onChangeSingleSelection: (option: SelectionOption) => void;
};

export const SingleSelectionDropdown = ({
  blok,
  isLoading = false,
  isRequired = false,
  options,
  selectedOption,
  ...props
}: CustomProps) => {
  const getHintComponent = () => (
    <div>
      {blok.hints?.map((hintComponent) => (
        <StoryblokComponent key={hintComponent._uid} blok={hintComponent} />
      ))}
    </div>
  );

  const handleChangeSingleSelection = (option: SelectionOption) => {
    props.onChangeSingleSelection(option);
  };

  return (
    <SelectionDropdown
      isLoading={isLoading}
      title={defaultTo(blok.title, "Single selection dropdown")}
      placeHolder={defaultTo(blok.placeholder, "Select ...")}
      hintComponent={getHintComponent()}
      options={options}
      selectedValues={selectedOption ? [selectedOption] : []}
      onChangeSingleSelection={handleChangeSingleSelection}
      isShowTitle={blok.isShowTitle}
      isShowHint={blok.isShowHint}
      isRequired={isRequired}
    />
  );
};
