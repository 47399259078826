import {
  HeaderNavItemStoryblok,
  LinkStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { Colors } from "enums";
import { cn } from "utils";

export const NavItem = ({ blok }: Props<HeaderNavItemStoryblok>) => {
  const link = {
    text: blok.name,
    href: blok.link,
    component: "link",
    color: Colors.PRIMARY,
  } as LinkStoryblok;

  return (
    <div {...storyblokEditable(blok)} className="cursor-pointer">
      {blok.icon?.filename && (
        <img
          src={blok.icon.filename}
          alt={blok.icon.alt}
          className={cn("w-4 h-4 mx-[5px] my-auto", {
            "order-last": !blok.isIconBefore,
          })}
        />
      )}
      <StoryblokComponent blok={link} />
    </div>
  );
};
