import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { WarningModal } from "components/forms/WarningModal";
import { LoadingScreen } from "components/shared/LoadingScreen";
import {
  DEFAULT_AFTER_REBATE_COST,
  DEFAULT_FUNDING_NOT_FOUND_CONTENT,
  DEFAULT_FUNDING_NOT_FOUND_TITLE,
  DEFAULT_SESSION_NOT_FOUND_CONTENT,
  DEFAULT_SESSION_NOT_FOUND_TITLE,
  DEFAULT_SIGNED_UP_DATA_NOT_FOUND_CONTENT,
  DEFAULT_SIGNED_UP_DATA_NOT_FOUND_TITLE,
  MODAL_TIMEOUT_RESET_REMAINING_MINUTES,
} from "core/booking.constant";
import { ConfirmRadioType, MedicareRole } from "enums";
import { first, isEqual } from "lodash";
import { Funding } from "models";
import { MinifiedSignupUserData } from "models/client.model";
import { createPortal } from "react-dom";
import { Routes } from "routes/main.routes";
import { AppointmentResponse } from "services/booking.service";
import { PsychologistResponse } from "services/psychologist.service";
import { match } from "ts-pattern";
import { v4 as GUID } from "uuid";
import {
  getAppointmentRateByMedicareRole,
  isMatchedUrl,
  openInNewTab,
  redirectToPsychologistDetails,
} from "./common.util";
import { fundingMethodOptions } from "./settings.util";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  getStoredSignupSessionUser,
} from "./storage.util";
import {
  cn,
  getDefaultButtonStyles,
  getDefaultOutlinedButtonStyles,
} from "./styles.util";

export type StoredDataEligibility = {
  fundingMethod: Funding;
  appointmentData: AppointmentResponse;
  signedUpUserData: MinifiedSignupUserData;
  validFundingMethod: boolean;
  validAppointmentData: boolean;
  validSignedUpUserData: boolean;
  isEligible: boolean;
};

export type StoredDataValidateConditions = {
  shouldCheckFundingMethod?: boolean;
  shouldCheckAppointmentData?: boolean;
  shouldCheckSignedUpData?: boolean;
};

export const validateStoredBookingData = ({
  shouldCheckFundingMethod = true,
  shouldCheckAppointmentData = true,
  shouldCheckSignedUpData = true,
}: StoredDataValidateConditions = {}): StoredDataEligibility => {
  const storedFundingMethod = getStoredFundingMethod() as Funding;
  const reserveData = getStoredReserveData();
  const storedSignupUserData =
    getStoredSignupSessionUser() as MinifiedSignupUserData;

  const appointmentData = first(
    reserveData?.reserveAppointment.appointments
  ) as AppointmentResponse;
  const existsFundingMethod =
    Object.values(Funding).includes(storedFundingMethod);

  const isValidFundingMethod = shouldCheckFundingMethod
    ? Boolean(storedFundingMethod) && existsFundingMethod
    : true;
  const isValidAppointmentData = shouldCheckAppointmentData
    ? Boolean(appointmentData)
    : true;
  const isValidSignedUpUserData = shouldCheckSignedUpData
    ? Boolean(storedSignupUserData)
    : true;

  return {
    fundingMethod: storedFundingMethod,
    appointmentData: appointmentData,
    signedUpUserData: storedSignupUserData,
    validAppointmentData: isValidAppointmentData,
    validFundingMethod: isValidFundingMethod,
    validSignedUpUserData: isValidSignedUpUserData,
    isEligible:
      isValidAppointmentData && isValidFundingMethod && isValidSignedUpUserData,
  };
};

const renderWarningModal = (heading: string, content: string[]) => (
  <>
    <LoadingScreen />
    <WarningModal
      heading={heading}
      content={content}
      isVisible
      redirectUrl={Routes.OUR_PSYCHOLOGISTS}
    />
  </>
);

export const renderSessionBookingWarning = (
  storedDataEligibility: StoredDataEligibility
): JSX.Element => {
  if (!storedDataEligibility.validFundingMethod) {
    return renderWarningModal(
      DEFAULT_FUNDING_NOT_FOUND_TITLE,
      DEFAULT_FUNDING_NOT_FOUND_CONTENT
    );
  }

  if (!storedDataEligibility.validAppointmentData) {
    return renderWarningModal(
      DEFAULT_SESSION_NOT_FOUND_TITLE,
      DEFAULT_SESSION_NOT_FOUND_CONTENT
    );
  }

  if (!storedDataEligibility.validSignedUpUserData) {
    return renderWarningModal(
      DEFAULT_SIGNED_UP_DATA_NOT_FOUND_TITLE,
      DEFAULT_SIGNED_UP_DATA_NOT_FOUND_CONTENT
    );
  }

  return <></>;
};

const getAuthenticatedTimeOutMessage = (
  handleOnClick: () => void
): JSX.Element => (
  <>
    <p>
      Due to high demand, appointment slots are reserved for 15 minutes before
      being released.
    </p>
    <p>
      Please{" "}
      <span
        onClick={handleOnClick}
        className="underline underline-offset-2 cursor-pointer"
      >
        restart
      </span>{" "}
      your search and try again.
    </p>
  </>
);

const getUnauthenticatedTimeOutMessage = (
  handleOnClick: () => void
): JSX.Element => (
  <>
    <p>Your appointment session has timed out.</p>
    <p>
      Please{" "}
      <span
        onClick={handleOnClick}
        className="underline underline-offset-2 cursor-pointer"
      >
        login
      </span>{" "}
      to reschedule and confirm your booking.
    </p>
  </>
);

const getGPSignUpTimeOutMessage = (handleOnClick: () => void): JSX.Element => (
  <>
    <p>Your time limit has been reached.</p>
    <p>
      Please{" "}
      <span
        onClick={handleOnClick}
        className="underline underline-offset-2 cursor-pointer"
      >
        login
      </span>{" "}
      again to complete your profile.
    </p>
  </>
);

const getTimeOutModalMessage = (
  isAuthenticated: boolean,
  isGPBooking: boolean,
  handleOnClickRestart: () => void,
  handleOnClickLogin: () => void
): JSX.Element => {
  if (isGPBooking) {
    return getGPSignUpTimeOutMessage(handleOnClickLogin);
  }

  const shouldDisplayAuthenticatedMessage =
    !isMatchedUrl(Routes.SIGNUP) || isAuthenticated;

  if (shouldDisplayAuthenticatedMessage) {
    return getAuthenticatedTimeOutMessage(handleOnClickRestart);
  }

  return getUnauthenticatedTimeOutMessage(handleOnClickLogin);
};

export const renderTimeOutModal = (
  isAuthenticated: boolean,
  isGPBooking: boolean,
  shouldShowTimeOutModal: boolean,
  psychologistSlugUrl: string,
  loginRedirectSOH: () => void
) => {
  const handleOnClickRestart = () => {
    redirectToPsychologistDetails(psychologistSlugUrl);
  };

  const handleOnClickLogin = () => {
    loginRedirectSOH();
  };

  const navigateMessageContent: JSX.Element = getTimeOutModalMessage(
    isAuthenticated,
    isGPBooking,
    handleOnClickRestart,
    handleOnClickLogin
  );

  return (
    <WarningModal
      isVisible={shouldShowTimeOutModal}
      redirectUrl={Routes.OUR_PSYCHOLOGISTS}
      content={[navigateMessageContent]}
      shouldShowButtons={false}
    />
  );
};

const getTimeOutWarningModalMessage = (
  isAuthenticated: boolean,
  isGPBooking: boolean
): string => {
  const defaultMessage: string = `You only have ${MODAL_TIMEOUT_RESET_REMAINING_MINUTES} minutes left until your appointment hold time out.`;

  if (isAuthenticated) {
    return `${defaultMessage} Do you want to reset timer?`;
  }

  if (isGPBooking) {
    return `${defaultMessage} Please complete your booking promptly to avoid having to book again.`;
  }

  return `${defaultMessage} Please complete your booking promptly to avoid losing your reserved time slot.`;
};

export const renderTimeOutWarningModal = (
  isAuthenticated: boolean,
  shouldShowTimeOutResetModal: boolean,
  isLoading: boolean,
  isGPBooking: boolean,
  onResetTimerModalButtonClick: (confirmType: ConfirmRadioType) => void
) => {
  const contents: string = getTimeOutWarningModalMessage(
    isAuthenticated,
    isGPBooking
  );

  const buttons: JSX.Element[] = isAuthenticated
    ? [
        <button
          key={GUID()}
          disabled={isLoading}
          className={cn(getDefaultButtonStyles())}
          onClick={() => onResetTimerModalButtonClick(ConfirmRadioType.YES)}
        >
          <span className="flex justify-center items-center">
            {isLoading ? <SpinnerLoading className="size-7" /> : "Yes"}
          </span>
        </button>,
        <button
          key={GUID()}
          disabled={isLoading}
          className={cn(getDefaultOutlinedButtonStyles())}
          onClick={() => onResetTimerModalButtonClick(ConfirmRadioType.NO)}
        >
          No
        </button>,
      ]
    : [
        <button
          key={GUID()}
          className={cn(getDefaultButtonStyles(), "min-w-[200px]")}
          onClick={() => onResetTimerModalButtonClick(ConfirmRadioType.NO)}
        >
          Continue
        </button>,
      ];

  return (
    <WarningModal
      isVisible={shouldShowTimeOutResetModal}
      redirectUrl={Routes.OUR_PSYCHOLOGISTS}
      heading={`Attention: ${MODAL_TIMEOUT_RESET_REMAINING_MINUTES} minute timeout warning`}
      content={[contents]}
      additionButtons={buttons}
      shouldShowButtons={false}
    />
  );
};

export const renderFeeWarningModal = (params: {
  isVisible: boolean;
  fundingMethod: Funding | null;
  psychologistData: PsychologistResponse;
  onClose: () => void;
  onContinue: () => void;
  onGoBack: () => void;
}) => {
  const { isVisible, fundingMethod, psychologistData } = params || {};

  const selectedFundingMethod = fundingMethodOptions.find((opt) =>
    isEqual(opt.value, fundingMethod)
  )?.label;

  const medicareRole = psychologistData?.medicare?.role as MedicareRole;
  const apptRate = getAppointmentRateByMedicareRole(medicareRole);

  const modalContent = match(fundingMethod)
    .with(Funding.REBATE, () => (
      <>
        <strong>{selectedFundingMethod}</strong> appointment selected. Upfront
        charge is <strong>${apptRate}</strong> plus booking fee. <br />
        After reimbursement, <strong>out of pocket cost</strong> is{" "}
        <strong>${DEFAULT_AFTER_REBATE_COST}</strong>. More on{" "}
        <span
          onClick={() => openInNewTab(Routes.PRICING)}
          className="underline underline-offset-2 cursor-pointer"
        >
          pricing
        </span>
        .
      </>
    ))
    .with(Funding.SELF_FUNDED, () => (
      <>
        <strong>{selectedFundingMethod}</strong> appointment selected. Cost is{" "}
        <strong>${apptRate}</strong> plus booking fee. <br />
        More on{" "}
        <span
          onClick={() => openInNewTab(Routes.PRICING)}
          className="underline underline-offset-2 cursor-pointer"
        >
          pricing
        </span>
        .
      </>
    ))
    .otherwise(() => <></>);

  return createPortal(
    <WarningModal
      isVisible={isVisible}
      redirectUrl={Routes.ROOT}
      heading="This appointment comes with a fee"
      content={[modalContent]}
      additionButtons={[
        <button
          key={GUID()}
          className={cn(getDefaultButtonStyles(), "min-w-[200px]")}
          onClick={params.onContinue}
        >
          Continue
        </button>,
      ]}
      shouldShowButtons={false}
      showCloseButton
      onClose={params.onClose}
    ></WarningModal>,
    document.body
  );
};
