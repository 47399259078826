import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { WarningSvg } from "assets/icons/WarningSvg";
import { NoticeBoxStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const NoticeBox = ({ blok }: Props<NoticeBoxStoryblok>) => {
  return (
    <div
      className="flex max-sm:flex-col flex-row gap-5 p-7 bg-alerts-warning rounded-lg mx-auto w-full"
      {...storyblokEditable(blok)}
    >
      <div className="mt-2">
        <WarningSvg />
      </div>
      <div>
        {blok.content?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  );
};
