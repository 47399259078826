import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { MedicareRole } from "enums";
import { Funding } from "models";
import { match } from "ts-pattern";
import { SignupSectionStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import {
  getStoredFundingMethod,
  getStoredReserveData,
} from "utils/storage.util";
import { CountDownClock } from "../CountDownClock";
import { isEqual } from "lodash";
import { getSignUpFormType } from "utils";

export const SignupWithFundingContainer = ({
  blok,
}: Props<SignupSectionStoryblok>) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col max-md:gap-8 gap-16 py-16 mx-0 md:mx-7 lg:mx-14 xl:mx-28"
    >
      <div className="xl:mx-14">{renderNoticeBox(blok)}</div>

      <div className="flex max-lg:flex-col-reverse flex-col lg:flex-row gap-4 lg:gap-14">
        {blok.sections?.map((section) => (
          <StoryblokComponent
            key={section._uid}
            blok={section}
            formType={getSignUpFormType(blok?.signUpFormTypes)}
          />
        ))}
      </div>

      {/* COUNTDOWN CLOCK */}
      <CountDownClock isVisible={false} shouldShowTimeOutNoticeBox={false} />
    </div>
  );
};

const renderNoticeBox = (blok: SignupSectionStoryblok) => {
  const fundingMethod = getStoredFundingMethod();
  const psychologistRole = getStoredReserveData()?.psychologist?.medicare
    ?.role as MedicareRole;

  return match(fundingMethod)
    .with(Funding.BULK_BILL, () => (
      <div>
        {blok.bulkBillNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .with(Funding.REBATE, () => {
      if (isEqual(psychologistRole, MedicareRole.ClinicalPsychologists)) {
        return (
          <div>
            {blok.rebateClinicalNotice?.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}
          </div>
        );
      }

      return (
        <div>
          {blok.rebatePsychologistNotice?.map((item) => (
            <StoryblokComponent key={item._uid} blok={item} />
          ))}
        </div>
      );
    })
    .with(Funding.DVA, () => (
      <div>
        {blok.dvaNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .with(Funding.WORK_COVER, () => (
      <div>
        {blok.workCoverNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .with(Funding.SELF_FUNDED, () => (
      <div>
        {blok.selfFundNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .with(Funding.NDIS, () => (
      <div>
        {blok.ndisNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .otherwise(() => <></>);
};
