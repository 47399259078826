import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { FeedbackCarouselStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import Slider from "react-slick";
import { getTestimonialsCarouselSettings } from "utils/settings.util";
import { DoubleQuotesStartSvg } from "assets/icons/DoubleQuotesStartSvg";
import { DoubleQuotesEndSvg } from "assets/icons/DoubleQuotesEndSvg";
import { cn } from "utils";

export const FeedbackCarousel = ({
  blok,
}: Props<FeedbackCarouselStoryblok>) => {
  const settings = getTestimonialsCarouselSettings();

  const quoteStyle = cn("absolute z-10 -mt-5 h-7 w-7 md:w-10 md:h-8");

  return (
    <div className="max-w-screen overflow-hidden -mx-4 sm:-mx-6 md:-mx-8 lg:-mx-12">
      <div
        style={{
          backgroundImage: `url(${blok.background?.filename})`,
          backgroundSize: "120% 80%",
          backgroundRepeat: "no-repeat",
        }}
        {...storyblokEditable(blok)}
      >
        <div className="w-full py-20 text-center">
          {blok.information?.map((item) => (
            <div key={item._uid} className="py-1">
              <StoryblokComponent key={item._uid} blok={item} />
            </div>
          ))}
        </div>

        <div className="block mb-12">
          <div className="flex">
            <DoubleQuotesStartSvg
              className={cn(
                quoteStyle,
                "left-[15%] xl:left-[5%] md:left-[10%]"
              )}
            />

            <DoubleQuotesEndSvg
              className={cn(
                quoteStyle,
                "right-[15%] xl:right-[5%] md:right-[10%]"
              )}
            />
          </div>
          <div {...storyblokEditable(blok)} className="-mx-[20%] sm:-mx-[10%]">
            <Slider {...settings}>
              {blok.items?.map((item, index) => (
                <StoryblokComponent key={item._uid} blok={item} index={index} />
              ))}
            </Slider>
          </div>

          <div className="px-5 pt-3">
            {blok.note && <span className="text-10">Note: {blok.note}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
