import { isString } from "lodash";
import { Helmet } from "react-helmet";
import { SeoTagsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const SeoTags = ({ blok }: Props<SeoTagsStoryblok>) => {
  const metaKeywords = blok?.keywords?.trim() ?? null;
  const canonicalUrl = blok?.canonical_url?.trim() ?? null;

  const {
    title,
    description,
    og_title,
    og_description,
    og_image,
    twitter_title,
    twitter_description,
    twitter_image,
  } = blok?.metatags || {};

  return (
    <Helmet>
      {/* General tags */}
      {title && <title>{title}</title>}

      {description && (
        <meta key="description" name="description" content={description} />
      )}

      {metaKeywords && isString(metaKeywords) && (
        <meta key="keywords" name="keywords" content={metaKeywords} />
      )}

      {/* Canonical URL */}
      {canonicalUrl && isString(canonicalUrl) && (
        <link key="canonical" rel="canonical" href={canonicalUrl} />
      )}

      {/* Open Graph tags */}
      {og_title && <meta property="og:title" content={og_title} />}
      {og_description && (
        <meta property="og:description" content={og_description} />
      )}
      {og_image && <meta property="og:image" content={og_image} />}

      {/* Twitter Card tags */}
      {twitter_title && <meta name="twitter:title" content={twitter_title} />}
      {twitter_description && (
        <meta name="twitter:description" content={twitter_description} />
      )}
      {twitter_image && <meta name="twitter:image" content={twitter_image} />}
    </Helmet>
  );
};
