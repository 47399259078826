type Props = {
  className: string;
};

export const DoubleQuotesEndSvg = ({className}: Props) => {
  return (
    <svg
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.9831 24.0647C14.2918 26.8248 12.2622 28.8949 9.89429 30.2749C8.0902 31.425 6.0888 32 3.89006 32C2.42424 32 1.69133 31.5975 1.69133 30.7925C1.69133 30.4474 2.00141 30.1599 2.62156 29.9299C5.10218 28.9524 7.07541 27.1698 8.54123 24.5822C9.72516 22.5121 10.4017 20.442 10.5708 18.372C10.2326 18.4295 9.72516 18.4582 9.04863 18.4582C3.01621 18.4582 0 15.3819 0 9.22911C0 3.07637 3.01621 0 9.04863 0C12.093 0 14.5455 1.03504 16.4059 3.10512C18.4919 5.29021 19.5349 8.22282 19.5349 11.903C19.5349 16.3306 18.351 20.3845 15.9831 24.0647ZM36.4482 24.0647C34.7569 26.8248 32.7273 28.8949 30.3594 30.2749C28.5553 31.425 26.5539 32 24.3552 32C22.8894 32 22.1564 31.5975 22.1564 30.7925C22.1564 30.4474 22.4665 30.1599 23.0867 29.9299C25.5673 28.9524 27.5405 27.1698 29.0063 24.5822C30.1903 22.5121 30.8668 20.442 31.0359 18.372C30.6977 18.4295 30.1903 18.4582 29.5137 18.4582C23.4813 18.4582 20.4651 15.3819 20.4651 9.22911C20.4651 3.07637 23.4813 0 29.5137 0C32.5581 0 35.0106 1.03504 36.871 3.10512C38.957 5.29021 40 8.22282 40 11.903C40 16.3306 38.8161 20.3845 36.4482 24.0647Z"
        fill="#502334"
      />
    </svg>
  );
};
