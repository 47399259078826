import { GlobalStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

export const Global = ({ blok }: Props<GlobalStoryblok>) => {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.body?.map((blok) => (
        <StoryblokComponent key={blok._uid} blok={blok} />
      ))}
    </main>
  );
};
