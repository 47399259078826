type Props = {};

export const InfoCircleSvg = (props: Props) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5C7.3125 10.5 7 10.8438 7 11.25C7 11.6875 7.3125 12 7.75 12H10.25C10.6562 12 11 11.6875 11 11.25C11 10.8438 10.6562 10.5 10.25 10.5H10V7.75C10 7.34375 9.65625 7 9.25 7H7.75C7.3125 7 7 7.34375 7 7.75C7 8.1875 7.3125 8.5 7.75 8.5H8.5V10.5H7.75ZM9 4C8.4375 4 8 4.46875 8 5C8 5.5625 8.4375 6 9 6C9.53125 6 10 5.5625 10 5C10 4.46875 9.53125 4 9 4Z"
        className="fill-secondary-darker hover:fill-primary"
      />
    </svg>
  );
};
