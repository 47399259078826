type Props = {
  className: string;
};

export const DoubleQuotesStartSvg = ({className}: Props) => {
  return (
    <svg
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.0169 7.93531C25.7082 5.1752 27.7378 3.10512 30.1057 1.72507C31.9098 0.575022 33.9112 0 36.1099 0C37.5758 0 38.3087 0.402515 38.3087 1.20755C38.3087 1.55256 37.9986 1.84007 37.3784 2.07008C34.8978 3.04762 32.9246 4.83019 31.4588 7.41779C30.2748 9.48787 29.5983 11.558 29.4292 13.628C29.7674 13.5705 30.2748 13.5418 30.9514 13.5418C36.9838 13.5418 40 16.6181 40 22.7709C40 28.9236 36.9838 32 30.9514 32C27.907 32 25.4545 30.965 23.5941 28.8949C21.5081 26.7098 20.4651 23.7772 20.4651 20.097C20.4651 15.6694 21.649 11.6155 24.0169 7.93531ZM3.5518 7.93531C5.24313 5.1752 7.27273 3.10512 9.64059 1.72507C11.4447 0.575022 13.4461 0 15.6448 0C17.1106 0 17.8436 0.402515 17.8436 1.20755C17.8436 1.55256 17.5335 1.84007 16.9133 2.07008C14.4327 3.04762 12.4595 4.83019 10.9937 7.41779C9.80972 9.48787 9.13319 11.558 8.96406 13.628C9.30232 13.5705 9.80972 13.5418 10.4863 13.5418C16.5187 13.5418 19.5349 16.6181 19.5349 22.7709C19.5349 28.9236 16.5187 32 10.4863 32C7.44186 32 4.98943 30.965 3.12896 28.8949C1.04299 26.7098 0 23.7772 0 20.097C0 15.6694 1.18393 11.6155 3.5518 7.93531Z"
        fill="#502334"
      />
    </svg>
  );
};
