import "./index.scss";

import { apiPlugin, storyblokInit } from "@storyblok/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AboutValueContainer } from "components/about-us/our-values/AboutValueContainer";
import { AboutValueItem } from "components/about-us/our-values/AboutValueItem";
import { AboutReferenceContainer } from "components/about-us/reference/AboutReferenceContainer";
import { AboutReferenceItem } from "components/about-us/reference/AboutReferenceItem";
import { Advantages } from "components/about-us/reference/Advantages";
import { Avatar } from "components/atoms/Avatar";
import { Badge } from "components/atoms/Badge";
import { Button } from "components/atoms/Button";
import { Card } from "components/atoms/Card";
import { CheckPoint } from "components/atoms/CheckPoint";
import { Checkbox } from "components/atoms/Checkbox";
import { DateSchedule } from "components/atoms/DatePicker";
import { EmbedVideo } from "components/atoms/EmbedVideo";
import { Grid } from "components/atoms/Grid";
import { Heading } from "components/atoms/Heading";
import { Hint } from "components/atoms/Hint";
import { Image } from "components/atoms/Image";
import { Label } from "components/atoms/Label";
import { Link } from "components/atoms/Link";
import { MultiSelectionDropdown } from "components/atoms/MultiSelectionDropdown";
import { Quote } from "components/atoms/Quote";
import { Radio } from "components/atoms/Radio";
import { RichText } from "components/atoms/RichText";
import { SeoTags } from "components/atoms/SeoTags";
import { SingleSelectionDropdown } from "components/atoms/SingleSelectionDropdown";
import { Spacing } from "components/atoms/Spacing";
import { Table } from "components/atoms/Table";
import { Text } from "components/atoms/Text";
import { ArticleContainer } from "components/blog/ArticleContainer";
import { ArticleReference } from "components/blog/ArticleReference";
import { ArticleReferencesContainer } from "components/blog/ArticleReferencesContainer";
import { ArticleRelatedContainer } from "components/blog/ArticleRelatedContainer";
import { BlogItem } from "components/blog/BlogItem";
import { BlogSection } from "components/blog/BlogSection";
import { PricingCardItem } from "components/card/pricing/PricingCardItem";
import { PsychologistCardItem } from "components/card/psychologist/PsychologistCardItem";
import { PsychologistCardSection } from "components/card/psychologist/PsychologistCardSection";
import { ErrorWrapper } from "components/errors/ErrorWrapper";
import { Faq } from "components/faq/Faq";
import { Question } from "components/faq/Question";
import { AuthGuard } from "components/forms/AuthGuard";
import { NoticeBox } from "components/forms/NoticeBox";
import { BlogSearchForm } from "components/forms/blog/BlogForm";
import { BookDVAContainer } from "components/forms/book-dva/BookDVAContainer";
import { BookDVAForm } from "components/forms/book-dva/BookDVAForm";
import { ConfirmBookingContainer } from "components/forms/book-finalise/ConfirmBookingContainer";
import { BookGPContainer } from "components/forms/book-gp/BookGPContainer";
import { BookGPForm } from "components/forms/book-gp/BookGPForm";
import { BookMedicareActions } from "components/forms/book-medicare/BookMedicareActions";
import { BookMedicareContainer } from "components/forms/book-medicare/BookMedicareContainer";
import { BookMedicareForm } from "components/forms/book-medicare/BookMedicareForm";
import { BookNDISContainer } from "components/forms/book-ndis/BookNDISContainer";
import { BookNDISForm } from "components/forms/book-ndis/BookNDISForm";
import { BookWorkCoverContainer } from "components/forms/book-workcover/BookWorkCoverContainer";
import { BookWorkCoverForm } from "components/forms/book-workcover/BookWorkCoverForm";
import { ContactUsActions } from "components/forms/contact-us/ContactUsActions";
import { ContactUsContainer } from "components/forms/contact-us/ContactUsContainer";
import { ContactUsForm } from "components/forms/contact-us/ContactUsForm";
import { CheckboxInputForm } from "components/forms/get-matched/CheckboxInputForm";
import { ContactInformationForm } from "components/forms/get-matched/ContactInformationForm";
import { QuestionStep } from "components/forms/get-matched/QuestionStep";
import { QuestionStepper } from "components/forms/get-matched/QuestionStepper";
import { LoginActionSidebar } from "components/forms/login/LoginActionSidebar";
import { LoginForm } from "components/forms/login/LoginForm";
import { SignupActions } from "components/forms/signup/SignupActions";
import { SignupForm } from "components/forms/signup/SignupForm";
import { SignupSection } from "components/forms/signup/SignupSection";
import { ThankYouPage } from "components/forms/thank-you/ThankYouPage";
import { MatchedBanner } from "components/get-matched/MatchedBanner";
import { Results } from "components/get-matched/Results";
import { Footer } from "components/global/Footer";
import { Global } from "components/global/Global";
import { GlobalReference } from "components/global/GlobalReference";
import { Header } from "components/global/Header";
import { ClinicalStandards } from "components/gp-referrals/ClinicalStandards";
import { ReferralTemplates } from "components/gp-referrals/ReferralTemplates";
import { ReferralTemplatesItems } from "components/gp-referrals/ReferralTemplatesItems";
import { Logo } from "components/header/Logo";
import { NavItem } from "components/header/NavItem";
import { NavItemDropdown } from "components/header/NavItemDropdown";
import { NavItemLogin } from "components/header/NavItemLogin";
import { HowItWorks } from "components/how-it-works/HowItWorks";
import { WorkItem } from "components/how-it-works/WorkItem";
import { JourneyItem } from "components/journey/JourneyItem";
import { GetAGpReferralJourney } from "components/journey/get-a-gp-referral/GetAGpReferralJourney";
import { GpRefferalsJourney } from "components/journey/gp-refferals/GpRefferalsJourney";
import { HowItWorksJourney } from "components/journey/how-it-works/HowItWorksJourney";
import { HowItWorksJourneyFlow } from "components/journey/how-it-works/HowItWorksJourneyFlow";
import { OurPsychologistList } from "components/our-psychologists/OurPsychologistList";
import { OurPsychologists } from "components/our-psychologists/OurPsychologists";
import { OurPsychologistsFilterBar } from "components/our-psychologists/OurPsychologistsFilterBar";
import { OurPsychologistsSearchBar } from "components/our-psychologists/OurPsychologistsSearchBar";
import { CardService } from "components/our-services/CardService";
import { ListService } from "components/our-services/ListService";
import { ServiceHeading } from "components/our-services/ServiceHeading";
import { ServicePricingItem } from "components/our-services/ServicePricingItem";
import { ServicePricingList } from "components/our-services/ServicePricingList";
import { RichTextPolicyContainer } from "components/policy/RichTextPolicyContainer";
import { PricingList } from "components/pricing/PricingList";
import { PsychologistDetailFunctions } from "components/psychologist-detail/PsychologistDetailFunctions";
import { Banner } from "components/stats/Banner";
import { Title } from "components/stats/BannerTitle";
import { Description } from "components/stats/Description";
import { StatItem } from "components/stats/StatItem";
import { Stats } from "components/stats/Stats";
import { ComparisonTable } from "components/table/ComparisonTable";
import { PricingTable } from "components/table/PricingTable";
import { PricingTableCell } from "components/table/PricingTableCell";
import { PricingTableRow } from "components/table/PricingTableRow";
import { TagsFrame } from "components/tags-frame/TagsFrame";
import { StraightTimeLineItem } from "components/timeline/StraightTimeLineItem";
import { StraightTimeLineSection } from "components/timeline/StraightTimeLineSection";
import { FeedbackCard } from "components/user-feedback/FeedbackCard";
import { FeedbackCarousel } from "components/user-feedback/FeedbackCarousel";
import { InlineHeading } from "components/user-feedback/InlineHeading";
import { RichTextHeading } from "components/user-feedback/RichTextHeading";
import { SearchedHelpWithPsychologists } from "components/what-we-treat/SearchedHelpWithPsychologists";
import { Treatment } from "components/what-we-treat/Treatment";
import { TreatmentConditions } from "components/what-we-treat/TreatmentConditions";
import { TreatmentDetail } from "components/what-we-treat/TreatmentDetail";
import { AuthProvider } from "provider/AuthProvider";
import { ScriptsProvider } from "provider/ScriptsProvider";
import { ToasterProvider } from "provider/ToasterProvider";
import ReactDOM from "react-dom/client";
import { Routes as RoutePaths } from "routes/main.routes";
import { Container } from "stories/Container";
import { Page } from "stories/Page";
import App from "./App";
import { PsychologistDetail } from "./components/psychologist-detail/PsychologistDetail";
import { PsychologistDetailBanner } from "./components/psychologist-detail/PsychologistDetailBanner";
import { PsychologistDetailExperience } from "./components/psychologist-detail/PsychologistDetailExperience";
import { PsychologistDetailFinancialPayment } from "./components/psychologist-detail/PsychologistDetailFinancialPayment";
import { PsychologistDetailHelpWith } from "./components/psychologist-detail/PsychologistDetailHelpWith";
import { PsychologistDetailInfo } from "./components/psychologist-detail/PsychologistDetailInfo";
import { PsychologistDetailIntroduce } from "./components/psychologist-detail/PsychologistDetailIntroduce";
import { PsychologistDetailPractice } from "./components/psychologist-detail/PsychologistDetailPractice";
import { PsychologistDetailQuote } from "./components/psychologist-detail/PsychologistDetailQuote";
import { PsychologistDetailServices } from "./components/psychologist-detail/PsychologistDetailServices";
import { PsychologistDetailTrainedIn } from "./components/psychologist-detail/PsychologistDetailTrainedIn";
import reportWebVitals from "./reportWebVitals";

import AOS from "aos";
import { seoRedirectionUrls } from "routes/redirection.routes";
import { v4 as GUID } from "uuid";
import { ExternalRedirect } from "routes/ExternalRedirect";

AOS.init();

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    // STORIES
    page: Page,
    container: Container,

    // REFERENCES
    global_reference: GlobalReference,
    header: Header,
    footer: Footer,
    global: Global,

    // COMMON
    errorWrapper: ErrorWrapper,

    // COMPONENTS
    headerLogo: Logo,
    headerNavItem: NavItem,
    headerNavItemDropdown: NavItemDropdown,
    tagsFrame: TagsFrame,
    howItWorks: HowItWorks,
    workItem: WorkItem,
    comparisonTable: ComparisonTable,
    psychologistCardSection: PsychologistCardSection,
    psychologistCardItem: PsychologistCardItem,
    banner: Banner,
    description: Description,
    stats: Stats,
    statItem: StatItem,
    title: Title,
    richTextHeading: RichTextHeading,
    feedbackCard: FeedbackCard,
    feedbackCarousel: FeedbackCarousel,
    inlineHeading: InlineHeading,
    faq: Faq,
    question: Question,
    psychologistDetail: PsychologistDetail,
    psychologistDetailBanner: PsychologistDetailBanner,
    psychologistDetailInfo: PsychologistDetailInfo,
    psychologistDetailIntroduce: PsychologistDetailIntroduce,
    psychologistDetailHelpWith: PsychologistDetailHelpWith,
    psychologistDetailFinancialPayment: PsychologistDetailFinancialPayment,
    psychologistDetailQuote: PsychologistDetailQuote,
    psychologistDetailExperience: PsychologistDetailExperience,
    psychologistDetailTrainedIn: PsychologistDetailTrainedIn,
    psychologistDetailServices: PsychologistDetailServices,
    psychologistDetailPractice: PsychologistDetailPractice,
    psychologistDetailFunctions: PsychologistDetailFunctions,
    advantages: Advantages,
    badge: Badge,
    treatmentConditions: TreatmentConditions,
    treatment: Treatment,
    listService: ListService,
    cardService: CardService,
    treatmentDetail: TreatmentDetail,
    serviceHeading: ServiceHeading,
    servicePricingList: ServicePricingList,
    servicePricingItem: ServicePricingItem,
    pricingCardItem: PricingCardItem,
    straightTimeLineSection: StraightTimeLineSection,
    straightTimeLineItem: StraightTimeLineItem,
    referralTemplates: ReferralTemplates,
    referralTemplatesItems: ReferralTemplatesItems,
    clinicalStandards: ClinicalStandards,
    ourPsychologists: OurPsychologists,
    ourPsychologistsSearchBar: OurPsychologistsSearchBar,
    ourPsychologistsFilterBar: OurPsychologistsFilterBar,
    ourPsychologistList: OurPsychologistList,
    noticeBox: NoticeBox,
    signupSection: SignupSection,
    signupActions: SignupActions,
    signupForm: SignupForm,
    authGuard: AuthGuard,
    loginActionSidebar: LoginActionSidebar,
    bookMedicareContainer: BookMedicareContainer,
    bookMedicareForm: BookMedicareForm,
    bookMedicareActions: BookMedicareActions,
    pricingList: PricingList,
    pricingTable: PricingTable,
    pricingTableRow: PricingTableRow,
    pricingTableCell: PricingTableCell,
    finaliseBookingContainer: ConfirmBookingContainer,
    loginForm: LoginForm,
    richTextPolicyContainer: RichTextPolicyContainer,
    headerNavItemLogin: NavItemLogin,
    contactUsContainer: ContactUsContainer,
    contactUsForm: ContactUsForm,
    contactUsActions: ContactUsActions,
    thankYouPage: ThankYouPage,
    blogItem: BlogItem,
    blogSection: BlogSection,
    blogSearchForm: BlogSearchForm,
    bookNDISContainer: BookNDISContainer,
    bookNDISForm: BookNDISForm,
    journeyItem: JourneyItem,
    howItWorksJourney: HowItWorksJourney,
    howItWorksJourneyFlow: HowItWorksJourneyFlow,
    bookWorkCoverForm: BookWorkCoverForm,
    bookWorkCoverContainer: BookWorkCoverContainer,
    aboutReferenceContainer: AboutReferenceContainer,
    aboutReferenceItem: AboutReferenceItem,
    getAGpReferralJourney: GetAGpReferralJourney,
    gpRefferalsJourney: GpRefferalsJourney,
    aboutValueContainer: AboutValueContainer,
    aboutValueItem: AboutValueItem,
    articleContainer: ArticleContainer,
    articleReferencesContainer: ArticleReferencesContainer,
    articleReference: ArticleReference,
    articleRelatedContainer: ArticleRelatedContainer,
    bookDVAContainer: BookDVAContainer,
    bookDVAForm: BookDVAForm,
    bookGPContainer: BookGPContainer,
    bookGPForm: BookGPForm,
    questionStepper: QuestionStepper,
    questionStep: QuestionStep,
    checkboxInputForm: CheckboxInputForm,
    results: Results,
    contactInformationForm: ContactInformationForm,
    searchedHelpWithPsychologists: SearchedHelpWithPsychologists,

    // ATOMS
    button: Button,
    dateSchedule: DateSchedule,
    text: Text,
    heading: Heading,
    image: Image,
    link: Link,
    avatar: Avatar,
    card: Card,
    checkbox: Checkbox,
    radio: Radio,
    hint: Hint,
    label: Label,
    quote: Quote,
    richText: RichText,
    matchedBanner: MatchedBanner,
    singleSelectionDropdown: SingleSelectionDropdown,
    multiSelectionDropdown: MultiSelectionDropdown,
    checkpoint: CheckPoint,
    spacing: Spacing,
    grid: Grid,
    embedVideo: EmbedVideo,
    table: Table,
    seoTags: SeoTags,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ToasterProvider>
    <ScriptsProvider>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="*" element={<App />} />

            {seoRedirectionUrls.map(({ from, to }) => (
              <Route
                key={GUID()}
                path={from}
                element={<Navigate replace to={to} />}
              />
            ))}

            <Route
              path="/careers"
              element={<ExternalRedirect to="https://health-bright.com.au" />}
            />

            <Route path={RoutePaths.NOT_FOUND} element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ScriptsProvider>
  </ToasterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
