import { RichTextResolver, storyblokEditable } from "@storyblok/react";

import { Props } from "@/types/core";
import { RichTextHeadingStoryblok } from "@/types/component-types-sb";
import { getHeadingStyles } from "utils";

export const RichTextHeading = ({ blok }: Props<RichTextHeadingStoryblok>) => {
  const content = new RichTextResolver().render(blok.text);
  const headingStyles = getHeadingStyles(blok);

  return (
    <div
      {...storyblokEditable(blok)}
      style={{
        width: `${blok.width}%`,
      }}
      className={headingStyles}
    >
      <div
        className="text-primary"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
};
