import { StoryblokComponent } from "@storyblok/react";
import { PricingTableCellStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const PricingTableCell = ({
  blok,
}: Props<PricingTableCellStoryblok>) => {
  return (
    <div className="flex gap-1 whitespace-nowrap">
      {blok.content}

      {blok.hint && (
        <span className="-mt-2">
          {blok.hint?.map((hint) => (
            <StoryblokComponent key={hint._uid} blok={hint} />
          ))}
        </span>
      )}
    </div>
  );
};
