import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isNil } from "lodash";
import { ErrorWrapperStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const ErrorWrapper = ({ blok }: Props<ErrorWrapperStoryblok>) => {
  return (
    <div
      className="w-full flex flex-col items-center gap-y-10 py-20"
      {...storyblokEditable(blok)}
    >
      <div>
        <img src={blok.image?.filename} alt={blok.image?.alt} />
      </div>

      <div className="flex flex-col gap-y-5 items-center">
        <h1 className="text-primary text-2xl sm:text-4xl font-medium">
          {blok.title}
        </h1>
        <p className="text-dark-grey text-sm sm:text-15 text-center">
          {blok.description}
        </p>

        {!isNil(blok?.actions) && (
          <div className="flex flex-col sm:flex-row items-center gap-6 mt-5">
            {blok.actions.map((action) => (
              <StoryblokComponent key={action._uid} blok={action} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
