import { StoryblokComponent } from "@storyblok/react";
import { isEmpty, isEqual } from "lodash";
import { StraightTimeLineItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

export const StraightTimeLineItem = ({
  blok,
  index,
  maxIndex,
}: Props<StraightTimeLineItemStoryblok> & {
  index: number;
  maxIndex: number;
}) => {
  const isLeftHandSide = index % 2 !== 0;

  return (
    <div
      className={cn({
        "border border-transparent border-l-secondary-darker": !isEqual(
          index,
          maxIndex
        ),
      })}
    >
      <div className="absolute w-3 h-3 bg-primary rounded-full -start-1.5"></div>
      <li
        className={cn("relative -top-4 ms-8 me-8", {
          "relative -left-full": isLeftHandSide,
          "mb-8 md:mb-16 lg:mb-32": !isEqual(index, maxIndex),
        })}
      >
        <div className="flex flex-col gap-2 md:gap-4">
          <div
            className={cn({
              "flex items-center gap-1": !isEmpty(blok?.title),
              "justify-end text-right": !isEmpty(blok?.title) && isLeftHandSide,
            })}
          >
            {blok.title?.map((content) => (
              <StoryblokComponent key={content._uid} blok={content} />
            ))}
          </div>

          <div
            className={cn({
              "flex gap-1": !isEmpty(blok?.description),
              "justify-end": !isEmpty(blok?.description) && isLeftHandSide,
            })}
          >
            {blok.description?.map((content) => (
              <StoryblokComponent key={content._uid} blok={content} />
            ))}
          </div>
        </div>
      </li>
    </div>
  );
};
