import { storyblokEditable } from "@storyblok/react";
import { defaultTo } from "lodash";
import { SpacingStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const Spacing = ({ blok }: Props<SpacingStoryblok>) => {
  return (
    <div
      style={{
        width: defaultTo(blok?.width, 0) + "px",
        height: defaultTo(blok?.height, 0) + "px",
      }}
      {...storyblokEditable(blok)}
    />
  );
};
