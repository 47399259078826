import { StoryblokComponent } from "@storyblok/react";
import { ServicePricingListStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const ServicePricingList = ({
  blok,
}: Props<ServicePricingListStoryblok>) => {
  return (
    <div className="flex flex-wrap xl:flex-nowrap justify-center gap-6 lg:gap-10 px-4 lg:px-16 my-8 lg:my-16 bg-light-grey py-14">
      {blok.pricingItems?.map((pricingItem) => (
        <StoryblokComponent key={pricingItem._uid} blok={pricingItem} />
      ))}
    </div>
  );
};
