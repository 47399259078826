import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { HintStoryblok } from "types/component-types-sb";

import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { BookBehalfOfType } from "enums";
import { SelectionOption } from "models";
import { defaultStateOptions } from "utils";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { BookDVAFormData } from "../book-dva/BookDVAForm";
import { SelfFundConfirmBookingFormData } from "../book-finalise/ConfirmBookingSelfFundForm";
import { BookMedicareChildFormData } from "../book-medicare/BehalfOfChildForm";
import { BookMedicareMySelfFormData } from "../book-medicare/BehalfOfMySelfForm";
import { BookMedicareSomeOneFormData } from "../book-medicare/BehalfOfSomeOneForm";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { BookNDISFormData } from "../book-ndis/BookNDISForm";
import { BookWorkCoverFormData } from "../book-workcover/BookWorkCoverForm";
import { MedicareChildFieldsType } from "./MedicareChildFields";
import { MedicareMySelfFieldsType } from "./MedicareMySelfFields";
import { MedicareSomeOneFieldsType } from "./MedicareSomeOneFields";

type Props<T extends FieldValues> = {
  control: Control<T>;
  errors: FieldErrors<T>;

  streetAddressHints?: HintStoryblok[];
  behalfOfType?: BookBehalfOfType;
};

export const AddressFields = <
  T extends
    | BookMedicareSomeOneFormData
    | BookMedicareMySelfFormData
    | BookMedicareChildFormData
    | BookNDISFormData
    | BookWorkCoverFormData
    | BookDVAFormData
    | MedicareMySelfFieldsType
    | MedicareSomeOneFieldsType
    | MedicareChildFieldsType
    | SelfFundConfirmBookingFormData
>({
  control,
  errors,
  streetAddressHints,
  behalfOfType,
  ...props
}: Props<T>) => {
  const isBehalfOfSomeOneElse =
    behalfOfType === BookBehalfOfType.SOMEONE_ELSE_ADULT;

  return (
    <div className="flex flex-col gap-y-3">
      <Controller
        name={"streetAddress" as FieldPath<T>}
        control={control}
        render={({ field }) => (
          <div className="flex flex-col flex-1">
            <TextInput
              {...field}
              title={
                isBehalfOfSomeOneElse
                  ? "Their street address"
                  : "Street address"
              }
              hintElement={getHintFromStoryBlok(streetAddressHints)}
              onChangeValue={field.onChange}
            />

            <TextError fieldError={errors.streetAddress as FieldError} />
          </div>
        )}
      />

      <Controller
        name={"streetLineTwoAddress" as FieldPath<T>}
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            title="Street address, line 2"
            onChangeValue={field.onChange}
          />
        )}
      />

      <div className="flex flex-col items-stretch justify-center md:items-start md:flex-row gap-y-3 md:gap-x-5">
        <Controller
          name={"city" as FieldPath<T>}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col flex-1 min-w-[110px]">
              <TextInput
                {...field}
                title="City / Suburb"
                onChangeValue={field.onChange}
              />

              <TextError fieldError={errors.city as FieldError} />
            </div>
          )}
        />

        <Controller
          name={"state" as FieldPath<T>}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col flex-1 min-w-[100px]">
              <SelectionDropdown
                isLoading={false}
                title={"State"}
                placeHolder={"Select ..."}
                hintComponent={<></>}
                selectedValues={defaultStateOptions.filter(
                  ({ value }) => value === field.value
                )}
                options={defaultStateOptions}
                onChangeSingleSelection={(selectedOption: SelectionOption) => {
                  field.onChange(selectedOption.value);
                }}
                isShowTitle
                isShowHint
                isRequired
              />

              <TextError fieldError={errors.state as FieldError} />
            </div>
          )}
        />

        <Controller
          name={"postcode" as FieldPath<T>}
          control={control}
          render={({ field }) => (
            <div className="flex flex-col flex-1">
              <TextInput
                {...field}
                title="Postcode"
                numberOnly
                onChangeValue={field.onChange}
              />

              <TextError fieldError={errors.postcode as FieldError} />
            </div>
          )}
        />
      </div>
    </div>
  );
};
