import { QuestionStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo, isEmpty } from "lodash";
import { useToggle } from "react-use";

import { ArrowSvg } from "assets/icons/ArrowSvg";
import { ChevronSvg } from "assets/icons/ChevronSvg";
import { Routes } from "routes/main.routes";
import { cn, openInNewTab } from "utils";

type QuestionCustomProps = Props<QuestionStoryblok> & {
  isSubQuestion?: boolean;
  contentLineClamp?: string;
};

export const Question = ({
  blok,
  isSubQuestion = false,
  contentLineClamp,
}: QuestionCustomProps) => {
  const isValidAccordion =
    !isEmpty(blok.description) ||
    !isEmpty(blok.content) ||
    !isEmpty(blok.sub_questions);

  const [isOpen, toggleIsOpen] = useToggle(false);

  const handleToggleAccordion = () => {
    if (!isValidAccordion) return;

    toggleIsOpen();
  };

  return (
    <div
      className={cn(
        "border-secondary",
        isOpen || isSubQuestion ? "border-b-0" : "border-b-2"
      )}
      {...storyblokEditable(blok)}
    >
      <h2>
        <button
          className={cn(
            "flex items-center justify-between w-full font-medium text-left px-1.5 py-3"
          )}
          onClick={handleToggleAccordion}
          aria-expanded={isOpen}
        >
          <span className="text-15 leading-normal line-clamp-2">
            {blok.title}
          </span>

          <ArrowSvg
            className={cn(
              "w-3 h-3 duration-300 ease-in-out shrink-0",
              isOpen ? "rotate-0 text-primary" : "rotate-180 text-secondary"
            )}
            color="black"
          />
        </button>
      </h2>
      <div
        className={cn(
          "grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out",
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        )}
      >
        <div
          className={cn(
            "flex flex-col justify-center items-start overflow-hidden bg-light-grey tracking-wide",
            !isSubQuestion && "border-t-2 border-b-2"
          )}
        >
          {!isEmpty(blok.description) && (
            <p className="px-3 pt-3 leading-relaxed text-primary text-14 font-medium">
              {blok.description}
            </p>
          )}

          <div className="p-3 text-gray-900">
            {blok.content?.map((item) => {
              item.lineClamp = contentLineClamp;
              item.fontSize = "text-13";

              return <StoryblokComponent key={item._uid} blok={item} />;
            })}
          </div>

          {!isEmpty(blok.readMoreUrl?.cached_url) && (
            <div
              className="pl-3 pb-3"
              onClick={() =>
                openInNewTab(
                  defaultTo(blok.readMoreUrl?.cached_url, Routes.PLACEHOLDER)
                )
              }
            >
              <span className="text-primary cursor-pointer font-medium underline flex items-center justify-center gap-1 text-sm">
                Read more
                <span className="-rotate-90">
                  <ChevronSvg />
                </span>
              </span>
            </div>
          )}

          <div className="px-3">
            {blok?.sub_questions?.map((subQuestion) => (
              <StoryblokComponent
                key={subQuestion._uid}
                blok={subQuestion}
                isSubQuestion
                contentLineClamp={contentLineClamp}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
