import { StoryblokComponent } from "@storyblok/react";
import { DEFAULT_AFTER_REBATE_COST, DEFAULT_COST } from "core/booking.constant";
import { HeadingLevels, MedicareRole } from "enums";
import { isEqual } from "lodash";
import { Funding } from "models";
import { match } from "ts-pattern";
import {
  FinaliseBookingContainerStoryblok,
  HeadingStoryblok,
} from "types/component-types-sb";
import {
  formatDollar,
  getAppointmentRate,
  pronounsDisplayOptions,
  psychologistRoleDisplayOptions,
} from "utils";
import {
  getStoredFundingMethod,
  getStoredReserveData,
} from "utils/storage.util";
import { v4 as GUID } from "uuid";
import { AppointmentSummarySection } from "./AppointmentSummarySection";
import { ConfirmBookingDefaultForm } from "./ConfirmBookingDefaultForm";
import { ConfirmBookingSelfFundForm } from "./ConfirmBookingSelfFundForm";
import { SelfFundCartSummarySection } from "./SelfFundCartSummarySection";
import { PsychologistResponse } from "services/psychologist.service";

export const renderConfirmBookingContainerHeading = () => {
  const fundingType = getStoredFundingMethod();
  const headingContent = isEqual(fundingType, Funding.SELF_FUNDED)
    ? "Summary"
    : "Emergency contact";

  const heading: HeadingStoryblok = {
    _uid: GUID(),
    component: "heading",
    level: HeadingLevels.H1,
    content: headingContent,
    fontWeight: "font-medium",
  };

  return <StoryblokComponent key={heading._uid} blok={heading} />;
};

export const renderConfirmBookingSummarySection = () => {
  const fundingType = getStoredFundingMethod();

  return match(fundingType)
    .with(Funding.SELF_FUNDED, () => <SelfFundCartSummarySection />)
    .otherwise(() => <AppointmentSummarySection />);
};

export const renderConfirmBookingForm = (
  blok: FinaliseBookingContainerStoryblok,
  reserveId: string,
  accessToken: string
) => {
  const fundingType = getStoredFundingMethod();

  return match(fundingType)
    .with(Funding.SELF_FUNDED, () => (
      <ConfirmBookingSelfFundForm
        blok={blok}
        reserveId={reserveId}
        accessToken={accessToken}
      />
    ))
    .otherwise(() => (
      <ConfirmBookingDefaultForm blok={blok} reserveId={reserveId} />
    ));
};

export const renderConfirmBookingRichtextNotice = (
  blok: FinaliseBookingContainerStoryblok
) => {
  const fundingType = getStoredFundingMethod();

  return match(fundingType)
    .with(Funding.BULK_BILL, () => (
      <div>
        {blok.medicareBulkBillNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .with(Funding.REBATE, () => {
      const reserveData = getStoredReserveData();
      const psychologistRole = reserveData?.psychologist?.medicare
        ?.role as MedicareRole;

      return match(psychologistRole)
        .with(MedicareRole.ClinicalPsychologists, () => (
          <div>
            {blok.medicareRebateClinicalNotice?.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}
          </div>
        ))
        .with(MedicareRole.RegisteredPsychologists, () => (
          <div>
            {blok.medicareRebateGeneralNotice?.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}
          </div>
        ))
        .otherwise(() => <></>);
    })
    .with(Funding.NDIS, () => (
      <div>
        {blok.nonMedicareNotice?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    ))
    .otherwise(() => <></>);
};

export const renderPsychologistDisplayRole = (
  role: MedicareRole | string
): string => {
  if (!role) return "";

  return (
    psychologistRoleDisplayOptions.find((opt) => isEqual(opt.value, role))
      ?.label ?? ""
  );
};

export const renderPsychologistPronouns = (
  psychologist: PsychologistResponse
) => {
  const pronounsLabel = pronounsDisplayOptions.find((option) =>
    isEqual(option?.value, psychologist?.pronouns)
  )?.label;

  return pronounsLabel || "Other";
};

export const renderAppointmentCost = () => {
  const fundingType = getStoredFundingMethod();
  return match(fundingType)
    .with(Funding.REBATE, () => {
      const defaultAfterRebateCost = (
        <div>After rebate: {formatDollar(DEFAULT_AFTER_REBATE_COST, 0, 0)}</div>
      );
      const plusBankFee = <span className="text-11">+ bank fee</span>;
      const apptRate = getAppointmentRate();

      return (
        <div>
          <div>
            Upfront: {formatDollar(apptRate)} {plusBankFee}
          </div>
          {defaultAfterRebateCost}
        </div>
      );
    })
    .with(Funding.SELF_FUNDED, () => {
      const apptRate = getAppointmentRate();

      return <div>{formatDollar(apptRate)}</div>;
    })
    .otherwise(() => <>{formatDollar(DEFAULT_COST, 0, 0)}</>);
};
