import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ContactUsContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const ContactUsContainer = ({
  blok,
}: Props<ContactUsContainerStoryblok>) => {
  return (
    <div className="flex flex-col justify-between gap-4 px-0 py-12 md:flex-row md:gap-8 lg:gap-16 lg:px-24 xl:px-36" {...storyblokEditable(blok)}>
      {blok.sections?.map((section) => (
        <StoryblokComponent key={section._uid} blok={section} />
      ))}
    </div>
  );
};
