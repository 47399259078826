import { CheckSvgDataUrl } from "assets/icons/CheckSvg";
import { ControllerRenderProps } from "react-hook-form";
import { CheckboxStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";
import { v4 } from "uuid";

export type CustomProps = Props<CheckboxStoryblok> & {
  value: string;
  field: ControllerRenderProps;

  isMultipleSelect?: boolean;

  onCheck: (name: string) => void;
};

export const Checkbox = ({
  blok,
  field,
  value,
  isMultipleSelect,
  onCheck,
}: CustomProps) => {
  const dynamicId = v4();

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event?.target || {};

    if (checked) {
      onCheck(field.name);
      field.onChange(value);

      return;
    }

    field.onChange("");
  };

  return (
    <div className="flex items-center cursor-pointer me-2">
      <input
        type="checkbox"
        id={dynamicId}
        {...field}
        value={value}
        checked={field?.value}
        onChange={handleChangeCheckbox}
        className={cn(
          "w-6 h-6 border cursor-pointer border-secondary-darker",
          "focus:ring-2 focus:ring-secondary-darker",
          "checked:bg-light checked:border-secondary-darker",
          isMultipleSelect ? "rounded-[4px]" : "rounded-full"
        )}
        style={{
          backgroundImage: field?.value ? `url("${CheckSvgDataUrl}")` : "",
          backgroundSize: "15px 15px",
        }}
      />
      <label
        htmlFor={dynamicId}
        className="font-normal text-15 ms-2 text-dark dark:text-neutral"
      >
        {blok.title}
      </label>
    </div>
  );
};
