import { useAuth0 } from "@auth0/auth0-react";
import { storyblokEditable } from "@storyblok/react";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { BookMedicareContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import {
  renderSessionBookingWarning,
  validateStoredBookingData,
} from "utils/warning.util";
import { AppointmentSummarySection } from "../book-finalise/AppointmentSummarySection";
import { BookMedicareForm } from "./BookMedicareForm";
import { useState } from "react";
import { calculateAdditionStickyHeight } from "./booking.medicare.util";
import { useWindowSize } from "react-use";

export const BookMedicareContainer = ({
  blok,
}: Props<BookMedicareContainerStoryblok>) => {
  const { isAuthenticated } = useAuth0();
  useSwrMyProfile();

  const { width: windowWidth } = useWindowSize();
  const [stickyHeight, setStickyHeight] = useState<number>(0);

  const handleStickyHeightFunction = (height: number) => {
    setStickyHeight(height);
  };

  const validateStoredDataResults = validateStoredBookingData({
    shouldCheckSignedUpData: !isAuthenticated,
  });

  if (!validateStoredDataResults.isEligible) {
    return renderSessionBookingWarning(validateStoredDataResults);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col lg:flex-row gap-12 px-0 xl:px-20 py-10 max-md:pt-0"
    >
      {/* MEDICARE FORM */}
      <div className="flex flex-[2] order-2 lg:order-1">
        {blok.forms?.map((formBlok) => (
          <BookMedicareForm
            key={formBlok._uid}
            blok={formBlok}
            actionBlok={blok.actions}
          />
        ))}
      </div>

      {/* MEDICARE ACTIONS */}
      <div className="flex flex-1 order-1 lg:order-2 flex-col gap-y-3">
        <div
          className="sticky"
          style={{
            top: calculateAdditionStickyHeight(windowWidth, stickyHeight),
          }}
        >
          <AppointmentSummarySection
            retrieveStickyHeightFunction={handleStickyHeightFunction}
          />
        </div>
      </div>
    </div>
  );
};
