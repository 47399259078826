import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  useWatch,
} from "react-hook-form";
import { BookMedicareFormStoryblok } from "types/component-types-sb";

import { BookBehalfOfType, ConfirmRadioType, ProvideReferralType } from "enums";
import { isArray } from "lodash";
import { FileItem } from "models";
import { match } from "ts-pattern";
import { FileInput } from "../FileInput";
import { RadioInput } from "../RadioInput";
import { TextError } from "../TextError";
import { BookMedicareChildFormData } from "../book-medicare/BehalfOfChildForm";
import { BookMedicareMySelfFormData } from "../book-medicare/BehalfOfMySelfForm";
import { BookMedicareSomeOneFormData } from "../book-medicare/BehalfOfSomeOneForm";
import {
  MAX_UPLOAD_GP_FILE,
  MAX_UPLOAD_HEALTH_CARE_PLAN_FILE,
  getHintFromStoryBlok,
  validFileTypes,
} from "../book-medicare/BookMedicareForm";

export type UploadFieldsType = {
  isHaveGPReferralAndTreatmentPlan: string;
  provideReferral: string;
  videoAppointmentWithBulkBillGP: string;
  gPReferralList?: FileItem[];
  treatmentPlanList?: FileItem[];
};

type Props<T extends FieldValues> = {
  control: Control<T>;
  errors: FieldErrors<T>;

  bookMedicareFormBlok: BookMedicareFormStoryblok;
  behalfOfType: BookBehalfOfType;
};

export const UploadFields = <
  T extends
    | BookMedicareSomeOneFormData
    | BookMedicareMySelfFormData
    | BookMedicareChildFormData
>({
  control,
  errors,
  bookMedicareFormBlok,
  behalfOfType,
  ...props
}: Props<T>) => {
  const {
    isHaveGPReferralAndTreatmentPlan,
    videoAppointmentWithBulkBillGP,
    provideReferral,
  } = useWatch({ control });

  const isNoReferral = isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.NO;
  const isVideoWithBulkBillGP =
    videoAppointmentWithBulkBillGP === ConfirmRadioType.YES;
  const isProvideReferralUpload =
    provideReferral === ProvideReferralType.UPLOAD;
  const isProvideReferralSentTo =
    provideReferral === ProvideReferralType.SENT_TO;

  const isHideUploadInputs =
    isNoReferral || (!isNoReferral && isProvideReferralSentTo);

  const getHaveAGPQuestion = (behalfOf: BookBehalfOfType) => {
    return match(behalfOf)
      .with(
        BookBehalfOfType.MY_SELF,
        () => "Do you have a GP referral and mental health treatment plan?"
      )
      .with(
        BookBehalfOfType.SOMEONE_ELSE_ADULT,
        () => "Do they have a GP referral and mental health treatment plan?"
      )
      .with(
        BookBehalfOfType.MY_CHILD,
        () =>
          "Does your child have a GP referral and mental health treatment plan?"
      )
      .otherwise(() => "");
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-5">
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-row items-center justify-between">
            <span>{getHaveAGPQuestion(behalfOfType)}</span>
            {getHintFromStoryBlok(bookMedicareFormBlok.haveGPReferralHints)}
          </div>

          <Controller
            name={"isHaveGPReferralAndTreatmentPlan" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-row gap-x-5">
                <RadioInput
                  {...field}
                  label="Yes"
                  value={ConfirmRadioType.YES}
                  checked={!isNoReferral}
                />

                <RadioInput
                  {...field}
                  label="No"
                  value={ConfirmRadioType.NO}
                  checked={isNoReferral}
                />
              </div>
            )}
          />
        </div>

        {isNoReferral ? (
          <div className="flex flex-col gap-y-3">
            <div className="flex flex-row items-center justify-start">
              <span>
                Would you like to make a video appointment with our bulk billed
                GP?
              </span>
            </div>

            <Controller
              name={"videoAppointmentWithBulkBillGP" as FieldPath<T>}
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-x-5">
                  <RadioInput
                    {...field}
                    label="Yes"
                    value={ConfirmRadioType.YES}
                    checked={isVideoWithBulkBillGP}
                  />

                  <RadioInput
                    {...field}
                    label="No"
                    value={ConfirmRadioType.NO}
                    checked={!isVideoWithBulkBillGP}
                  />
                </div>
              )}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-y-3">
            <div className="flex flex-row items-center justify-start">
              <span>How can you provide your referral documents to us?</span>
            </div>

            <Controller
              name={"provideReferral" as FieldPath<T>}
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-x-5">
                  <RadioInput
                    {...field}
                    label="I have them ready to upload"
                    value={ProvideReferralType.UPLOAD}
                    checked={isProvideReferralUpload}
                  />

                  <RadioInput
                    {...field}
                    label="My GP has sent them to you"
                    value={ProvideReferralType.SENT_TO}
                    checked={isProvideReferralSentTo}
                  />
                </div>
              )}
            />
          </div>
        )}
      </div>

      {!isHideUploadInputs && (
        <div className="flex flex-col gap-y-3">
          <Controller
            name={"gPReferralList" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-col flex-1">
                <FileInput
                  title="GP Referral"
                  validFileTypes={validFileTypes}
                  maxUploaded={MAX_UPLOAD_GP_FILE}
                  selectedFiles={(field.value as FileItem[]) || []}
                  onChangeFiles={(selectedFiles: FileItem[]) => {
                    if (!isArray(field.value)) return;

                    field.onChange([
                      ...(field.value as FileItem[]),
                      ...selectedFiles,
                    ]);
                  }}
                  onRemoveFile={(fileId: string) => {
                    if (!isArray(field.value)) return;

                    field.onChange(
                      field.value.filter(({ id }) => id !== fileId)
                    );
                  }}
                />

                <TextError fieldError={errors.gPReferralList as FieldError} />
              </div>
            )}
          />

          <Controller
            name={"treatmentPlanList" as FieldPath<T>}
            control={control}
            render={({ field }) => (
              <div className="flex flex-col flex-1">
                <FileInput
                  title="Mental Health Care Plan (MHCP)"
                  validFileTypes={validFileTypes}
                  maxUploaded={MAX_UPLOAD_HEALTH_CARE_PLAN_FILE}
                  selectedFiles={(field.value as FileItem[]) || []}
                  onChangeFiles={(selectedFiles: FileItem[]) => {
                    if (!isArray(field.value)) return;

                    field.onChange([
                      ...(field.value as FileItem[]),
                      ...selectedFiles,
                    ]);
                  }}
                  onRemoveFile={(fileId: string) => {
                    if (!isArray(field.value)) return;

                    field.onChange(
                      field.value.filter(({ id }) => id !== fileId)
                    );
                  }}
                />
                <TextError
                  fieldError={errors.treatmentPlanList as FieldError}
                />
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};
