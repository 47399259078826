type Props = {
  className?: string;
};

export const TooltipCloseSvg = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "16px" }}
      className={props.className}
    >
      <path
        d="M18.5391 3.59766L12.3867 9.75L18.5391 15.9609C19.3008 16.6641 19.3008 17.8945 18.5391 18.5977C17.8359 19.3594 16.6055 19.3594 15.9023 18.5977L9.75 12.4453L3.53906 18.5977C2.83594 19.3594 1.60547 19.3594 0.902344 18.5977C0.140625 17.8945 0.140625 16.6641 0.902344 15.9609L7.05469 9.75L0.902344 3.59766C0.140625 2.89453 0.140625 1.66406 0.902344 0.960938C1.60547 0.199219 2.83594 0.199219 3.53906 0.960938L9.75 7.11328L15.9023 0.960938C16.6055 0.199219 17.8359 0.199219 18.5391 0.960938C19.3008 1.66406 19.3008 2.89453 18.5391 3.59766Z"
        className="fill-secondary-darker hover:fill-[#b798af] hover:shadow-lg"
      />
    </svg>
  );
};
