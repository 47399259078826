export const seoRedirectionUrls = [
  { from: "/schedule", to: "/how-it-works" },
  { from: "/telemental-health", to: "/how-it-works" },
  { from: "/psychologists", to: "/find-a-psychologist" },
  { from: "/psychologist-directory", to: "/find-a-psychologist" },

  { from: "/gp", to: "/get-a-gp-referral" },
  { from: "/match", to: "/get-matched" },
  { from: "/about", to: "/about-someone-health" },

  {
    form: "/404",
    to: "not-found",
  },

  { from: "/gambling-addiction-counselling", to: "/therapy/addiction" },
  {
    from: "/adjustment-difficulties-counselling",
    to: "/therapy/adjustment-difficulties",
  },
  { from: "/anger-management-counselling", to: "/therapy/anger-management" },
  { from: "/anxiety-counselling", to: "/therapy/anxiety" },
  { from: "/bipolar-counselling", to: "/therapy/bipolar" },
  { from: "/body-dysmorphia-counselling", to: "/therapy/body-dysmorphia" },
  {
    from: "/borderline-personality-counselling",
    to: "/therapy/borderline-personality",
  },
  { from: "/depression-mood-counselling", to: "/therapy/depression" },
  { from: "/drug-alcohol-counselling", to: "/therapy/drug-alcohol" },
  { from: "/dyslexia-counselling", to: "/therapy/dyslexia" },
  { from: "/eating-disorder-counselling", to: "/therapy/eating-disorder" },
  {
    from: "/gender-sexual-identity-counselling",
    to: "/therapy/gender-sexual-identity",
  },
  {
    from: "/grief-bereavement-counselling",
    to: "/therapy/grief-bereavement",
  },
  {
    from: "/infant-sleep-counselling",
    to: "/therapy/infant-sleep-counselling",
  },
  { from: "/life-transition-counselling", to: "/therapy/life-transition" },
  { from: "/narcissism-counselling", to: "/therapy/narcissism" },
  { from: "/new-parent-counselling", to: "/therapy/new-parent" },
  { from: "/obsessive-compulsive-disorder-counselling", to: "/therapy/ocd" },
  { from: "/pain-management-counselling", to: "/therapy/pain-management" },
  {
    from: "/perinatal-depression-anxiety-counselling",
    to: "/therapy/perinatal-postnatal-depression",
  },
  { from: "/phobia-counselling", to: "/therapy/phobias" },
  { from: "/ptsd-cptsd-counselling", to: "/therapy/ptsd-cptsd" },
  {
    from: "/relationship-counselling",
    to: "/therapy/relationship-counselling",
  },
  { from: "/sexual-abuse-counselling", to: "/therapy/sexual-abuse" },
  { from: "/sleep-counselling", to: "/therapy/sleep" },
  { from: "/smoke-addiction-counselling", to: "/therapy/smoke-addiction" },
  {
    from: "/stress-management-relaxation-counselling",
    to: "/therapy/stress-management",
  },
  { from: "/trauma-ptsd-counselling", to: "/therapy/trauma" },
  {
    from: "/weight-management-counselling",
    to: "/therapy/weight-management",
  },
  { from: "/health-lifestyle-counselling", to: "/therapy" },
  { from: "/autism-counselling", to: "/therapy" },
  { from: "/bulk-bill-application", to: "/financial-hardship" },
];
