import { NoticeBoxStoryblok, TextStoryblok } from "types/component-types-sb";
import { v4 } from "uuid";
import { NoticeBox } from "./NoticeBox";

type customProps = {
  isVisible: boolean;
};

export const ReserveTimeOutNoticeBox = ({ isVisible }: customProps) => {
  const noticeContent: TextStoryblok = {
    _uid: v4(),
    component: "text",
    text: "We're sorry - time has run out for this appointment.",
    fontSize: "text-15",
  };

  const noticeBox: NoticeBoxStoryblok = {
    _uid: v4(),
    component: "noticeBox",
    content: [noticeContent],
  };

  if (!isVisible) return;

  return <NoticeBox blok={noticeBox} />;
};
