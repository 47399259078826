type Props = React.SVGProps<SVGPathElement>;

export const ChevronSvg = (props: Props) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91602 6.95898L1.04102 2.08398C0.710938 1.7793 0.710938 1.24609 1.04102 0.941406C1.3457 0.611328 1.87891 0.611328 2.18359 0.941406L6.5 5.23242L10.791 0.941406C11.0957 0.611328 11.6289 0.611328 11.9336 0.941406C12.2637 1.24609 12.2637 1.7793 11.9336 2.08398L7.05859 6.95898C6.75391 7.28906 6.2207 7.28906 5.91602 6.95898Z"
        fill="#CBAAC3"
        {...props}
      />
    </svg>
  );
};
