import { storyblokEditable } from "@storyblok/react";
import { TableStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import "styles/table.scss";
import { cn } from "utils";

export const Table = ({ blok }: Props<TableStoryblok>) => {
  const tableMarginValue = blok.tableMargin ?? "0px 0px 0px 0px";
  const headerPaddingValue = blok.headerPadding ?? "0px 0px 0px 0px";
  const cellPaddingValue = blok.cellPadding ?? "0px 0px 0px 0px";
  const minWidth = blok.minWidth
    ? `min-w-[${blok.minWidth}px]`
    : "min-w-[768px]";

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full overflow-x-scroll"
      style={{
        margin: tableMarginValue,
      }}
    >
      <table
        className={cn(
          "text-xs md:text-sm lg:text-15 leading-relaxed break-words",
          blok.type,
          blok.customTailwindCss,
          {
            "table-fixed": blok.isFixedLayout,
          },
          minWidth
        )}
      >
        <thead>
          <tr className={cn(blok.headerTextAlignment)}>
            {blok.content?.thead?.map((row) => (
              <th
                key={row._uid}
                style={{
                  padding: headerPaddingValue,
                }}
              >
                {renderTableCellPlainText(row.value)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={cn(blok.cellTextAlignment)}>
          {blok.content?.tbody?.map((row) => (
            <tr key={row._uid}>
              {row.body?.map((cell) => (
                <td
                  key={cell._uid}
                  style={{
                    padding: cellPaddingValue,
                  }}
                >
                  {renderTableCellPlainText(cell.value)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const renderTableCellPlainText = (content?: string): JSX.Element => {
  if (!content) {
    return <p></p>;
  }

  const lines = content.split("\n");
  return (
    <>
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};
