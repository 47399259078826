/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

type CallbackFn = (width?: number) => void;

export const useResizedFn = (fn: CallbackFn) => {
  const { width, height } = useWindowSize();

  const [cachedWidth, setCachedWidth] = useState<number | null>(width);

  useEffect(() => {
    if (width !== cachedWidth) {
      fn(width);
    } else {
      setCachedWidth(width);
    }
  }, [width, cachedWidth]);

  return {
    width,
    height,
  };
};
