import { FilterCheckbox } from "components/forms/FilterCheckbox";
import { Controller, get, useFormContext, useWatch } from "react-hook-form";
import { cn, linkStyles } from "utils";

type Props = {};

export const ConsentCheckboxSection = (props: Props) => {
  const { control } = useFormContext();

  const form = useWatch({ control });

  const consentPrivacy = get(form, "consentPrivacy", false);

  return (
    <div className="text-sm text-dark-grey">
      <p>
        We respect your privacy. Your personal information is used only for
        communicating with you about the services you've requested. Please check
        below to receive occasional updates about mental health research and{" "}
        <a
          href="https://someone.health/"
          target="_blank"
          rel="noreferrer"
          className={cn(linkStyles)}
        >
          Someone.health
        </a>{" "}
        services.
      </p>

      <Controller
        name={"consentPrivacy"}
        control={control}
        render={({ field }) => (
          <div className="mt-2.5 mb-2 cursor-pointer">
            <FilterCheckbox
              isChecked={consentPrivacy}
              onCheck={field.onChange}
              title={
                <p className="text-sm text-dark-grey">
                  I agree to receive additional communications from{" "}
                  <a
                    href="https://someone.health/"
                    target="_blank"
                    rel="noreferrer"
                    className={cn(linkStyles)}
                  >
                    Someone.health
                  </a>
                  .
                </p>
              }
            />
          </div>
        )}
      />

      <p className="mb-1.5">
        Opt out anytime. See our{" "}
        <a
          href="https://someone.health/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className={cn(linkStyles)}
        >
          privacy policy
        </a>{" "}
        for details on data protection and unsubscribing.
      </p>
      <p>
        By submitting, you agree to our storage and use of your data to fulfil
        your request.
      </p>
    </div>
  );
};
