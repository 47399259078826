import { first, get } from "lodash";
import { Auth0ApiClient, getSessionAuthTokenOrDefault } from "./axios.service";

export const KEY_TACKLIT_ATTACHED_CLINICIAN =
  "https://tacklit.com/attachedClinicians";
export const KEY_TACKLIT_ROLES = "https://tacklit.com/roles";

export const refetchRoleAuth0Async = async (
  accessToken: string
): Promise<string | null | undefined> => {
  try {
    const response = await Auth0ApiClient.get(
      "/userinfo",
      getSessionAuthTokenOrDefault(accessToken)
    );

    const roles = get(response?.data, KEY_TACKLIT_ROLES, []) as string[];
    const userRole = first(roles);

    return userRole;
  } catch {
    return null;
  }
};
