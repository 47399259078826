import { yupResolver } from "@hookform/resolvers/yup";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { BlogSearchFormData, SelectionOption } from "models";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEffectOnce } from "react-use";
import { blogCategories, cn, extract, getDefaultButtonStyles } from "utils";
import { TextInput } from "../TextInput";
import { getBlogSearchFormResolver } from "./blog.resolver";

export type CustomProps = {
  searchedCategories?: string[];

  onFormSubmit: (searchData: BlogSearchFormData) => void;
};

export const BlogSearchForm = (props: CustomProps) => {
  const [keywordAndTagSelectedOptions, setKeywordAndTagSelectedOptions] =
    useState<SelectionOption[]>([]);

  const { control, handleSubmit } = useForm<BlogSearchFormData>({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(getBlogSearchFormResolver()),
  });

  const handleFormSubmit = (searchData: BlogSearchFormData) => {
    props.onFormSubmit({
      ...searchData,
      category: extract(keywordAndTagSelectedOptions, "value"),
    });
  };

  const handleChangeKeywordAndTag = (selectionOptions: SelectionOption[]) => {
    setKeywordAndTagSelectedOptions(selectionOptions);
  };

  const options = Object.entries(blogCategories).map(([, value]) => ({
    label: value.label,
    value: value.value,
  }));

  useEffectOnce(() => {
    setKeywordAndTagSelectedOptions(
      options.filter((option) =>
        props.searchedCategories?.includes(option.value)
      )
    );
  });

  return (
    <div className="mx-auto max-w-[740px] py-5 lg:pt-0">
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex flex-col gap-4 align-middle md:flex-row"
      >
        <div className="w-full md:w-[300px]">
          {/* KEYWORD */}
          <Controller
            name="keywordOrTag"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col w-full gap-1.5">
                <TextInput
                  title={"Search by key word or tag"}
                  name={field.name}
                  onChangeValue={field.onChange}
                  placeholder="Enter keyword or tag"
                />
              </div>
            )}
          />
        </div>

        <div className="w-full md:w-[300px]">
          <SelectionDropdown
            title={"Search by category"}
            placeHolder={"Optional"}
            onChangeMultiSelection={handleChangeKeywordAndTag}
            selectedValues={keywordAndTagSelectedOptions}
            options={options}
            isLoading={false}
            isMultiple
            isShowTitle
          />
        </div>

        <div className="block md:flex md:items-end">
          <button
            className={cn(
              "w-full md:w-fit h-fit flex justify-center items-center",
              getDefaultButtonStyles()
            )}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};
