import { getStoryblokApi } from "@storyblok/react";
import { isEmpty } from "lodash";
import { sbParams } from "useHandlerStoryblok";
import {
  filterSearchStoriesDataByCategories,
  filterSearchStoriesDataByVisibility,
  filterSelfSearchStoriesData,
  sortSearchStoriesDataByPublishedDate,
} from "utils/blog.util";

export type SearchStoriesDataParams = {
  startWith?: string;
  searchTerm?: string;
  excludingSlugs?: string;
  categories?: string[];
  take?: number;
  skip?: number;
  shouldRemoveSelfSearch?: boolean;
};

export type Story = {
  uuid: string;
  id: number;
  name: string;
  created_at: Date;
  published_at: Date;
  content: StoryContent;
  slug: string;
  full_slug: string;
  sort_by_date: null;
  position: number;
  tag_list: any[];
  is_startpage: boolean;
  parent_id: number;
  meta_data: null;
  group_id: string;
  first_published_at: Date;
  release_id: null;
  lang: string;
  alternates: any[];
  default_full_slug: null;
  translated_slugs: null;
};

export type StoryContent = {
  _uid: string;
  _editable: string;
  component: "page";
  body: any[];
};

export type SearchStoriesDataResponse = {
  responses: Story[];
  totalItem: number;
};

export const searchBlogsData = async (
  searchParams: SearchStoriesDataParams
): Promise<SearchStoriesDataResponse> => {
  let response: Story[] = await getStoryblokApi().getAll("cdn/stories", {
    ...sbParams,
    starts_with: searchParams.startWith ?? "",
    search_term: searchParams.searchTerm ?? "",
    excluding_slugs: searchParams.excludingSlugs ?? "",
  });

  response = filterSearchStoriesDataByVisibility(response);

  if (searchParams.categories && !isEmpty(searchParams.categories)) {
    response = filterSearchStoriesDataByCategories(
      response,
      searchParams.categories
    );
  }

  if (searchParams.shouldRemoveSelfSearch) {
    response = filterSelfSearchStoriesData(response);
  }

  response = sortSearchStoriesDataByPublishedDate(response);

  const itemsToSkip = searchParams.skip ?? 0;
  const itemsToTake = searchParams.take
    ? itemsToSkip + searchParams.take
    : response.length;

  return {
    responses: response.slice(itemsToSkip, itemsToTake),
    totalItem: response.length,
  };
};

export const getCurrentStoryData = async (): Promise<Story> => {
  const { data } = await getStoryblokApi().get(
    `cdn/stories/${window.location.pathname}`,
    sbParams
  );

  return data.story;
};
