import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { BadgeTypes } from "enums";
import { PricingCardItemStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";

type CustomProps = Props<PricingCardItemStoryblok> & {
  isActive?: boolean;

  onClickCard: (
    pricingDetails: PricingCardItemStoryblok["pricingDetails"],
    id: string,
    shouldHideParentContent: boolean
  ) => void;
};

export const PricingCardItem = ({
  blok,
  isActive = false,
  onClickCard,
}: CustomProps) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-between flex-1 p-4 duration-200 cursor-pointer lg:p-8 gap-y-5 bg-light-grey hover:ring-1 hover:ring-secondary-darker rounded-3xl hover:-translate-y-2 hover:shadow-lg",
        {
          "bg-secondary ring-1 ring-secondary-darker": isActive,
        }
      )}
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col gap-3">
        {/* BADGE */}
        <div className="min-h-4">
          {blok.badges
            ?.map((badge) => ({
              ...badge,
              type: isActive ? BadgeTypes.PLAIN : BadgeTypes.SECONDARY,
            }))
            .map((badge) => (
              <StoryblokComponent key={badge._uid} blok={badge} />
            ))}
        </div>

        {/* TITLE */}
        <div>
          {blok.pricing?.map((pricing) => (
            <StoryblokComponent key={pricing._uid} blok={pricing} />
          ))}
        </div>

        {/* BRIEF */}
        <div className="flex flex-col gap-y-2.5">
          {blok.brief?.map((brief) => (
            <StoryblokComponent key={brief._uid} blok={brief} />
          ))}
        </div>
      </div>

      {/* Price */}
      {blok.pricePerSession ? (
        <div className="font-medium">
          <sup className="text-40">$</sup>
          <span className="text-58">{blok.pricePerSession}</span>
          <span className="text-16">/session</span>
        </div>
      ) : (
        <div
          onClick={() => {
            onClickCard(
              blok.pricingDetails,
              blok._uid,
              Boolean(blok.shouldHideParentContent)
            );

            const element = document.getElementById("tbl-pricing-detail");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
          className="flex items-center gap-x-1.5 text-primary font-medium text-sm hover:underline underline-offset-4 pb-4"
        >
          {blok.actionTitle}
          <img
            src={blok.actionIcon?.filename}
            alt={blok.actionIcon?.alt}
            className="max-w-4 max-h-4"
          />
        </div>
      )}
    </div>
  );
};
