import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { SelectionOption } from "models";
import { useState } from "react";
import { HowItWorksJourneyFlowStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { getSingleSelectedOption, howItWorksOptions } from "utils";

export const HowItWorksJourneyFlow = ({
  blok,
}: Props<HowItWorksJourneyFlowStoryblok>) => {
  const [selectedOption, setSelectedOption] = useState(howItWorksOptions[0]);
  const [selectedJourney, setSelectedJourney] = useState(
    blok.journeys?.find(
      (journey) => journey.fundingMethod === selectedOption.value
    )
  );

  const handleChangeOption = (selectedOption: SelectionOption) => {
    setSelectedOption(selectedOption);

    const journey = blok.journeys?.find(
      (journey) => journey.fundingMethod === selectedOption.value
    );
    setSelectedJourney(journey);
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex flex-col gap-y-5">
        {blok.title?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </div>

      <div className="flex flex-col md:flex-row items-center	gap-4 max-w-[500px] mx-auto my-8">
        {blok.options?.map((option) => (
          <StoryblokComponent
            key={option._uid}
            blok={option}
            isLoading={false}
            selectedOption={getSingleSelectedOption(
              howItWorksOptions,
              selectedOption.value
            )}
            options={howItWorksOptions}
            onChangeSingleSelection={(selectedOption: SelectionOption) =>
              handleChangeOption(selectedOption)
            }
            isRequired
          />
        ))}
      </div>

      <StoryblokComponent key={selectedJourney?._uid} blok={selectedJourney} />
    </div>
  );
};
