import { useSearchParam } from "react-use";
import { getPsychologistByIdAsync } from "services/psychologist.service";
import useSWR from "swr";

export const useSwrClinicianDetails = () => {
  const psychologistId = useSearchParam("id");

  const fetcher = async () => {
    if (!psychologistId) return Promise.resolve(null);

    const { data } = await getPsychologistByIdAsync(psychologistId);

    return data;
  };

  const queryResult = useSWR(["/clinicians", psychologistId], fetcher);

  return queryResult;
};
