type Props = React.SVGProps<SVGPathElement>;

export const DatePickerSvg = (props: Props) => {
  return (
    <svg
      width="36"
      height="42"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.875 0.625C4.30664 0.625 4.6875 1.00586 4.6875 1.4375V2.25H7.9375V1.4375C7.9375 1.00586 8.29297 0.625 8.75 0.625C9.18164 0.625 9.5625 1.00586 9.5625 1.4375V2.25H10.7812C11.4414 2.25 12 2.80859 12 3.46875V4.6875H0.625V3.46875C0.625 2.80859 1.1582 2.25 1.84375 2.25H3.0625V1.4375C3.0625 1.00586 3.41797 0.625 3.875 0.625ZM0.625 5.5H12V12.4062C12 13.0918 11.4414 13.625 10.7812 13.625H1.84375C1.1582 13.625 0.625 13.0918 0.625 12.4062V5.5ZM2.25 7.53125V8.34375C2.25 8.57227 2.42773 8.75 2.65625 8.75H3.46875C3.67188 8.75 3.875 8.57227 3.875 8.34375V7.53125C3.875 7.32812 3.67188 7.125 3.46875 7.125H2.65625C2.42773 7.125 2.25 7.32812 2.25 7.53125ZM5.5 7.53125V8.34375C5.5 8.57227 5.67773 8.75 5.90625 8.75H6.71875C6.92188 8.75 7.125 8.57227 7.125 8.34375V7.53125C7.125 7.32812 6.92188 7.125 6.71875 7.125H5.90625C5.67773 7.125 5.5 7.32812 5.5 7.53125ZM9.15625 7.125C8.92773 7.125 8.75 7.32812 8.75 7.53125V8.34375C8.75 8.57227 8.92773 8.75 9.15625 8.75H9.96875C10.1719 8.75 10.375 8.57227 10.375 8.34375V7.53125C10.375 7.32812 10.1719 7.125 9.96875 7.125H9.15625ZM2.25 10.7812V11.5938C2.25 11.8223 2.42773 12 2.65625 12H3.46875C3.67188 12 3.875 11.8223 3.875 11.5938V10.7812C3.875 10.5781 3.67188 10.375 3.46875 10.375H2.65625C2.42773 10.375 2.25 10.5781 2.25 10.7812ZM5.90625 10.375C5.67773 10.375 5.5 10.5781 5.5 10.7812V11.5938C5.5 11.8223 5.67773 12 5.90625 12H6.71875C6.92188 12 7.125 11.8223 7.125 11.5938V10.7812C7.125 10.5781 6.92188 10.375 6.71875 10.375H5.90625ZM8.75 10.7812V11.5938C8.75 11.8223 8.92773 12 9.15625 12H9.96875C10.1719 12 10.375 11.8223 10.375 11.5938V10.7812C10.375 10.5781 10.1719 10.375 9.96875 10.375H9.15625C8.92773 10.375 8.75 10.5781 8.75 10.7812Z"
        fill="#502334"
      />
    </svg>
  );
};
