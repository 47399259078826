/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { defaultTo, isEqual, toNumber } from "lodash";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useToggle } from "react-use";
import { BookMedicareFormStoryblok } from "types/component-types-sb";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { BookBehalfOfType, ConfirmRadioType } from "enums";
import { useClientType } from "hooks/useClientType";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { Profile } from "models/client.model";
import { ExternalRoutes, Routes } from "routes/main.routes";
import {
  postEmergencyContactAsync,
  processReserveWithPaymentAsync,
} from "services/booking.service";
import { validateMedicareAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import {
  getDefaultButtonStyles,
  getDefaultOutlinedButtonStyles,
  openInNewTab,
  redirectTo,
  toDateOfBirth,
  toExpiryDate,
} from "utils";
import {
  getBehalfOfChildFormDefaultData,
  getChildBasicUserInformationForBookingForm,
  getUserMedicareBasicInformation,
  handleMedicareUpdateReferralAsync,
  isEmergencyContactExisted,
  reclaimReserveAppointment,
} from "utils/booking.util";
import {
  getStoredReserveId,
  setStoredAppointmentHasGpReferral,
} from "utils/storage.util";
import { customToast } from "utils/toast.util";
import { FilterCheckbox } from "../FilterCheckbox";
import {
  EmergencyContactField,
  EmergencyContactFieldType,
} from "../shared/EmergencyContactField";
import {
  MedicareChildFields,
  MedicareChildFieldsType,
} from "../shared/MedicareChildFields";
import { UploadFields, UploadFieldsType } from "../shared/UploadFields";
import { getBookMedicareChildFormResolver } from "./book-medicare.resolver";
import { renderMedicareNotice } from "./booking.medicare.util";

type Props = {
  bookMedicareFormBlok: BookMedicareFormStoryblok;
  profile: Profile;
  accessToken: string;
};

export type BookMedicareChildFormData = MedicareChildFieldsType &
  UploadFieldsType &
  EmergencyContactFieldType;

export const BehalfOfChildForm = ({
  bookMedicareFormBlok,
  profile,
  accessToken,
}: Props) => {
  const { isAuthenticated } = useAuth0();
  const { loginRedirectTacklit } = useLoginNavigate();

  const { isExistingClient } = useClientType();

  const [isValidating, toggleValidating] = useToggle(false);

  const {
    control,
    formState: { errors, isValid: isFormValid },
    handleSubmit,
  } = useForm<BookMedicareChildFormData>({
    mode: "onChange",
    defaultValues: getBehalfOfChildFormDefaultData(profile),
    resolver: yupResolver(
      getBookMedicareChildFormResolver(profile, accessToken)
    ),
  });

  const {
    isHaveGPReferralAndTreatmentPlan,
    isConsentForParent,
    videoAppointmentWithBulkBillGP,
  } = useWatch({ control });

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  if (!userMedicareInformation) return;

  const isNoReferral = isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.NO;

  const isWantBookGP = videoAppointmentWithBulkBillGP === ConfirmRadioType.YES;

  const isDisabledSubmit = isValidating || !isConsentForParent || !isFormValid;

  const isValidateMedicareCardAsync = async (
    formData: BookMedicareChildFormData
  ): Promise<boolean> => {
    try {
      const userBasicData = getChildBasicUserInformationForBookingForm(
        formData,
        profile,
        accessToken
      );

      const usingChildCard = isEqual(
        formData.hasChildSeparateCard,
        ConfirmRadioType.YES
      );

      const medicareNumber = usingChildCard
        ? toNumber(formData.childSeparateMedicareNumber)
        : toNumber(formData.parentMedicareNumber);
      const medicareExpiryDate = usingChildCard
        ? toExpiryDate(formData.childSeparateExpiryDate)
        : toExpiryDate(formData.parentExpiryDate);
      const irnNumber = usingChildCard
        ? toNumber(formData.childSeparateIrn)
        : toNumber(formData.childIrn);

      await validateMedicareAsync(
        {
          firstName: userBasicData.firstName,
          lastName: userBasicData.lastName,
          dateOfBirth: userBasicData.dateOfBirth,
          number: medicareNumber,
          expiryDate: medicareExpiryDate,
          irn: irnNumber,
          parent: usingChildCard
            ? undefined
            : {
                firstName: defaultTo(formData.parentFirstName, ""),
                lastName: defaultTo(formData.parentLastName, ""),
                irn: toNumber(formData.parentIrn),
                dateOfBirth: defaultTo(
                  toDateOfBirth(formData.parentDateOfBirth),
                  ""
                ),
              },
          shouldRejectInvalidDetails: true,
        },
        userBasicData.authToken
      );

      customToast.success("Valid Medicare card");

      return true;
    } catch (err) {
      console.log("[error]: >>", err);

      customToast.error("Invalid Medicare card");

      return false;
    }
  };

  const onSubmit: SubmitHandler<BookMedicareChildFormData> = async (
    formData
  ) => {
    toggleValidating(true);

    try {
      const reserveId = getStoredReserveId();

      if (!reserveId) {
        customToast.error(
          "Unable to get reserve appointment data. Please try again"
        );

        return;
      }

      // Put contact address and guardian profile
      await Promise.all([
        TacklitService.putContactAddressAsync(formData, accessToken),

        TacklitService.putChildGuardianProfileAsync(
          {
            parentFirstName: formData.guardianFirstName,
            parentLastName: formData.guardianLastName,
            parentEmail: formData.guardianEmail,
          },
          accessToken
        ),
      ]);

      // Validate medicare card
      const isValidMedicareCheck = await isValidateMedicareCardAsync(formData);
      if (!isValidMedicareCheck) return;

      // Put emergency contact
      const isDuplicatedEmergencyContact = isEmergencyContactExisted({
        profile: profile,
        firstName: formData.emergencyContactName,
        mobileNumber: formData.emergencyContactPhoneNumber,
      });

      if (!isDuplicatedEmergencyContact) {
        await postEmergencyContactAsync(
          {
            firstName: formData.emergencyContactName,
            mobileNumber: formData.emergencyContactPhoneNumber,
          },
          accessToken
        );
      }

      // Put referral
      const isUpdateReferralSuccess = await handleMedicareUpdateReferralAsync({
        formData: formData,
        isExistingClient: isExistingClient,
        accessToken: accessToken,
      });

      if (!isUpdateReferralSuccess) return;

      setStoredAppointmentHasGpReferral(!isNoReferral);

      // Reclaim reserve appointment
      await reclaimReserveAppointment(reserveId, accessToken);

      // Process reserve
      if (isAuthenticated && Boolean(profile.hasSavedCard)) {
        await processReserveWithPaymentAsync(reserveId, accessToken);

        redirectTo(Routes.BOOKING_THANK_YOU);
      } else {
        const { data: processResponse } = await processReserveWithPaymentAsync(
          reserveId,
          accessToken
        );

        if (processResponse?.checkoutUrl) {
          redirectTo(processResponse.checkoutUrl);
        }
      }

      customToast.success("Appointment claimed successfully");
    } catch (err) {
      console.log("[error]: >>", err);

      customToast.error(
        "Error while processing your appointment. Please try again"
      );
    } finally {
      toggleValidating(false);
    }
  };

  const handleClickConsentAgreement = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();

    openInNewTab(Routes.CONSENT_AGREEMENT);
  };

  const handleClickBookGP = async () => {
    loginRedirectTacklit(ExternalRoutes.GP_BOOKING);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col flex-1 gap-y-5"
    >
      {/* MEDICARE CHILD */}
      <MedicareChildFields<BookMedicareChildFormData>
        control={control}
        errors={errors}
        userInformation={userMedicareInformation}
        medicareNumberHints={bookMedicareFormBlok.medicareNumberHints}
        irnHints={bookMedicareFormBlok.irnHints}
        expiryDateHints={bookMedicareFormBlok.expiryDateHints}
        streetAddressHints={bookMedicareFormBlok.streetAddressHints}
        medicareImageAsset={bookMedicareFormBlok.medicareImage}
        medicareImageAlt={bookMedicareFormBlok.medicareImageAlt}
      />

      {/* EMERGENCY CONTACT */}
      <EmergencyContactField<BookMedicareChildFormData>
        control={control}
        errors={errors}
        hints={bookMedicareFormBlok.emergencyContactHints}
      />

      {/* UPLOAD */}
      <UploadFields<BookMedicareChildFormData>
        bookMedicareFormBlok={bookMedicareFormBlok}
        control={control}
        errors={errors}
        behalfOfType={BookBehalfOfType.MY_CHILD}
      />

      {isNoReferral && (
        <div>
          {bookMedicareFormBlok.notices?.map((noticeBlok) => (
            <StoryblokComponent key={noticeBlok._uid} blok={noticeBlok} />
          ))}
        </div>
      )}

      <div>{renderMedicareNotice(bookMedicareFormBlok)}</div>

      <Controller
        name="isConsentForParent"
        control={control}
        render={({ field }) => (
          <div className="flex flex-row items-center justify-start">
            <FilterCheckbox
              isChecked={isConsentForParent || false}
              title={
                <div className="static lg:flex flex-row items-center justify-start lg:min-w-[345px]">
                  <span>I have read and agree to the</span>
                  <span
                    onClick={handleClickConsentAgreement}
                    className="ml-1 text-sm underline cursor-pointer"
                  >
                    consent agreement
                  </span>
                </div>
              }
              onCheck={field.onChange}
            />
          </div>
        )}
      />

      {isNoReferral ? (
        <div className="flex flex-wrap items-center justify-between gap-y-3">
          <div className="flex items-center justify-start mt-2">
            <button
              type="submit"
              disabled={isDisabledSubmit}
              className={getDefaultButtonStyles(isValidating)}
            >
              Confirm psychology session
            </button>
            {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
          </div>

          {isWantBookGP && (
            <button
              type="button"
              onClick={handleClickBookGP}
              className={getDefaultOutlinedButtonStyles()}
            >
              Login to book one of our GPs
            </button>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-start mt-2">
          <button
            type="submit"
            disabled={isDisabledSubmit}
            className={getDefaultButtonStyles(isValidating)}
          >
            Proceed to payment details to finalise your booking
          </button>
          {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
        </div>
      )}
    </form>
  );
};
