import {
  HeadingStoryblok,
  PsychologistDetailExperienceStoryblok,
  TextStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PsychologistResponse } from "@/services/psychologist.service";

type CustomProps = Props<PsychologistDetailExperienceStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailExperience = ({
  blok,
  psychologist,
}: CustomProps) => {
  const { helmControl } = psychologist;

  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  const contentList = helmControl?.credentials?.split("\n\n");

  const getTextBlok = (text: string): TextStoryblok =>
    ({
      text: text,
      fontSize: "20px",
      fontWeight: 400,
      component: "text",
    } as TextStoryblok);

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5"
    >
      <StoryblokComponent blok={headingBlok} />

      {contentList?.map((textItem, index) => (
        <div key={index} className="leading-7">
          <StoryblokComponent blok={getTextBlok(textItem)} />
        </div>
      ))}
    </div>
  );
};
