import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ContactUsActionsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const ContactUsActions = ({
  blok,
}: Props<ContactUsActionsStoryblok>) => {
  return (
    <div {...storyblokEditable(blok)} className="w-[300px]">
      {/* TITLE */}
      {blok.title?.map((title) => (
        <StoryblokComponent key={title._uid} blok={title} />
      ))}

      {/* ACTION BUTTONS */}
      {blok.actionButtons?.map((button) => (
        <StoryblokComponent key={button._uid} blok={button} />
      ))}

      {/* CONTACT BOX */}
      {blok.contactBox?.map((contact) => (
        <StoryblokComponent key={contact._uid} blok={contact} />
      ))}
    </div>
  );
};
