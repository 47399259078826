/* eslint-disable react-hooks/exhaustive-deps */
import { DateFormatType } from "enums";
import { defaultTo, first, get } from "lodash";
import { useMemo } from "react";

import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import {
  auLabelledTimeZone,
  fundingMethodOptions,
  isDaylightSavingTime,
  redirectToPsychologistDetails,
  toZonedTimeFormat,
} from "utils";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  getStoredTimeZone,
} from "utils/storage.util";

export const BookingInformation = () => {
  const { data: profile } = useSwrMyProfile();

  const profileTimeZone = profile?.timeZone;

  const storedTimeZone = getStoredTimeZone();

  const storedReserveData = getStoredReserveData();

  const appointmentResp = first(
    storedReserveData?.reserveAppointment?.appointments
  );

  const psychologistDetails = storedReserveData?.psychologist;

  const selectedTimes = useMemo(() => {
    if (!appointmentResp) return;

    const timeZone = defaultTo(profileTimeZone, storedTimeZone);

    const startTime = toZonedTimeFormat(
      appointmentResp.startDateTime,
      timeZone,
      DateFormatType.TWELVE_HOURS
    );
    const endTime = toZonedTimeFormat(
      appointmentResp.endDateTime,
      timeZone,
      DateFormatType.TWELVE_HOURS
    );
    const date = toZonedTimeFormat(
      appointmentResp.endDateTime,
      timeZone,
      DateFormatType.DATE_STRING
    );

    const getTimeZoneLabel = get(auLabelledTimeZone, timeZone);

    if (!getTimeZoneLabel) {
      return `${startTime} - ${endTime}, ${date}`;
    }

    const isDst = isDaylightSavingTime(timeZone);

    const dstCode = isDst
      ? getTimeZoneLabel.dstCode
      : getTimeZoneLabel.nonDstCode;

    const dstHr = isDst ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;

    return `${startTime} - ${endTime} (GMT+${dstHr} ${dstCode}), ${date}`;
  }, [appointmentResp, profileTimeZone]);

  const getAppointmentConsult = () => {
    const storedFundingMethod = getStoredFundingMethod();

    const fundingMethod = fundingMethodOptions.find(
      ({ value }) => value === storedFundingMethod
    )?.label;

    return defaultTo(fundingMethod, "");
  };

  if (!appointmentResp) return;

  return (
    <div className="flex flex-col gap-y-2">
      <span>
        Selected time: <b>{selectedTimes}</b> with{" "}
        <b>{psychologistDetails?.name}</b> for a{" "}
        <b>{getAppointmentConsult()}</b> consult.
      </span>

      <div
        onClick={() =>
          redirectToPsychologistDetails(psychologistDetails?.slugUrl)
        }
        className="underline cursor-pointer w-fit"
      >
        Select another date or time
      </div>
    </div>
  );
};
