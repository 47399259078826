import { HeadingStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { storyblokEditable } from "@storyblok/react";
import { HeadingLevels, ScreenBreakpoints } from "enums";
import React from "react";
import { useWindowSize } from "react-use";
import { cn, getHeadingStyles } from "utils";

export const Heading = ({ blok }: Props<HeadingStoryblok>) => {
  const { width } = useWindowSize();
  const headingStyles = getHeadingStyles(blok, width);

  return (
    <div
      className={cn({
        "flex justify-center": Boolean(blok.wrapWidth),
      })}
      {...storyblokEditable(blok)}
    >
      {React.createElement(
        blok.level || HeadingLevels.H1,
        {
          className: cn(
            blok.fontWeight,
            blok.lineHeight,
            headingStyles,
            "text-primary"
          ),
          style: {
            width:
              width > ScreenBreakpoints.MD ? `${blok.wrapWidth}%` : undefined,
          },
        },
        blok.content
      )}
    </div>
  );
};
