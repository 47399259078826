import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isEqual, toNumber } from "lodash";
import { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useToggle } from "react-use";
import { match } from "ts-pattern";
import { BookDvaFormStoryblok, HintStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { AxiosError } from "axios";
import { DVACardType } from "enums";
import { useClientType } from "hooks/useClientType";
import { AddressFieldsType } from "models";
import { Routes } from "routes/main.routes";
import {
  UpdateMedicareParams,
  validateMedicareAsync,
} from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import { useProfileStore } from "stores/profile.store";
import {
  getDefaultButtonStyles,
  getFormHeadingBlok,
  redirectTo,
  toExpiryDate,
} from "utils";
import {
  getDVADefaultFormData,
  getUserMedicareBasicInformation,
  handleUploadNoReferralAsync,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import { BookingInformation } from "../BookingInformation";
import { CountDownClock } from "../CountDownClock";
import { DateInput } from "../DateInput";
import { RadioInput } from "../RadioInput";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { AddressFields } from "../shared/AddressFields";
import { getBookDVAFormResolver } from "./book-dva.resolver";

export type BookDVAFormData = {
  dvaCardNumber: string;
  dvaCardType: string;

  medicareNumber?: string;
  irnNumber?: string;
  expiryDate?: string;
} & AddressFieldsType;

export const BookDVAForm = ({ blok }: Props<BookDvaFormStoryblok>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const profile = useProfileStore((state) => state.profile);

  const { isExistingClient } = useClientType();

  const [isValidating, toggleValidating] = useToggle(false);
  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  const {
    control,
    formState: { errors, isValid, isValidating: isFormValidating },
    handleSubmit,
    setError,
  } = useForm<BookDVAFormData>({
    mode: "onChange",
    defaultValues: getDVADefaultFormData(profile),
    resolver: yupResolver(getBookDVAFormResolver(profile, accessToken)),
  });

  const { dvaCardType, dvaCardNumber, medicareNumber, irnNumber, expiryDate } =
    useWatch({ control });

  const isChooseWhiteDVACard = isEqual(dvaCardType, DVACardType.WHITE);
  const isChooseGoldDVACard = isEqual(dvaCardType, DVACardType.GOLD);

  const isDisabledForm = isValidating || !isValid || isFormValidating;

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  const getDVACardTypeHint = (): ReactElement | undefined => {
    return match(dvaCardType)
      .with(DVACardType.WHITE, () => getHintFromStoryBlok(blok.dvaCardHints))
      .with(DVACardType.GOLD, () =>
        getHintFromStoryBlok(blok.secondDvaCardHints)
      )
      .otherwise(() => undefined);
  };

  const getStreetAddressHint = (): HintStoryblok[] | undefined => {
    return match(dvaCardType)
      .with(DVACardType.WHITE, () => blok.homeAddressHints)
      .with(DVACardType.GOLD, () => blok.secondAddressHints)
      .otherwise(() => undefined);
  };

  const onSubmit: SubmitHandler<BookDVAFormData> = async (formData) => {
    if (!userMedicareInformation) return;

    toggleValidating(true);

    const whiteDVACardParam = isChooseWhiteDVACard
      ? {
          number: toNumber(medicareNumber),
          expiryDate: toExpiryDate(expiryDate),
          irn: toNumber(irnNumber),
        }
      : undefined;

    const requestParam: UpdateMedicareParams = {
      firstName: userMedicareInformation.firstName,
      lastName: userMedicareInformation.lastName,
      dateOfBirth: userMedicareInformation.dateOfBirth,
      dva: dvaCardNumber,
      dvaCardDetails: {
        type: dvaCardType ?? "",
      },
      shouldRejectInvalidDetails: true,

      ...whiteDVACardParam,
    };

    try {
      await validateMedicareAsync(requestParam, accessToken);

      customToast.success("Validate your DVA card successfully");

      await Promise.all([
        TacklitService.putContactAddressAsync(formData, accessToken),
      ]);

      const isUploaded = await handleUploadNoReferralAsync(
        accessToken,
        isExistingClient
      );

      if (!isUploaded) return;

      redirectTo(Routes.BOOKING_CONFIRM);
    } catch (err) {
      console.log("[error]: >>", err);

      const errorResponse = (err as AxiosError<any>).response?.data;
      const errorMessage =
        errorResponse?.errors?.dva ||
        errorResponse?.message ||
        "Error while validating your DVA card";

      setError("dvaCardNumber", { type: "manual", message: errorMessage });
    } finally {
      toggleValidating(false);
    }
  };

  if (!userMedicareInformation) return;

  return (
    <div
      className="flex flex-col w-full gap-12 px-0 py-10 md:flex-row lg:px-20"
      {...storyblokEditable(blok)}
    >
      <form
        className="flex flex-[2] gap-y-5 flex-col order-2 md:order-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StoryblokComponent blok={getFormHeadingBlok(blok.title)} />

        <BookingInformation />

        <div className="flex flex-col flex-1 gap-y-1">
          <Controller
            name="dvaCardNumber"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                title="DVA Card number"
                onChangeValue={field.onChange}
              />
            )}
          />
          <TextError fieldError={errors.dvaCardNumber} />
        </div>

        <div className="flex flex-col p-5 gap-y-3 bg-neutral-100 rounded-xl">
          <div className="flex flex-row items-center justify-between">
            <span>Gold or White Card?</span>
            {getDVACardTypeHint()}
          </div>

          <Controller
            name="dvaCardType"
            control={control}
            render={({ field }) => (
              <div className="flex flex-row gap-x-5">
                <RadioInput
                  {...field}
                  label="Gold"
                  value={DVACardType.GOLD}
                  checked={isChooseGoldDVACard}
                />

                <RadioInput
                  {...field}
                  label="White Card (approved condition)"
                  value={DVACardType.WHITE}
                  checked={isChooseWhiteDVACard}
                />
              </div>
            )}
          />
        </div>

        <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-3">
          {isChooseWhiteDVACard && (
            <div className="flex flex-col gap-y-3">
              <div>
                {/* MEDICARE CARD IMAGE */}
                <img
                  src={blok.medicareImage?.filename}
                  alt={blok.medicareImageAlt}
                />
              </div>

              <div className="flex flex-col flex-1">
                <Controller
                  name="medicareNumber"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      title="Medicare card number"
                      onChangeValue={field.onChange}
                      hintElement={getHintFromStoryBlok(
                        blok.medicareNumberHints
                      )}
                      numberOnly
                    />
                  )}
                />

                <TextError fieldError={errors.medicareNumber} />
              </div>

              <div className="flex flex-col items-start justify-center w-full lg:flex-row gap-x-5 gap-y-3">
                <Controller
                  name="irnNumber"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-1 lg:min-w-[275px] flex-col w-full">
                      <TextInput
                        {...field}
                        title="Your individual reference (IRN)"
                        onChangeValue={field.onChange}
                        hintElement={getHintFromStoryBlok(
                          blok.medicareIrnHints
                        )}
                        numberOnly
                      />

                      <TextError fieldError={errors.irnNumber} />
                    </div>
                  )}
                />

                <Controller
                  name="expiryDate"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col flex-1 w-full">
                      <DateInput
                        title="Expiry date"
                        type="month"
                        selectedDate={
                          field.value ? new Date(field.value) : undefined
                        }
                        hintComponent={getHintFromStoryBlok(
                          blok.medicareExpiryHints
                        )}
                        onChangeValue={field.onChange}
                        showMonthYearPicker
                        minDate={new Date()}
                        isShowHint
                      />

                      <TextError fieldError={errors.expiryDate} />
                    </div>
                  )}
                />
              </div>
            </div>
          )}

          {/* ADDRESS */}
          <AddressFields<BookDVAFormData>
            streetAddressHints={getStreetAddressHint()}
            control={control}
            errors={errors}
          />
        </div>

        <div className="flex items-center justify-start mt-2">
          <button
            type="submit"
            disabled={isDisabledForm}
            className={getDefaultButtonStyles(isValidating)}
          >
            Next
          </button>
          {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
        </div>
      </form>

      {/* ACTIONS BLOK */}
      <div className="flex flex-col flex-1 gap-y-3 order-1 h-fit md:order-2">
        {blok.actions?.map((actionBlok) => (
          <StoryblokComponent key={actionBlok._uid} blok={actionBlok} />
        ))}

        <div className="flex bg-light-grey p-7 min-w-[280px] w-full rounded-xl">
          {/* COUNTDOWN CLOCK */}
          <CountDownClock />
        </div>
      </div>
    </div>
  );
};
