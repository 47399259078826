type Props = {
  pathProps?: React.SVGProps<SVGPathElement>;
};

export const WarningSvg = ({ pathProps }: Props) => (
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.65625 30.8906C2.5 31.125 2.5 31.3594 2.5 31.5938C2.5 32.375 3.125 33.0781 3.90625 33.0781H36.0156C36.7969 33.0781 37.5 32.375 37.5 31.5938C37.5 31.3594 37.4219 31.125 37.2656 30.8906L21.5625 3.9375C21.25 3.39062 20.625 3 20 3C19.2969 3 18.6719 3.39062 18.3594 3.9375L2.65625 30.8906ZM0.46875 29.6406L16.1719 2.6875C16.9531 1.35938 18.4375 0.5 20 0.5C21.4844 0.5 22.9688 1.35938 23.75 2.6875L39.4531 29.6406C39.7656 30.1875 40 30.8906 40 31.5938C40 33.7812 38.2031 35.5 36.0156 35.5H3.90625C1.71875 35.5 0 33.7812 0 31.5938C0 30.8906 0.15625 30.1875 0.46875 29.6406ZM20 10.5C20.625 10.5 21.25 11.125 21.25 11.75V21.75C21.25 22.4531 20.625 23 20 23C19.2969 23 18.75 22.4531 18.75 21.75V11.75C18.75 11.125 19.2969 10.5 20 10.5ZM18.125 28C18.125 26.9844 18.9062 26.125 20 26.125C21.0156 26.125 21.875 26.9844 21.875 28C21.875 29.0938 21.0156 29.875 20 29.875C18.9062 29.875 18.125 29.0938 18.125 28Z"
      fill="#502334"
      {...pathProps}
    />
  </svg>
);
