import { useAuth0 } from "@auth0/auth0-react";
import { first } from "lodash";
import { getAppointmentsHistory } from "services/booking.service";

import useSWR from "swr";
import { isRoleClinician } from "utils";

export const useSwrLastAppointment = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const fetcher = async () => {
    if (!isAuthenticated) return Promise.resolve(null);

    const accessToken = await getAccessTokenSilently();

    const { data } = await getAppointmentsHistory(accessToken);

    const lastAppointment = first(data);
    const lastPsychologistAppointment = data.find(
      (appt) => !Boolean(appt.isGP)
    );
    const lastGpAppointment = data.find((appt) => Boolean(appt.isGP));

    return {
      lastAppointment: lastAppointment,
      lastPsychologistAppointment: lastPsychologistAppointment,
      lastGpAppointment: lastGpAppointment,
    };
  };

  const queryResult = useSWR(
    ["/client-records/me/appointments", user?.sub],
    fetcher,
    {
      isPaused: () => isRoleClinician(),
      errorRetryCount: 3,
    }
  );

  return queryResult;
};
