import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";
import { validatePhoneAsync } from "../signup/signup.resolver";

export const getContactUsFormResolver = () => {
  return Yup.object().shape({
    subject: Yup.string(),
    firstName: Yup.string().trim().required("First name is required"),
    lastName: Yup.string().trim().required("Last name is required"),
    email: Yup.string()
      .email("Email must be a valid email")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test({
        name: "phone",
        message: "Phone number is not found",
        test: async (phoneNumber, context) => {
          if (
            !isValidPhoneNumber(phoneNumber || "", DEFAULT_PHONE_COUNTRY_CODE)
          )
            return context.createError({
              path: "phoneNumber",
              message: "Phone number is not valid",
            });

          const isValid = await validatePhoneAsync(phoneNumber);

          return isValid;
        },
      }),
    comments: Yup.string().trim().required("Comments is required"),
    preferredContactMethod: Yup.string(),
    isReceiveUpdates: Yup.boolean().required(),
  });
};
