import { ConsultPreferenceType } from "enums";
import { CountryCode } from "libphonenumber-js";

import { Funding } from "models";
import { FundingCategory } from "services/psychologist.service";
import { isTestMode } from "./config";

export const DEFAULT_COST = 0;
export const DEFAULT_CLINICIAN_PSYCHOLOGIST_COST = 181.85;
export const DEFAULT_GENERAL_PSYCHOLOGIST_COST = 136.65;
export const DEFAULT_AFTER_REBATE_COST = 40;
export const UNKNOWN_COST = "?";

export const DEFAULT_HOLDING_TIMEOUT_MINUTES = 15;
export const DEFAULT_HOLDING_TIMEOUT_SECONDS =
  DEFAULT_HOLDING_TIMEOUT_MINUTES * 60;
export const MODAL_TIMEOUT_RESET_REMAINING_MINUTES = 2;
export const MODAL_TIMEOUT_RESET_REMAINING_SECONDS =
  MODAL_TIMEOUT_RESET_REMAINING_MINUTES * 60;
export const MODAL_TIMEOUT_RESET_HIDE_AFTER_MINUTES = 1;
export const MODAL_TIMEOUT_RESET_HIDE_AT_SECONDS =
  MODAL_TIMEOUT_RESET_REMAINING_SECONDS -
  MODAL_TIMEOUT_RESET_HIDE_AFTER_MINUTES * 60;
export const AGE_CHILDREN = 13;
export const AGE_ADULT = 18;
export const DEFAULT_MINIMUM_PASSWORD_LENGTH = 11;
export const DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE = 4;
export const DEFAULT_PSYCHOLOGIST_CAROUSEL_PER_PAGE = 12;
export const BLOG_DEFAULT_ITEM_PER_PAGE = 12;

export const DEFAULT_PHONE_COUNTRY_CODE: CountryCode = isTestMode ? "VN" : "AU";
export const DEFAULT_PLACEHOLDER_PHONE_NUMBER = "0482 555 444";

export const EMAIL_SUPPORT_TEAM = "team@someone.health";

export const VIDEO_INTRO_FALLBACK =
  "https://www.youtube.com/watch?v=sGtxuCE0Cfo&list=PLIbLfYSA8ACNYCOaDWmj6EA1F1uS-pyVL&index=7";
export const AVATAR_FALLBACK =
  "https://storage.googleapis.com/au-clinician-avatars/No_image.png";
export const DEFAULT_TIMEZONE = "Australia/Sydney";
export const SUPPORT_CENTER_URL = "https://support.someone.health/";
export const SUPPORT_THANK_YOU_URL =
  "https://support.someone.health/managing-my-appointments";

export const DEFAULT_GST = 12.08;

export const DEFAULT_BOOKING_CONSULT_METHOD = ConsultPreferenceType.VIDEO_CALL;

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again.";

export const DEFAULT_WARNING_BUTTON_CONTENT = "Go back to our psychologists";

export const DEFAULT_TIMEOUT_TITLE = "Your appointment hold has timed out.";

export const DEFAULT_TIMEOUT_CONTENT = [
  `Due to high demand, appointment slots are reserved for 15 minutes to
  accommodate everyone. Please finalise your booking within this time
  to secure your appointment. Please restart your search.`,
];

export const DEFAULT_SESSION_NOT_FOUND_TITLE = "Booking session not found";

export const DEFAULT_SESSION_NOT_FOUND_CONTENT = [
  `Oops! It seems like you don't have an active booking session or it might have been lost.
  Please return to the booking page and start a new session.`,
];

export const DEFAULT_FUNDING_NOT_FOUND_TITLE =
  "Appointment type selection required";

export const DEFAULT_FUNDING_NOT_FOUND_CONTENT = [
  `Choosing an appointment type is crucial for the sign-up process. Before proceeding with booking, 
  we need to know which psychologist service you're interested in.`,
  `Please go back and select an appointment type.`,
];

export const DEFAULT_SIGNED_UP_DATA_NOT_FOUND_TITLE =
  "Signed up user data not found";

export const DEFAULT_SIGNED_UP_DATA_NOT_FOUND_CONTENT = [
  `Signed up user data is required to proceed with the booking process.
  Please go back and sign up to continue.`,
];

export const EMAIL_ADDRESS_EXISTED_MESSAGE =
  "Email address is already in use. Please use a different email or login to your account.";

export const DEFAULT_UPLOAD_REFERRAL_ERROR_MESSAGE =
  "An error occurred while uploading the referral. Please try again.";

export const DEFAULT_APPOINTMENT_TYPE: Funding = Funding.BULK_BILL;

export const DEFAULT_CLAIM_TYPE: FundingCategory = "bulkBill";

export const RESEND_INTERVAL_OTP_SECONDS = 30;

export const MSG_INVITED_EMAIL_ERROR =
  "The email address entered isn't the same as where we sent your invite. Please check for typos and try again. If you would like to update your email address, email";

export const MSG_INVITED_PHONE_ERROR =
  "Mobile number not recognized. Please check for typos and try again.";

export const SIGNUP_INVITED_OTP_LENGTH = 6;
