import { Heading } from "components/atoms/Heading";
import {
  DEFAULT_PHONE_COUNTRY_CODE,
  DEFAULT_PLACEHOLDER_PHONE_NUMBER,
} from "core/booking.constant";
import { HeadingLevels } from "enums";
import { AsYouType } from "libphonenumber-js";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { HeadingStoryblok, HintStoryblok } from "types/component-types-sb";
import { v4 as GUID } from "uuid";
import { BookMedicareMySelfFormData } from "../book-medicare/BehalfOfMySelfForm";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { BookMedicareChildFormData } from "../book-medicare/BehalfOfChildForm";
import { BookMedicareSomeOneFormData } from "../book-medicare/BehalfOfSomeOneForm";

export type EmergencyContactFieldType = {
  emergencyContactName: string;
  emergencyContactPhoneNumber: string;
};

type Props<T extends FieldValues> = {
  control: Control<T>;
  errors: FieldErrors<T>;

  hints?: HintStoryblok[];
};

export const EmergencyContactField = <
  T extends
    | BookMedicareMySelfFormData
    | BookMedicareChildFormData
    | BookMedicareSomeOneFormData
>({
  control,
  errors,
  hints,
  ...props
}: Props<T>) => {
  return (
    <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-5">
      <div className="flex flex-row items-center justify-between">
        {renderEmergencyContactHeading()}
        {getHintFromStoryBlok(hints)}
      </div>

      <div className="flex flex-col md:flex-row md:gap-x-5">
        <div className="flex-1 mb-2">
          <Controller
            name={"emergencyContactName" as FieldPath<T>}
            defaultValue={"" as PathValue<T, FieldPath<T>>}
            control={control}
            render={({ field }) => (
              <>
                <TextInput
                  {...field}
                  name={field.name}
                  title={"Name"}
                  onChangeValue={field.onChange}
                />

                <TextError
                  fieldError={errors.emergencyContactName as FieldError}
                />
              </>
            )}
          />
        </div>

        <div className="flex-1 mb-2">
          {/* Phone number */}
          <Controller
            name={"emergencyContactPhoneNumber" as FieldPath<T>}
            control={control}
            defaultValue={"" as PathValue<T, FieldPath<T>>}
            render={({ field }) => (
              <>
                <TextInput
                  {...field}
                  name={field.name}
                  title={"Phone number"}
                  placeholder={DEFAULT_PLACEHOLDER_PHONE_NUMBER}
                  onChangeValue={(event) => {
                    const value = event.target.value;

                    const formattedValue = new AsYouType(
                      DEFAULT_PHONE_COUNTRY_CODE
                    ).input(value);

                    event.target.value = formattedValue;

                    field.onChange(event);
                  }}
                />

                <TextError
                  fieldError={errors.emergencyContactPhoneNumber as FieldError}
                />
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const renderEmergencyContactHeading = () => {
  const heading: HeadingStoryblok = {
    _uid: GUID(),
    component: "heading",
    content: "Emergency contact",
    level: HeadingLevels.H3,
  };

  return <Heading blok={heading} />;
};
