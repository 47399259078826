import { ReactElement } from "react";
import toast, { CheckmarkIcon, ErrorIcon } from "react-hot-toast";
import { match } from "ts-pattern";
import { motion } from "framer-motion";

import { WarningToastSvg } from "assets/icons/WarningToastSvg";
import { CustomToastType } from "enums";
import { InfoCircleSvg } from "assets/icons/InfoCircleSvg";

import { TooltipCloseSvg } from "assets/icons/TooltipCloseSvg";
import { TooltipIconFallbackSvg } from "assets/icons/TooltipIconFallbackSvg";

type Props = {
  type?: CustomToastType;
  title?: ReactElement | string;
  message?: ReactElement | string;
};

export const CustomToast = ({ type, title, message, ...props }: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 35 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      className="flex gap-3.5 justify-between text-primary"
    >
      <div className="flex items-start justify-center max-w-8 pt-1.5 rounded min-w-[32px]">
        {match(type)
          .with(CustomToastType.INFO, () => <InfoCircleSvg />)
          .with(CustomToastType.SUCCESS, () => <CheckmarkIcon />)
          .with(CustomToastType.WARNING, () => <WarningToastSvg />)
          .with(CustomToastType.ERROR, () => <ErrorIcon />)
          .otherwise(() => (
            <TooltipIconFallbackSvg />
          ))}
      </div>

      <div className="flex-1 font-poppins">
        <h2 className="pr-4 mb-1 font-medium text-17 line-clamp-1">
          {title ?? "Lorem ipsum"}
        </h2>
        <div className="text-sm text-dark-grey line-clamp-4">
          {message || "Message"}
        </div>
      </div>

      <motion.span
        animate="visible"
        className="absolute right-[16px] hover:cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();

          toast.dismiss();
        }}
      >
        <TooltipCloseSvg className="hover:cursor-pointer" />
      </motion.span>
    </motion.div>
  );
};

export const DefaultCustomToast = ({
  type,
  title,
  message,
  ...props
}: Props) => {
  return (
    <div className="flex flex-row items-center justify-start">
      <div className="mr-5">
        {match(type)
          .with(CustomToastType.INFO, () => <InfoCircleSvg />)
          .with(CustomToastType.SUCCESS, () => <CheckmarkIcon />)
          .with(CustomToastType.WARNING, () => <WarningToastSvg />)
          .with(CustomToastType.ERROR, () => <ErrorIcon />)
          .otherwise(() => null)}
      </div>

      <div className="flex flex-col">
        <div className="font-medium text-15">{title || "Title"}</div>
        {message && (
          <div className="mt-1 break-words text-14">{message || "Message"}</div>
        )}
      </div>
    </div>
  );
};
