type Props = {};

export const QuoteSvg = (props: Props) => {
  return (
    <svg
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2135 6.19946C20.5666 4.04313 22.1903 2.42588 24.0846 1.34771C25.5278 0.449236 27.129 0 28.8879 0C30.0606 0 30.6469 0.314465 30.6469 0.943396C30.6469 1.21294 30.3989 1.43756 29.9027 1.61725C27.9183 2.38095 26.3397 3.77358 25.167 5.79515C24.2199 7.4124 23.6786 9.02965 23.5433 10.6469C23.814 10.602 24.2199 10.5795 24.7611 10.5795C29.587 10.5795 32 12.9829 32 17.7898C32 22.5966 29.587 25 24.7611 25C22.3256 25 20.3636 24.1914 18.8753 22.5741C17.2065 20.867 16.3721 18.5759 16.3721 15.7008C16.3721 12.2417 17.3192 9.07457 19.2135 6.19946ZM2.84144 6.19946C4.1945 4.04313 5.81818 2.42588 7.71247 1.34771C9.15574 0.449236 10.7569 0 12.5159 0C13.6885 0 14.2748 0.314465 14.2748 0.943396C14.2748 1.21294 14.0268 1.43756 13.5307 1.61725C11.5462 2.38095 9.96758 3.77358 8.79493 5.79515C7.84778 7.4124 7.30655 9.02965 7.17125 10.6469C7.44186 10.602 7.84778 10.5795 8.38901 10.5795C13.2149 10.5795 15.6279 12.9829 15.6279 17.7898C15.6279 22.5966 13.2149 25 8.38901 25C5.95349 25 3.99154 24.1914 2.50317 22.5741C0.834389 20.867 0 18.5759 0 15.7008C0 12.2417 0.947145 9.07457 2.84144 6.19946Z"
        fill="#CBAAC3"
      />
    </svg>
  );
};
