import { StoryblokComponent } from "@storyblok/react";
import { CheckSvgDataUrl } from "assets/icons/CheckSvg";
import { isEmpty } from "lodash";
import { ReactElement } from "react";
import { HintStoryblok } from "types/component-types-sb";
import { v4 } from "uuid";

type Props = {
  title: string | ReactElement;
  hint?: string;
  isChecked: boolean;

  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FilterCheckbox = ({
  isChecked,
  title,
  hint = "",
  ...props
}: Props) => {
  const id = v4();

  const hintBlok: HintStoryblok = {
    _uid: v4(),
    description: {
      type: "text",
      text: hint,
    },
    theme: "light",
    component: "hint",
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onCheck(e);
  };

  return (
    <div className="flex items-center cursor-pointer me-2">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleChangeCheckbox}
        className="w-6 h-6 rounded-[4px] border border-secondary-darker focus:ring-2 focus:ring-secondary-darker checked:bg-light checked:border-secondary-darker cursor-pointer"
        style={{
          backgroundImage: isChecked ? `url("${CheckSvgDataUrl}")` : "",
          backgroundSize: "15px 15px",
        }}
      />
      <label htmlFor={id} className="text-sm ms-2 text-dark dark:text-neutral">
        {title}
      </label>

      {!isEmpty(hint) && (
        <div className="ml-2">
          <StoryblokComponent blok={hintBlok} />
        </div>
      )}
    </div>
  );
};
