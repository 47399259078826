import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { LoginActionSidebarStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

export const LoginActionSidebar = ({
  blok,
}: Props<LoginActionSidebarStoryblok>) => {
  return (
    <div className="flex flex-col gap-y-6" {...storyblokEditable(blok)}>
      <div className="flex flex-col gap-y-2">
        <div className="font-normal leading-10 text-center text-24 md:text-left text-primary">
          {blok.title}
        </div>
        <div className="w-full">
          {blok.createAccountButtons?.map((createAccountButton) => (
            <StoryblokComponent
              key={createAccountButton._uid}
              blok={createAccountButton}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col w-full p-8 rounded-lg bg-highlight-green gap-y-2">
        <img
          className="w-10"
          src={blok.securityIcon?.filename}
          alt={blok.securityIconAlt}
        />
        <div className="font-normal text-18 text-primary">
          {blok.securityTitle}
        </div>

        <div className="font-normal text-15 text-dark-grey">
          {blok.securityMessage}
        </div>
      </div>
    </div>
  );
};
