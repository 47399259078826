import { useAuth0 } from "@auth0/auth0-react";
import { getBookingRuleType } from "services/booking.service";
import useSWR from "swr";
import { isRoleClinician } from "utils";

export const useSwrBookingRuleType = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const fetcher = async () => {
    if (!isAuthenticated) return Promise.resolve(null);

    const accessToken = await getAccessTokenSilently();

    const { data } = await getBookingRuleType(accessToken);

    return data;
  };

  const queryResult = useSWR(
    ["/client-records/me/appointments:getBookingRuleType", user?.sub],
    fetcher,
    {
      isPaused: () => isRoleClinician(),
      errorRetryCount: 3,
    }
  );

  return queryResult;
};
