import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ScreenBreakpoints } from "enums";
import { useRef } from "react";
import { useEvent, useWindowSize } from "react-use";
import { GpRefferalsJourneyStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { DOT_RADIUS, updateDotPosition } from "utils";

export const GpRefferalsJourney = ({
  blok,
}: Props<GpRefferalsJourneyStoryblok>) => {
  const { width } = useWindowSize();
  const isMobile = width < ScreenBreakpoints.MD;

  const pathRef = useRef<SVGGeometryElement>(null);
  const startPointRef = useRef<SVGCircleElement>(null);
  const dotRef1 = useRef<SVGCircleElement>(null);
  const dotRef2 = useRef<SVGCircleElement>(null);
  const dotRef3 = useRef<SVGCircleElement>(null);
  const dotRef4 = useRef<SVGCircleElement>(null);

  const handleScrollEvent = () => {
    updateDotPosition(
      pathRef.current,
      startPointRef.current,
      dotRef1.current,
      dotRef2.current,
      dotRef3.current,
      dotRef4.current
    );
  };
  useEvent("scroll", handleScrollEvent);

  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex flex-col gap-3 my-8 md:my-16">
        {blok.title?.map((title) => (
          <StoryblokComponent key={title._uid} blok={title} />
        ))}
      </div>
      <div className="flex justify-center">
        {!isMobile && (
          <svg
            width="1268"
            viewBox="0 0 1268 1631"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={pathRef}
              d="M633 0V100 M633 100V100C633 170.692 575.692 228 505 228H328C244.605 228 177 295.605 177 379V379C177 462.395 244.605 530 328 530H932.5C1018.38 530 1088 599.62 1088 685.5V685.5C1088 771.38 1018.38 841 932.5 841H326.5C243.933 841 177 907.933 177 990.5V990.5C177 1073.07 243.933 1140 326.5 1140H955C1028.45 1140 1088 1199.55 1088 1273V1273C1088 1346.45 1028.45 1406 955 1406H817.5C708.976 1406 621 1493.98 621 1602.5V1602.5V1665.14C621 1684.95 637.053 1701 656.856 1701V1701C691.012 1701 705.825 1744.23 678.847 1765.18L634 1800"
              stroke="#CBAAC3"
              strokeWidth="2"
            />
            <circle
              ref={startPointRef}
              cx="633"
              cy="6"
              r={DOT_RADIUS}
              fill="#502334"
            />
            <circle
              ref={dotRef1}
              cx="633"
              cy="6"
              r={DOT_RADIUS}
              fill="#502334"
            />
            <circle
              ref={dotRef2}
              cx="633"
              cy="6"
              r={DOT_RADIUS}
              fill="#502334"
              opacity="0.85"
            />
            <circle
              ref={dotRef3}
              cx="633"
              cy="6"
              r={DOT_RADIUS}
              fill="#502334"
              opacity="0.7"
            />
            <circle
              ref={dotRef4}
              cx="633"
              cy="6"
              r={DOT_RADIUS}
              fill="#502334"
              opacity="0.55"
            />

            {blok.items.map((item, index) => (
              <foreignObject
                key={`${index}_${item._uid}`}
                x={item.pointX}
                y={item.pointY}
                width="100%"
                height="100%"
              >
                <StoryblokComponent
                  key={item._uid}
                  blok={item}
                  otherProps={{ index }}
                />
              </foreignObject>
            ))}
          </svg>
        )}

        {isMobile && (
          <div>
            {blok.items.map((item) => (
              <StoryblokComponent
                key={item._uid}
                blok={item}
                otherProps={{ isMobile }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
