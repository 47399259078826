import {
  BadgeStoryblok,
  HeadingStoryblok,
  PsychologistDetailFinancialPaymentStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PsychologistResponse } from "@/services/psychologist.service";
import { BadgeSizes, BadgeTypes } from "enums";
import { getFundingMethodDisplayLabel, isValidFunding } from "utils";
import { v4 as GUID } from "uuid";

type CustomProps = Props<PsychologistDetailFinancialPaymentStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailFinancialPayment = ({
  blok,
  psychologist,
}: CustomProps) => {
  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5"
    >
      <StoryblokComponent blok={headingBlok} />

      <div className="flex flex-wrap items-center justify-start gap-3">
        {psychologist?.helmControl?.fundingMethods
          ?.filter(isValidFunding)
          .map((paymentOption) => {
            const badge: BadgeStoryblok = {
              _uid: GUID(),
              component: "badge",
              name: getFundingMethodDisplayLabel(paymentOption),
              type: BadgeTypes.PLAIN,
              size: BadgeSizes.MEDIUM,
              isUpperCase: false,
            };

            return <StoryblokComponent key={badge._uid} blok={badge} />;
          })}
      </div>
    </div>
  );
};
