import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { match } from "ts-pattern";

import { globalConfig } from "core/config";
import { StatusCode } from "enums";
import { getStoredSignupSessionUser } from "utils/storage.util";
import { customToast } from "utils/toast.util";

// URLs that should not trigger Toast notifications on errors
const silentErrorResponseURLs = ["client-records:checkEmailAlreadyExisted"];

const handleResponseError = (error: AxiosError<any>) => {
  const statusCode = error?.response?.status;

  const isSilentError = silentErrorResponseURLs.some((responseURL) =>
    error?.request?.responseURL?.includes(responseURL)
  );

  if (error.response && statusCode) {
    const errorMessage = error.response?.data?.message || error?.message;

    match(statusCode)
      .with(StatusCode.UNAUTHORIZED, () => {
        customToast.error(errorMessage || "Unauthorized. Token expired.");
      })
      .with(StatusCode.FORBIDDEN, () => {
        customToast.error(
          errorMessage || "Forbidden. You do not have permission to access."
        );
      })
      .with(StatusCode.CONFLICT, () => {
        customToast.error(errorMessage || "Conflict. Please try again later.");
      })
      .with(StatusCode.INTERNAL_SERVER_ERROR, () => {
        if (isSilentError) return;

        customToast.error(
          errorMessage || "Internal server error. Please try again later."
        );
      })
      .otherwise(() => {});
  }

  return Promise.reject(error);
};

const initApiClient = (baseURL: string) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.response.use(
    (response) => response,
    handleResponseError
  );

  return instance;
};

// PUBLIC - NO AUTH
export const HubspotApiClient = initApiClient(globalConfig.HUBSPOT_BASE_URL);
export const NotifyApiClient = initApiClient(
  globalConfig.NOTIFY_SERVICE_BASE_URL
);
export const PatientProfileApiClient = initApiClient(
  globalConfig.PATIENT_PROFILE_BASE_URL
);

// PROTECTED - WITH AUTH
export const ClinicianApiClient = initApiClient(
  globalConfig.CLINICIAN_BASE_URL
);
export const ScheduleApiClient = initApiClient(globalConfig.SCHEDULE_BASE_URL);
export const BillingApiClient = initApiClient(globalConfig.BILLING_BASE_URL);
export const Auth0ApiClient = initApiClient(globalConfig.AUTH0_DOMAIN);

const authRequiredApiClients: AxiosInstance[] = [];

export const injectAuth0InterceptorToken = (
  getAccessTokenSilently: Function
) => {
  authRequiredApiClients?.forEach((apiClient) => {
    apiClient.interceptors.request.use(
      async (config) => {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  });
};

export const getSessionAuthTokenOrDefault = (
  accessToken?: string
): AxiosRequestConfig | undefined => {
  const signedUpToken = getStoredSignupSessionUser()?.authToken;

  const authToken = accessToken || signedUpToken;

  return authToken
    ? {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    : undefined;
};
