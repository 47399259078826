import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect, useState } from "react";
import {
  BookMedicareActionsStoryblok,
  BookMedicareFormStoryblok,
  HintStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";

import { useAuth0 } from "@auth0/auth0-react";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { BookBehalfOfType, FileType } from "enums";
import { SelectionOption } from "models";
import { useProfileStore } from "stores/profile.store";
import { getDefaultBehalfOfOptions, getFormHeadingBlok } from "utils";
import { BookingInformation } from "../BookingInformation";
import { BehalfOfChildForm } from "./BehalfOfChildForm";
import { BehalfOfMySelfForm } from "./BehalfOfMySelfForm";
import { BehalfOfSomeOneForm } from "./BehalfOfSomeOneForm";

export const validFileTypes = [
  FileType.PDF,
  FileType.DOCX,
  FileType.PNG,
  FileType.JPG,
  FileType.DOC,
];

export const MAX_UPLOAD_GP_FILE = 4;
export const MAX_UPLOAD_HEALTH_CARE_PLAN_FILE = 6;
export const MAX_UPLOAD_ANY_DOCUMENT_FILE = 6;

export const getHintFromStoryBlok = (
  hintStoryblok: HintStoryblok[] | undefined
) => (
  <div>
    {hintStoryblok &&
      hintStoryblok.map((hintBlok) => (
        <StoryblokComponent key={hintBlok._uid} blok={hintBlok} />
      ))}
  </div>
);

type CustomBookMedicareFormProps = Props<BookMedicareFormStoryblok> & {
  actionBlok: BookMedicareActionsStoryblok[] | undefined;
};

export const BookMedicareForm = ({
  blok,
  actionBlok,
}: CustomBookMedicareFormProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const profile = useProfileStore((state) => state.profile);

  const [accessToken, setAccessToken] = useState<string>("");
  const [behalfOfType, setBehalfOfType] = useState<BookBehalfOfType>(
    BookBehalfOfType.MY_SELF
  );

  const canAccessForm = isAuthenticated ? accessToken : true;

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  const handleChangeBehalfOf = (selectedOption: SelectionOption) => {
    setBehalfOfType(selectedOption.value as BookBehalfOfType);
  };

  const behalfOfOptions = getDefaultBehalfOfOptions(profile);

  return (
    <div
      className="flex flex-col flex-1 w-full gap-y-5"
      {...storyblokEditable(blok)}
    >
      <StoryblokComponent blok={getFormHeadingBlok(blok.title)} />

      <BookingInformation />

      <SelectionDropdown
        isLoading={false}
        title="I am booking on behalf of:"
        placeHolder={"Select..."}
        hintComponent={getHintFromStoryBlok(blok.behalfOfHints)}
        selectedValues={behalfOfOptions.filter(
          ({ value }) => value === behalfOfType
        )}
        options={behalfOfOptions}
        onChangeSingleSelection={handleChangeBehalfOf}
        isShowTitle
        isShowHint
        isRequired
      />

      {behalfOfType === BookBehalfOfType.MY_SELF && canAccessForm && (
        <BehalfOfMySelfForm
          bookMedicareFormBlok={blok}
          profile={profile}
          accessToken={accessToken}
        />
      )}

      {behalfOfType === BookBehalfOfType.SOMEONE_ELSE_ADULT &&
        canAccessForm && (
          <BehalfOfSomeOneForm
            bookMedicareFormBlok={blok}
            profile={profile}
            accessToken={accessToken}
          />
        )}

      {behalfOfType === BookBehalfOfType.MY_CHILD && canAccessForm && (
        <BehalfOfChildForm
          bookMedicareFormBlok={blok}
          profile={profile}
          accessToken={accessToken}
        />
      )}

      <div className="w-full mt-10">
        {actionBlok?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  );
};
